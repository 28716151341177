import { FilterTableEnum } from "../../../common/enum/filterTable.enum"
import { DepartmentUserInterface } from "../../../common/interfaces/department.interface"

export interface Column {
   id: "fullname" | "email" | "phoneNumber" | "province"
   label: string
   minWidth?: number
   align?: "right"
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
   FilterTableEnum.PHONE_NUMBER,
]

export const columns: readonly Column[] = [
   { id: "fullname", label: "userTable.name", minWidth: 170 },
   { id: "email", label: "userTable.email", minWidth: 170 },
   { id: "phoneNumber", label: "userTable.phoneNumber", minWidth: 170 },
   { id: "province", label: "userTable.province", minWidth: 170 },
]

export function createData(
   id: string,
   fullname: string,
   email: string,
   phoneNumber: string,
   province: string
): DepartmentUserInterface {
   return { id, fullname, email, phoneNumber, province }
}
