import { ChartUserTypeState } from "./chartUserType.slice"
import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectChartUserTypeState = (state: RootState): ChartUserTypeState =>
   state.chartUserType

export const selectRequestingChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.requesting
)

export const selectRequestingExportChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.excelExporting
)

export const selectDataChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.data
)

export const selectErrorChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.error
)

export const selectFilterChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.filter
)

export const selectSearchConditionChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.searchCondition
)

export const selectIsDisableStatistic = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => false
)

export const selectIsDisableExport = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.data.length === 0
)

export const selectDateSyncChartUserType = createSelector(
   selectChartUserTypeState,
   (state: ChartUserTypeState) => state.dateSync
)
