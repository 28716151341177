import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { ManagementWalletPackInterface } from "../../common/interfaces/managementWalletPack.interface"
import { ManagementWalletPackComponent } from "./managementWalletPack/managementWalletPack.component"
import { CircularProgress, Stack, Typography } from "@mui/material"

type ManagementWalletRechargeComponentProp = {
   requesting: boolean
   data: ManagementWalletPackInterface[]
   error: string
}

export const ManagementWalletRechargeComponent = (
   props: ManagementWalletRechargeComponentProp
) => {
   return (
      <Container maxWidth="xl">
         {/* Loading */}
         {props.requesting && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "20vh",
               }}
            >
               <CircularProgress color="secondary" />
            </Stack>
         )}
         {/* Error */}
         {!props.requesting && props.error !== "" && (
            <Typography
               mt={5}
               variant="body1"
               color="error"
               textAlign={"center"}
            >
               {props.error}
            </Typography>
         )}
         {!props.requesting && props.error === "" && (
            <Grid container rowSpacing={5} columnSpacing={5}>
               {props.data.map((pack, index) => (
                  <Grid item xs={12} md={6} xl={3} key={index}>
                     <ManagementWalletPackComponent pack={pack} />
                  </Grid>
               ))}
            </Grid>
         )}
      </Container>
   )
}
