import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetChartSignInByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const getChartSignInByDateAPI = (
   payload: GetChartSignInByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-login-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface ExportChartSignInByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const exportChartSignInByDateAPI = (
   payload: ExportChartSignInByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-login-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface GetChartSignInByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const getChartSignInByAppAPI = (
   payload: GetChartSignInByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-login-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartSignInByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const exportChartSignInByAppAPI = (
   payload: ExportChartSignInByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-login-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface GetChartSignInByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const getChartSignInByProvinceAPI = (
   payload: GetChartSignInByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-login-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartSignInByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const exportChartSignInByProvinceAPI = (
   payload: ExportChartSignInByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-login-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}
