export enum FilterTableEnum {
   FULLNAME = "FULLNAME",
   PROVINCE = "PROVINCE",
   EMAIL = "EMAIL",
   PHONE_NUMBER = "PHONENUMBER",
   CREATE_AT = "CREATEAT",
   NUMBER_OF_TOP_UP = "NUMBEROFTOPUP",
   ACCOUNT_NO = "ACCOUNTNO",
   DEPARTMENT_NAME = "DEPARTMENTNAME",
   USER_TYPE_NAME = "USERTYPENAME",
   RECHARGE_PACKAGE_NAME = "RECHARGEPACKAGENAME",
   COIN = "WALLETAMOUNT",
   USAGE = "WALLETEXPIREDDATE",
   ALMOST_OUT_OF_DATE = "WALLETOUTOFEXPIREDDATE",
   TITLE = "TITLE",
   EMAIL_NAME = "EMAILNAME",
   TEMPLATE_NAME = "EMAILTEMPLATENAME",
   APP_NAME = "APPNAME",
   USER_STATUS = "USERSTATUS",
   NONE = "",
}
