import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetChartUserTypePayloadAPIInterface {
   UserTypeIds: string[]
}

export const getChartUserTypeAPI = (
   payload: GetChartUserTypePayloadAPIInterface
) => {
   return AxiosAuth.post("statistics/user-type", payload).then((response) => ({
      response,
   }))
}

export interface ExportChartUserTypePayloadAPIInterface {
   UserTypeIds: string[]
}

export const exportChartUserTypeAPI = (
   payload: ExportChartUserTypePayloadAPIInterface
) => {
   return AxiosAuth.post("statistics/export-user-type", payload).then(
      (response) => ({
         response,
      })
   )
}

export interface GetChartUserTypeByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
}
