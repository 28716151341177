import { AxiosBase } from "./config/axiosBase.instance"

export interface RegisterPayloadAPIInterface {
   username: string
   provinceId: string
   email: string
   password: string
   fullname: string
   phoneNumber: string
   accountTypeId: string
}

export const registerAPI = (
   token: string,
   payload: RegisterPayloadAPIInterface
) => {
   return AxiosBase.post("user", payload, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }).then((response) => ({ response }))
}

export interface OtpRegisterPayloadAPIInterface {
   phoneNumber: string
   otp: string
}

export const otpRegisterAPI = (
   token: string,
   payload: OtpRegisterPayloadAPIInterface
) => {
   return AxiosBase.post("user/verify-phone-number", payload, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }).then((response) => ({ response }))
}

export interface ResendOtpRegisterPayloadAPIInterface {
   phoneNumber: string
}

export const resendOtpRegisterAPI = (
   token: string,
   payload: ResendOtpRegisterPayloadAPIInterface
) => {
   return AxiosBase.post("user/resend-otp", payload, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }).then((response) => ({ response }))
}
