import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetCreateEmailTemplatePayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getCreateEmailTemplateAPI = (
   payload: GetCreateEmailTemplatePayloadAPIInterface
) => {
   return AxiosAuth.post("template/get-list", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddCreateEmailTemplatePayloadAPIInterface {
   name: string
   content: string
}

export const addCreateEmailTemplateAPI = (
   payload: AddCreateEmailTemplatePayloadAPIInterface
) => {
   return AxiosAuth.post("template", payload).then((response) => ({
      response,
   }))
}

export interface UpdateCreateEmailTemplatePayloadAPIInterface {
   id: string
   name: string
   content: string
}

export const updateCreateEmailTemplateAPI = (
   payload: UpdateCreateEmailTemplatePayloadAPIInterface
) => {
   return AxiosAuth.put("template/" + payload.id, payload).then((response) => ({
      response,
   }))
}

export interface DeleteCreateEmailTemplatePayloadAPIInterface {
   id: string
}

export const deleteCreateEmailTemplateAPI = (
   payload: DeleteCreateEmailTemplatePayloadAPIInterface
) => {
   return AxiosAuth.delete("template/" + payload.id).then((response) => ({
      response,
   }))
}

export interface GetDetailCreateEmailTemplatePayloadAPIInterface {
   id: string
}

export const getDetailCreateEmailTemplateAPI = (
   payload: GetDetailCreateEmailTemplatePayloadAPIInterface
) => {
   return AxiosAuth.get("template/" + payload.id).then((response) => ({
      response,
   }))
}
