import * as React from "react"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import { CircularProgress, Stack, TextField, Typography } from "@mui/material"
import {
   getFeatureAPI,
   GetFeaturePayloadAPIInterface,
} from "../../../api/feature.api"
import { useEffect } from "react"
import { FeatureInterface } from "../../interfaces/feature.interface"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@mui/icons-material/Search"
import Box from "@material-ui/core/Box"

type TransferListComponentProps = {
   leftTitle: string
   leftContext: string
   rightTitle: string
   rightContext: string
   right: FeatureInterface[]
   setRight: (features: FeatureInterface[]) => void
}

function not(a: readonly FeatureInterface[], b: readonly FeatureInterface[]) {
   return a.filter((value) => b.map((e) => e.id).indexOf(value.id) === -1)
}

function intersection(
   a: readonly FeatureInterface[],
   b: readonly FeatureInterface[]
) {
   return a.filter((value) => b.map((e) => e.id).indexOf(value.id) !== -1)
}

export const TransferListSyncComponent = (
   props: TransferListComponentProps
) => {
   // State
   const [checked, setChecked] = React.useState<readonly FeatureInterface[]>([])
   const [left, setLeft] = React.useState<readonly FeatureInterface[]>([])
   const [requesting, setRequesting] = React.useState<boolean>(false)
   const [leftSearchString, setLeftSearchString] = React.useState<string>("")
   const [rightSearchString, setRightSearchString] = React.useState<string>("")

   const leftChecked = intersection(checked, left)
   const rightChecked = intersection(checked, props.right)

   const handleToggle = (value: FeatureInterface) => () => {
      const currentIndex = checked.indexOf(value)
      const newChecked = [...checked]

      if (currentIndex === -1) {
         newChecked.push(value)
      } else {
         newChecked.splice(currentIndex, 1)
      }

      setChecked(newChecked)
   }

   const handleAllRight = () => {
      props.setRight(props.right.concat(left))
      setLeft([])
   }

   const handleCheckedRight = () => {
      props.setRight(props.right.concat(leftChecked))
      setLeft(not(left, leftChecked))
      setChecked(not(checked, leftChecked))
   }

   const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked))
      props.setRight(not(props.right, rightChecked))
      setChecked(not(checked, rightChecked))
   }

   const handleAllLeft = () => {
      setLeft(left.concat(props.right))
      props.setRight([])
   }

   const handleChange =
      (isLeft: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => {
         if (isLeft) {
            setLeftSearchString(event.target.value as string)
         } else {
            setRightSearchString(event.target.value)
         }
      }

   const customList = (
      isLeft: boolean,
      title: string,
      subheader: string,
      items: readonly FeatureInterface[]
   ) => (
      <Card>
         <CardHeader
            sx={{
               width: 300,
               px: 2,
               py: 2,
            }}
            title={<Typography variant="h6">{title}</Typography>}
            subheader={<Typography variant="body1">{subheader}</Typography>}
         />
         <Box
            sx={{
               px: 2,
               pb: 2,
            }}
         >
            <TextField
               fullWidth
               id="input-with-icon-textfield"
               color="primary"
               onChange={handleChange(isLeft)}
               InputProps={{
                  startAdornment: (
                     <InputAdornment position="start">
                        <SearchIcon />
                     </InputAdornment>
                  ),
               }}
               variant="standard"
            />
         </Box>
         <Divider />
         {/* Requesting */}
         {requesting && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "100px",
               }}
            >
               <CircularProgress color="secondary" />
            </Stack>
         )}
         {/* Data */}
         {!requesting && (
            <List
               sx={{
                  width: 300,
                  height: 250,
                  bgcolor: "background.paper",
                  overflow: "auto",
               }}
               dense
               component="div"
               role="list"
            >
               {items.map((value: FeatureInterface) => {
                  const labelId = `transfer-list-all-item-${value.id}-label`

                  return (
                     <ListItem
                        key={value.id}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                     >
                        <ListItemIcon>
                           <Checkbox
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                 "aria-labelledby": labelId,
                              }}
                           />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.name} />
                     </ListItem>
                  )
               })}
               <ListItem />
            </List>
         )}
      </Card>
   )

   useEffect(() => {
      setRequesting(true)
      const params: GetFeaturePayloadAPIInterface = {
         PageNumber: 0,
         PageSize: 1000,
         SearchConditions: [["", ""]],
      }
      getFeatureAPI(params).then((res: any) => {
         const features: FeatureInterface[] = res.response.data.data.map(
            (e: any) => {
               const feature: FeatureInterface = {
                  id: e.id,
                  name: e.name,
               }
               return feature
            }
         )

         setLeft(not(features, props.right))
         setRequesting(false)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <Grid
         container
         mt={3}
         spacing={2}
         justifyContent="center"
         alignItems="center"
      >
         <Grid item>
            {customList(
               true,
               props.leftTitle,
               props.leftContext,
               left.filter((e) =>
                  e.name.toLowerCase().includes(leftSearchString.toLowerCase())
               )
            )}
         </Grid>
         <Grid item>
            <Grid container direction="column" alignItems="center">
               <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
               >
                  ≫
               </Button>
               <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
               >
                  &gt;
               </Button>
               <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
               >
                  &lt;
               </Button>
               <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={props.right.length === 0}
                  aria-label="move all left"
               >
                  ≪
               </Button>
            </Grid>
         </Grid>
         <Grid item>
            {customList(
               false,
               props.rightTitle,
               props.rightContext,
               props.right.filter((e) =>
                  e.name.toLowerCase().includes(rightSearchString.toLowerCase())
               )
            )}
         </Grid>
      </Grid>
   )
}
