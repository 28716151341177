import {
   PagingBody,
   PagingParam,
} from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetDepartmentUserPayloadAPIInterface
   extends PagingParam,
      SearchConditionBody {
   id: string
}

export const getDepartmentUserAPI = (
   payload: GetDepartmentUserPayloadAPIInterface
) => {
   return AxiosAuth.post("department-user/" + payload.id, payload).then(
      (response) => ({ response })
   )
}

export interface AddUserToDepartmentUserAPIInterface {
   userIds: string[]
   departmentId: string
}

export const addUserToDepartmentUserAPI = (
   payload: AddUserToDepartmentUserAPIInterface
) => {
   return AxiosAuth.put("department-user", payload).then((response) => ({
      response,
   }))
}

export interface DeleteUserInDepartmentUserPayloadAPIInterface {
   id: string
}

export const deleteUserInDepartmentUserAPI = (
   payload: DeleteUserInDepartmentUserPayloadAPIInterface
) => {
   return AxiosAuth.delete("department-user/" + payload.id).then(
      (response) => ({
         response,
      })
   )
}

export interface GetDepartmentUserAddingPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getDepartmentUserAddingAPI = (
   payload: GetDepartmentUserAddingPayloadAPIInterface
) => {
   return AxiosAuth.post("user/get-list", payload)
}
