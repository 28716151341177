import axios from "axios"
import { getHeaderAuthorization } from "../../common/helper/auth.helper"

export const AxiosAuth = axios.create({
   baseURL: process.env.REACT_APP_API_ENDPOINT,
})

AxiosAuth.interceptors.request.use(function (config) {
   const tokenData = getHeaderAuthorization()
   if (!tokenData) {
      return
   }

   if (config.headers) {
      config.headers.Authorization = "Bearer " + tokenData.token
      config.headers.TokenExpiredIn = tokenData.expiredIn
   }

   if (new Date(tokenData.expiredIn as string) < new Date()) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = "/session"
      return
   }

   return config
})
