import { QueriesEnum } from "../enum/queries.enum"

/**
 * Gen queries
 * @param queries
 * @returns
 */
export const genQueries = (...queries: QueriesEnum[]): string => {
   if (queries.length === 1 && queries[0] === QueriesEnum.ALL) {
      return ""
   }
   return queries.join(",")
}
