import clsx from "clsx"
import { registerWallpage } from "../../images/images"
import Container from "@mui/material/Container"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { TextLogo } from "../../common/components/TextLogo/textLogo"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import { Footer } from "../../common/components/Footer/footer"
import styles from "./register.module.css"
import commonStyles from "../../common/styles/common.module.css"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import React, { useEffect, useRef, useState } from "react"
import { FormErrorComponent } from "../../common/components/FormError/formError.component"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import LinearProgress from "@mui/material/LinearProgress"
import { ProvinceSelectAsyncComponent } from "../../common/components/ProvinceSelectAsync/provinceSelectAsync.component"
import OtpInput from "react-otp-input"
import {
   FormControlLabel,
   FormHelperText,
   Link,
   Radio,
   RadioGroup,
   Typography,
} from "@mui/material"
import { RegisterInput, registerSchema } from "./register.form"
import {
   adminAccountTypes,
   registerAccountTypes,
} from "../../common/variables/accountType"

type RegisterComponentProps = {
   isRegisterRequesting: boolean
   showOtp: boolean
   registerError: string | null
   onSubmitForm: (values: RegisterInput) => void
   onSubmitOtp: (phoneNumber: string, otp: string, otpTimer: number) => void
   onSignIn: () => void
   onResendOtp: (phoneNumber: string) => void
}

export const RegisterComponent = (props: RegisterComponentProps) => {
   const { t } = useTranslation(namespaces.pages.register)
   const _registerButtonRef = useRef<any>()

   // State
   const [showPassword, setShowPassword] = useState<boolean>(false)
   const [showPasswordConfirmation, setShowPasswordConfirmation] =
      useState<boolean>(false)
   const [showHeplerTextPassword, setShowHeplerTextPassword] =
      useState<boolean>(false)
   const [
      showHeplerTextPasswordConfirmation,
      setShowHeplerTextPasswordConfirmation,
   ] = useState<boolean>(false)
   const [otpValue, setOtpValue] = useState<string>("")
   const [otpTimer, setOtpTimer] = useState<number>(30)

   // Form
   const {
      register,
      formState: { errors, isValid },
      handleSubmit,
      control,
      getValues,
   } = useForm<RegisterInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(registerSchema),
   })

   /**
    * Handle mouse down password
    * @param event
    */
   const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
   }

   /**
    * Handle click show password
    * @param isPasswordConfirmation
    */
   const handleClickShowPassword = (isPasswordConfirmation: boolean) => {
      if (isPasswordConfirmation) {
         setShowPasswordConfirmation(!showPasswordConfirmation)
      } else {
         setShowPassword(!showPassword)
      }
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<RegisterInput> = (
      values: RegisterInput
   ) => {
      props.onSubmitForm(values)
   }

   /**
    * Handle submit otp
    */
   const onSubmitOtp = () => {
      props.onSubmitOtp(getValues("phoneNumber"), otpValue, otpTimer)
   }

   /**
    * Handle resend otp
    */
   const onResendOtp = () => {
      setOtpValue("")
      props.onResendOtp(getValues("phoneNumber"))
      setOtpTimer(30)
   }

   /**
    * Effect enter key
    */
   useEffect(() => {
      const listener = (event: any) => {
         if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault()
            _registerButtonRef.current.click()
         }
      }
      document.addEventListener("keydown", listener)
      return () => {
         document.removeEventListener("keydown", listener)
      }
   }, [])

   useEffect(() => {
      const timerID = setInterval(() => {
         if (props.showOtp) {
            setOtpTimer(otpTimer === 0 ? 0 : otpTimer - 1)
         }
      }, 1000)
      return () => clearInterval(timerID)
   })

   return (
      <React.Fragment>
         {props.isRegisterRequesting && <LinearProgress color="secondary" />}
         <Container maxWidth="xl">
            <Grid2 container>
               <Grid2 xs={12} md={12} xl={8}>
                  <div
                     className={clsx(
                        commonStyles.flexCenterCenter,
                        styles.wallpaper
                     )}
                  >
                     <img src={registerWallpage} alt="Register Wallpaper"></img>
                  </div>
               </Grid2>
               <Grid2 xs={12} md={12} xl={4}>
                  <div
                     className={clsx(
                        commonStyles.flexColumnCenterCenter,
                        styles.form
                     )}
                  >
                     <TextLogo introduce={t("introduce")}></TextLogo>
                     {props.registerError && (
                        <FormErrorComponent
                           error={t(props.registerError)}
                        ></FormErrorComponent>
                     )}
                     {props.showOtp ? (
                        <React.Fragment>
                           <Typography mt={5}>
                              {t("otpCountDown")}
                              <Link
                                 disabled={props.isRegisterRequesting}
                                 onClick={onResendOtp}
                                 component="button"
                                 color="inherit"
                                 variant="body1"
                              >
                                 {t("button.resend")} ({otpTimer}s)
                              </Link>
                           </Typography>
                           <Stack
                              mt={5}
                              direction={"row"}
                              justifyContent={"center"}
                           >
                              <OtpInput
                                 value={otpValue}
                                 onChange={(value: string) =>
                                    setOtpValue(value)
                                 }
                                 numInputs={6}
                                 separator={<span> - </span>}
                                 isInputNum={true}
                                 inputStyle={{
                                    height: "60px",
                                    width: "50px",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                 }}
                              />
                           </Stack>
                           <Stack
                              sx={{
                                 width: "100%",
                              }}
                              mt={5}
                              justifyContent={"center"}
                              gap={2}
                           >
                              <Button
                                 fullWidth
                                 className={styles.shadowButton}
                                 color="secondary"
                                 variant="contained"
                                 onClick={onSubmitOtp}
                                 disabled={
                                    otpValue.length < 6 ||
                                    props.isRegisterRequesting === true
                                 }
                              >
                                 {t("buttons.otpConfirm", { ns: "common" })}
                              </Button>
                           </Stack>
                        </React.Fragment>
                     ) : (
                        <Box
                           component="form"
                           autoComplete="off"
                           noValidate
                           sx={{ width: "100%" }}
                           onSubmit={handleSubmit(onSubmitForm)}
                        >
                           <FormControl fullWidth margin="normal">
                              <Controller
                                 control={control}
                                 name="accountType"
                                 defaultValue={adminAccountTypes[0].id}
                                 render={({ field }) => (
                                    <RadioGroup
                                       {...field}
                                       row
                                       value={field.value}
                                       onChange={(event, value) =>
                                          field.onChange(value)
                                       }
                                    >
                                       {registerAccountTypes.map((e) => (
                                          <FormControlLabel
                                             key={e.id}
                                             value={e.id}
                                             control={<Radio />}
                                             label={e.name}
                                          />
                                       ))}
                                    </RadioGroup>
                                 )}
                              />
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <Controller
                                 control={control}
                                 name="province"
                                 rules={{ required: true }}
                                 render={({
                                    field: { onChange, value },
                                 }: any) => (
                                    <ProvinceSelectAsyncComponent
                                       value={value}
                                       error={!!errors.province}
                                       helperText={
                                          errors.province
                                             ? "Vui lòng chọn Tỉnh thành"
                                             : ""
                                       }
                                       disabled={props.isRegisterRequesting}
                                       onInputChange={(inputValue: any) => {
                                          onChange(inputValue)
                                       }}
                                    />
                                 )}
                              />
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <TextField
                                 required
                                 id="outlined-adornment-name"
                                 inputProps={{ maxLength: 100 }}
                                 label={t("field.fullname", { ns: "common" })}
                                 error={!!errors.name}
                                 helperText={
                                    errors.name ? errors.name.message : ""
                                 }
                                 disabled={props.isRegisterRequesting}
                                 {...register("name")}
                              />
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <TextField
                                 id="outlined-adornment-email"
                                 inputProps={{ maxLength: 100 }}
                                 label={t("field.email", { ns: "common" })}
                                 error={!!errors.email}
                                 helperText={
                                    errors.email ? errors.email.message : ""
                                 }
                                 disabled={props.isRegisterRequesting}
                                 {...register("email")}
                              />
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <TextField
                                 required
                                 id="outlined-adornment-phone"
                                 inputProps={{ maxLength: 10 }}
                                 label={t("field.phoneNumber", {
                                    ns: "common",
                                 })}
                                 error={!!errors.phoneNumber}
                                 helperText={
                                    errors.phoneNumber
                                       ? errors.phoneNumber.message
                                       : ""
                                 }
                                 disabled={props.isRegisterRequesting}
                                 {...register("phoneNumber")}
                              />
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <TextField
                                 required
                                 id="outlined-adornment-password"
                                 label={t("field.password", { ns: "common" })}
                                 type={showPassword ? "text" : "password"}
                                 error={!!errors.password}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={() =>
                                                handleClickShowPassword(false)
                                             }
                                             onMouseDown={
                                                handleMouseDownPassword
                                             }
                                             edge="end"
                                          >
                                             {showPassword ? (
                                                <VisibilityOff />
                                             ) : (
                                                <Visibility />
                                             )}
                                          </IconButton>
                                       </InputAdornment>
                                    ),
                                 }}
                                 helperText={
                                    errors.password
                                       ? errors.password.message
                                       : ""
                                 }
                                 disabled={props.isRegisterRequesting}
                                 onFocus={() => {
                                    setShowHeplerTextPassword(true)
                                 }}
                                 onKeyDown={() => {
                                    setShowHeplerTextPassword(false)
                                 }}
                                 {...register("password")}
                              />
                              {!errors.password && showHeplerTextPassword && (
                                 <FormHelperText>
                                    Mật khẩu có chứa ít nhất 6 ký tự.
                                 </FormHelperText>
                              )}
                           </FormControl>
                           <FormControl fullWidth margin="normal">
                              <TextField
                                 required
                                 id="outlined-adornment-passwordConfirmation"
                                 label={t("field.confirmPassword", {
                                    ns: "common",
                                 })}
                                 type={
                                    showPasswordConfirmation
                                       ? "text"
                                       : "password"
                                 }
                                 error={!!errors.passwordConfirmation}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password confirm visibility"
                                             onClick={() =>
                                                handleClickShowPassword(true)
                                             }
                                             onMouseDown={
                                                handleMouseDownPassword
                                             }
                                             edge="end"
                                          >
                                             {showPasswordConfirmation ? (
                                                <VisibilityOff />
                                             ) : (
                                                <Visibility />
                                             )}
                                          </IconButton>
                                       </InputAdornment>
                                    ),
                                 }}
                                 helperText={
                                    errors.passwordConfirmation
                                       ? errors.passwordConfirmation.message
                                       : ""
                                 }
                                 disabled={props.isRegisterRequesting}
                                 onFocus={() => {
                                    setShowHeplerTextPasswordConfirmation(true)
                                 }}
                                 onKeyDown={() => {
                                    setShowHeplerTextPasswordConfirmation(false)
                                 }}
                                 {...register("passwordConfirmation")}
                              />
                              {!errors.passwordConfirmation &&
                                 showHeplerTextPasswordConfirmation && (
                                    <FormHelperText>
                                       Mật khẩu có chứa ít nhất 6 ký tự.
                                    </FormHelperText>
                                 )}
                           </FormControl>
                           <Stack
                              sx={{
                                 width: "100%",
                              }}
                              flex={"row"}
                              alignItems={"center"}
                              mt={2}
                              gap={2}
                           >
                              <Button
                                 fullWidth
                                 className={styles.shadowButton}
                                 ref={_registerButtonRef}
                                 type="submit"
                                 color="secondary"
                                 variant="contained"
                                 disabled={
                                    !isValid || props.isRegisterRequesting
                                 }
                              >
                                 {t("buttons.signUp", { ns: "common" })}
                              </Button>
                              <Link
                                 sx={{
                                    width: "fit-content",
                                 }}
                                 disabled={props.isRegisterRequesting}
                                 onClick={props.onSignIn}
                                 textAlign="center"
                                 component="button"
                                 color="inherit"
                                 variant="body1"
                              >
                                 {t("button.signIn")}
                              </Link>
                           </Stack>
                           <Divider className={styles.divider} />
                        </Box>
                     )}
                  </div>
               </Grid2>
               <Grid2 xs={12}>
                  <Footer></Footer>
               </Grid2>
            </Grid2>
         </Container>
      </React.Fragment>
   )
}
