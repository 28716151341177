import {
   allowExportChartSignInByApp,
   allowExportChartSignInByProvince,
} from "./../../states/permission/permission.selector"
import { useSelector } from "react-redux"
import {
   allowGetChartSignInByDate,
   allowGetChartSignInByApp,
   allowGetChartSignInByProvince,
   allowExportChartSignInByDate,
} from "../../states/permission/permission.selector"

export const useChartSignInPermission = () => {
   return {
      allowGetChartSignInByDate: useSelector(allowGetChartSignInByDate),
      allowGetChartSignInByApp: useSelector(allowGetChartSignInByApp),
      allowGetChartSignInByProvince: useSelector(allowGetChartSignInByProvince),
      allowExportChartSignInByDate: useSelector(allowExportChartSignInByDate),
      allowExportChartSignInByApp: useSelector(allowExportChartSignInByApp),
      allowExportChartSignInByProvince: useSelector(
         allowExportChartSignInByProvince
      ),
   }
}
