import { useSelector } from "react-redux"
import {
   allowAddApps,
   allowGetKeyApps,
   allowDeleteApps,
   allowUpdateApps,
} from "../../states/permission/permission.selector"

export const useAppsPermission = () => {
   return {
      allowAddApps: useSelector(allowAddApps),
      allowGetKeyApps: useSelector(allowGetKeyApps),
      allowUpdateApps: useSelector(allowUpdateApps),
      allowDeleteApps: useSelector(allowDeleteApps),
   }
}
