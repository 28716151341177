import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import styles from "./footer.module.css"

export const Footer = () => {
   const { t } = useTranslation(namespaces.common)

   return (
      <Typography className={styles.footer} variant="body1">
         {t("copyright")}
      </Typography>
   )
}
