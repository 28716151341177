export const VNPAY_PAYMENT_URL =
   "https://sandbox.vnpayment.vn/paymentv2/vpcpay.html"
export const VNPAY_PAYMENT_COMMAND = "pay"
export const VNPAY_PAYMENT_CURRCODE = "VND"
export const VNPAY_PAYMENT_IPADDR = "127.0.0.1"
export const VNPAY_PAYMENT_LOCALE = "vn"
export const VNPAY_PAYMENT_ORDER_INFO = "Nạp tiền từ hệ thống CenterID"
export const VNPAY_PAYMENT_ORDER_TYPE = "other"
export const VNPAY_PAYMENT_RETURN_URL =
   process.env.REACT_APP_ENDPOINT + "payment"
export const VNPAY_PAYMENT_TMNCODE = process.env
   .REACT_APP_VNPAY_PAYMENT_TMNCODE as string
export const VNPAY_PAYMENT_VERSION = "2.1.0"
export const VNPAY_PAYMENT_SECURE_HASH = process.env
   .REACT_APP_VNPAY_PAYMENT_SECURE_HASH as string
