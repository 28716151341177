import { Fragment } from "react"
import { useChartUserTypePermission } from "./chartUserType.permission"
import { ChartUserTypeFilterComponent } from "./chartUserTypeFilter/chartUserTypeFilter.component"
import { TypeUserSelectInterface } from "../../common/interfaces/typeUser.interface"
import { ChartUserTypeRenderComponent } from "./chartUserTypeRender/chartUserTypeRender.component"

type ChartUserTypeComponentProps = {
   refChart: any
   // Chart
   requestingChart: boolean
   requestingExport: boolean
   dataChart: any[]
   errorChart: string
   searchConditionChart: TypeUserSelectInterface[]
   isDisableStatistic: boolean
   isDisableExport: boolean
   handleStatistic: () => void
   handleChangeSearchCondition: (data: any) => void
   handleChartExport: () => void
}

export const ChartUserTypeComponent = (props: ChartUserTypeComponentProps) => {
   const { allowGetChartUserType, allowExportChartUserType } =
      useChartUserTypePermission()

   return (
      <Fragment>
         <ChartUserTypeFilterComponent
            requesting={props.requestingChart}
            requestingExport={props.requestingExport}
            searchCondition={props.searchConditionChart}
            isDisableStatistic={props.isDisableStatistic}
            isDisableExport={props.isDisableExport}
            allowGetChartUserType={allowGetChartUserType}
            allowExportChartUserType={allowExportChartUserType}
            handleStatistic={props.handleStatistic}
            handleChartExport={props.handleChartExport}
            handleChangeSearchCondition={props.handleChangeSearchCondition}
         />
         <ChartUserTypeRenderComponent
            refChart={props.refChart}
            requesting={props.requestingChart}
            searchCondition={props.searchConditionChart}
            data={props.dataChart}
            error={props.errorChart}
         />
      </Fragment>
   )
}
