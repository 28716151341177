import { call, select } from "redux-saga/effects"
import { put, takeLatest } from "redux-saga/effects"
import {
   finishExportExcelChartUserType,
   getChartUserTypeFail,
   getChartUserTypeSuccess,
   requestChartUserType,
   startExportExcelChartUserType,
   syncUserSuccess,
} from "./chartUserType.slice"
import {
   exportChartUserTypeAPI,
   ExportChartUserTypePayloadAPIInterface,
   getChartUserTypeAPI,
   GetChartUserTypePayloadAPIInterface,
} from "../../api/chartUserType.api"
import {
   exportExcelChartUserTypeAsync,
   getChartUserTypeAsync,
} from "./chartUserType.action"
import { selectSearchConditionChartUserType } from "./chartUserType.selector"
import { PayloadAction } from "@reduxjs/toolkit"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   ColumnsExportExcel,
   ConditionsExportExcel,
} from "../../common/interfaces/exportExcel.interface"
import { exportExcel } from "../../common/helper/exportExcel.helper"
import { i18n } from "../../i18n/i18n"
import { namespaces } from "../../i18n/i18n.constants"
import { getSyncTypeUserAPI } from "../../api/typeUser.api"
import { selectAuthTokenApp } from "../auth/auth.selector"

/**
 * Get chart sign in saga
 * @param action
 */
function* getChartUserTypeSaga(): any {
   try {
      yield put(requestChartUserType())
      const tokenApp = yield select(selectAuthTokenApp)
      const searchCondition = yield select(selectSearchConditionChartUserType)

      const { response, error } = yield call(getSyncTypeUserAPI, tokenApp)
      if (!response) {
         throw error
      }
      if (response.data.value === "Succeeded") {
         yield put(syncUserSuccess(response.data.updatedAt))

         const params: GetChartUserTypePayloadAPIInterface = {
            UserTypeIds: searchCondition.map((e: any) => e.id),
         }

         const { response: responseChart, error: errorChart } = yield call(
            getChartUserTypeAPI,
            params
         )
         if (!responseChart) {
            throw errorChart
         }
         yield put(getChartUserTypeSuccess(responseChart.data.data))
      } else {
         yield put(
            pushNotistack({
               message: i18n.t("notistack.syncUserType", {
                  ns: namespaces.pages.chart,
               }),
               variant: "error",
            })
         )
         yield put(getChartUserTypeFail(""))
      }
   } catch (error: any) {
      yield put(getChartUserTypeFail("error.chart"))
   }
}

/**
 * Export excel chart sign in saga
 * @param action
 */
function* exportExcelChartUserTypeSaga(action: PayloadAction<any>): any {
   try {
      yield put(startExportExcelChartUserType())
      const searchCondition = yield select(selectSearchConditionChartUserType)

      // Excel config
      const workBookName =
         i18n.t("workBookName", {
            ns: namespaces.pages.chart,
         }) + "_UserType"
      const titleSheetData = i18n.t("titleSheetData.userType", {
         ns: namespaces.pages.chart,
      })

      const titleSheetChart = i18n.t("titleSheetChart.userType", {
         ns: namespaces.pages.chart,
      })

      const conditions: ConditionsExportExcel[] = []

      const params: ExportChartUserTypePayloadAPIInterface = {
         UserTypeIds: searchCondition.map((e: any) => e.id),
      }
      const { response, error } = yield call(exportChartUserTypeAPI, params)
      if (!response) {
         throw error
      }

      const columns: ColumnsExportExcel[] = [
         {
            header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
            key: "stt",
         },
         {
            header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
            key: "fullName",
         },
         {
            header: i18n.t("table.phoneNumber", {
               ns: namespaces.pages.chart,
            }),
            key: "phoneNumber",
         },
         {
            header: i18n.t("table.email", {
               ns: namespaces.pages.chart,
            }),
            key: "email",
         },
         {
            header: i18n.t("table.countSignIn", {
               ns: namespaces.pages.chart,
            }),
            key: "numberOfLoginTime",
         },
         {
            header: i18n.t("table.addedAmount", {
               ns: namespaces.pages.chart,
            }),
            key: "topUpAmount",
         },
         {
            header: i18n.t("table.usedAmount", {
               ns: namespaces.pages.chart,
            }),
            key: "useAmount",
         },
         {
            header: i18n.t("table.userTypeName", {
               ns: namespaces.pages.chart,
            }),
            key: "userTypeName",
         },
      ]

      const data = response.data.users.map((e: any, index: number) => ({
         stt: index + 1,
         ...e,
      }))

      const sum =
         "Tổng sô lần đăng nhập: " +
         data.reduce(
            (accumulator: number, currentValue: any) =>
               accumulator + currentValue.numberOfLoginTime,
            0
         ) +
         " - " +
         "Tổng coin nạp: " +
         data.reduce(
            (accumulator: number, currentValue: any) =>
               accumulator + currentValue.topUpAmount,
            0
         ) +
         " - " +
         "Tổng coin sử dụng: " +
         data.reduce(
            (accumulator: number, currentValue: any) =>
               accumulator + currentValue.useAmount,
            0
         )

      yield exportExcel({
         workBookName: workBookName,
         titleSheetData: titleSheetData,
         titleSheetChart: titleSheetChart,
         conditions: conditions,
         columns: columns,
         data: data,
         chartRef: action.payload.refChart,
         sum: sum,
      })

      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportSuccess", {
               ns: namespaces.pages.chart,
            }),
            variant: "success",
         })
      )

      yield put(finishExportExcelChartUserType())
   } catch (error) {
      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportFail", {
               ns: namespaces.pages.chart,
            }),
            variant: "error",
         })
      )
      yield put(finishExportExcelChartUserType())
   }
}

/**
 * Root chart sign in saga
 */
export function* chartUserTypeSaga() {
   yield takeLatest(getChartUserTypeAsync, getChartUserTypeSaga)
   yield takeLatest(exportExcelChartUserTypeAsync, exportExcelChartUserTypeSaga)
}
