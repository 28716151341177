import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import Grid from "@mui/material/Grid"
import { FilterTableWalletEnum } from "../../../common/enum/filterTableWallet.enum"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { IconButton, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { SearchConditionWalletState } from "../../../common/interfaces/searchConditionParam"

type ManagementUserChargeHistoryFilterTableComponentProp = {
   searchCondition: SearchConditionWalletState
   onFilter: (option: FilterTableWalletEnum, from: Dayjs, to: Dayjs) => void
}

export const ManagementUserChargeHistoryFilterTableComponent = (
   props: ManagementUserChargeHistoryFilterTableComponentProp
) => {
   const { t } = useTranslation(namespaces.common)
   const fromRef = useRef<HTMLDivElement>(null)
   const toRef = useRef<HTMLDivElement>(null)
   const [fromInvalid, setFromInvalid] = useState<boolean>(true)
   const [toInvalid, setToInvalid] = useState<boolean>(true)
   const [option, setOption] = useState(props.searchCondition.option)
   const [fromDate, setFromDate] = useState<Dayjs | null>(
      props.searchCondition.from
   )
   const [toDate, setToDate] = useState<Dayjs | null>(props.searchCondition.to)

   useEffect(() => {
      setOption(props.searchCondition.option)
      setFromDate(props.searchCondition.from)
      setToDate(props.searchCondition.to)
   }, [props.searchCondition])

   /**
    * Handle change options
    * @param event
    */
   const handleChangeOption = (event: SelectChangeEvent) => {
      setOption(event.target.value as any)
   }

   /**
    * Handle change datetime from
    * @param newValue
    */
   const handleChangeDatetimeFrom = (newValue: Dayjs | null) => {
      setFromDate(newValue ? newValue : null)
   }

   /**
    * Handle change datetime to
    * @param newValue
    */
   const handleChangeDatetimeTo = (newValue: Dayjs | null) => {
      setToDate(newValue ? newValue : null)
   }

   useEffect(() => {
      const fromInvalidRef = fromRef.current
         ?.getElementsByTagName("input")[0]
         .getAttribute("aria-invalid")
      const toInvalidRef = toRef.current
         ?.getElementsByTagName("input")[0]
         .getAttribute("aria-invalid")
      setFromInvalid(fromInvalidRef === "true")
      setToInvalid(toInvalidRef === "true")
   }, [fromDate, toDate])

   return (
      <Grid
         container
         alignItems={"center"}
         justifyContent="flex-start"
         spacing={2}
      >
         <Grid item xs={12} md={3}>
            <FormControl fullWidth>
               <InputLabel id="demo-simple-select-label">
                  {t("filter.searchType")}
               </InputLabel>
               <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={option}
                  label={t("filter.searchType")}
                  onChange={handleChangeOption}
               >
                  <MenuItem
                     key={FilterTableWalletEnum.ALL}
                     value={FilterTableWalletEnum.ALL}
                  >
                     {t("filter.all")}
                  </MenuItem>
                  <MenuItem
                     key={FilterTableWalletEnum.IN}
                     value={FilterTableWalletEnum.IN}
                  >
                     {t("filter.in")}
                  </MenuItem>
                  <MenuItem
                     key={FilterTableWalletEnum.OUT}
                     value={FilterTableWalletEnum.OUT}
                  >
                     {t("filter.out")}
                  </MenuItem>
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={12} md={4}>
            <FormControl fullWidth>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                     disableFuture
                     label={t("filter.datetimeFrom")}
                     inputFormat={"DD/MM/YYYY"}
                     value={fromDate}
                     onChange={handleChangeDatetimeFrom}
                     ref={fromRef}
                     renderInput={(params) => <TextField {...params} />}
                  />
               </LocalizationProvider>
            </FormControl>
         </Grid>
         <Grid item xs={12} md={4}>
            <FormControl fullWidth>
               <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"de"}
               >
                  <DesktopDatePicker
                     disableFuture
                     label={t("filter.datetimeTo")}
                     inputFormat={"DD/MM/YYYY"}
                     minDate={fromDate ? fromDate : dayjs()}
                     value={toDate}
                     onChange={handleChangeDatetimeTo}
                     ref={toRef}
                     renderInput={(params) => <TextField {...params} />}
                  />
               </LocalizationProvider>
            </FormControl>
         </Grid>
         <Grid item xs={12} md={1}>
            <IconButton
               aria-label="filter"
               disabled={!fromDate || fromInvalid || !toDate || toInvalid}
               onClick={() =>
                  props.onFilter(
                     option,
                     fromDate ? fromDate : dayjs(),
                     toDate ? toDate : dayjs()
                  )
               }
            >
               <SearchIcon />
            </IconButton>
         </Grid>
      </Grid>
   )
}
