import { namespaces } from "./i18n.constants"

export const en = {
   [namespaces.common]: {},
}

export const vn = {
   [namespaces.common]: {
      appName: "Center ID",
      copyright: "centerid@copyright2022",
      field: {
         phoneNumber: "Số điện thoại",
         email: "Email",
         account: "Tài khoản",
         password: "Mật khẩu",
         confirmPassword: "Nhập lại mật khẩu",
         passwordConfirmation: "Nhập lại mật khẩu",
         fullname: "Họ và Tên",
         saveSession: "Duy  trì đăng nhập",
         forgotPassword: "Quên mật khẩu?",
         province: "Tỉnh thành",
         district: "Quận/Huyện",
         ward: "Phường/Xã",
         search: "Tìm kiếm...",
         typeUser: "Phân loại",
         app: "Ứng dụng",
      },
      buttons: {
         signIn: "Đăng nhập",
         signUp: "Đăng ký",
         select: "Chọn",
         cancel: "Hủy",
         statisticize: "Thống kê",
         exportExcel: "Xuất excel",
         back: "Trở về",
         next: "Tiếp theo",
         finish: "Hoàn tất",
         otpConfirm: "Xác thực",
         filter: "Lọc",
         delete: "Xóa",
      },
      sidebar: {
         chart: "Thống kê",
         management: "Quản lý",
         managementUser: "Người dùng",
         managementUserDetail: "Chi tiết",
         managementUserChargeHistory: "Lịch sử giao dịch",
         managementUserInfo: "Chi tiết",
         managementUserHistory: "Nhật ký hoạt động",
         managementWallet: "Ví tiền",
         managementWalletRecharge: "Nạp tiền",
         managementChargeWallet: "User nạp tiền vào ví",
         typeUser: "Phân loại",
         createTypeUser: "Tạo",
         permision: "Phân quyền",
         department: "Phòng ban",
         departmentUser: "Danh sách người dùng",
         email: "Marketing",
         createEmail: "Tạo",
         editEmail: "Cập nhật",
         setting: "Cài đặt",
         recharge: "Gói nạp",
         promotion: "Gói khuyến mãi",
         apps: "Ứng dụng",
      },
      breadcrumbHeader: {
         chart: "Thống kê",
         managementUser: "Quản lý người dùng",
         managementUserDetail: "Chi tiết người dùng",
         managementUserChargeHistory: "Lịch sử giao dịch người dùng",
         managementUserInfo: "Chi tiết thông tin user",
         managementUserHistory: "Nhật ký hoạt động user",
         managementWallet: "Quản lý ví tiền",
         managementWalletRecharge: "Nạp tiền vào ví",
         department: "Quản lý phòng ban",
         departmentUser: "Danh sách người dùng thuộc phòng ban",
         typeUser: "Phân loại",
         createTypeUser: "Tạo phân loại",
         email: "Chiến dịch marketing",
         createEmail: "Tạo chiến dịch marketing",
         editEmail: "Cập nhật chiến dịch marketing",
         setting: "Cài đặt",
         rechargePack: "Quản lý gói nạp",
         promotionPack: "Quản lý gói khuyến mãi",
         apps: "Quản lí ứng dụng",
      },
      filter: {
         searchType: "Tìm kiếm theo",
         searchValue: "Giá trị tìm kiếm",
         chartType: "Thống kê theo",
         chartUserType: "Thống kê theo phân loại",
         day: "Ngày",
         month: "Tháng",
         year: "Năm",
         apps: "Ứng dụng",
         province: "Tỉnh thành",
         datetimeFrom: "Từ",
         datetimeTo: "Đến",
         fullname: "Họ và Tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         departmentName: "Tên phòng ban",
         typeName: "Tên phân loại",
         all: "Tất cả",
         in: "Nạp",
         out: "Rút",
         rechargePackageName: "Tên gói",
         title: "Tiêu đề",
         template: "Tên mẫu",
         emailName: "Tên chiến dịch",
         appsName: "Tên ứng dụng",
      },
      chart: {
         bar: "Bar",
         pie: "Pie",
         sin: "Sin",
         horizontal: "Chiều ngang",
         vertical: "Chiếu dọc",
         note: "* Dữ liệu được thống kê đến ngày ",
      },
      userMenu: {
         setting: "Cài đặt",
         logout: "Đăng xuất",
         verifyEkyc: "Đã xác thực Ekyc",
         notVerifyEkyc: "Chưa xác thực Ekyc",
      },
      divider: {
         userList: "DANH SÁCH NGƯỜI DÙNG",
         chart: "BIỂU ĐỒ",
      },
      table: {
         page: "Trang",
         pageSize: "Số dòng mỗi trang:",
         total: "Tổng",
         record: "dòng",
         empty: "Danh sách trống",
         error: "Xảy ra lỗi khi lấy thông tin",
      },
      dialog: {
         noOption: "Không có dữ liệu",
      },
      error: {
         register: "Đăng ký thất bại! Xảy ra lỗi trong quá trình đăng ký",
         otp: "Xác nhận thất bại! Xảy ra lỗi trong quá trình xác nhận",
         resendOtp: "Gửi mã thất bại! Xảy ra lỗi trong quá trình gửi lại mã",
         signIn: "Đăng nhập thất bại! Xảy ra lỗi trong quá trình đăng nhập",
         chart: "Đã xảy ra lỗi trong quá trình thống kê",
         empty: "Thông tin dữ liệu không có",
      },
      notistack: {
         welcome: "Chào mừng quay trở lại",
      },
   },
   [namespaces.pages.signIn]: {
      introduce: "Chào mừng bạn quay trở lại!",
      forgotPassword: "Quên mật khẩu?",
   },
   [namespaces.pages.register]: {
      introduce: "Đăng ký tài khoản mới!",
      otpCountDown:
         "Mã OTP đã được gửi đến số điện thoại đã được đăng ký. Vui lòng kiểm tra và nhập mã. ",
      button: {
         signIn: "Bạn đã có tài khoản? Đăng nhập",
         resend: "Gửi lại mã",
      },
      notistack: {
         expiredOtp: "Mã OTP hết hiệu lực!",
         registerSuccess: "Đăng kí tài khoản thành công!",
         resendOtpSuccess: "Mã xác thực OTP đã được gửi lại thành công!",
      },
   },
   [namespaces.pages.forgotPassword]: {
      introduce: "Bạn thật sự quên mật khẩu?",
      otpCountDown:
         "Mã OTP đã được gửi đến số điện thoại đã được đăng ký. Vui lòng kiểm tra và nhập mã. ",
      success1: "Mật khẩu mới đã được thay đổi thành công với số điện thoại ",
      success2: "Vui lòng đăng nhập lại.",
      steps: {
         phoneNumber: "Nhập số điện thoại",
         otp: "Nhập OTP",
         newPassword: "Thành công",
      },
      button: {
         resend: "Gửi lại mã",
      },
   },
   [namespaces.pages.chart]: {
      workBookName: "REPORT",
      titleSheetData: {
         signIn: "Dữ liệu Người dùng đăng nhập",
         signUp: "Dữ liệu Người dùng đăng kí",
         usingWallet: "Dữ liệu Coin đã dùng",
         chargeWallet: "Dữ liệu Coin nạp",
         userType: "Dữ liệu Phân loại",
      },
      titleSheetChart: {
         signIn: "Biểu đồ Người dùng đăng nhập",
         signUp: "Biểu đồ Người dùng đăng kí",
         usingWallet: "Biểu đồ Coin đã dùng",
         chargeWallet: "Biểu đồ Coin nạp",
         userType: "Biểu đồ Phân loại",
      },
      table: {
         stt: "STT",
         fullname: "Họ và tên",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         note: "Ghi chú",
         email: "Email",
         countSignIn: "Số lần đăng nhập",
         accountTypeName: "Loại tài khoản",
         registerAt: "Ngày đăng ký",
         usedAmount: "Số coin đã dùng",
         addedAmount: "Số coin đã nạp",
         description: "Ghi chú",
         createAt: "Thời gian giao dịch",
         loginAt: "Thời gian đăng nhập",
         applicationName: "Ứng dụng",
         userTypeName: "Thuộc phòng ban",
      },
      tab: {
         chartSignIn: "Người dùng đăng nhập",
         chartSignUp: "Người dùng đăng ký",
         chartUsingWallet: "Coin đã dùng",
         chartChargeWallet: "Coin nạp",
         chartUserType: "Phân loại",
      },
      notistack: {
         syncUserType: "Hệ thống đang đồng bộ phân loại, hãy thử lại sau!",
         exportSuccess: "Xuất Excel thành công",
         exportFail: "Xuất Excel thất bại",
      },
   },
   [namespaces.pages.managementUser]: {
      userTable: {
         stt: "STT",
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         cmnd: "CMND/CCCD",
         gender: "Giới tính",
         accountTypeName: "Loại tài khoản",
         createAt: "Ngày đăng ký",
         outDateAt: "Hạn dùng",
         wallet: "Số dư",
         note: "Ghi chú",
         status: "Trạng thái",
         password: "Mật khẩu",
         passwordConfirmation: "Nhập lại mật khẩu",
         coinRange: "Coin",
         usageTimeRange: "Hạn dùng",
         almostOutOfDate: "Sắp hết hạn dùng",
         action: "Thực hiện",
      },
      userInfo: {
         title: "Thông tin cơ bản",
         account: "Tài khoản",
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         district: "Quận/Huyện",
         ward: "Phường/Xã",
         wallet: "Số dư trong ví",
         walletDate: "Hạn dùng ví",
      },
      userHistoryTable: {
         invokeAt: "Ngày thực hiện",
         featureName: "Chức năng",
         application: "Application",
         isSuccess: "Trạng thái",
      },
      button: {
         add: "Thêm",
         cancel: "Hủy",
         update: "Cập nhật",
         save: "Lưu",
         delete: "Xóa",
         back: "Trở lại",
         addUser: "Thêm người dùng",
         userInfo: "Chi tiết",
         userHistory: "Nhật ký hoạt động",
         userCharge: "Lịch sử giao dịch",
         exportExcel: "Xuất excel",
      },
      dialog: {
         title: {
            addUser: "Thêm người dùng",
            updateUser: "Cập nhật người dùng",
            detailUser: "Thông tin người dùng",
            deleteUser: "Xóa người dùng",
            historyUser: "Nhật kí hoạt động",
            chargeUser: "Lịch sử giao dịch",
         },
         field: {
            name: "Họ và tên",
            email: "Email",
            phoneNumber: "Số điện thoại",
            province: "Tỉnh thành",
            password: "Mật khẩu",
            passwordConfirmation: "Nhập lại mật khẩu",
            note: "Ghi chú",
            isEmployee: "Nhân viên Onland",
         },
         deleteUserConfirm:
            "Bạn có thực sự muốn xóa user <{{user}}> này không?",
      },
      divider: {
         userCharge: "LỊCH SỬ GIAO DỊCH",
      },
   },
   [namespaces.pages.managementUserDetail]: {
      info: {
         title: "Thông tin cơ bản",
         name: "Họ và Tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         note: "Ghi chú",
         birthday: "Ngày sinh",
         male: "Nam",
         female: "Nữ",
         identityCard: "CMND/CCCD",
         identityCardDate: "Ngày cấp",
         identityCardPlace: "Nơi cấp",
         addressNumber: "Số nhà",
         address: "Địa chỉ",
      },
      button: {
         update: "Cập nhật",
         save: "Lưu",
         back: "Hủy",
      },
      company: {
         title: "Thông tin công ty",
         name: "Tên công ty",
         taxCode: "Mã số thuế",
         address: "Địa chỉ công ty",
         phoneNumber: "Số điện thoại công ty",
         email: "Email công ty",
      },
      wallet: {
         title: "Thông tin ví",
         coin: "Số dư",
         date: "Hạn dùng",
      },
   },
   [namespaces.pages.managementUserHistory]: {
      userHistoryTable: {
         invokeAt: "Ngày thực hiện",
         featureName: "Chức năng",
         application: "Application",
         isSuccess: "Trạng thái",
      },
   },
   [namespaces.pages.managementUserChargeHistory]: {
      walletTable: {
         id: "Mã giao dịch",
         transactionText: "Loại giao dịch",
         amount: "Số coin",
         description: "Ghi chú",
         addDay: "Số ngày được gia hạn",
         createdAt: "Ngày giao dịch",
         wallet: "Số dư trong ví",
         walletDate: "Hạn dùng ví",
      },
      button: {
         recharge: "Nạp",
         update: "Cập nhật",
         cancel: "Hủy",
         rechargeWallet: "Nạp tiến",
         walletInfo: "Thông tin ví tiền",
      },
      card: {
         wallet: "Số dư",
         walletDate: "Hạn dùng",
         user: "Người dùng",
      },
   },
   [namespaces.pages.managementWallet]: {
      walletTable: {
         id: "Mã giao dịch",
         transactionText: "Loại giao dịch",
         amount: "Số coin",
         description: "Ghi chú",
         addDay: "Số ngày được gia hạn",
         createdAt: "Ngày giao dịch",
         wallet: "Số dư trong ví",
         walletDate: "Hạn dùng ví",
      },
      button: {
         recharge: "Nạp",
         update: "Cập nhật",
         cancel: "Hủy",
         rechargeWallet: "Nạp tiến",
         walletInfo: "Thông tin ví tiền",
      },
      card: {
         wallet: "Số dư",
         walletDate: "Hạn dùng",
      },
   },
   [namespaces.pages.managementWalletRecharge]: {
      addDay: "Số ngày gia hạn: ",
      coin: "Số coin: ",
      button: {
         select: "Chọn",
      },
   },
   [namespaces.pages.typeUser]: {
      typeTable: {
         name: "Tên loại",
         color: "Thẻ địa diện",
         numberUser: "Số lượng user",
         createAt: "Ngày tạo",
         action: "Hành động",
         dateSync: "Thời gian đồng bộ gần nhất: ",
      },
      button: {
         add: "Thêm phân loại",
         update: "Cập nhật phân loại",
         delete: "Xóa phân loại",
         listUser: "Danh sách người dùng",
         cancel: "Hủy",
         updateUsersType: "Đồng bộ phân loại",
      },
      notistack: {
         syncSuscess: "Đồng bộ phân loại thành công!",
      },
   },
   [namespaces.pages.typeUserDialog]: {
      field: {
         name: "Tên phân loại",
         isProvince: "Tỉnh thành",
         moneyChargeWallet: "Số Coin nạp vào ví",
         moneyUsingWallet: "Số Coin sử dụng",
         timeSignIn: "Số lần đăng nhập",
         from: "Giá trị từ",
         to: "Giá trị đến",
         color: "Màu đại diện",
      },
      userTable: {
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         moneyChargeWallet: "Số tiền nạp vào ví",
         moneyUsingWallet: "Số tiền sử dụng ví",
         timeSignIn: "Số lần đăng nhập",
      },
      button: {
         add: "Thêm phân loại",
         update: "Cập nhật phân loại",
         delete: "Xóa phân loại",
         cancel: "Hủy",
      },
      dialog: {
         title: {
            add: "Thêm phân loại",
            update: "Cập nhật phân loại",
            delete: "Xóa phân loại",
            detail: "Danh sách người dùng thuộc phân loại: ",
         },
         deleteConfirm: "Bạn thực sự muốn xóa phân loại <{{item}}> này không?",
      },
      card: {
         typeUserInfo: "Thông tin phân loại",
         typeUserCondition: "Thiết lập điều kiện",
         typeUserSetting: "Điều kiện",
      },
   },
   [namespaces.pages.rechargePack]: {
      rechargePackTable: {
         name: "Tên gói",
         createAt: "Ngày tạo",
         amount: "Giá gói",
         isAddDay: "Gia hạn sử dụng",
         coin: "Số coin",
         addDay: "Số ngày gia hạn",
         action: "Hành động",
      },
      button: {
         add: "Thêm gói nạp",
         update: "Cập nhật gói nạp",
         delete: "Xóa gói nạp",
         cancel: "Hủy",
      },
      dialog: {
         add: "Thêm gói nạp",
         update: "Cập nhật gói nạp",
         delete: "Xóa gói nạp",
         deleteRechargePackConfirm:
            "Bạn có thực sự muốn xóa <{{item}}> này không?",
         allUser:
            "Nếu không chọn phân loại nào, gói nạp sẽ được áp dụng cho tất cả người dùng",
      },
   },
   [namespaces.pages.promotionPack]: {
      promotionPackTable: {
         name: "Tên gói",
         createAt: "Ngày tạo",
         percent: "Giá trị khuyến mãi(%)",
         action: "Hành động",
      },
      button: {
         add: "Thêm gói khuyến mãi",
         update: "Cập nhật gói khuyến mãi",
         delete: "Xóa gói khuyến mãi",
         cancel: "Hủy",
      },
      dialog: {
         add: "Thêm gói khuyến mãi",
         update: "Cập nhật gói khuyến mãi",
         delete: "Xóa gói khuyến mãi",
         deletePromotionPackConfirm:
            "Bạn có thực sự muốn xóa <{{item}}> này không?",
         allUser:
            "Nếu không chọn phân loại nào, gói khuyến mãi sẽ được áp dụng cho tất cả người dùng",
      },
   },
   [namespaces.pages.email]: {
      emailTable: {
         name: "Tên chiến dịch",
         createdAt: "Ngày tạo",
         startDate: "Ngày bắt đầu",
         endDate: "Ngày kết thúc",
         time: "Thời gian",
         status: "Trạng thái",
         publishDate: "Ngày áp dụng",
         action: "Hành động",
      },
      button: {
         add: "Thêm chiến dịch",
         update: "Cập nhật chiến dịch",
         delete: "Xóa chiến dịch",
         cancel: "Hủy",
         start: "Khởi động",
         restart: "Khởi động lại",
         pause: "Tạm dừng",
      },
      dialog: {
         delete: "Xóa chiến dịch",
         deleteEmailConfirm:
            "Bạn có thực sự muốn xóa chiến dịch email này không?",
         startEmailConfirm:
            "Bạn có thực sự muốn khởi động chiến dịch email này không?",
         restartEmailConfirm:
            "Bạn có thực sự muốn khởi động lại chiến dịch email này không?",
         pauseEmailConfirm:
            "Bạn có thực sự muốn tạm dừng chiến dịch email này không?",
      },
   },
   [namespaces.pages.createEmail]: {
      createEmailTemplate: {
         name: "Tên mẫu",
         createAt: "Ngày tạo",
         action: "Hành động",
         add: "Thêm mẫu",
         update: "Cập nhật mẫu",
         apply: "Sử dụng mẫu",
         delete: "Xóa mẫu",
         cancel: "Hủy",
      },
      createEmailUserTable: {
         account: "Tài khoản",
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         createAt: "Ngày đăng ký",
         action: "Hành động",
         emtpy: "Danh sách người dùng trống",
      },
      field: {
         name: "Tên mẫu",
         title: "Tên chiến dịch",
         type: "Loại chiến dịch",
         userType: "Phân loại",
         startDate: "Ngày bắt đầu",
         endDate: "Ngày kết thúc",
         time: "Thời gian thực hiện chiến dịch",
         isAll: "Áp dụng cho tất cả người dùng",
      },
      card: {
         template: "Mẫu có sẵn",
         content: "Nội dung",
         type: "Thiết lập phân loại",
         user: "Thiết lập user",
         action: "Hành động",
      },
      button: {
         addTemplate: "Thêm mẫu",
         update: "Cập nhật",
         add: "Thêm chiến dịch",
         cancel: "Hủy",
         edit: "Cập nhật",
         delete: "Xoá",
         sendNow: "Gửi",
      },
      dialog: {
         title: {
            add: "Thêm mẫu",
            delete: "Xóa mẫu",
            userlist: "Danh sách người dùng đã chọn",
            update: "Cập nhật mẫu",
         },
         field: {
            name: "Tên mẫu",
         },
         deleteConfirm: "Bạn có thực sự muốn xóa mẫu này không?",
      },
   },
   [namespaces.pages.department]: {
      departmentTable: {
         name: "Tên phòng ban",
         totalUser: "Số lượng user",
         totalAuthority: "Số lượng quyền",
         createAt: "Ngày tạo",
         tag: "Thẻ đại diện",
         action: "Thực hiện",
         roleList: "Danh sách quyền",
         roleName: "Tên quyền",
      },
      userTable: {
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         action: "Thực hiện",
      },
      departmentUserTable: {},
      button: {
         add: "Thêm",
         cancel: "Hủy",
         update: "Cập nhật",
         delete: "Xóa",
         addDepartment: "Thêm phòng ban",
         updateDepartment: "Cập nhật",
         deleteDepartment: "Xóa",
         addUser: "Thêm người dùng",
         deleteUser: "Xóa user",
         listUser: "Danh sách người dùng",
      },
      dialog: {
         leftTitle: "Quyền",
         leftContext: "Vui lòng chọn quyền để thêm vào phòng ban",
         rightTitle: "Quyền của phòng ban",
         rightContext: "Phòng ban sẽ được thiết lập các quyền sau",
         deleteDepartmentConfirm:
            "Bạn có thực sự muốn xóa phòng ban <{{item}}> này không?",
         noOption: "Không có dữ liệu",
         deleteUserConfirm:
            "Bạn có thực sự muốn user <{{item}}> khỏi phòng ban này không?",
      },
      divider: {
         departmentUser: "THÔNG TIN NGƯỜI DÙNG THUỘC PHÒNG BAN",
      },
   },
   [namespaces.pages.setting]: {
      changePassword: {
         accordion: "Thay đổi mật khẩu",
         password: "Mật khẩu hiện tại",
         newPassword: "Mật khẩu mới",
         confirmNewPassword: "Nhập lại mật khẩu mới",
      },
      userInfo: {
         accordion: "Thông tin cơ bản",
         name: "Họ và tên",
         email: "Email",
         phoneNumber: "Số điện thoại",
         province: "Tỉnh thành",
         district: "Quận/Huyện",
         ward: "Phường/Xã",
         birthday: "Ngày sinh",
         male: "Nam",
         female: "Nữ",
         identityCard: "CMND/CCCD",
         identityCardDate: "Ngày cấp",
         identityCardPlace: "Nơi cấp",
         addressNumber: "Số nhà",
         address: "Địa chỉ",
      },
      company: {
         accordion: "Thông tin công ty",
         name: "Tên công ty",
         taxCode: "Mã số thuế",
         address: "Địa chỉ công ty",
         phoneNumber: "Điện thoại công ty",
         email: "Email công ty",
      },
      ekyc: {
         accordion: "Ekyc",
         verify: "Đã xác thực",
         notVerify: "Chưa xác thực",
         name: "Họ và Tên",
         identityCard: "Chứng minh nhân dân",
         birthday: "Ngày sinh",
         address: "Địa chỉ",
         addressHome: "Địa chỉ nhà",
         ethnic: "Dân tộc",
         religion: "Tôn giáo",
         issuedDAte: "Ngày cấp",
         issuedBy: "Nơi cấp",
         similar: "Đặc điểm nhận dạng",
      },
      configuration: {
         accordion: "Cấu hình",
         failTime: "Số lần đăng nhập thất bại tối đa",
         lockTime: "Thời gian khoá tài khoản (giây)",
         tokenTime: "Thời gian hoạt động của Token (giây)",
         outOfDate: "Số ngày thông báo hết hạn",
      },
      button: {
         update: "Cập nhật",
      },
   },
   [namespaces.pages.apps]: {
      appsTable: {
         name: "Tên ứng dụng",
         status: "Trạng thái",
         createdAt: "Ngày tạo",
         action: "Hành động",
         zaloOaId: "[Zalo] Mã OA",
         zaloUserName: "[Zalo] Tài khoản",
         zaloOtpTemplateId: "[Zalo] Mã mẫu",
         isUseOtpSystem: "Sử dụng hệ thống xác thực OTP của CenterID (SMS)",
      },
      button: {
         add: "Thêm",
         cancel: "Hủy",
         update: "Cập nhật",
         delete: "Xóa",
         getKey: "Tạo mật khẩu mới",
         active: "Tiếp tục",
         deactive: "Tạm dừng",
         addApps: "Thêm ứng dụng",
         updateApps: "Cập nhật ứng dụng",
         activeApps: "Tiếp tục hoạt động ứng dụng",
         deactiveApps: "Tạm dừng hoạt động ứng dụng",
         deleteApps: "Xóa ứng dụng",
         getKeyApps: "Tạo lại mật khẩu",
      },
      dialog: {
         deleteAppsConfirm:
            "Bạn có thực sự muốn xóa ứng dụng <{{item}}> này không?",
         activeAppsConfirm:
            "Bạn có thực sự muốn ứng dụng <{{item}}> này hoạt động trở lại không?",
         deactiveAppsConfirm:
            "Bạn có thực sự muốn ứng dụng <{{item}}> này dừng hoạt động không?",
         getKeyAppsConfirm:
            "Mật khẩu của ứng dụng <{{item}}> sẽ được tạo lại và gửi vào email của hệ thống?",
         noOption: "Không có dữ liệu",
      },
      divider: {
         departmentUser: "THÔNG TIN NGƯỜI DÙNG THUỘC PHÒNG BAN",
      },
   },
   [namespaces.pages.outOfSession]: {
      introduce: "Hết phiên đăng nhập!",
      content:
         "Bạn đã hết phiên đăng nhập, vui lòng đăng nhập lại để tiếp tục nhé.",
   },
   [namespaces.pages.paymentResult]: {
      introduce: "Phiên thanh toán!",
      none: "Không tìm thấy phiên thanh toán, vui lòng trở lại trang chủ!",
      M00: "Giao dịch thành công.",
      M07: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
      M09: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
      M10: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần.",
      M11: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
      M12: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
      M13: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
      M15: "Giao dịch không thành công do: Đã quá hạn thời gian giao dịch. Xin quý khách vui lòng thực hiện lại giao dịch.",
      M24: "Giao dịch không thành công do: Khách hàng hủy giao dịch.",
      M51: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
      M65: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
      M75: "Ngân hàng thanh toán đang bảo trì.",
      M79: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch.",
      M99: "Giao dịch bị lỗi.",
   },
}
