import {
   Box,
   Button,
   Card,
   Checkbox,
   Container,
   FormControl,
   FormControlLabel,
   InputLabel,
   MenuItem,
   Select,
   Stack,
   TextField,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import CardHeader from "@mui/material/CardHeader"
import { CardContent } from "@mui/material"
import Typography from "@mui/material/Typography"
import MouseIcon from "@mui/icons-material/Mouse"
import { useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import ClearIcon from "@mui/icons-material/Clear"
import { CKEditorEventAction, useCKEditor } from "ckeditor4-react"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { TypeUserSelectAsyncComponent } from "../../common/components/TypeUserSelectAsync/typeUserSelectAsync.component"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import LoadingButton from "@mui/lab/LoadingButton"
import { CreateEmailTemplateContainer } from "./createEmailTemplate/createEmailTemplate.container"
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
import { CreateEmailInput, createEmailSchema } from "./createEmail.form"
import { EmailTypeEnum } from "../../common/enum/email.enum"
import {
   LocalizationProvider,
   DesktopDatePicker,
   DesktopTimePicker,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { CreateEmailUserContainer } from "./createEmailUser/createEmailUser.container"
import ForwardIcon from "@mui/icons-material/Forward"

type CreateEmailComponentProps = {
   updateId: string | undefined
   handleCancel: () => void
   handlePushNotistack: (message: string, variant: string) => void
   handleReloadGetEmail: () => void
   handleAddAsync: (
      values: CreateEmailInput,
      content: string,
      users: string[]
   ) => Promise<any>
   handleUpdateAsync: (
      id: string,
      values: CreateEmailInput,
      content: string,
      users: string[]
   ) => Promise<any>
   handleDeleteAsync: (id: string) => Promise<any>
   handleGetDetailAsync: (id: string) => Promise<any>
}

export const CreateEmailComponent = (props: CreateEmailComponentProps) => {
   const { t } = useTranslation(namespaces.pages.createEmail)

   // State
   const [emailTemplate, setEmailTemplate] = useState<string>("")
   const [element, setElement] = useState<HTMLDivElement | null>(null)
   const [requesting, setRequesting] = useState<boolean>(true)
   const [selectedUser, setSelectedUser] = useState<string[]>([])

   // Form
   const {
      formState: { errors },
      handleSubmit,
      control,
      watch,
      setValue,
      reset,
   } = useForm<CreateEmailInput>({
      resolver: zodResolver(createEmailSchema),
   })

   // Editor
   const { editor } = useCKEditor({
      element,
      subscribeTo: ["blur", "change", "focus", "instanceReady"],
      dispatchEvent: ({ type, payload }) => {
         if (type === CKEditorEventAction.instanceReady) {
            handleGetData()
         }
      },
   })

   /**
    * Handle apply email template
    * @param template
    */
   const handleApplyEmailTemplate = (template: string) => {
      setEmailTemplate(template)
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<CreateEmailInput> = (
      values: CreateEmailInput
   ) => {
      setRequesting(true)

      if (
         watch("type") === EmailTypeEnum.USER_REGISTER &&
         watch("type") === EmailTypeEnum.USER_EXTEND
      ) {
         values.userType = []
         values.isAll = true
         setSelectedUser([])
      }

      if (props.updateId) {
         props
            .handleUpdateAsync(
               props.updateId,
               values,
               editor.getData(),
               selectedUser
            )
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack(
                  "Cập nhật chiến dịch thành công!",
                  "success"
               )
               props.handleReloadGetEmail()
               props.handleCancel()
            })
            .catch((err) => {
               setRequesting(false)
               props.handlePushNotistack(err.response.data.detail, "error")
            })
      } else {
         props
            .handleAddAsync(values, editor.getData(), selectedUser)
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack(
                  "Thêm chiến dịch thành công!",
                  "success"
               )
               props.handleReloadGetEmail()
               props.handleCancel()
            })
            .catch((err) => {
               setRequesting(false)
               props.handlePushNotistack(err.response.data.detail, "error")
            })
      }
   }

   const handleGetData = () => {
      if (!props.updateId) {
         return
      }
      setRequesting(true)
      props
         .handleGetDetailAsync(props.updateId)
         .then((res) => {
            setValue("name", res.response.data.name)
            setValue("type", res.response.data.type)
            setValue(
               "startDate",
               res.response.data.startDate
                  ? new Date(res.response.data.startDate)
                  : new Date()
            )
            setValue(
               "endDate",
               res.response.data.endDate
                  ? new Date(res.response.data.endDate)
                  : new Date()
            )
            setValue(
               "time",
               res.response.data.time
                  ? new Date(res.response.data.time)
                  : new Date(2000, 0, 1, 0, 0, 0, 0)
            )
            setValue("isAll", res.response.data.allUser === 0 ? false : true)
            setValue("userType", res.response.data.userTypeConditions)
            setSelectedUser(res.response.data.users.map((x: any) => x.id))
            setEmailTemplate(res.response.data.content)
            setRequesting(false)
         })
         .catch((err) => {
            setRequesting(false)
            props.handlePushNotistack(
               "Lấy thông tin chiến dịch thất bại!",
               "error"
            )
            props.handleCancel()
         })
   }

   useEffect(() => {
      if (editor) {
         editor.setData(emailTemplate)
      }
   }, [emailTemplate, editor, element])

   useEffect(() => {
      if (props.updateId) {
         setRequesting(true)
      } else {
         setRequesting(false)
      }
   }, [props.updateId])

   return (
      <Container maxWidth="xl">
         {/* Template */}
         <Card
            sx={{
               marginTop: "15px",
            }}
         >
            <CardHeader
               avatar={<FormatColorFillIcon></FormatColorFillIcon>}
               title={
                  <Typography variant="body1">{t("card.template")}</Typography>
               }
            ></CardHeader>
            <CardContent>
               <CreateEmailTemplateContainer
                  handleApplyEmailTemplate={handleApplyEmailTemplate}
               />
            </CardContent>
         </Card>
         <Box
            component="form"
            sx={{ width: "100%" }}
            onSubmit={handleSubmit(onSubmitForm)}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
               if (event.code === "Enter" || event.code === "NumpadEnter") {
                  event.preventDefault()
               }
            }}
         >
            {/* Content */}
            <Card
               sx={{
                  marginTop: "15px",
               }}
            >
               <CardHeader
                  avatar={<BorderColorIcon></BorderColorIcon>}
                  title={
                     <Typography variant="body1">
                        {t("card.content")}
                     </Typography>
                  }
               ></CardHeader>
               <CardContent>
                  <Box
                     component="div"
                     sx={{
                        width: "100%",
                        marginBottom: "17px",
                     }}
                  >
                     <FormControl fullWidth margin="normal">
                        <Controller
                           defaultValue=""
                           name="name"
                           control={control}
                           render={({ field: { onChange, value } }) => (
                              <TextField
                                 autoFocus
                                 fullWidth
                                 required
                                 error={!!errors.name}
                                 helperText={
                                    errors.name ? errors.name.message : ""
                                 }
                                 value={value}
                                 onChange={onChange}
                                 id="name"
                                 label={t("field.title")}
                                 disabled={requesting}
                              />
                           )}
                        />
                     </FormControl>
                     <Controller
                        control={control}
                        name="type"
                        rules={{ required: true }}
                        defaultValue={EmailTypeEnum.BIRTHDAY}
                        render={({ field }: any) => (
                           <FormControl fullWidth margin="normal">
                              <InputLabel id="type-simple-select-disabled-label">
                                 {t("field.type")}
                              </InputLabel>
                              <Select
                                 labelId="type-simple-select-label"
                                 id="type-simple-select"
                                 label={t("field.type")}
                                 disabled={requesting}
                                 {...field}
                              >
                                 <MenuItem
                                    key={EmailTypeEnum.BIRTHDAY}
                                    value={EmailTypeEnum.BIRTHDAY}
                                 >
                                    Sinh nhật
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.SENT_NOW}
                                    value={EmailTypeEnum.SENT_NOW}
                                 >
                                    Gửi ngay lập tức
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.TIMER}
                                    value={EmailTypeEnum.TIMER}
                                 >
                                    Tuỳ chọn thời gian
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.USER_REGISTER}
                                    value={EmailTypeEnum.USER_REGISTER}
                                 >
                                    Người dùng đăng kí thành công
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.USER_EXTEND}
                                    value={EmailTypeEnum.USER_EXTEND}
                                 >
                                    Người dùng gia hạn thành công
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.USER_ALMOST_EXPIRED}
                                    value={EmailTypeEnum.USER_ALMOST_EXPIRED}
                                 >
                                    Người dùng gần hết hạn
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.USER_EXPIRED}
                                    value={EmailTypeEnum.USER_EXPIRED}
                                 >
                                    Người dùng đã hết hạn (1 tháng/1 lần)
                                 </MenuItem>
                                 <MenuItem
                                    key={EmailTypeEnum.USER_UNACTIVE}
                                    value={EmailTypeEnum.USER_UNACTIVE}
                                 >
                                    Người dùng đã bị khoá (1 tháng/1 lần)
                                 </MenuItem>
                              </Select>
                           </FormControl>
                        )}
                     />
                     {watch("type") === EmailTypeEnum.TIMER && (
                        <FormControl fullWidth margin="normal">
                           <Controller
                              control={control}
                              name="time"
                              defaultValue={new Date(2000, 0, 1, 0, 0, 0, 0)}
                              render={({ field }) => (
                                 <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                 >
                                    <DesktopTimePicker
                                       minutesStep={30}
                                       label={t("field.time")}
                                       value={field.value}
                                       disabled={requesting}
                                       onChange={(value) => {
                                          if (value === null) {
                                             return field.onChange(null)
                                          }
                                          return field.onChange(
                                             value?.toString() ===
                                                "Invalid Date"
                                                ? value?.toString()
                                                : new Date(value)
                                          )
                                       }}
                                       renderInput={(params) => (
                                          <TextField
                                             {...params}
                                             error={!!errors.time}
                                             helperText={
                                                errors.time
                                                   ? errors.time.message
                                                   : ""
                                             }
                                          />
                                       )}
                                    />
                                 </LocalizationProvider>
                              )}
                           />
                        </FormControl>
                     )}
                     {watch("type") !== EmailTypeEnum.SENT_NOW &&
                        watch("type") !== EmailTypeEnum.USER_REGISTER &&
                        watch("type") !== EmailTypeEnum.USER_EXTEND &&
                        watch("type") !== EmailTypeEnum.USER_ALMOST_EXPIRED &&
                        watch("type") !== EmailTypeEnum.USER_EXPIRED &&
                        watch("type") !== EmailTypeEnum.USER_UNACTIVE && (
                           <FormControl fullWidth margin="normal">
                              <Controller
                                 control={control}
                                 name="startDate"
                                 render={({ field }) => (
                                    <LocalizationProvider
                                       dateAdapter={AdapterDayjs}
                                    >
                                       <DesktopDatePicker
                                          disableFuture
                                          label={t("field.startDate")}
                                          disabled={requesting}
                                          inputFormat="DD/MM/YYYY"
                                          value={field.value}
                                          onChange={(value) => {
                                             if (value === null) {
                                                return field.onChange(null)
                                             }
                                             return field.onChange(
                                                value?.toString() ===
                                                   "Invalid Date"
                                                   ? value?.toString()
                                                   : new Date(value)
                                             )
                                          }}
                                          renderInput={(params) => (
                                             <TextField
                                                {...params}
                                                error={!!errors.startDate}
                                                helperText={
                                                   errors.startDate
                                                      ? errors.startDate.message
                                                      : ""
                                                }
                                             />
                                          )}
                                       />
                                    </LocalizationProvider>
                                 )}
                              />
                           </FormControl>
                        )}
                     {watch("type") !== EmailTypeEnum.SENT_NOW &&
                        watch("type") !== EmailTypeEnum.USER_REGISTER &&
                        watch("type") !== EmailTypeEnum.USER_EXTEND &&
                        watch("type") !== EmailTypeEnum.USER_ALMOST_EXPIRED &&
                        watch("type") !== EmailTypeEnum.USER_EXPIRED &&
                        watch("type") !== EmailTypeEnum.USER_UNACTIVE && (
                           <FormControl fullWidth margin="normal">
                              <Controller
                                 control={control}
                                 name="endDate"
                                 render={({ field }) => (
                                    <LocalizationProvider
                                       dateAdapter={AdapterDayjs}
                                    >
                                       <DesktopDatePicker
                                          label={t("field.endDate")}
                                          inputFormat="DD/MM/YYYY"
                                          value={field.value}
                                          disabled={requesting}
                                          onChange={(value) => {
                                             if (value === null) {
                                                return field.onChange(null)
                                             }
                                             return field.onChange(
                                                value?.toString() ===
                                                   "Invalid Date"
                                                   ? value?.toString()
                                                   : new Date(value)
                                             )
                                          }}
                                          renderInput={(params) => (
                                             <TextField
                                                {...params}
                                                error={!!errors.endDate}
                                                helperText={
                                                   errors.endDate
                                                      ? errors.endDate.message
                                                      : ""
                                                }
                                             />
                                          )}
                                       />
                                    </LocalizationProvider>
                                 )}
                              />
                           </FormControl>
                        )}
                     <Controller
                        control={control}
                        name="isAll"
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                           <FormControlLabel
                              label={t("field.isAll")}
                              disabled={requesting}
                              control={
                                 <Checkbox
                                    checked={value}
                                    onChange={onChange}
                                 />
                              }
                           />
                        )}
                     />
                  </Box>

                  <div ref={setElement}></div>
               </CardContent>
            </Card>
            {/* Type User */}
            {watch("type") !== EmailTypeEnum.USER_REGISTER &&
               watch("type") !== EmailTypeEnum.USER_EXTEND &&
               !watch("isAll") && (
                  <Card
                     sx={{
                        marginTop: "15px",
                     }}
                  >
                     <CardHeader
                        avatar={<LocalOfferIcon></LocalOfferIcon>}
                        title={
                           <Typography variant="body1">
                              {t("card.type")}
                           </Typography>
                        }
                     ></CardHeader>
                     <CardContent>
                        <Controller
                           control={control}
                           name="userType"
                           rules={{ required: true }}
                           render={({ field: { onChange, value } }: any) => (
                              <TypeUserSelectAsyncComponent
                                 variant="outlined"
                                 value={value}
                                 error={!!errors.userType}
                                 helperText={
                                    errors.userType ? "Chưa chọn phân loại" : ""
                                 }
                                 disabled={requesting}
                                 onInputChange={(inputValue: any) => {
                                    onChange(inputValue)
                                 }}
                              />
                           )}
                        />
                     </CardContent>
                  </Card>
               )}
            {/* User */}
            {watch("type") !== EmailTypeEnum.USER_REGISTER &&
               watch("type") !== EmailTypeEnum.USER_EXTEND &&
               !watch("isAll") && (
                  <CreateEmailUserContainer
                     disabled={requesting}
                     selected={selectedUser}
                     setSelected={setSelectedUser}
                  ></CreateEmailUserContainer>
               )}
            {/* Action */}
            <Card
               sx={{
                  marginTop: "15px",
               }}
            >
               <CardHeader
                  avatar={<MouseIcon></MouseIcon>}
                  title={
                     <Typography variant="body1">{t("card.action")}</Typography>
                  }
               ></CardHeader>
               <CardContent>
                  <Stack
                     sx={{
                        width: "100%",
                     }}
                     direction={"row"}
                     justifyContent={"flex-end"}
                     spacing={2}
                  >
                     <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ClearIcon />}
                        disabled={requesting}
                        onClick={() => {
                           reset()
                           setEmailTemplate("")
                           props.handleCancel()
                        }}
                     >
                        {t("button.cancel")}
                     </Button>
                     {!props.updateId && (
                        <LoadingButton
                           type="submit"
                           color="secondary"
                           loading={requesting}
                           loadingPosition="start"
                           startIcon={
                              watch("type") === EmailTypeEnum.SENT_NOW ? (
                                 <ForwardIcon />
                              ) : (
                                 <AddIcon />
                              )
                           }
                           variant="contained"
                        >
                           {watch("type") === EmailTypeEnum.SENT_NOW
                              ? t("button.sendNow")
                              : t("button.add")}
                        </LoadingButton>
                     )}
                     {props.updateId && (
                        <LoadingButton
                           type="submit"
                           color="secondary"
                           loading={requesting}
                           loadingPosition="start"
                           startIcon={<EditIcon />}
                           variant="contained"
                        >
                           {t("button.edit")}
                        </LoadingButton>
                     )}
                  </Stack>
               </CardContent>
            </Card>
         </Box>
      </Container>
   )
}
