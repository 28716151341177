import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { ManagementWalletState } from "./managementWallet.slice"

const selectManagementWalletState = (state: RootState): ManagementWalletState =>
   state.managementWallet

export const selectRequestingManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.requesting
)

export const selectDataManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.data
)

export const selectErrorManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.error
)

export const selectCountManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.rowCount
)

export const selectPageNumberManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.paging.pageNumber
)

export const selectPageSizeManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.paging.pageSize
)

export const selectSearchConditionManagementWallet = createSelector(
   selectManagementWalletState,
   (state: ManagementWalletState) => state.searchCondition
)
