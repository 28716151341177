import { ManagementUserHistoryComponent } from "./managementUserHistory.component"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementUserHistoryAsync,
} from "../../../states/managementUserHistory/managementUserHistory.action"
import {
   selectCountManagementUserHistory,
   selectDataManagementUserHistory,
   selectErrorManagementUserHistory,
   selectPageNumberManagementUserHistory,
   selectPageSizeManagementUserHistory,
   selectRequestingManagementUserHistory,
   selectSearchConditionManagementUserHistory,
   selectSelectedManagementUserHistory,
} from "../../../states/managementUserHistory/managementUserHistory.selector"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { useEffect } from "react"

type ManagementUserHistoryContainerProps = {
   id: string
}

export const ManagementUserHistoryContainer = (
   props: ManagementUserHistoryContainerProps
) => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingManagementUserHistory)
   const error = useSelector(selectErrorManagementUserHistory)
   const data = useSelector(selectDataManagementUserHistory)
   const rowCount = useSelector(selectCountManagementUserHistory)
   const pageNumber = useSelector(selectPageNumberManagementUserHistory)
   const pageSize = useSelector(selectPageSizeManagementUserHistory)
   const selectedId = useSelector(selectSelectedManagementUserHistory)
   const searchCondition = useSelector(
      selectSearchConditionManagementUserHistory
   )

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: any, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   useEffect(() => {
      dispatch(getManagementUserHistoryAsync({ id: props.id }))
   }, [dispatch, props.id])

   return (
      <ManagementUserHistoryComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         error={error}
         data={data}
         selectedId={selectedId}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
      />
   )
}
