export enum FunctionEnum {
   GET_SIGN_IN_CHART_BY_APP = "GET_/statistics/user-login-by-app",
   GET_SIGN_IN_CHART_BY_DATE = "GET_/statistics/user-login-by-date",
   GET_SIGN_IN_CHART_BY_PROVINCE = "GET_/statistics/user-login-by-province",
   EXPORT_SIGN_IN_CHART_BY_APP = "GET_/statistics/export-user-login-by-app",
   EXPORT_SIGN_IN_CHART_BY_DATE = "GET_/statistics/export-user-login-by-date",
   EXPORT_SIGN_IN_CHART_BY_PROVINCE = "GET_/statistics/export-user-login-by-province",
   GET_SIGN_UP_CHART_BY_APP = "GET_/statistics/user-register-by-app",
   GET_SIGN_UP_CHART_BY_DATE = "GET_/statistics/user-register-by-date",
   GET_SIGN_UP_CHART_BY_PROVINCE = "GET_/statistics/user-register-by-province",
   EXPORT_SIGN_UP_CHART_BY_APP = "GET_/statistics/export-user-register-by-app",
   EXPORT_SIGN_UP_CHART_BY_DATE = "GET_/statistics/export-user-register-by-date",
   EXPORT_SIGN_UP_CHART_BY_PROVINCE = "GET_/statistics/export-user-register-by-province",
   GET_USING_WALLET_CHART_BY_APP = "GET_/statistics/user-checkout-by-app",
   GET_USING_WALLET_CHART_BY_DATE = "GET_/statistics/user-checkout-by-date",
   GET_USING_WALLET_CHART_BY_PROVINCE = "GET_/statistics/user-checkout-by-province",
   EXPORT_USING_WALLET_CHART_BY_APP = "GET_/statistics/export-user-checkout-by-app",
   EXPORT_USING_WALLET_CHART_BY_DATE = "GET_/statistics/export-user-checkout-by-date",
   EXPORT_USING_WALLET_CHART_BY_PROVINCE = "GET_/statistics/export-user-checkout-by-province",
   GET_CHARGE_WALLET_CHART_BY_APP = "GET_/statistics/user-recharge-by-app",
   GET_CHARGE_WALLET_CHART_BY_DATE = "GET_/statistics/user-recharge-by-date",
   GET_CHARGE_WALLET_CHART_BY_PROVINCE = "GET_/statistics/user-recharge-by-province",
   EXPORT_CHARGE_WALLET_CHART_BY_APP = "GET_/statistics/export-user-recharge-by-app",
   EXPORT_CHARGE_WALLET_CHART_BY_DATE = "GET_/statistics/export-user-recharge-by-date",
   EXPORT_CHARGE_WALLET_CHART_BY_PROVINCE = "GET_/statistics/export-user-recharge-by-province",
   GET_USER_TYPE_CHART = "POST_/statistics/user-type",
   EXPORT_USER_TYPE_CHART = "POST_/statistics/export-user-type",
   GET_LIST_MANAGEMENT_USER = "POST_/user/get-list",
   ADD_MANAGEMENT_USER = "POST_/user/admin",
   UPDATE_MANAGEMENT_USER = "PUT_/user/admin/",
   DELETE_MANAGEMENT_USER = "DELETE_/user/",
   EXPORT_MANAGEMENT_USER = "POST_/user/get-list/export",
   HISTORY_MANAGEMENT_USER = "GET_/user-history",
   HISTORY_CHARGE_MANAGEMENT_USER = "POST_/transaction/admin",
   GET_COUNT_MANAGEMENT_USER = "GET_/user/get-count",
   GET_MANAGEMENT_USER_DETAIL = "GET_/user/admin/",
   GET_LIST_USER_TYPE = "POST_/user-type-condition",
   GET_DETAIL_USER_TYPE = "POST_/user-type/get-list",
   ADD_USER_TYPE = "POST_/user-type-condition/create",
   UPDATE_USER_TYPE = "PUT_/user-type-condition",
   DELETE_USER_TYPE = "DELETE_/user-type-condition/",
   GET_LIST_RECHARGE_PACK = "POST_/recharge-package/get-list",
   ADD_RECHARGE_PACK = "POST_/recharge-package",
   UPDATE_RECHARGE_PACK = "PUT_/recharge-package/",
   DELETE_RECHARGE_PACK = "DELETE_/recharge-package/",
   GET_RECHARGE_PACK = "GET_/recharge-package/",
   GET_LIST_PROMOTION_PACK = "POST_/promotion-package/get-list",
   ADD_PROMOTION_PACK = "POST_/promotion-package",
   UPDATE_PROMOTION_PACK = "PUT_/recpromotionharge-package/",
   DELETE_PROMOTION_PACK = "DELETE_/promotion-package/",
   GET_PROMOTION_PACK = "GET_/promotion-package/",
   GET_LIST_DEPARTMENT = "POST_/department",
   GET_LIST_MANAGEMENT_WALLET = "POST_/wallet/get-wallet",
   GET_LIST_EMAIL = "POST_/marketing/get-list",
   ADD_EMAIL = "POST_/marketing",
   UPDATE_EMAIL = "PUT_/marketing/",
   DELETE_EMAIL = "DELETE_/marketing/",
   PAUSE_EMAIL = "PUT_/marketing/pause/",
   START_EMAIL = "PUT_/marketing/start/",
   RESTART_EMAIL = "GET_/marketing/restart/",
   GET_LIST_EMAIL_TEMPLATE = "POST_/template/get-list",
   ADD_EMAIL_TEMPLATE = "POST_/template",
   UPDATE_EMAIL_TEMPLATE = "PUT_/template/",
   DELETE_EMAIL_TEMPLATE = "DELETE_/template/",
   GET_LIST_APPS = "POST_/application/get-list",
   ADD_APPS = "POST_/application",
   UPDATE_APPS = "PUT_/application/",
   DELETE_APPS = "DELETE_/application/",
   GET_KEY_APPS = "GET_/application/reset/",
   NONE = "NONE",
   // For dev
   DISABLE = "DISABLE",
}
