import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { UpdateQueriesInterface } from "../common/interfaces/updateQueries.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export const getCountManagementUserAPI = () => {
   return AxiosAuth.get("user/get-count").then((response) => ({
      response,
   }))
}

export interface GetManagementUserPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getManagementUserAPI = (
   payload: GetManagementUserPayloadAPIInterface
) => {
   return AxiosAuth.post("user/get-list", payload).then((response) => ({
      response,
   }))
}

export const getManagementUserExcelAPI = (
   payload: GetManagementUserPayloadAPIInterface
) => {
   return AxiosAuth.post("user/get-list/export", payload).then((response) => ({
      response,
   }))
}

export interface GetManagementUserInfoPayloadAPIInterface {
   id: string
   queries: string
}

export const getManagementUserInfoAPI = (
   payload: GetManagementUserInfoPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "user/admin/" + payload.id + "?queries=" + payload.queries
   ).then((response) => ({ response }))
}

export interface AddManagementUserInfoPayloadAPIInterface {
   username: string
   fullname: string
   phoneNumber: string
   email: string
   password: string
   accountTypeId: string
   provinceId: string
   note?: string
}

export const addManagementUserInfoAPI = (
   payload: AddManagementUserInfoPayloadAPIInterface
) => {
   return AxiosAuth.post("user/admin", payload).then((response) => ({
      response,
   }))
}

export interface UpdateManagementUserInfoPayloadAPIInterface {
   Properties: UpdateQueriesInterface[]
}

export const updateManagementUserInfoAPI = (
   id: string,
   payload: UpdateManagementUserInfoPayloadAPIInterface
) => {
   return AxiosAuth.put("user/admin/" + id, payload).then((response) => ({
      response,
   }))
}

export interface DeleteManagementUserInfoPayloadAPIInterface {
   id: string
}

export const deleteManagementUserInfoAPI = (
   payload: DeleteManagementUserInfoPayloadAPIInterface
) => {
   return AxiosAuth.delete("user/" + payload.id).then((response) => ({
      response,
   }))
}
