import {
   allowExportChartUsingWalletByApp,
   allowExportChartUsingWalletByProvince,
} from "../../states/permission/permission.selector"
import { useSelector } from "react-redux"
import {
   allowGetChartUsingWalletByDate,
   allowGetChartUsingWalletByApp,
   allowGetChartUsingWalletByProvince,
   allowExportChartUsingWalletByDate,
} from "../../states/permission/permission.selector"

export const useChartUsingWalletPermission = () => {
   return {
      allowGetChartUsingWalletByDate: useSelector(
         allowGetChartUsingWalletByDate
      ),
      allowGetChartUsingWalletByApp: useSelector(allowGetChartUsingWalletByApp),
      allowGetChartUsingWalletByProvince: useSelector(
         allowGetChartUsingWalletByProvince
      ),
      allowExportChartUsingWalletByDate: useSelector(
         allowExportChartUsingWalletByDate
      ),
      allowExportChartUsingWalletByApp: useSelector(
         allowExportChartUsingWalletByApp
      ),
      allowExportChartUsingWalletByProvince: useSelector(
         allowExportChartUsingWalletByProvince
      ),
   }
}
