import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetAppsPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getAppAPI = () => {
   const payload = {
      pageNumber: 1,
      pageSize: 10,
      SearchConditions: [["", ""]],
      SortCondition: { column: "", order: "ASC" },
   }
   return AxiosAuth.post("application/get-list", payload)
}
