import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getTypeUserAsync,
   getSyncUserSync,
   triggerSyncUserSync,
} from "../../states/typeUser/typeUser.action"
import {
   selectCountTypeUser,
   selectDataTypeUser,
   selectDateSyncTypeUser,
   selectErrorTypeUser,
   selectPageNumberTypeUser,
   selectPageSizeTypeUser,
   selectRequestingSyncTypeUser,
   selectRequestingTypeUser,
   selectSearchConditionTypeUser,
   selectSortConditionTypeUser,
} from "../../states/typeUser/typeUser.selector"
import { TypeUserComponent } from "./typeUser.component"

export const TypeUserContainer = () => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingTypeUser)
   const requestingSync = useSelector(selectRequestingSyncTypeUser)
   const typeData = useSelector(selectDataTypeUser)
   const rowCount = useSelector(selectCountTypeUser)
   const pageNumber = useSelector(selectPageNumberTypeUser)
   const pageSize = useSelector(selectPageSizeTypeUser)
   const error = useSelector(selectErrorTypeUser)
   const sortCondition = useSelector(selectSortConditionTypeUser)
   const searchCondition = useSelector(selectSearchConditionTypeUser)
   const dateSync = useSelector(selectDateSyncTypeUser)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle sync user
    */
   const handleSyncUser = () => {
      dispatch(triggerSyncUserSync())
   }

   useEffect(() => {
      dispatch(getSyncUserSync())
      dispatch(getTypeUserAsync())
   }, [dispatch])

   return (
      <TypeUserComponent
         requesting={requesting}
         requestingSync={requestingSync}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         typeData={typeData}
         sortCondition={sortCondition}
         searchCondition={searchCondition}
         error={error}
         dateSync={dateSync}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChangeSortCondition={handleChangeSortCondition}
         handleSyncUser={handleSyncUser}
      />
   )
}
