import { array, boolean, number, object, string, TypeOf, z } from "zod"
import { SPACE_REGEX } from "../../../common/variables/regex"

export const defaultFromNumberErr = {
   required_error: "Vui lòng nhập Giá trị từ",
   condition_error: "Giá trị <từ> phải nhỏ hơn giá trị <đến>",
   positive: "Giá trị phải lớn hơn 0",
   int: "Giá trị phải là số nguyên dương",
}

export const defaultToNumberErr = {
   required_error: "Vui lòng nhập Giá trị đến",
   condition_error: "Giá trị <từ> phải nhỏ hơn giá trị <đến>",
   positive: "Giá trị phải lớn hơn 0",
   int: "Giá trị phải là số nguyên dương",
}

const max10Error = "Giá trị phải nhỏ hơn hoặc bằng 10 kí tự"

export const typeUserDialogSchema = object({
   name: string()
      .min(1, "Tên phân loại bắt buộc nhập")
      .regex(new RegExp(SPACE_REGEX), "Tên phân loại bắt buộc nhập"),
   isProvince: boolean().default(false),
   isMoneyChargeWallet: boolean().default(false),
   isMoneyUsingWallet: boolean().default(false),
   isTimeSignIn: boolean().default(false),
   province: array(
      object({
         id: string().min(1),
         name: string().min(1),
      })
   ).optional(),
   fromMoneyChargeWallet: number()
      .int(defaultFromNumberErr.int)
      .lte(9999999999, max10Error)
      .optional(),
   toMoneyChargeWallet: number()
      .int(defaultToNumberErr.int)
      .positive(defaultToNumberErr.positive)
      .lte(9999999999, max10Error)
      .optional(),
   fromMoneyUsingWallet: number()
      .int(defaultFromNumberErr.int)
      .lte(9999999999, max10Error)
      .optional(),
   toMoneyUsingWallet: number()
      .int(defaultToNumberErr.int)
      .positive(defaultToNumberErr.positive)
      .lte(9999999999, max10Error)
      .optional(),
   fromTimeSignIn: number()
      .int(defaultFromNumberErr.int)
      .lte(9999999999, max10Error)
      .optional(),
   toTimeSignIn: number()
      .int(defaultToNumberErr.int)
      .positive(defaultToNumberErr.positive)
      .lte(9999999999, max10Error)
      .optional(),
}).superRefine((data, ctx) => {
   if (
      data.isProvince === false &&
      data.isMoneyChargeWallet === false &&
      data.isMoneyUsingWallet === false &&
      data.isTimeSignIn === false
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         path: ["isProvince"],
      })
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         path: ["isMoneyChargeWallet"],
      })
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         path: ["isMoneyUsingWallet"],
      })
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         path: ["isTimeSignIn"],
      })
   }
   if (data.isProvince && (!data.province || data.province?.length === 0)) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.required_error,
         path: ["province"],
      })
   }
   if (
      data.isMoneyChargeWallet &&
      data.fromMoneyChargeWallet !== 0 &&
      !data.fromMoneyChargeWallet
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.required_error,
         path: ["fromMoneyChargeWallet"],
      })
   }
   if (
      data.isMoneyChargeWallet &&
      data.fromMoneyChargeWallet &&
      data.toMoneyChargeWallet &&
      data.fromMoneyChargeWallet > data.toMoneyChargeWallet
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.condition_error,
         path: ["fromMoneyChargeWallet"],
      })
   }
   if (
      data.isMoneyUsingWallet &&
      data.fromMoneyUsingWallet !== 0 &&
      !data.fromMoneyUsingWallet
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.required_error,
         path: ["fromMoneyUsingWallet"],
      })
   }
   if (
      data.isMoneyUsingWallet &&
      data.fromMoneyUsingWallet &&
      data.toMoneyUsingWallet &&
      data.fromMoneyUsingWallet > data.toMoneyUsingWallet
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.condition_error,
         path: ["fromMoneyUsingWallet"],
      })
   }
   if (data.isTimeSignIn && data.fromTimeSignIn !== 0 && !data.fromTimeSignIn) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.required_error,
         path: ["fromTimeSignIn"],
      })
   }
   if (
      data.isTimeSignIn &&
      data.fromTimeSignIn &&
      data.toTimeSignIn &&
      data.fromTimeSignIn > data.toTimeSignIn
   ) {
      ctx.addIssue({
         code: z.ZodIssueCode.custom,
         message: defaultFromNumberErr.condition_error,
         path: ["fromTimeSignIn"],
      })
   }
})

export type TypeUserDialogInput = TypeOf<typeof typeUserDialogSchema>
