import {
   selectPageNumberCreateEmailUser,
   selectPageSizeCreateEmailUser,
   selectSearchConditionCreateEmailUser,
} from "./createEmailUser.selector"
import {
   getCreateEmailUserAPI,
   GetCreateEmailUserPayloadAPIInterface,
} from "../../api/createEmailUser.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getCreateEmailUserAsync,
} from "./createEmailUser.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   getCreateEmailUserFail,
   getCreateEmailUserSuccess,
   requestCreateEmailUser,
} from "./createEmailUser.slice"

/**
 * Get create email user saga
 * @param action
 */
function* getCreateEmailUserSaga(): any {
   try {
      yield put(requestCreateEmailUser())
      const pageNumber = yield select(selectPageNumberCreateEmailUser)
      const pageSize = yield select(selectPageSizeCreateEmailUser)
      const searchCondition = yield select(selectSearchConditionCreateEmailUser)
      const params: GetCreateEmailUserPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
      }
      const { response, error } = yield call(getCreateEmailUserAPI, params)
      if (!response) {
         throw error
      }
      yield put(getCreateEmailUserSuccess(response))
   } catch (error: any) {
      yield put(getCreateEmailUserFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getCreateEmailUserAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getCreateEmailUserAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getCreateEmailUserAsync())
   } catch (error) {}
}

/**
 * Root create email user saga
 */
export function* createEmailUserSaga() {
   yield takeLatest(getCreateEmailUserAsync, getCreateEmailUserSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
}
