import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { SettingCompanyContainer } from "./settingCompany/settingCompany.container"
import { SettingPasswordContainer } from "./settingPassword/settingPassword.container"
import { SettingUserInfoContainer } from "./settingUserInfo/settingUserInfo.container"
import { SettingEkycContainer } from "./settingEkyc/settingEkyc.container"
import { SettingConfigurationContainer } from "./settingConfiguration/settingConfiguration.container"

type SettingComponentProp = {
   isAccountCompany: boolean
   isEkyc: boolean
   isAdmin: boolean
}

export const SettingComponent = (props: SettingComponentProp) => {
   return (
      <Container maxWidth="xl">
         <Grid container rowSpacing={5} columnSpacing={5}>
            <Grid item xs={12} md={6}>
               <SettingUserInfoContainer />
            </Grid>
            {props.isAccountCompany && (
               <Grid item xs={12} md={6}>
                  <SettingCompanyContainer />
               </Grid>
            )}
            {props.isEkyc && (
               <Grid item xs={12} md={6}>
                  <SettingEkycContainer />
               </Grid>
            )}
            {!props.isAdmin && (
               <Grid item xs={12} md={6}>
                  <SettingPasswordContainer />
               </Grid>
            )}
            {props.isAdmin && (
               <Grid item xs={12} md={6}>
                  <SettingConfigurationContainer />
               </Grid>
            )}
         </Grid>
      </Container>
   )
}
