import { object, string, TypeOf } from "zod"

export const settingEkycSchema = object({
   name: string(),
   identityCard: string(),
   birthday: string(),
   address: string(),
   addressHome: string(),
   ethnic: string(),
   religion: string(),
   issuedDAte: string(),
   issuedBy: string(),
   similar: string(),
})

export type SettingEkycInput = TypeOf<typeof settingEkycSchema>
