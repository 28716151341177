import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetDepartmentsPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getDepartmentsAPI = (
   payload: GetDepartmentsPayloadAPIInterface
) => {
   return AxiosAuth.post("department", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface GetDepartmentUserDetailPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody {
   id: string
}

export const getDepartmentInfoAPI = (
   payload: GetDepartmentUserDetailPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "department-user/" +
         payload.id +
         "?PageNumber=" +
         payload.pageNumber +
         "&PageSize=" +
         payload.pageSize +
         "&SearchConditions=" +
         JSON.stringify(payload.SearchConditions)
   )
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddDepartmentPayloadAPIInterface {
   name: string
   roleIds: string[]
}

export const addDepartmentAPI = (payload: AddDepartmentPayloadAPIInterface) => {
   return AxiosAuth.post("department/create", payload).then((response) => ({
      response,
   }))
}

export interface UpdateDepartmentPayloadAPIInterface {
   id: string
   name: string
   roleIds: string[]
}

export const updateDepartmentAPI = (
   payload: UpdateDepartmentPayloadAPIInterface
) => {
   return AxiosAuth.put("department/" + payload.id, payload).then(
      (response) => ({ response })
   )
}

export interface DeleteDepartmentPayloadAPIInterface {
   id: string
}

export const deleteDepartmentAPI = (
   payload: DeleteDepartmentPayloadAPIInterface
) => {
   return AxiosAuth.delete("department/" + payload.id).then((response) => ({
      response,
   }))
}
