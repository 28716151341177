import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { CreateEmailUserState } from "./createEmailUser.slice"

const selectCreateEmailUserState = (state: RootState): CreateEmailUserState =>
   state.createEmailUser

export const selectRequestingCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.requesting
)

export const selectDataCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.data
)

export const selectErrorCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.error
)

export const selectCountCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.rowCount
)

export const selectPageNumberCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.paging.pageNumber
)

export const selectPageSizeCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.paging.pageSize
)

export const selectSearchConditionCreateEmailUser = createSelector(
   selectCreateEmailUserState,
   (state: CreateEmailUserState) => state.searchCondition
)
