import { createAction } from "@reduxjs/toolkit"

export const getEmailAsync = createAction("email/getEmailAsync")

export const changePageNumberAsync = createAction<number>(
   "email/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "email/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "email/changeSearchConditionAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "email/changeSortConditionAsync"
)
