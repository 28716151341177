import { RouteInterface } from "../../../variables/route"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Icon from "@mui/material/Icon"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../../i18n/i18n.constants"
import { useLocation, useNavigate } from "react-router-dom"
import styles from "./sidebarItem.module.css"

type SidebarItemComponentPropsType = {
   open: boolean
   config: RouteInterface
}

export const SidebarItemComponent = (props: SidebarItemComponentPropsType) => {
   const { t } = useTranslation(namespaces.common)
   const location = useLocation()
   const navigate = useNavigate()
   // Props
   const { config } = props
   // States
   const [open, setOpen] = useState<boolean>(false)

   /**
    * Handle click for parent item
    */
   const handleClickParent = () => {
      if (config.menu && config.menu.some((e) => e.showOnSidebar)) {
         setOpen(!open)
      } else {
         navigate(config.link as string)
      }
   }

   /**
    * Handle click for child item
    * @param link
    */
   const handleClickChild = (link: string | undefined) => {
      navigate(link as string)
   }

   useEffect(() => {
      if (config.menu) {
         const isActiveChild = config.menu.filter(
            (e) => e.link === location.pathname
         )
         if (isActiveChild.length > 0) {
            setOpen(true)
         }
      }
   }, [location.pathname, config.menu])

   return (
      <React.Fragment>
         <ListItemButton
            sx={{
               height: !props.open ? "48px" : "unset",
            }}
            className={location.pathname === config.link ? styles.active : ""}
            onClick={handleClickParent}
         >
            <ListItemIcon
               sx={{
                  minWidth: !props.open ? "unset" : "56px",
               }}
            >
               <Icon sx={{ color: "white" }}>{config.icon}</Icon>
            </ListItemIcon>
            {props.open && (
               <ListItemText sx={{ color: "white" }} primary={t(config.text)} />
            )}
            {props.open &&
               config.menu &&
               config.menu.some((e) => e.showOnSidebar) &&
               (open ? (
                  <ExpandLess sx={{ color: "white" }} />
               ) : (
                  <ExpandMore sx={{ color: "white" }} />
               ))}
         </ListItemButton>
         {config.menu && (
            <Collapse in={open} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>
                  {config.menu
                     .filter((e) => e.showOnSidebar)
                     .map((e, key) => (
                        <ListItemButton
                           className={
                              location.pathname === e.link ? styles.active : ""
                           }
                           key={key}
                           sx={{ pl: 9 }}
                           onClick={() => handleClickChild(e.link)}
                        >
                           {props.open && (
                              <ListItemText
                                 sx={{ color: "white" }}
                                 primary={t(e.text)}
                              />
                           )}
                        </ListItemButton>
                     ))}
               </List>
            </Collapse>
         )}
      </React.Fragment>
   )
}
