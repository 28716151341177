import { ChartUsingWalletState } from "./chartUsingWallet.slice"
import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectChartUsingWalletState = (state: RootState): ChartUsingWalletState =>
   state.chartUsingWallet

export const selectRequestingChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.requesting
)

export const selectRequestingExportChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.excelExporting
)

export const selectDataChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.data
)

export const selectErrorChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.error
)

export const selectFilterChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.filter
)

export const selectSearchConditionChartUsingWallet = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.searchCondition
)

export const selectIsDisableStatistic = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => false
)

export const selectIsDisableExport = createSelector(
   selectChartUsingWalletState,
   (state: ChartUsingWalletState) => state.data.length === 0
)
