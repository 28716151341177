import "./App.css"
import { theme } from "./common/styles/customMUI"
import { Provider } from "react-redux"
import store from "./states/rootStore"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import { SnackbarProvider } from "notistack"
import { checkAuthAsync } from "./states/auth/auth.action"
import { NotistackComponent } from "./common/components/Notistack/notistack.component"
import { PageLoadingComponent } from "./common/components/PageLoading/pageLoading.component"

// I18n
import "./i18n/i18n"

// Router
import { MainRoute } from "./route/main.route"

store.dispatch(checkAuthAsync())

const App = () => {
   return (
      <Provider store={store}>
         <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
               <PageLoadingComponent>
                  <NotistackComponent>
                     <BrowserRouter>
                        <MainRoute></MainRoute>
                     </BrowserRouter>
                  </NotistackComponent>
               </PageLoadingComponent>
            </ThemeProvider>
         </SnackbarProvider>
      </Provider>
   )
}

export default App
