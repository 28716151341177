import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import CardContent from "@mui/material/CardContent"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import TuneIcon from "@mui/icons-material/Tune"
import SettingsIcon from "@mui/icons-material/Settings"
import Box from "@mui/material/Box"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Stack from "@mui/material/Stack"
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormHelperText,
   LinearProgress,
   Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { TypeUserInterface } from "../../../common/interfaces/typeUser.interface"
import { DialogMethodEnum } from "../../../common/enum/dialog.enum"
import {
   TypeUserDialogInput,
   typeUserDialogSchema,
} from "./typeUserDialog.form"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useTypeUserPermission } from "../typeUser.permission"
import { TypeUserDetailContainer } from "../typeUserDetail/typeUserDetail.container"
import { ProvinceSelectAsyncComponent } from "../../../common/components/ProvinceSelectAsync/provinceSelectAsync.component"

type TypeUserDialogComponentProps = {
   openDialog: boolean
   type: DialogMethodEnum
   selected: TypeUserInterface | null
   handleCloseDialog: () => void
   handlePushNotistack: (message: string, variant: string) => void
   handleCancel: () => void
   handleAddTypeUserAsync: (values: TypeUserDialogInput) => Promise<any>
   handleUpdateTypeUserAsync: (values: TypeUserDialogInput) => Promise<any>
   handleDeleteTypeUserAsync: (id: string) => Promise<any>
   handleReloadGetTypeUserAsync: () => void
}

export const TypeUserDialogComponent = (
   props: TypeUserDialogComponentProps
) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
   const { allowUpdateUserType } = useTypeUserPermission()

   const { t } = useTranslation(namespaces.pages.typeUserDialog)

   // State
   const [requesting, setRequesting] = useState<boolean>(false)

   // Switch
   const [switchProvince, setSwitchProvince] = useState(false)
   const [switchMoneyChargeWallet, setSwitchMoneyChargeWallet] = useState(false)
   const [switchMoneyUsingWallet, setSwitchMoneyUsingWallet] = useState(false)
   const [switchTimeSignIn, setSwitchTimeSignIn] = useState(false)

   // Form
   const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      setValue,
      resetField,
      reset,
      clearErrors,
   } = useForm<TypeUserDialogInput>({
      resolver: zodResolver(typeUserDialogSchema),
   })

   /**
    * On delete type user
    */
   const onDeleteTypeUser = () => {
      setRequesting(true)
      props
         .handleDeleteTypeUserAsync(props.selected?.id as string)
         .then((res) => {
            setRequesting(false)
            props.handlePushNotistack("Xóa phân loại thành công!", "success")
            handleCloseDialog()
            props.handleReloadGetTypeUserAsync()
         })
         .catch((err) => {
            setRequesting(false)
            if (err.response.data.status === 500) {
               props.handlePushNotistack(err.response.data.detail, "error")
            } else {
               props.handlePushNotistack("Xóa phân loại thất bại!", "error")
            }
         })
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {props.type === DialogMethodEnum.ADD && t("dialog.title.add")}
            {props.type === DialogMethodEnum.DELETE && t("dialog.title.delete")}
            {props.type === DialogMethodEnum.UPDATE && t("dialog.title.update")}
            {props.type === DialogMethodEnum.DETAIL &&
               props.selected &&
               t("dialog.title.detail") + props.selected?.name}
         </DialogTitle>
      )
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requesting) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {props.type === DialogMethodEnum.ADD && (
               <Button type="submit" disabled={requesting}>
                  {t("button.add")}
               </Button>
            )}
            {props.type === DialogMethodEnum.UPDATE && allowUpdateUserType && (
               <Button type="submit" disabled={requesting}>
                  {t("button.update")}
               </Button>
            )}
            {props.type === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requesting}
                  onClick={onDeleteTypeUser}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requesting}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (props.type === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteConfirm", { item: props.selected?.name })}
               </Typography>
            </DialogContent>
         )
      }

      if (props.type === DialogMethodEnum.DETAIL && props.selected !== null) {
         return (
            <DialogContent>
               <TypeUserDetailContainer typeUser={props.selected} />
            </DialogContent>
         )
      }

      if (
         props.type === DialogMethodEnum.ADD ||
         props.type === DialogMethodEnum.UPDATE
      ) {
         return (
            <DialogContent>
               <Grid container>
                  <Grid item xs={12} xl={4} pb={2}>
                     {/* Basic Information */}
                     <Card
                        sx={{
                           boxShadow: "none",
                           border: "1px solid grey",
                           borderRadius: 1,
                        }}
                     >
                        <CardHeader
                           avatar={<DisplaySettingsIcon></DisplaySettingsIcon>}
                           title={
                              <Typography variant="body1">
                                 {t("card.typeUserInfo")}
                              </Typography>
                           }
                        ></CardHeader>
                        <CardContent>
                           <FormControl fullWidth>
                              <TextField
                                 required
                                 variant="standard"
                                 id="outlined-adornment-name"
                                 label={t("field.name")}
                                 error={!!errors.name}
                                 helperText={
                                    errors.name ? errors.name.message : ""
                                 }
                                 inputProps={{ maxLength: 100 }}
                                 InputProps={{
                                    readOnly: !allowUpdateUserType,
                                 }}
                                 {...register("name")}
                              />
                           </FormControl>
                        </CardContent>
                     </Card>
                     {/* Setting switch */}
                     <Card
                        sx={{
                           marginTop: "15px",
                           boxShadow: "none",
                           border: "1px solid grey",
                           borderRadius: 1,
                        }}
                     >
                        <CardHeader
                           avatar={<TuneIcon></TuneIcon>}
                           title={
                              <Typography variant="body1">
                                 {t("card.typeUserCondition")}
                              </Typography>
                           }
                        ></CardHeader>
                        <CardContent>
                           {errors.isProvince &&
                              errors.isMoneyChargeWallet &&
                              errors.isMoneyUsingWallet &&
                              errors.isTimeSignIn && (
                                 <FormHelperText
                                    sx={{
                                       color: "#d32f2f",
                                    }}
                                 >
                                    * Phải thiết lập ít nhất 1 điều kiện
                                 </FormHelperText>
                              )}
                           <div>
                              <FormControlLabel
                                 sx={{
                                    marginTop: "15px",
                                 }}
                                 control={
                                    <Switch
                                       checked={switchProvince}
                                       onChange={handleChangeSwitchProvince}
                                    />
                                 }
                                 disabled={!allowUpdateUserType}
                                 {...register("isProvince")}
                                 label={t("field.isProvince")}
                              />
                           </div>
                           <div>
                              <FormControlLabel
                                 sx={{
                                    marginTop: "15px",
                                 }}
                                 control={
                                    <Switch
                                       checked={switchMoneyChargeWallet}
                                       onChange={
                                          handleChangeSwitchMoneyChargeWallet
                                       }
                                    />
                                 }
                                 disabled={!allowUpdateUserType}
                                 {...register("isMoneyChargeWallet")}
                                 label={t("field.moneyChargeWallet")}
                              />
                           </div>
                           <div>
                              <FormControlLabel
                                 sx={{
                                    marginTop: "15px",
                                 }}
                                 control={
                                    <Switch
                                       checked={switchMoneyUsingWallet}
                                       onChange={
                                          handleChangeSwitchMoneyUsingWallet
                                       }
                                    />
                                 }
                                 disabled={!allowUpdateUserType}
                                 {...register("isMoneyUsingWallet")}
                                 label={t("field.moneyUsingWallet")}
                              />
                           </div>
                           <div>
                              <FormControlLabel
                                 sx={{
                                    marginTop: "15px",
                                 }}
                                 control={
                                    <Switch
                                       checked={switchTimeSignIn}
                                       onChange={handleChangeSwitchTimeSignIn}
                                    />
                                 }
                                 disabled={!allowUpdateUserType}
                                 {...register("isTimeSignIn")}
                                 label={t("field.timeSignIn")}
                              />
                           </div>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid
                     item
                     sx={{
                        paddingLeft: { xs: "0px", xl: "20px" },
                     }}
                     xs={12}
                     xl={8}
                  >
                     {/* Setting Detail */}
                     <Card
                        sx={{
                           boxShadow: "none",
                           border: "1px solid grey",
                           borderRadius: 1,
                        }}
                     >
                        <CardHeader
                           avatar={<SettingsIcon></SettingsIcon>}
                           title={
                              <Typography variant="body1">
                                 {t("card.typeUserSetting")}
                              </Typography>
                           }
                        ></CardHeader>
                        <CardContent>
                           {/* Province */}
                           <Typography mb={1}>
                              {t("field.isProvince")}
                           </Typography>
                           <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"center"}
                              alignItems="center"
                           >
                              <FormControl fullWidth>
                                 <Controller
                                    control={control}
                                    name="province"
                                    rules={{ required: true }}
                                    render={({
                                       field: { onChange, value },
                                    }: any) => (
                                       <ProvinceSelectAsyncComponent
                                          multiple
                                          value={value ? value : []}
                                          variant="standard"
                                          error={!!errors.province}
                                          helperText={
                                             errors.province
                                                ? "Tỉnh bắt buộc nhập"
                                                : ""
                                          }
                                          required={switchProvince}
                                          disabled={!switchProvince}
                                          InputProps={{
                                             readOnly: !allowUpdateUserType,
                                          }}
                                          onInputChange={(inputValue: any) => {
                                             onChange(inputValue)
                                          }}
                                       />
                                    )}
                                 />
                              </FormControl>
                           </Stack>
                           {/* Money Charge Wallet */}
                           <Typography mt={3} mb={1}>
                              {t("field.moneyChargeWallet")}
                           </Typography>
                           <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"center"}
                              alignItems="center"
                           >
                              <FormControl fullWidth>
                                 <TextField
                                    required={switchMoneyChargeWallet}
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-from"
                                    label={t("field.from")}
                                    error={!!errors.fromMoneyChargeWallet}
                                    helperText={
                                       errors.fromMoneyChargeWallet
                                          ? errors.fromMoneyChargeWallet.message
                                          : ""
                                    }
                                    disabled={!switchMoneyChargeWallet}
                                    inputProps={{
                                       min: 0,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("fromMoneyChargeWallet", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                              <ArrowForwardIcon></ArrowForwardIcon>
                              <FormControl fullWidth>
                                 <TextField
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-to"
                                    label={t("field.to")}
                                    error={!!errors.toMoneyChargeWallet}
                                    helperText={
                                       errors.toMoneyChargeWallet
                                          ? errors.toMoneyChargeWallet.message
                                          : ""
                                    }
                                    disabled={!switchMoneyChargeWallet}
                                    inputProps={{
                                       min: 1,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("toMoneyChargeWallet", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                           </Stack>
                           {/* Money Using Wallet */}
                           <Typography mt={3} mb={1}>
                              {t("field.moneyUsingWallet")}
                           </Typography>
                           <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"center"}
                              alignItems="center"
                           >
                              <FormControl fullWidth>
                                 <TextField
                                    required={switchMoneyUsingWallet}
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-from"
                                    label={t("field.from")}
                                    error={!!errors.fromMoneyUsingWallet}
                                    helperText={
                                       errors.fromMoneyUsingWallet
                                          ? errors.fromMoneyUsingWallet.message
                                          : ""
                                    }
                                    disabled={!switchMoneyUsingWallet}
                                    inputProps={{
                                       min: 0,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("fromMoneyUsingWallet", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                              <ArrowForwardIcon></ArrowForwardIcon>
                              <FormControl fullWidth>
                                 <TextField
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-to"
                                    label={t("field.to")}
                                    error={!!errors.toMoneyUsingWallet}
                                    helperText={
                                       errors.toMoneyUsingWallet
                                          ? errors.toMoneyUsingWallet.message
                                          : ""
                                    }
                                    disabled={!switchMoneyUsingWallet}
                                    inputProps={{
                                       min: 1,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("toMoneyUsingWallet", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                           </Stack>
                           {/* Time Sign In */}
                           <Typography mt={3} mb={1}>
                              {t("field.timeSignIn")}
                           </Typography>
                           <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"center"}
                              alignItems="center"
                           >
                              <FormControl fullWidth>
                                 <TextField
                                    required={switchTimeSignIn}
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-from"
                                    label={t("field.from")}
                                    error={!!errors.fromTimeSignIn}
                                    helperText={
                                       errors.fromTimeSignIn
                                          ? errors.fromTimeSignIn.message
                                          : ""
                                    }
                                    disabled={!switchTimeSignIn}
                                    inputProps={{
                                       min: 0,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("fromTimeSignIn", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                              <ArrowForwardIcon></ArrowForwardIcon>
                              <FormControl fullWidth>
                                 <TextField
                                    type="number"
                                    variant="standard"
                                    id="outlined-adornment-to"
                                    label={t("field.to")}
                                    error={!!errors.toTimeSignIn}
                                    helperText={
                                       errors.toTimeSignIn
                                          ? errors.toTimeSignIn.message
                                          : ""
                                    }
                                    disabled={!switchTimeSignIn}
                                    inputProps={{
                                       min: 1,
                                    }}
                                    InputProps={{
                                       readOnly: !allowUpdateUserType,
                                    }}
                                    onKeyDown={(e) => onKeydownInteger(e)}
                                    {...register("toTimeSignIn", {
                                       setValueAs: (v) =>
                                          v === "" ? undefined : parseFloat(v),
                                    })}
                                 />
                              </FormControl>
                           </Stack>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
            </DialogContent>
         )
      }

      return <DialogContent></DialogContent>
   }

   /**
    * Handle change switch time using wallet
    * @param event
    */
   const handleChangeSwitchProvince = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setSwitchProvince(event.target.checked)
      setValue("isProvince", event.target.checked)
      resetField("province")
      clearErrorSwitch()
   }

   /**
    * Handle change switch money charge wallet
    * @param event
    */
   const handleChangeSwitchMoneyChargeWallet = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setSwitchMoneyChargeWallet(event.target.checked)
      setValue("isMoneyChargeWallet", event.target.checked)
      resetField("fromMoneyChargeWallet")
      resetField("toMoneyChargeWallet")
      clearErrorSwitch()
   }

   /**
    * Handle change switch money using wallet
    * @param event
    */
   const handleChangeSwitchMoneyUsingWallet = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setSwitchMoneyUsingWallet(event.target.checked)
      setValue("isMoneyUsingWallet", event.target.checked)
      resetField("fromMoneyUsingWallet")
      resetField("toMoneyUsingWallet")
      clearErrorSwitch()
   }

   /**
    * Handle change switch time sign in
    * @param event
    */
   const handleChangeSwitchTimeSignIn = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setSwitchTimeSignIn(event.target.checked)
      setValue("isTimeSignIn", event.target.checked)
      resetField("fromTimeSignIn")
      resetField("toTimeSignIn")
      clearErrorSwitch()
   }

   /**
    * Clear error swicth
    */
   const clearErrorSwitch = () => {
      clearErrors("isMoneyChargeWallet")
      clearErrors("isMoneyUsingWallet")
      clearErrors("isTimeSignIn")
      clearErrors("isProvince")
   }
   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<TypeUserDialogInput> = (
      values: TypeUserDialogInput
   ) => {
      setRequesting(true)
      if (props.type === DialogMethodEnum.ADD) {
         props
            .handleAddTypeUserAsync(values)
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack(
                  "Thêm phân loại thành công!",
                  "success"
               )
               handleCloseDialog()
               props.handleReloadGetTypeUserAsync()
            })
            .catch((err) => {
               setRequesting(false)
               if (err.response.data.status === 500) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack("Thêm phân loại thất bại!", "error")
               }
            })
      } else if (props.type === DialogMethodEnum.UPDATE) {
         props
            .handleUpdateTypeUserAsync(values)
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack(
                  "Cập nhật phân loại thành công!",
                  "success"
               )
               handleCloseDialog()
               props.handleReloadGetTypeUserAsync()
            })
            .catch((err) => {
               setRequesting(false)
               if (err.response.data?.detail != null) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack(
                     "Cập nhật phân loại thất bại!",
                     "error"
                  )
               }
            })
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setValue("isProvince", false)
      setValue("province", [])
      setSwitchProvince(false)
      setValue("isMoneyChargeWallet", false)
      setSwitchMoneyChargeWallet(false)
      setValue("isMoneyUsingWallet", false)
      setSwitchMoneyUsingWallet(false)
      setValue("isTimeSignIn", false)
      setSwitchTimeSignIn(false)
      reset()
      clearErrors()
      props.handleCloseDialog()
   }

   /**
    * On key down
    * @param e
    */
   const onKeydownInteger = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (
         e.keyCode === 189 ||
         e.keyCode === 109 ||
         e.keyCode === 110 ||
         e.keyCode === 190 ||
         e.keyCode === 188
      ) {
         e.preventDefault()
      }
   }

   useEffect(() => {
      if (props.selected) {
         setValue("province", [])
         setValue("isProvince", false)
         setSwitchProvince(false)
         setValue("isMoneyChargeWallet", false)
         setSwitchMoneyChargeWallet(false)
         setValue("isMoneyUsingWallet", false)
         setSwitchMoneyUsingWallet(false)
         setValue("isTimeSignIn", false)
         if (
            props.selected.province !== undefined &&
            props.selected.province.length > 0
         ) {
            setValue("isProvince", true)
            setSwitchProvince(true)
         }

         if (
            props.selected.topup_min !== undefined &&
            props.selected.topup_max !== undefined
         ) {
            setValue("isMoneyChargeWallet", true)
            setSwitchMoneyChargeWallet(true)
         }

         if (
            props.selected.use_amount_min !== undefined &&
            props.selected.use_amount_max !== undefined
         ) {
            setValue("isMoneyUsingWallet", true)
            setSwitchMoneyUsingWallet(true)
         }

         if (
            props.selected.login_min !== undefined &&
            props.selected.login_max !== undefined
         ) {
            setValue("isTimeSignIn", true)
            setSwitchTimeSignIn(true)
         }

         setValue("name", props.selected.name)
         setValue("province", props.selected.province)
         setValue("fromMoneyChargeWallet", props.selected.topup_min)
         setValue("toMoneyChargeWallet", props.selected.topup_max)
         setValue("fromMoneyUsingWallet", props.selected.use_amount_min)
         setValue("toMoneyUsingWallet", props.selected.use_amount_max)
         setValue("fromTimeSignIn", props.selected.login_min)
         setValue("toTimeSignIn", props.selected.login_max)
      }
   }, [props.selected, setValue])

   useEffect(() => {
      if (props.type === DialogMethodEnum.ADD) {
         setValue("province", [])
      }
   }, [setValue, props.openDialog, props.type])

   return (
      <Dialog
         fullScreen={fullScreen}
         fullWidth={
            props.type === DialogMethodEnum.ADD ||
            props.type === DialogMethodEnum.UPDATE ||
            props.type === DialogMethodEnum.DETAIL
         }
         maxWidth={
            props.type === DialogMethodEnum.ADD ||
            props.type === DialogMethodEnum.UPDATE ||
            props.type === DialogMethodEnum.DETAIL
               ? "xl"
               : "sm"
         }
         open={props.openDialog}
         onClose={handleCloseDialog}
      >
         <Box
            component="form"
            autoComplete="on"
            noValidate
            sx={{ width: "100%" }}
            onSubmit={handleSubmit(onSubmitForm)}
         >
            {renderRequestingDialog()}
            {renderDialogTitle()}
            {renderDialogContent()}
            {renderDialogAction()}
         </Box>
      </Dialog>
   )
}
