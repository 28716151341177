import Excel from "exceljs"
import { ConfigExportExcel } from "../interfaces/exportExcel.interface"
import { saveAs } from "file-saver"
import {
   CHART_SCALE,
   WORK_SHEET_CHART_NAME,
   WORK_SHEET_DATA_NAME,
} from "../variables/exportExcel"
import html2canvas from "html2canvas"
import { getDateForChartTitle, getDateForHeaderExport } from "./date.helper"
import { ChartEnum } from "../enum/chart.enum"

const workbook = new Excel.Workbook()

/**
 * Export excel
 * @param config
 */
export const exportExcel = async (config: ConfigExportExcel) => {
   // Declare
   try {
      const recentTime = new Date()
      const fileName =
         config.workBookName + "_" + getDateForChartTitle(recentTime)
      const exportingTime = getDateForHeaderExport(new Date())
      // -------------------------- Chart --------------------------
      if (config.chartRef) {
         // Creating one worksheet in workbook
         const worksheet2 = workbook.addWorksheet(WORK_SHEET_CHART_NAME)
         // --- Header ---
         worksheet2.mergeCells(1, 1, 1, 20)
         const firstCell = worksheet2.getRow(1).getCell(1)
         firstCell.value = "Đơn vị báo cáo: CÔNG TY TNHH ĐẦU TƯ CAO NGUYÊN TECH"
         firstCell.font = { size: 11 }
         // --- Address ---
         worksheet2.mergeCells(2, 1, 2, 20)
         const secondCell = worksheet2.getRow(2).getCell(1)
         secondCell.value = "Mã số thuế: 6001716365"
         secondCell.font = { size: 11 }
         // --- Code ---
         worksheet2.mergeCells(3, 1, 3, 20)
         const thirdCell = worksheet2.getRow(3).getCell(1)
         thirdCell.value =
            "Địa chỉ: 39 Mai Xuân Thưởng, phường Thành Nhất, Tp Buôn Ma Thuột, tỉnh Đăk Lăk"
         thirdCell.font = { size: 11 }
         // --- Exporting Time ---
         worksheet2.mergeCells(4, 1, 4, 20)
         const fourthCell = worksheet2.getRow(4).getCell(1)
         fourthCell.value = "Thời gian xuất: " + exportingTime
         fourthCell.font = { size: 11 }
         // --- Condition Time ---
         worksheet2.mergeCells(5, 1, 5, 20)
         const fifththCell = worksheet2.getRow(5).getCell(1)
         fifththCell.value = config.conditions.reduce((total, e, index) => {
            return total + " " + e.headline[index] + ": " + e.value
         }, "")
         fifththCell.font = { size: 11 }
         // --- Title ---
         worksheet2.mergeCells(7, 1, 7, 20)
         const titleCell = worksheet2.getRow(7).getCell(1)
         titleCell.value = config.titleSheetChart?.toUpperCase()
         titleCell.font = { size: 18, bold: true }
         titleCell.alignment = { horizontal: "center" }
         // --- Sum ---
         worksheet2.mergeCells(8, 1, 8, 20)
         const sumCell = worksheet2.getRow(8).getCell(1)
         sumCell.value = config.sum

         let startRowChartSheet = 10

         if (config.chartRef.current.length === 0) {
            config.chartRef = Object.entries(config.chartRef).reduce(
               (acc, [key, value]) => {
                  const parsedKey = parseInt(key)
                  if (
                     typeof parsedKey === "number" &&
                     String(value) === "[object HTMLDivElement]"
                  ) {
                     acc.push({ current: value } as never)
                  }
                  return acc
               },
               []
            )
         } else {
            config.chartRef = [config.chartRef]
         }

         for (let index = 0; index < config.chartRef.length; index++) {
            const canvas = await html2canvas(config.chartRef[index].current)
            const base64 = canvas.toDataURL()

            // Add chart into worksheet.
            const imageId2 = workbook.addImage({
               base64: base64,
               extension: "png",
            })

            worksheet2.addImage(imageId2, {
               tl: { col: 0, row: startRowChartSheet },
               ext: {
                  width: canvas.width * CHART_SCALE,
                  height: canvas.height * CHART_SCALE,
               },
            })
            startRowChartSheet = startRowChartSheet + 25
         }
      }

      // -------------------------- Data --------------------------
      // Creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(WORK_SHEET_DATA_NAME)

      // Add worksheet columns
      // Each columns contains header and its mapping key from data
      const emptyColumns = config.columns.map((e) => {
         return {
            header: "",
            key: e.key,
         }
      })
      worksheet.columns = emptyColumns
      // Loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column: any) => {
         column.width = column.header.length + 30
         column.alignment = { wrapText: true }
      })

      // --- Header ---
      worksheet.mergeCells(1, 1, 1, config.columns.length)
      const firstCell = worksheet.getRow(1).getCell(1)
      firstCell.value = "Đơn vị báo cáo: CÔNG TY TNHH ĐẦU TƯ CAO NGUYÊN TECH"
      firstCell.font = { size: 11 }
      // --- Address ---
      worksheet.mergeCells(2, 1, 2, config.columns.length)
      const secondCell = worksheet.getRow(2).getCell(1)
      secondCell.value = "Mã số thuế: 6001716365"
      secondCell.font = { size: 11 }
      // --- Address ---
      worksheet.mergeCells(3, 1, 3, config.columns.length)
      const thirdCell = worksheet.getRow(3).getCell(1)
      thirdCell.value =
         "Địa chỉ: 39 Mai Xuân Thưởng, phường Thành Nhất, Tp Buôn Ma Thuột, tỉnh Đăk Lăk"
      thirdCell.font = { size: 11 }
      // --- Exporting Time ---
      worksheet.mergeCells(4, 1, 4, config.columns.length)
      const fourthCell = worksheet.getRow(4).getCell(1)
      fourthCell.value = "Thời gian xuất: " + exportingTime
      fourthCell.font = { size: 11 }
      // --- Condition Time ---
      worksheet.mergeCells(5, 1, 5, config.columns.length)
      const fifththCell = worksheet.getRow(5).getCell(1)
      fifththCell.value = config.conditions.reduce((total, e, index) => {
         return total + " " + e.headline[index] + ": " + e.value
      }, "")
      fifththCell.font = { size: 11 }
      // --- Title ---
      worksheet.mergeCells(7, 1, 7, config.columns.length)
      const titleCell = worksheet.getRow(7).getCell(1)
      titleCell.value = config.titleSheetData.toUpperCase()
      titleCell.font = { size: 18, bold: true }
      titleCell.alignment = { horizontal: "center" }
      // --- Sum ---
      worksheet.mergeCells(8, 1, 8, 20)
      const sumCell = worksheet.getRow(8).getCell(1)
      sumCell.value = config.sum

      let startRowDataSheet = 10

      // --- Table ---
      if (config.columns.length > 0 && config.data.length > 0) {
         // Add table
         worksheet.addTable({
            name: "Table",
            ref: "A" + startRowDataSheet,
            headerRow: true,
            style: {
               theme: "TableStyleMedium15",
            },
            columns: config.columns.map((e) => {
               return {
                  name: e.header,
                  filterButton: false,
               }
            }),
            rows: config.data.map((e: any) => {
               const filter: any = []
               config.columns.forEach((column) => {
                  filter.push(
                     column.format
                        ? column.format(e[column.key])
                        : e[column.key]
                  )
               })
               return filter
            }),
         })

         // Style table
         for (let rowIndex = 0; rowIndex < config.data.length + 1; rowIndex++) {
            for (
               let colIndex = 1;
               colIndex <= config.columns.length;
               colIndex++
            ) {
               if (rowIndex === 0) {
                  const headerCell = worksheet
                     .getRow(rowIndex + startRowDataSheet)
                     .getCell(colIndex)
                  headerCell.font = { bold: true }
                  headerCell.alignment = { horizontal: "center" }
               }

               const cell = worksheet
                  .getRow(rowIndex + startRowDataSheet)
                  .getCell(colIndex)
               cell.font = { color: { argb: "00000000" } }
               cell.fill = {
                  type: "pattern",
                  pattern: "none",
                  fgColor: { argb: "ffffffff" },
               }
               cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
               }
            }
         }
      }

      // --- Footer ---
      const startRowFooterSheet = startRowDataSheet + config.data.length + 2
      // Create by
      worksheet.mergeCells(
         startRowFooterSheet + 1,
         1,
         startRowFooterSheet + 1,
         2
      )
      const createdByCell = worksheet.getRow(startRowFooterSheet + 1).getCell(1)
      createdByCell.value = "NGƯỜI LẬP BIỂU"
      createdByCell.font = { size: 11 }
      createdByCell.alignment = { horizontal: "center" }
      worksheet.mergeCells(
         startRowFooterSheet + 2,
         1,
         startRowFooterSheet + 2,
         2
      )
      const createdCell = worksheet.getRow(startRowFooterSheet + 2).getCell(1)
      createdCell.value = "(Ký, ghi rõ họ và tên)"
      createdCell.font = { size: 11, italic: true }
      createdCell.alignment = { horizontal: "center" }

      // Sign
      worksheet.mergeCells(
         startRowFooterSheet,
         config.columns.length - 1,
         startRowFooterSheet,
         config.columns.length
      )
      const createdDateCell = worksheet
         .getRow(startRowFooterSheet)
         .getCell(config.columns.length - 1)
      createdDateCell.value = "Ngày.....tháng.....năm...."
      createdDateCell.font = { size: 11 }
      createdDateCell.alignment = { horizontal: "center" }

      worksheet.mergeCells(
         startRowFooterSheet + 1,
         config.columns.length - 1,
         startRowFooterSheet + 1,
         config.columns.length
      )
      const signByCell = worksheet
         .getRow(startRowFooterSheet + 1)
         .getCell(config.columns.length - 1)
      signByCell.value = "TRƯỞNG VĂN PHÒNG"
      signByCell.font = { size: 11 }
      signByCell.alignment = { horizontal: "center" }

      worksheet.mergeCells(
         startRowFooterSheet + 2,
         config.columns.length - 1,
         startRowFooterSheet + 2,
         config.columns.length
      )
      const signCell = worksheet
         .getRow(startRowFooterSheet + 2)
         .getCell(config.columns.length - 1)
      signCell.value = "(Ký tên, đóng dấu)"
      signCell.font = { size: 11, italic: true }
      signCell.alignment = { horizontal: "center" }

      // Write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // Download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`)
   } catch (error: any) {
      console.error("<<<ERRROR>>>", error)
      console.error("Something Went Wrong", error.message)
      throw error
   } finally {
      // Removing worksheet's instance to create new one
      workbook.removeWorksheet(WORK_SHEET_DATA_NAME)
      workbook.removeWorksheet(WORK_SHEET_CHART_NAME)
   }
}

/**
 * Get date headline
 * @param type
 * @returns
 */
export const getDateHeadline = (type: ChartEnum): string[] => {
   if (
      type === ChartEnum.DAY ||
      type === ChartEnum.APP ||
      type === ChartEnum.PROVINCE
   ) {
      return ["Từ ngày", "Đến ngày"]
   }

   if (type === ChartEnum.MONTH) {
      return ["Từ tháng", "Đến tháng"]
   }

   if (type === ChartEnum.YEAR) {
      return ["Từ năm", "Đến năm"]
   }

   return ["", ""]
}
