import { useSelector } from "react-redux"
import {
   allowAddManagementUser,
   allowDeleteManagementUser,
   allowDetailManagementUser,
   allowExportManagementUser,
   allowGetCountManagementUser,
   allowHistoryChargeManagementUser,
   allowHistoryManagementUser,
   allowUpdateManagementUser,
} from "../../states/permission/permission.selector"

export const useManagementUserPermission = () => {
   return {
      allowAddManagementUser: useSelector(allowAddManagementUser),
      allowUpdateManagementUser: useSelector(allowUpdateManagementUser),
      allowDetailManagementUser: useSelector(allowDetailManagementUser),
      allowDeleteManagementUser: useSelector(allowDeleteManagementUser),
      allowExportManagementUser: useSelector(allowExportManagementUser),
      allowHistoryManagementUser: useSelector(allowHistoryManagementUser),
      allowHistoryChargeManagementUser: useSelector(
         allowHistoryChargeManagementUser
      ),
      allowGetCountManagementUser: useSelector(allowGetCountManagementUser),
   }
}
