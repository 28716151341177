import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import { visuallyHidden } from "@mui/utils"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import Dialog from "@mui/material/Dialog"
import { useState } from "react"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import DialogActions from "@mui/material/DialogActions"
import { TransferListSyncComponent } from "../../common/components/TranferListSync/tranferlistSync.component"
import Box from "@mui/material/Box"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import {
   CircularProgress,
   Grid,
   LinearProgress,
   TableSortLabel,
   Typography,
} from "@mui/material"
import { DepartmentInterface } from "../../common/interfaces/department.interface"
import { DepartmentRoleDetailComponent } from "./departmentRoleDetail/departmentRoleDetail.component"
import { FilterTableComponent } from "../../common/components/FilterTable/filterTable.component"
import commonStyles from "../../common/styles/common.module.css"
import { FeatureInterface } from "../../common/interfaces/feature.interface"
import { DepartmentUserContainer } from "./departmentUser/departmentUser.container"
import { columns, createData, filterTypes } from "./department.table"
import {
   DepartmentDialogInput,
   departmentDialogSchema,
} from "./department.form"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { createSortDirection } from "../../common/helper/sortTable.helper"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"

type DepartmentComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   error: string
   departmentData: any[]
   sortCondition: SortConditionInterface
   searchCondition: SearchConditionState
   selectedId: string
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleReloadGetDeparmentsAsync: () => void
   handleAddDepartmentAsync: (
      values: DepartmentDialogInput,
      features: FeatureInterface[]
   ) => Promise<any>
   handleUpdateDepartmentAsync: (
      id: string,
      values: DepartmentDialogInput,
      features: FeatureInterface[]
   ) => Promise<any>
   handleDeleteDepartmentAsync: (id: string) => Promise<any>
   handleSelectDepartmentUser: (values: DepartmentInterface) => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
}

export const DepartmentComponent = (props: DepartmentComponentProps) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const { t } = useTranslation(namespaces.pages.department)

   // Department list
   const rows = props.departmentData.map((department) => {
      const features: FeatureInterface[] = department.roles.map((e: any) => {
         const feature: FeatureInterface = {
            id: e.id,
            name: e.roleName,
         }
         return feature
      })
      return createData(
         department.id,
         department.name,
         department.numOfUsers,
         department.numOfRoles,
         department.createdAt,
         features
      )
   })

   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }
   const [right, setRight] = useState<FeatureInterface[]>([])

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.ADD
   )
   const [selectedDialog, setSelectedDialog] =
      useState<DepartmentInterface | null>(null)

   // Form department dialog
   const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
   } = useForm<DepartmentDialogInput>({
      resolver: zodResolver(departmentDialogSchema),
   })

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: DepartmentInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      setSelectedDialog(selected)
      setValue("name", selected ? selected.name : "")
      if (type === DialogMethodEnum.ADD) {
         setRight([])
      }
      if (type === DialogMethodEnum.UPDATE && selected) {
         setRight(selected.features)
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      setValue("name", "")
      setRight([])
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<DepartmentDialogInput> = (
      values: DepartmentDialogInput
   ) => {
      setRequestingDialog(true)
      if (typeDialog === DialogMethodEnum.ADD) {
         props
            .handleAddDepartmentAsync(values, right)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Thêm phòng ban thành công!",
                  "success"
               )
               setOpenDialog(false)
               props.handleReloadGetDeparmentsAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               if (err.response.data?.detail != null) {
                  props.handlePushNotistack(err.response.data?.detail, "error")
               } else {
                  props.handlePushNotistack("Thêm phòng ban thất bại!", "error")
               }
            })
      } else if (typeDialog === DialogMethodEnum.UPDATE && selectedDialog) {
         props
            .handleUpdateDepartmentAsync(selectedDialog.id, values, right)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Cập nhật phòng ban thành công!",
                  "success"
               )
               setOpenDialog(false)
               props.handleReloadGetDeparmentsAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Cập nhật phòng ban thất bại!",
                  "error"
               )
            })
      }
   }

   /**
    * On delete department
    */
   const onDeleteDepartment = () => {
      setRequestingDialog(true)
      props
         .handleDeleteDepartmentAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa phòng ban thành công!", "success")
            setOpenDialog(false)
            props.handleReloadGetDeparmentsAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa phòng ban thất bại!", "error")
         })
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("button.addDepartment")}
            {typeDialog === DialogMethodEnum.UPDATE &&
               t("button.updateDepartment")}
            {typeDialog === DialogMethodEnum.DELETE &&
               t("button.deleteDepartment")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button type="submit" disabled={requestingDialog}>
                  {t("button.add")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.UPDATE && (
               <Button type="submit" disabled={requestingDialog}>
                  {t("button.update")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeleteDepartment}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button color="info" onClick={handleCloseDialog}>
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteDepartmentConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      return (
         <DialogContent>
            <TextField
               autoFocus
               margin="dense"
               id="name"
               label={t("departmentTable.name")}
               fullWidth
               variant="standard"
               disabled={requestingDialog}
               error={!!errors.name}
               helperText={errors.name ? errors.name.message : ""}
               {...register("name")}
            />
            <TransferListSyncComponent
               right={right}
               setRight={setRight}
               leftTitle={t("dialog.leftTitle")}
               leftContext={t("dialog.leftContext")}
               rightTitle={t("dialog.rightTitle")}
               rightContext={t("dialog.rightContext")}
            />
         </DialogContent>
      )
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        types={filterTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={12} xl={4}>
                           <Button
                              fullWidth
                              color="secondary"
                              variant="contained"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                 handleOpenDialog(DialogMethodEnum.ADD)
                              }
                           >
                              {t("button.addDepartment")}
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Toolbar>
            <TableContainer sx={{ maxHeight: 750 }}>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        <TableCell></TableCell>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {column.sortKey ? (
                                 <TableSortLabel
                                    active={
                                       props.sortCondition.column ===
                                       column.sortKey
                                    }
                                    direction={sortDirection}
                                    onClick={createSortHandler(column.sortKey)}
                                 >
                                    {t(column.label)}
                                    {props.sortCondition.column ===
                                    column.sortKey ? (
                                       <Box
                                          component="span"
                                          sx={visuallyHidden}
                                       >
                                          {props.sortCondition.order ===
                                          SortOrderEnum.DESC
                                             ? "Giảm"
                                             : "Tăng"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              ) : (
                                 t(column.label)
                              )}
                           </TableCell>
                        ))}
                        <TableCell key={"action"} style={{ minWidth: 170 }}>
                           {t("departmentTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 2}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 2}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 2}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => {
                           return (
                              <DepartmentRoleDetailComponent
                                 key={row.id}
                                 columns={columns}
                                 row={row}
                                 selectedId={props.selectedId}
                                 handleSelectDepartmentUser={
                                    props.handleSelectDepartmentUser
                                 }
                                 handleOpenDialog={handleOpenDialog}
                              />
                           )
                        })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <DepartmentUserContainer />
         <Dialog
            fullScreen={fullScreen}
            maxWidth={"xl"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="on"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Container>
   )
}
