import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface RegisterState {
   successful: boolean
   requesting: boolean
   error: string | null
   showOtp: boolean
}

const initialState: RegisterState = {
   successful: false,
   requesting: false,
   showOtp: false,
   error: null,
}

export const registerSlice = createSlice({
   name: "register",
   initialState,
   reducers: {
      registerUserRequest: (state: RegisterState) => {
         state.requesting = true
         state.error = null
      },
      registerUserSuccess: (state: RegisterState) => {
         state.requesting = false
         state.error = null
         state.showOtp = true
      },
      registerUserFail: (state: RegisterState, action: PayloadAction<any>) => {
         state.requesting = false
         state.error = action.payload
         state.successful = false
      },
      otpRegisterRequest: (state: RegisterState) => {
         state.requesting = true
         state.error = null
      },
      otpRegisterSuccess: (state: RegisterState) => {
         state.requesting = false
         state.error = null
         state.successful = true
      },
      otpRegisterUserFail: (
         state: RegisterState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.error = action.payload
         state.successful = false
      },
      resetRegisterUser: (state: RegisterState) => {
         state.successful = false
         state.requesting = false
         state.showOtp = false
         state.error = null
      },
   },
})

export const {
   registerUserRequest,
   registerUserSuccess,
   registerUserFail,
   otpRegisterRequest,
   otpRegisterSuccess,
   otpRegisterUserFail,
   resetRegisterUser,
} = registerSlice.actions

export default registerSlice.reducer
