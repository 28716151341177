import { useSelector } from "react-redux"
import {
   allowAddUserType,
   allowDeleteUserType,
   allowDetailUserType,
   allowUpdateUserType,
} from "../../states/permission/permission.selector"
import { selectAuthIsAdminRole } from "../../states/auth/auth.selector"

export const useTypeUserPermission = () => {
   return {
      allowDetailUserType: useSelector(allowDetailUserType),
      allowDeleteUserType: useSelector(allowDeleteUserType),
      allowAddUserType: useSelector(allowAddUserType),
      allowUpdateUserType: useSelector(allowUpdateUserType),
      allowSyncUserType: useSelector(selectAuthIsAdminRole),
   }
}
