import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { convertDate } from "../../common/helper/date.helper"
import { ProvinceInterface } from "../../common/interfaces/province.interface"
import { TypeUserInterface } from "../../common/interfaces/typeUser.interface"

export interface Column {
   id: "name" | "createAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [FilterTableEnum.USER_TYPE_NAME]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "typeTable.name",
      sortKey: SortColumnEnum.USER_TYPE_NAME,
      minWidth: 170,
      maxWidth: 300,
   },
   {
      id: "createAt",
      label: "typeTable.createAt",
      sortKey: SortColumnEnum.USER_TYPE_CREATE_AT,
      minWidth: 170,
      maxWidth: 300,
   },
]

export function createData(
   id: string,
   name: string,
   numberUser: number,
   province: ProvinceInterface[],
   topup_min: number,
   topup_max: number,
   use_amount_min: number,
   use_amount_max: number,
   login_min: number,
   login_max: number,
   createAt: string
): TypeUserInterface {
   createAt = convertDate(createAt)
   return {
      id,
      name,
      numberUser,
      province,
      topup_min,
      topup_max,
      use_amount_min,
      use_amount_max,
      login_min,
      login_max,
      createAt,
   }
}
