import { createAction } from "@reduxjs/toolkit"

export const getManagementUserHistoryAsync = createAction<any>(
   "managementUserHistory/getManagementUserHistoryAsync"
)

export const changePageNumberAsync = createAction<number>(
   "managementUserHistory/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "managementUserHistory/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "managementUserHistory/changeSearchConditionAsync"
)
