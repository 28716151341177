import { createAction } from "@reduxjs/toolkit"

export const getTypeUserAsync = createAction("typeUser/getTypeUserAsync")

export const changePageNumberAsync = createAction<number>(
   "typeUser/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "typeUser/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "typeUser/changeSearchConditionAsync"
)

export const reloadGetTypeUserAsync = createAction(
   "typeUser/reloadGetTypeUserAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "typeUser/changeSortConditionAsync"
)

export const getSyncUserSync = createAction("typeUser/getSyncUserSync")

export const triggerSyncUserSync = createAction("typeUser/triggerSyncUserSync")
