import { CreateEmailUserComponent } from "./createEmailUser.component"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getCreateEmailUserAsync,
} from "../../../states/createEmailUser/createEmailUser.action"
import {
   selectCountCreateEmailUser,
   selectDataCreateEmailUser,
   selectErrorCreateEmailUser,
   selectPageNumberCreateEmailUser,
   selectPageSizeCreateEmailUser,
   selectRequestingCreateEmailUser,
   selectSearchConditionCreateEmailUser,
} from "../../../states/createEmailUser/createEmailUser.selector"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import {
   GetUserEmailPayloadAPIInterface,
   getUserEmailAPI,
} from "../../../api/email.api"

type CreateEmailUserContainerProps = {
   disabled: boolean
   selected: string[]
   setSelected: (ids: string[]) => void
}

export const CreateEmailUserContainer = (
   props: CreateEmailUserContainerProps
) => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingCreateEmailUser)
   const userData = useSelector(selectDataCreateEmailUser)
   const rowCount = useSelector(selectCountCreateEmailUser)
   const pageNumber = useSelector(selectPageNumberCreateEmailUser)
   const pageSize = useSelector(selectPageSizeCreateEmailUser)
   const error = useSelector(selectErrorCreateEmailUser)
   const searchCondition = useSelector(selectSearchConditionCreateEmailUser)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle add async
    * @param values
    * @returns
    */
   const handleGetUserAsync = (userIds: string[]) => {
      const params: GetUserEmailPayloadAPIInterface = {
         userIds: userIds,
      }
      return getUserEmailAPI(params)
   }

   useEffect(() => {
      dispatch(getCreateEmailUserAsync())
   }, [dispatch])

   return (
      <CreateEmailUserComponent
         disabled={props.disabled}
         selected={props.selected}
         setSelected={props.setSelected}
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         userData={userData}
         error={error}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleGetUserAsync={handleGetUserAsync}
      />
   )
}
