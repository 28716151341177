import { permissionSlice } from "./permission/permission.slice"
import { createEmailUser } from "./createEmailUser/createEmailUser.slice"
import { createEmailTemplate } from "./createEmailTemplate/createEmailTemplate.slice"
import { emailSlice } from "./email/email.slice"
import { rechargePackSlice } from "./rechargePack/rechargePack.slice"
import { managementUserSlice } from "./managementUser/managementUser.slice"
import { departmentSlice } from "./department/department.slice"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import saga from "./rootSaga"
import { userSlice } from "./user/user.slice"
import { registerSlice } from "./register/register.slice"
import { authSlice } from "./auth/auth.slice"
import { notistackSlice } from "./notistack/notistack.slice"
import { chartSignInSlice } from "./chartSignIn/chartSignIn.slice"
import { departmentUserSlice } from "./departmentUser/departmentUser.slice"
import { managementWalletSlice } from "./managementWallet/managementWallet.slice"
import { typeUserSlice } from "./typeUser/typeUser.slice"
import { managementUserHistorySlice } from "./managementUserHistory/managementUserHistory.slice"
import { managementUserDetailSlice } from "./managementUserDetail/managementUserDetail.slice"
import { chartSignUpSlice } from "./chartSignUp/chartSignUp.slice"
import { chartUsingWalletSlice } from "./chartUsingWallet/chartUsingWallet.slice"
import { chartChargeWalletSlice } from "./chartChargeWallet/chartChargeWallet.slice"
import { chartUserTypeSlice } from "./chartUserType/chartUserType.slice"
import { managementUserChargeHistorySlice } from "./managementUserChargeHistory/managementUserChargeHistory.slice"
import { promotionPackSlice } from "./promotionPack/promotionPack.slice"
import { appsSlice } from "./apps/apps.slice"

const rootReducer = combineReducers({
   notistack: notistackSlice.reducer,
   permission: permissionSlice.reducer,
   auth: authSlice.reducer,
   register: registerSlice.reducer,
   user: userSlice.reducer,
   chartSignIn: chartSignInSlice.reducer,
   chartSignUp: chartSignUpSlice.reducer,
   chartUsingWallet: chartUsingWalletSlice.reducer,
   chartChargeWallet: chartChargeWalletSlice.reducer,
   chartUserType: chartUserTypeSlice.reducer,
   managementUser: managementUserSlice.reducer,
   managementUserDetail: managementUserDetailSlice.reducer,
   managementUserHistory: managementUserHistorySlice.reducer,
   managementUserChargeHistory: managementUserChargeHistorySlice.reducer,
   managementWallet: managementWalletSlice.reducer,
   typeUser: typeUserSlice.reducer,
   department: departmentSlice.reducer,
   departmentUser: departmentUserSlice.reducer,
   rechargePack: rechargePackSlice.reducer,
   promotionPack: promotionPackSlice.reducer,
   email: emailSlice.reducer,
   createEmailUser: createEmailUser.reducer,
   createEmailTemplate: createEmailTemplate.reducer,
   apps: appsSlice.reducer,
})

let sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

const store = configureStore({
   reducer: rootReducer,
   middleware,
   devTools: process.env.NODE_ENV === "development",
})

sagaMiddleware.run(saga)

export type RootState = ReturnType<typeof store.getState>
export default store
