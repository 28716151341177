import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
   addManagementUserInfoAPI,
   AddManagementUserInfoPayloadAPIInterface,
   deleteManagementUserInfoAPI,
   DeleteManagementUserInfoPayloadAPIInterface,
} from "../../api/managementUser.api"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   exportManagementUserAsync,
   getCountManagementUserAsync,
   getManagementUserAsync,
} from "../../states/managementUser/managementUser.action"
import {
   selectCountManagementUser,
   selectDataManagementCountUser,
   selectDataManagementUser,
   selectErrorManagementUser,
   selectErrorManagementUserCount,
   selectPageNumberManagementUser,
   selectPageSizeManagementUser,
   selectRequestingExportManagementUser,
   selectRequestingManagementUser,
   selectRequestingManagementUserCount,
   selectSearchConditionManagementUser,
   selectSortConditionManagementUser,
} from "../../states/managementUser/managementUser.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { ManagementUserComponent } from "./managementUser.component"
import { ManagementUserDialogInput } from "./managementUser.form"
import { MultipleSearchConditionState } from "../../common/interfaces/searchConditionParam"
import { useManagementUserPermission } from "./managementUser.permission"

export const ManagementUserContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { allowGetCountManagementUser } = useManagementUserPermission()

   // Selector
   const requesting = useSelector(selectRequestingManagementUser)
   const requestingCount = useSelector(selectRequestingManagementUserCount)
   const requestingExport = useSelector(selectRequestingExportManagementUser)
   const userData = useSelector(selectDataManagementUser)
   const userDataCount = useSelector(selectDataManagementCountUser)
   const rowCount = useSelector(selectCountManagementUser)
   const pageNumber = useSelector(selectPageNumberManagementUser)
   const pageSize = useSelector(selectPageSizeManagementUser)
   const sortCondition = useSelector(selectSortConditionManagementUser)
   const searchCondition = useSelector(selectSearchConditionManagementUser)
   const error = useSelector(selectErrorManagementUser)
   const errorCount = useSelector(selectErrorManagementUserCount)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle add user async
    * @param values
    * @returns
    */
   const handleAddUserAsync = (values: ManagementUserDialogInput) => {
      const params: AddManagementUserInfoPayloadAPIInterface = {
         username: "string",
         fullname: values.name,
         phoneNumber: values.phoneNumber,
         email: values.email ? values.email : "",
         password: values.password,
         accountTypeId: values.accountType,
         provinceId: values.province ? values.province.id : "",
         note: values.note,
      }
      return addManagementUserInfoAPI(params)
   }

   /**
    * Handle delete user sync
    * @param values
    * @returns
    */
   const handleDeleteUserAsync = (id: string) => {
      const params: DeleteManagementUserInfoPayloadAPIInterface = {
         id: id,
      }
      return deleteManagementUserInfoAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (
      searchConditionData: MultipleSearchConditionState[]
   ) => {
      dispatch(changeSearchConditionAsync(searchConditionData))
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Export excel
    */
   const handleExportExcel = () => {
      dispatch(exportManagementUserAsync())
   }

   /**
    * Handle reload get management user async
    */
   const handleReloadGetManagementUserAsync = () => {
      dispatch(getManagementUserAsync())
   }

   /**
    * Handle get management user detail async
    * @param id
    */
   const handleGetManagementUserDetailAsync = (id: string) => {
      navigate("/dashboard/managementUser/detail/" + id)
   }

   /**
    * Handle get charge history user async
    * @param id
    */
   const handleGetChargeHistoryUserAsync = (id: string) => {
      navigate("/dashboard/managementUser/charge/" + id)
   }

   useEffect(() => {
      dispatch(getManagementUserAsync())
      if (allowGetCountManagementUser) {
         dispatch(getCountManagementUserAsync())
      }
   }, [dispatch, allowGetCountManagementUser])

   return (
      <ManagementUserComponent
         requesting={requesting}
         requestingCount={requestingCount}
         requestingExport={requestingExport}
         pageNumber={pageNumber}
         pageSize={pageSize}
         searchCondition={searchCondition}
         sortCondition={sortCondition}
         rowCount={rowCount}
         userData={userData}
         userDataCount={userDataCount}
         error={error}
         errorCount={errorCount}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleAddUserAsync={handleAddUserAsync}
         handleDeleteUserAsync={handleDeleteUserAsync}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChangeSortCondition={handleChangeSortCondition}
         handleExportExcel={handleExportExcel}
         handleReloadGetManagementUserAsync={handleReloadGetManagementUserAsync}
         handleGetManagementUserDetailAsync={handleGetManagementUserDetailAsync}
         handleGetChargeHistoryUserAsync={handleGetChargeHistoryUserAsync}
      />
   )
}
