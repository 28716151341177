import { useDispatch } from "react-redux"
import {
   getUserInfoAPI,
   GetUserInfoPayloadAPIInterface,
   updateUserInfoAPI,
   UpdateUserInfoPayloadAPIInterface,
} from "../../../api/setting.api"
import { QueriesEnum } from "../../../common/enum/queries.enum"
import { genQueries } from "../../../common/helper/queries.helper"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { SettingCompanyComponent } from "./settingCompany.component"
import { SettingCompanyInput } from "./settingCompany.form"

export const SettingCompanyContainer = () => {
   const dispatch = useDispatch()

   /**
    * Handle get user info async
    * @returns
    */
   const handleGetCompanyAsync = () => {
      const params: GetUserInfoPayloadAPIInterface = {
         queries: genQueries(
            QueriesEnum.COMPANY_NAME,
            QueriesEnum.COMPANY_TAX,
            QueriesEnum.COMPANY_ADDRESS,
            QueriesEnum.COMPANY_PHONENUMBER,
            QueriesEnum.COMPANY_EMAIL
         ),
      }
      return getUserInfoAPI(params)
   }

   /**
    * Handle get company async
    * @returns
    */
   const handleUpdateCompanyAsync = (values: SettingCompanyInput) => {
      const params: UpdateUserInfoPayloadAPIInterface = {
         Properties: [
            {
               Property: QueriesEnum.COMPANY_NAME,
               Value: values.name,
            },
            {
               Property: QueriesEnum.COMPANY_TAX,
               Value: values.taxCode,
            },
            {
               Property: QueriesEnum.COMPANY_ADDRESS,
               Value: values.address,
            },
            {
               Property: QueriesEnum.COMPANY_PHONENUMBER,
               Value: values.phoneNumber,
            },
            {
               Property: QueriesEnum.COMPANY_EMAIL,
               Value: values.email,
            },
         ],
      }
      return updateUserInfoAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   return (
      <SettingCompanyComponent
         handleGetCompanyAsync={handleGetCompanyAsync}
         handleUpdateCompanyAsync={handleUpdateCompanyAsync}
         handlePushNotistack={handlePushNotistack}
      />
   )
}
