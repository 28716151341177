import { useSelector } from "react-redux"
import { allowUpdateManagementUser } from "../../states/permission/permission.selector"

interface ManagementUserPermissionInterface {
   allowUpdateManagementUser: boolean
}

export const useManagementUserDetailPermission =
   (): ManagementUserPermissionInterface => {
      return {
         allowUpdateManagementUser: useSelector(allowUpdateManagementUser),
      }
   }
