import { all } from "redux-saga/effects"
import { authSaga } from "./auth/auth.saga"
import { chartSignInSaga } from "./chartSignIn/chartSignIn.saga"
import { departmentSaga } from "./department/department.saga"
import { departmentUserSaga } from "./departmentUser/departmentUser.saga"
import { managementUserSaga } from "./managementUser/managementUser.saga"
import { managementUserDetailSaga } from "./managementUserDetail/managementUserDetail.saga"
import { managementUserHistorySaga } from "./managementUserHistory/managementUserHistory.saga"
import { managementWalletSaga } from "./managementWallet/managementWallet.saga"
import { permissionSaga } from "./permission/permission.saga"
import { emailSaga } from "./email/email.saga"
import { rechargePackSaga } from "./rechargePack/rechargePack.saga"
import { registerSaga } from "./register/register.saga"
import { typeUserSaga } from "./typeUser/typeUser.saga"
import { userSaga } from "./user/user.saga"
import { chartSignUpSaga } from "./chartSignUp/chartSignUp.saga"
import { chartUsingWalletSaga } from "./chartUsingWallet/chartUsingWallet.saga"
import { chartChargeWalletSaga } from "./chartChargeWallet/chartChargeWallet.saga"
import { chartUserTypeSaga } from "./chartUserType/chartUserType.saga"
import { managementUserChargeHistorySaga } from "./managementUserChargeHistory/managementUserChargeHistory.saga"
import { createEmailTemplateSaga } from "./createEmailTemplate/createEmailTemplate.saga"
import { createEmailUserSaga } from "./createEmailUser/createEmailUser.saga"
import { promotionPackSaga } from "./promotionPack/promotionPack.saga"
import { appsSaga } from "./apps/apps.saga"

export default function* RootSaga() {
   yield all([
      authSaga(),
      permissionSaga(),
      registerSaga(),
      userSaga(),
      chartSignInSaga(),
      chartSignUpSaga(),
      chartUsingWalletSaga(),
      chartChargeWalletSaga(),
      chartUserTypeSaga(),
      managementUserSaga(),
      managementUserDetailSaga(),
      managementUserHistorySaga(),
      managementUserChargeHistorySaga(),
      managementWalletSaga(),
      typeUserSaga(),
      departmentSaga(),
      departmentUserSaga(),
      rechargePackSaga(),
      promotionPackSaga(),
      emailSaga(),
      createEmailUserSaga(),
      createEmailTemplateSaga(),
      appsSaga(),
   ])
}
