import { useDispatch, useSelector } from "react-redux"
import { updateManagementUserDetailCompanyAsync } from "../../../states/managementUserDetail/managementUserDetail.action"
import {
   selectDataCompanyManagementUserDetailState,
   selectErrorCompanyManagementUserDetailState,
   selectModeEditCompanyManagementUserDetailState,
   selectRequestingCompanyManagementUserDetailState,
} from "../../../states/managementUserDetail/managementUserDetail.selector"
import { ManagementUserDetailCompanyComponent } from "./managementUserDetailCompany.component"
import { ManagementUserDetailCompanyInput } from "./managementUserDetailCompany.form"
import { setEditManagementUserDetailCompany } from "../../../states/managementUserDetail/managementUserDetail.slice"

export const ManagementUserDetailCompanyContainer = () => {
   const dispatch = useDispatch()

   // Selector
   const requesting = useSelector(
      selectRequestingCompanyManagementUserDetailState
   )
   const modeEdit = useSelector(selectModeEditCompanyManagementUserDetailState)
   const error = useSelector(selectErrorCompanyManagementUserDetailState)
   const data = useSelector(selectDataCompanyManagementUserDetailState)

   /**
    * Handle update
    * @param values
    */
   const handleUpdateManagementUserDetailCompanyAsync = (
      values: ManagementUserDetailCompanyInput
   ) => {
      dispatch(updateManagementUserDetailCompanyAsync(values))
   }

   /**
    * Handle set edit mode
    * @param value
    */
   const setModeEditAsync = (value: boolean) => {
      dispatch(setEditManagementUserDetailCompany(value))
   }

   return (
      <ManagementUserDetailCompanyComponent
         requesting={requesting}
         modeEdit={modeEdit}
         error={error}
         data={data}
         setModeEditAsync={setModeEditAsync}
         handleUpdateManagementUserDetailCompanyAsync={
            handleUpdateManagementUserDetailCompanyAsync
         }
      />
   )
}
