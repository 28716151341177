import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
   selectRegisterError,
   selectRegisterRequesting,
   selectShowOtp,
} from "../../states/register/register.selector"
import { RegisterComponent } from "./register.component"
import { useSnackbar } from "notistack"
import {
   otpRegisterUserAsync,
   registerUserAsync,
   resendOtpRegisterUserAsync,
} from "../../states/register/register.action"
import { RegisterInput } from "./register.form"
import { resetSignInAsync } from "../../states/auth/auth.action"

export const RegisterContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { enqueueSnackbar } = useSnackbar()

   // Selector
   const registerError = useSelector(selectRegisterError)
   const isRegisterRequesting = useSelector(selectRegisterRequesting)
   const showOtp = useSelector(selectShowOtp)

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm = (values: RegisterInput) => {
      dispatch(registerUserAsync({ values, navigate, enqueueSnackbar }))
   }

   /**
    * On submit otp
    * @param values
    */
   const onSubmitOtp = (phoneNumber: string, otp: string, otpTimer: number) => {
      dispatch(
         otpRegisterUserAsync({
            phoneNumber,
            otp,
            otpTimer,
            navigate,
            enqueueSnackbar,
         })
      )
   }

   /**
    * Pn resend otp
    * @param phoneNumber
    */
   const onResendOtp = (phoneNumber: string) => {
      dispatch(
         resendOtpRegisterUserAsync({ phoneNumber, navigate, enqueueSnackbar })
      )
   }

   /**
    * On sign in
    */
   const onSignIn = () => {
      navigate("/sign-in")
      dispatch(resetSignInAsync())
   }

   return (
      <RegisterComponent
         isRegisterRequesting={isRegisterRequesting}
         registerError={registerError}
         showOtp={showOtp}
         onSubmitForm={onSubmitForm}
         onSubmitOtp={onSubmitOtp}
         onSignIn={onSignIn}
         onResendOtp={onResendOtp}
      />
   )
}
