import { number, object, TypeOf } from "zod"
import {} from "../../../common/variables/regex"

export const defaultNumberErr = {
   required_error: "Vui lòng nhập giá trị",
   int: "Giá trị phải là số nguyên dương",
   positive: "Giá trị phải lớn hơn 0",
}

const max15Error = "Giá trị phải nhỏ hơn hoặc bằng 15 kí tự"

export const settingConfigurationSchema = object({
   failTime: number(defaultNumberErr)
      .int(defaultNumberErr.int)
      .positive(defaultNumberErr.positive)
      .lte(999999999999999, max15Error),
   lockTime: number(defaultNumberErr)
      .int(defaultNumberErr.int)
      .positive(defaultNumberErr.positive)
      .lte(999999999999999, max15Error),
   tokenTime: number(defaultNumberErr)
      .int(defaultNumberErr.int)
      .positive(defaultNumberErr.positive)
      .lte(999999999999999, max15Error),
   outOfDate: number(defaultNumberErr)
      .int(defaultNumberErr.int)
      .positive(defaultNumberErr.positive)
      .lte(999999999999999, max15Error),
})

export type SettingConfigurationInput = TypeOf<
   typeof settingConfigurationSchema
>
