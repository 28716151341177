import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { ManagementUserHistoryInterface } from "../../common/interfaces/managementUserHistory.interface"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"

export interface ManagementUserHistoryState {
   requesting: boolean
   successful: boolean
   error: string
   data: ManagementUserHistoryInterface[]
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionState
   selectedId: string
}

const initialState: ManagementUserHistoryState = {
   requesting: false,
   successful: false,
   error: "",
   data: [],
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableEnum.NONE,
      value: "",
   },
   selectedId: "",
}

export const managementUserHistorySlice = createSlice({
   name: "ManagementUserHistory",
   initialState,
   reducers: {
      requestManagementUserHistory: (
         state: ManagementUserHistoryState,
         action: PayloadAction<string>
      ) => {
         state.requesting = true
         state.selectedId = action.payload
      },
      getManagementUserHistorySuccess: (
         state: ManagementUserHistoryState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data.data
         state.rowCount = action.payload.data.rowCount
      },
      getManagementUserHistoryFail: (
         state: ManagementUserHistoryState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: ManagementUserHistoryState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: ManagementUserHistoryState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: ManagementUserHistoryState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.value = action.payload.value
      },
   },
})

export const {
   requestManagementUserHistory,
   getManagementUserHistorySuccess,
   getManagementUserHistoryFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
} = managementUserHistorySlice.actions

export default managementUserHistorySlice.reducer
