import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getRechargePackAsync,
} from "../../states/rechargePack/rechargePack.action"
import {
   selectCountRechargePack,
   selectDataRechargePack,
   selectErrorRechargePack,
   selectPageNumberRechargePack,
   selectPageSizeRechargePack,
   selectRequestingRechargePack,
   selectSearchConditionRechargePack,
   selectSortConditionRechargePack,
} from "../../states/rechargePack/rechargePack.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { RechargePackComponent } from "./rechargePack.component"
import {
   addRechargePackAPI,
   AddRechargePackPayloadAPIInterface,
   deleteRechargePackAPI,
   DeleteRechargePackPayloadAPIInterface,
   updateRechargePackAPI,
   UpdateRechargePackPayloadAPIInterface,
} from "../../api/rechargePack.api"
import { RechargePackRechargeDialogInput } from "./rechargePack.form"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"

export const RechargePackContainer = () => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingRechargePack)
   const rechargePackData = useSelector(selectDataRechargePack)
   const rowCount = useSelector(selectCountRechargePack)
   const pageNumber = useSelector(selectPageNumberRechargePack)
   const pageSize = useSelector(selectPageSizeRechargePack)
   const error = useSelector(selectErrorRechargePack)
   const sortCondition = useSelector(selectSortConditionRechargePack)
   const searchCondition = useSelector(selectSearchConditionRechargePack)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle add pack async
    * @param values
    * @returns
    */
   const handleAddRechargePackAsync = (
      values: RechargePackRechargeDialogInput
   ) => {
      const params: AddRechargePackPayloadAPIInterface = {
         pacakgeName: values.name,
         amount: values.amount,
         coin: values.coin,
         addDay: values.addDay,
         userTypeIds: values.userType ? values.userType.map((e) => e.id) : null,
      }
      return addRechargePackAPI(params)
   }

   /**
    * Handle update pack async
    * @param values
    * @returns
    */
   const handleUpdateRechargePackAsync = (
      id: string,
      values: RechargePackRechargeDialogInput
   ) => {
      const params: UpdateRechargePackPayloadAPIInterface = {
         id: id,
         packageName: values.name,
         amount: values.amount,
         coin: values.coin,
         addDay: values.addDay,
         userTypeIds: values.userType ? values.userType.map((e) => e.id) : null,
      }
      return updateRechargePackAPI(params)
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle delete pack async
    * @param values
    * @returns
    */
   const handleDeleteRechargePackAsync = (id: string) => {
      const params: DeleteRechargePackPayloadAPIInterface = {
         id: id,
      }
      return deleteRechargePackAPI(params)
   }

   /**
    * Handle reload recharge pack async
    */
   const handleReloadRechargePackAsync = () => {
      dispatch(getRechargePackAsync())
   }

   useEffect(() => {
      dispatch(getRechargePackAsync())
   }, [dispatch])

   return (
      <RechargePackComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         rechargePackData={rechargePackData}
         error={error}
         searchCondition={searchCondition}
         sortCondition={sortCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleAddRechargePackAsync={handleAddRechargePackAsync}
         handleUpdateRechargePackAsync={handleUpdateRechargePackAsync}
         handleDeleteRechargePackAsync={handleDeleteRechargePackAsync}
         handleReloadRechargePackAsync={handleReloadRechargePackAsync}
         handleChangeSortCondition={handleChangeSortCondition}
      />
   )
}
