import { object, string, TypeOf } from "zod"
import { SPACE_REGEX } from "../../common/variables/regex"

export const departmentDialogSchema = object({
   name: string()
      .min(1, "Phòng ban bắt buộc nhập")
      .regex(new RegExp(SPACE_REGEX), "Phòng ban bắt buộc nhập"),
})

export type DepartmentDialogInput = TypeOf<typeof departmentDialogSchema>
