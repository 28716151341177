import {
   selectPageNumberCreateEmailTemplate,
   selectPageSizeCreateEmailTemplate,
   selectSearchConditionCreateEmailTemplate,
   selectSortConditionCreateEmailTemplate,
} from "./createEmailTemplate.selector"
import {
   getCreateEmailTemplateAPI,
   GetCreateEmailTemplatePayloadAPIInterface,
} from "../../api/createEmailTemplate.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getCreateEmailTemplateAsync,
   reloadGetCreateEmailTemplateAsync,
} from "./createEmailTemplate.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getCreateEmailTemplateFail,
   getCreateEmailTemplateSuccess,
   requestCreateEmailTemplate,
} from "./createEmailTemplate.slice"

/**
 * Get template saga
 * @param action
 */
function* getCreateEmailTemplateSaga(): any {
   try {
      yield put(requestCreateEmailTemplate())
      const pageNumber = yield select(selectPageNumberCreateEmailTemplate)
      const pageSize = yield select(selectPageSizeCreateEmailTemplate)
      const searchCondition = yield select(
         selectSearchConditionCreateEmailTemplate
      )
      const sortCondition = yield select(selectSortConditionCreateEmailTemplate)
      const params: GetCreateEmailTemplatePayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getCreateEmailTemplateAPI, params)
      if (!response) {
         throw error
      }
      yield put(getCreateEmailTemplateSuccess(response))
   } catch (error: any) {
      yield put(getCreateEmailTemplateFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getCreateEmailTemplateAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getCreateEmailTemplateAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getCreateEmailTemplateAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getCreateEmailTemplateAsync())
   } catch (error) {}
}

/**
 * Reload get type user  saga
 */
function* reloadGetCreateEmailTemplateSaga(): any {
   try {
      yield put(getCreateEmailTemplateAsync())
   } catch (error) {}
}

/**
 * Root type user saga
 */
export function* createEmailTemplateSaga() {
   yield takeLatest(getCreateEmailTemplateAsync, getCreateEmailTemplateSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(
      reloadGetCreateEmailTemplateAsync,
      reloadGetCreateEmailTemplateSaga
   )
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
