import { getUserAPI, GetUserPayloadAPIInterface } from "./../../api/user.api"
import { call, put, takeLatest } from "redux-saga/effects"
import { getUserInfoAsync } from "./user.action"
import { addUserInfo, requestUserInfo } from "./user.slice"
import { genQueries } from "../../common/helper/queries.helper"
import { QueriesEnum } from "../../common/enum/queries.enum"

/**
 * Get user information saga
 */
export function* getUserInfoSaga(): any {
   try {
      yield put(requestUserInfo())
      const params: GetUserPayloadAPIInterface = {
         queries: genQueries(
            QueriesEnum.ACCOUNT_TYPE_ID,
            QueriesEnum.FULLNAME,
            QueriesEnum.EMAIL,
            QueriesEnum.IS_EKYC
         ),
      }
      const { response, error } = yield call(getUserAPI, params)
      if (!response) {
         throw error
      }
      yield put(addUserInfo(response.data))
   } catch (error: any) {}
}

/**
 * Root user saga
 */
export function* userSaga() {
   yield takeLatest(getUserInfoAsync, getUserInfoSaga)
}
