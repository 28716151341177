import { SortOrderEnum } from "./../../common/enum/sortOrder.enum"
import {
   selectPageNumberManagementUser,
   selectPageSizeManagementUser,
   selectSearchConditionManagementUser,
   selectSortConditionManagementUser,
} from "./managementUser.selector"
import {
   getCountManagementUserAPI,
   getManagementUserAPI,
   getManagementUserExcelAPI,
   GetManagementUserPayloadAPIInterface,
} from "./../../api/managementUser.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   exportManagementUserAsync,
   getCountManagementUserAsync,
   getManagementUserAsync,
} from "./managementUser.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   finishExportExcelManagementUser,
   getManagementUserCountFail,
   getManagementUserCountSuccess,
   getManagementUserFail,
   getManagementUserSuccess,
   requestManagementUser,
   requestManagementUserCount,
   startExportExcelManagementUser,
} from "./managementUser.slice"
import { exportExcel } from "../../common/helper/exportExcel.helper"
import { ColumnsExportExcel } from "../../common/interfaces/exportExcel.interface"
import { pushNotistack } from "../notistack/notistack.slice"
import { convertDate } from "../../common/helper/date.helper"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { getLabelUserStatus } from "../../common/helper/userStatus.helper"
import { getLabelGender } from "../../common/helper/gender.helper"
import dayjs, { Dayjs } from "dayjs"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { UserStatus } from "../../common/enum/userStatus.enum"

/**
 * Get management user saga
 * @param action
 */
function* getCountManagementUserSaga(): any {
   try {
      yield put(requestManagementUserCount())
      const { response, error } = yield call(getCountManagementUserAPI)
      if (!response) {
         throw error
      }
      yield put(getManagementUserCountSuccess(response.data))
   } catch (error: any) {
      yield put(getManagementUserCountFail("table.error"))
   }
}

/**
 * Get management user saga
 * @param action
 */
function* getManagementUserSaga(): any {
   try {
      yield put(requestManagementUser())
      const pageNumber = yield select(selectPageNumberManagementUser)
      const pageSize = yield select(selectPageSizeManagementUser)
      const searchCondition = yield select(selectSearchConditionManagementUser)
      const sortCondition = yield select(selectSortConditionManagementUser)
      const mappedSearchCondition = searchCondition.map((e: any) => {
         if (typeof e.value === "object") {
            if (
               e.option === FilterTableEnum.CREATE_AT ||
               e.option === FilterTableEnum.USAGE
            ) {
               const from = dayjs(Object.entries(e.value)[0][1] as Dayjs)
               const to = dayjs(Object.entries(e.value)[1][1] as Dayjs)
               return [
                  e.option,
                  from.startOf("hour").format(),
                  to.endOf("day").format(),
               ]
            }
            if (e.option === FilterTableEnum.USER_STATUS) {
               return [e.option, Object.entries(e.value)[0][1]]
            }
            return [
               e.option,
               Object.entries(e.value)[0][1],
               Object.entries(e.value)[1][1],
            ]
         }

         return [e.option, e.value]
      })
      console.log(mappedSearchCondition)
      const params: GetManagementUserPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: mappedSearchCondition,
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getManagementUserAPI, params)
      if (!response) {
         throw error
      }
      yield put(getManagementUserSuccess(response.data))
   } catch (error: any) {
      yield put(getManagementUserFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getManagementUserAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getManagementUserAsync())
   } catch (error) {}
}

/**
 * Change search condition saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getManagementUserAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getManagementUserAsync())
   } catch (error) {}
}

/**
 * Export excel saga
 */
function* exportExcelSaga(): any {
   try {
      yield put(startExportExcelManagementUser())
      const searchCondition = yield select(selectSearchConditionManagementUser)
      const mappedSearchCondition = searchCondition.map((e: any) => {
         if (typeof e.value === "object") {
            if (
               e.option === FilterTableEnum.CREATE_AT ||
               e.option === FilterTableEnum.USAGE
            ) {
               const from = dayjs(Object.entries(e.value)[0][1] as Dayjs)
               const to = dayjs(Object.entries(e.value)[1][1] as Dayjs)
               return [
                  e.option,
                  from.startOf("hour").format(),
                  to.endOf("day").format(),
               ]
            }
            if (e.option === FilterTableEnum.USER_STATUS) {
               return [e.option, Object.entries(e.value)[0][1]]
            }
            return [
               e.option,
               Object.entries(e.value)[0][1],
               Object.entries(e.value)[1][1],
            ]
         }

         return [e.option, e.value]
      })
      const params: GetManagementUserPayloadAPIInterface = {
         pageNumber: 1,
         pageSize: 10000,
         SearchConditions: mappedSearchCondition,
         SortCondition: {
            column: SortColumnEnum.NONE,
            order: SortOrderEnum.ASC,
         },
      }
      const { response, error } = yield call(getManagementUserExcelAPI, params)
      if (!response) {
         throw error
      }
      const columns: ColumnsExportExcel[] = [
         { header: "Họ và tên", key: "fullname" },
         { header: "Email", key: "email" },
         { header: "Số điện thoại", key: "phoneNumber" },
         { header: "Ngày sinh", key: "birthday" },
         { header: "Giới tính", key: "gender" },
         { header: "Tỉnh thành", key: "province" },
         { header: "Quận/Huyện", key: "district" },
         { header: "Phường/Xã", key: "ward" },
         { header: "Số nhà", key: "street" },
         { header: "Địa chỉ", key: "address" },
         { header: "CMND/CCCD", key: "identityCard" },
         { header: "Ngày cấp", key: "issuedDate" },
         { header: "Nơi cấp", key: "issuedBy" },
         { header: "Loại tài khoản", key: "accountTypeName" },
         { header: "Ngày đăng ký", key: "createAt" },
         { header: "Hạn dùng", key: "expiredDate" },
         { header: "Trạng thái", key: "status" },
         { header: "Ghi chú", key: "note" },
      ]
      const exportData = response.data.map((e: any) => ({
         ...e,
         createAt: convertDate(e.createAt),
         expiredDate: convertDate(e.expiredDate),
         status: e.status
            .map((s: UserStatus) => getLabelUserStatus(s))
            .join(", "),
         birthday: convertDate(e.birthday),
         issuedDate: convertDate(e.issuedDate),
         gender: getLabelGender(e.gender),
      }))

      const sum = "Tổng người dùng: " + exportData.length

      yield exportExcel({
         workBookName: "Danh sách người dùng",
         titleSheetData: "Quản lí danh sách người dùng",
         conditions: [],
         columns: columns,
         data: exportData,
         sum: sum,
      })
      yield put(
         pushNotistack({
            message: "Xuất Excel thành công",
            variant: "success",
         })
      )
      yield put(finishExportExcelManagementUser())
   } catch (error) {
      yield put(
         pushNotistack({
            message: "Xuất Excel thất bại",
            variant: "error",
         })
      )
      yield put(finishExportExcelManagementUser())
   }
}

/**
 * Root department saga
 */
export function* managementUserSaga() {
   yield takeLatest(getCountManagementUserAsync, getCountManagementUserSaga)
   yield takeLatest(getManagementUserAsync, getManagementUserSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
   yield takeLatest(exportManagementUserAsync, exportExcelSaga)
}
