import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetEmailPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getEmailAPI = (payload: GetEmailPayloadAPIInterface) => {
   return AxiosAuth.post("marketing/get-list", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface GetEmailDetailPayloadAPIInterface {
   id: string
}

export const getEmailDetailAPI = (
   payload: GetEmailDetailPayloadAPIInterface
) => {
   return AxiosAuth.get("marketing/" + payload.id)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddEmailPayloadAPIInterface {
   name: string
   content: string
   startDate: string
   endDate: string
   time: string
   allUser: number
   type: number
   userTypeIds: string[]
   userIds: string[]
}

export const addEmailAPI = (payload: AddEmailPayloadAPIInterface) => {
   return AxiosAuth.post("marketing", payload).then((response) => ({
      response,
   }))
}

export interface UpdateEmailPayloadAPIInterface {
   id: string
   name: string
   content: string
   startDate: string
   endDate: string
   time: string
   allUser: number
   type: number
   userTypeIds: string[]
   userIds: string[]
}

export const updateEmailAPI = (payload: UpdateEmailPayloadAPIInterface) => {
   return AxiosAuth.put("marketing/" + payload.id, payload).then(
      (response) => ({
         response,
      })
   )
}

export interface DeleteEmailPayloadAPIInterface {
   id: string
}

export const deleteEmailAPI = (payload: DeleteEmailPayloadAPIInterface) => {
   return AxiosAuth.delete("marketing/" + payload.id).then((response) => ({
      response,
   }))
}

export interface StartEmailPayloadAPIInterface {
   id: string
}

export const startEmailAPI = (payload: StartEmailPayloadAPIInterface) => {
   return AxiosAuth.put("marketing/start/" + payload.id).then((response) => ({
      response,
   }))
}

export interface RestartEmailPayloadAPIInterface {
   id: string
}

export const restartEmailAPI = (payload: RestartEmailPayloadAPIInterface) => {
   return AxiosAuth.get("marketing/restart/" + payload.id).then((response) => ({
      response,
   }))
}

export interface PauseEmailPayloadAPIInterface {
   id: string
}

export const pauseEmailAPI = (payload: PauseEmailPayloadAPIInterface) => {
   return AxiosAuth.put("marketing/pause/" + payload.id).then((response) => ({
      response,
   }))
}

export interface GetUserEmailPayloadAPIInterface {
   userIds: string[]
}

export const getUserEmailAPI = (payload: GetUserEmailPayloadAPIInterface) => {
   return AxiosAuth.post("marketing/get-list/user", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface SendEmailSamplePayloadAPIInterface {
   to: string[]
   subject: string
   content: string
}

export const sendEmailSampleAPI = (
   payload: SendEmailSamplePayloadAPIInterface
) => {
   return AxiosAuth.post("mail", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
