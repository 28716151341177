import { AxiosAuth } from "./config/axiosAuth.instance"

export interface PostPaymentResultAPIInterface {
   vnp_Amount: string | null
   vnp_BankCode: string | null
   vnp_CardType: string | null
   vnp_OrderInfo: string | null
   vnp_PayDate: string | null
   vnp_ResponseCode: string | null
   vnp_TmnCode: string | null
   vnp_TransactionNo: string | null
   vnp_TransactionStatus: string | null
   vnp_TxnRef: string | null
   vnp_SecureHash: string | null
   vnp_RechargePackageId: string | null
   vnp_PromotionPackageId: string | null
}

export const postPaymentResultAPI = (
   payload: PostPaymentResultAPIInterface
) => {
   return AxiosAuth.post("transaction", payload).then((response) => ({
      response,
   }))
}

export interface PutPaymentResultAPIInterface {
   vnp_Amount: string | null
   vnp_BankCode: string | null
   vnp_CardType: string | null
   vnp_OrderInfo: string | null
   vnp_PayDate: string | null
   vnp_ResponseCode: string | null
   vnp_TmnCode: string | null
   vnp_TransactionNo: string | null
   vnp_TransactionStatus: string | null
   vnp_TxnRef: string | null
   vnp_SecureHash: string | null
}

export const putPaymentResultAPI = (payload: PutPaymentResultAPIInterface) => {
   return AxiosAuth.put("transaction", payload).then((response) => ({
      response,
   }))
}
