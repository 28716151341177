import styles from "./chartUserTypeRender.module.css"
import { ResponsivePie } from "@nivo/pie"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import React from "react"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"
import commonStyles from "../../../common/styles/common.module.css"
import { TypeUserSelectInterface } from "../../../common/interfaces/typeUser.interface"
import { mappingChartPieUserType } from "../../../common/helper/chart.helper"
import { Grid } from "@mui/material"

type ChartUserTypeRenderComponentProps = {
   refChart?: any
   requesting: boolean
   searchCondition?: TypeUserSelectInterface[]
   data: any[]
   error: string
}

export const ChartUserTypeRenderComponent = (
   props: ChartUserTypeRenderComponentProps
) => {
   const { t } = useTranslation(namespaces.common)

   return (
      <React.Fragment>
         <Divider className={commonStyles.dividerMargin}>
            <Chip color="secondary" label={t("divider.chart")} />
         </Divider>
         <div>
            {/* Loading */}
            {props.requesting && (
               <div className={styles.chartHeight}>
                  <CircularProgress color="secondary" />
               </div>
            )}
            {/* Error */}
            {!props.requesting && props.error && (
               <div className={styles.chartHeight}>
                  <Typography variant="body1" color="error">
                     {t(props.error, { ns: "common" })}
                  </Typography>
               </div>
            )}
            {/* Empty */}
            {!props.requesting && !props.error && props.data.length === 0 && (
               <div className={styles.chartHeight}>
                  <Typography variant="body1">
                     {t("error.empty", { ns: "common" })}
                  </Typography>
               </div>
            )}
            <Grid container>
               {/* Pie */}
               {!props.requesting &&
                  !props.error &&
                  props.data.length > 0 &&
                  props.data.map((e, index) => (
                     <Grid
                        key={index}
                        item
                        xs={12}
                        lg={
                           props.data.length % 2 !== 0 &&
                           props.data.length - 1 === index
                              ? 12
                              : 6
                        }
                     >
                        <div
                           ref={(el) => (props.refChart[index] = el)}
                           className={styles.chartHeight}
                        >
                           <ResponsivePie
                              data={mappingChartPieUserType(e)}
                              margin={{
                                 top: 40,
                                 right: 80,
                                 bottom: 80,
                                 left: 80,
                              }}
                              colors={{ scheme: "paired" }}
                              innerRadius={0.5}
                              padAngle={0.7}
                              cornerRadius={3}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              borderColor={{
                                 from: "color",
                                 modifiers: [["darker", 0.2]],
                              }}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsThickness={2}
                              arcLinkLabelsColor={{ from: "color" }}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor={{
                                 from: "color",
                                 modifiers: [["darker", 2]],
                              }}
                              defs={[
                                 {
                                    id: "dots",
                                    type: "patternDots",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                 },
                                 {
                                    id: "lines",
                                    type: "patternLines",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                 },
                              ]}
                              fill={[
                                 {
                                    match: {
                                       id: "ruby",
                                    },
                                    id: "dots",
                                 },
                                 {
                                    match: {
                                       id: "c",
                                    },
                                    id: "dots",
                                 },
                                 {
                                    match: {
                                       id: "go",
                                    },
                                    id: "dots",
                                 },
                                 {
                                    match: {
                                       id: "python",
                                    },
                                    id: "dots",
                                 },
                                 {
                                    match: {
                                       id: "scala",
                                    },
                                    id: "lines",
                                 },
                                 {
                                    match: {
                                       id: "lisp",
                                    },
                                    id: "lines",
                                 },
                                 {
                                    match: {
                                       id: "elixir",
                                    },
                                    id: "lines",
                                 },
                                 {
                                    match: {
                                       id: "javascript",
                                    },
                                    id: "lines",
                                 },
                              ]}
                              legends={[
                                 {
                                    anchor: "bottom",
                                    direction: "row",
                                    justify: false,
                                    translateX: 0,
                                    translateY: 100,
                                    itemsSpacing: 20,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: "#999",
                                    itemDirection: "left-to-right",
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: "circle",
                                    effects: [
                                       {
                                          on: "hover",
                                          style: {
                                             itemTextColor: "#000",
                                          },
                                       },
                                    ],
                                 },
                              ]}
                           />
                        </div>
                     </Grid>
                  ))}
            </Grid>
         </div>
      </React.Fragment>
   )
}
