import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AxisTypeEnum } from "../../common/enum/axisType.enum"
import { ChartTypeEnum } from "../../common/enum/chartType.enum"
import { FilterChartGUIInterface } from "../../common/interfaces/filterChartGUI.interface"
import { TypeUserSelectInterface } from "../../common/interfaces/typeUser.interface"

export interface ChartUserTypeState {
   requesting: boolean
   excelExporting: boolean
   error: string
   successful: boolean
   filter: FilterChartGUIInterface
   searchCondition: TypeUserSelectInterface[]
   data: any[]
   dateSync: string
}

const initialState: ChartUserTypeState = {
   requesting: false,
   excelExporting: false,
   error: "",
   successful: false,
   filter: {
      chartType: ChartTypeEnum.PIE,
      axisType: AxisTypeEnum.VERTICAL,
   },
   searchCondition: [],
   data: [],
   dateSync: "",
}

export const chartUserTypeSlice = createSlice({
   name: "chartUserType",
   initialState,
   reducers: {
      requestChartUserType: (state: ChartUserTypeState) => {
         state.requesting = true
      },
      getChartUserTypeSuccess: (
         state: ChartUserTypeState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload
      },
      getChartUserTypeFail: (
         state: ChartUserTypeState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changeSearchCondtion: (
         state: ChartUserTypeState,
         action: PayloadAction<any>
      ) => {
         state.data = []
         state.dateSync = ""
         state.error = ""
         state.searchCondition = action.payload
      },
      startExportExcelChartUserType: (state: ChartUserTypeState) => {
         state.excelExporting = true
      },
      finishExportExcelChartUserType: (state: ChartUserTypeState) => {
         state.excelExporting = false
      },
      syncUserSuccess: (
         state: ChartUserTypeState,
         action: PayloadAction<string>
      ) => {
         state.dateSync = action.payload
      },
   },
})

export const {
   requestChartUserType,
   getChartUserTypeSuccess,
   getChartUserTypeFail,
   changeSearchCondtion,
   startExportExcelChartUserType,
   finishExportExcelChartUserType,
   syncUserSuccess,
} = chartUserTypeSlice.actions

export default chartUserTypeSlice.reducer
