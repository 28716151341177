import { zodResolver } from "@hookform/resolvers/zod"
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Typography,
} from "@mui/material"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import {
   SettingCompanyInput,
   settingCompanySchema,
} from "./settingCompany.form"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import LinearProgress from "@mui/material/LinearProgress"
import ApartmentIcon from "@mui/icons-material/Apartment"

type SettingCompanyComponentProps = {
   handlePushNotistack: (message: string, variant: string) => void
   handleGetCompanyAsync: () => Promise<any>
   handleUpdateCompanyAsync: (values: SettingCompanyInput) => Promise<any>
}

export const SettingCompanyComponent = (
   props: SettingCompanyComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.setting)
   // State
   const [isRequesting, setIsRequesting] = useState<boolean>(false)
   const [error, setError] = useState<string>("")

   // Form
   const {
      register,
      formState: { errors },
      handleSubmit,
      clearErrors,
      setValue,
   } = useForm<SettingCompanyInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(settingCompanySchema),
   })

   /**
    * On change accordion
    */
   const onChangeAccordion = (
      event: SyntheticEvent<Element, Event>,
      expanded: boolean
   ) => {
      if (expanded) {
         clearErrors()
         handleGetCompanyAsync()
      }
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<SettingCompanyInput> = (
      values: SettingCompanyInput
   ) => {
      setIsRequesting(true)
      props
         .handleUpdateCompanyAsync(values)
         .then((res) => {
            props.handlePushNotistack(
               "Cập nhật thông tin thành công!",
               "success"
            )
            setIsRequesting(false)
         })
         .catch((err) => {
            setIsRequesting(false)
            props.handlePushNotistack("Cập nhật thông tin thất bại!", "error")
            handleGetCompanyAsync()
         })
   }

   /**
    * Handle get company async
    */
   const handleGetCompanyAsync = useCallback(() => {
      setIsRequesting(true)
      props
         .handleGetCompanyAsync()
         .then((res) => {
            setValue("name", res.data.Name)
            setValue("taxCode", res.data.Tax)
            setValue("address", res.data.Address)
            setValue("phoneNumber", res.data.Phone)
            setValue("email", res.data.Email)
            setIsRequesting(false)
         })
         .catch((err) => {
            setError("Xảy ra lỗi khi lấy thông tin.")
            setIsRequesting(false)
         })
   }, [props, setValue])

   useEffect(() => {
      handleGetCompanyAsync()
   }, [props, setValue, handleGetCompanyAsync])

   return (
      <Accordion defaultExpanded={false} onChange={onChangeAccordion}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
         >
            <ApartmentIcon />
            <Typography ml={2}>{t("company.accordion")}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            {/* Loading */}
            {isRequesting && <LinearProgress color="secondary" />}
            {/* Error */}
            {!isRequesting && error !== "" && (
               <Typography variant="body1" color="error">
                  {error}
               </Typography>
            )}
            {/* Data */}
            {!isRequesting && error === "" && (
               <Box
                  component="form"
                  autoComplete="on"
                  noValidate
                  sx={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmitForm)}
               >
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-name"
                        label={t("company.name")}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        disabled={isRequesting}
                        {...register("name")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-taxCode"
                        label={t("company.taxCode")}
                        error={!!errors.taxCode}
                        helperText={
                           errors.taxCode ? errors.taxCode.message : ""
                        }
                        disabled={isRequesting}
                        {...register("taxCode")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-address"
                        label={t("company.address")}
                        error={!!errors.address}
                        helperText={
                           errors.address ? errors.address.message : ""
                        }
                        disabled={isRequesting}
                        {...register("address")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-phone"
                        label={t("company.phoneNumber")}
                        error={!!errors.phoneNumber}
                        helperText={
                           errors.phoneNumber ? errors.phoneNumber.message : ""
                        }
                        inputProps={{ maxLength: 10 }}
                        disabled={isRequesting}
                        {...register("phoneNumber")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-email"
                        label={t("company.email")}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                        disabled={isRequesting}
                        {...register("email")}
                     />
                  </FormControl>
                  <Stack mt={2} spacing={2} direction="row">
                     <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isRequesting}
                     >
                        {t("button.update")}
                     </Button>
                  </Stack>
               </Box>
            )}
         </AccordionDetails>
      </Accordion>
   )
}
