import { createAction } from "@reduxjs/toolkit"
import { ManagementUserDetailCompanyInput } from "../../pages/managementUserDetail/managementUserDetailCompany/managementUserDetailCompany.form"
import { ManagementUserDetailInfoInput } from "../../pages/managementUserDetail/managementUserDetailInfo/managementUserDetailInfo.form"

export const getManagementUserDetailAsync = createAction<string>(
   "managementUser/getManagementUserDetailAsync"
)

export const updateManagementUserDetailInfoAsync =
   createAction<ManagementUserDetailInfoInput>(
      "managementUser/updateManagementUserDetailInfoAsync"
   )

export const updateManagementUserDetailCompanyAsync =
   createAction<ManagementUserDetailCompanyInput>(
      "managementUser/updateManagementUserDetailCompanyAsync"
   )
