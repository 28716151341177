import commonStyles from "../../../common/styles/common.module.css"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import TablePagination from "@mui/material/TablePagination"
import Toolbar from "@mui/material/Toolbar"
import Stack from "@mui/material/Stack"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import { FilterTableComponent } from "../../../common/components/FilterTable/filterTable.component"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import DoneIcon from "@mui/icons-material/Done"
import Chip from "@mui/material/Chip"
import CloseIcon from "@mui/icons-material/Close"
import { columns, createData, filterTypes } from "./managementUserHistory.table"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import { TablePaginationActionsComponent } from "../../../common/components/TablePaginationActions/tablePaginationActions.component"

type ManagementUserHistoryComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   error: string
   data: any[]
   selectedId: string
   searchCondition: SearchConditionState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
}

export const ManagementUserHistoryComponent = (
   props: ManagementUserHistoryComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.managementUserHistory)

   // User list
   const rows = props.data.map((user) =>
      createData(
         user.id,
         user.invokeAt,
         user.featureName,
         user.application,
         user.isSuccess
      )
   )

   /**
    * Render status icon
    * @param isSuccess
    * @returns
    */
   const renderStatusIcon = (isSuccess: boolean) => {
      if (isSuccess) {
         return <Chip color="success" label="Thành công" icon={<DoneIcon />} />
      }
      return <Chip color="error" label="Thất bại" icon={<CloseIcon />} />
   }

   return (
      <Container maxWidth="xl">
         <Toolbar
            sx={{
               pl: { sm: 2 },
               pr: { xs: 1, sm: 1 },
               py: 2,
            }}
         >
            <Stack
               sx={{
                  width: "100%",
               }}
               direction={"column"}
            >
               <Stack
                  sx={{
                     width: "100%",
                  }}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
               >
                  <FilterTableComponent
                     types={filterTypes}
                     searchCondition={props.searchCondition}
                     onFilter={(value: string, option: string) =>
                        props.handleChangeSearchCondition(value, option)
                     }
                  />
                  <div></div>
               </Stack>
            </Stack>
         </Toolbar>
         <TableContainer sx={{ maxHeight: 450 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{ minWidth: column.minWidth }}
                        >
                           {t(column.label)}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {/* Loading */}
                  {props.requesting && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <div className={commonStyles.flexCenterCenter}>
                              <CircularProgress color="secondary" />
                           </div>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Error */}
                  {!props.requesting && props.error && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <Typography variant="body1" color="error">
                              {t(props.error, { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Empty */}
                  {!props.requesting && !props.error && rows.length === 0 && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <Typography variant="body1">
                              {t("table.empty", { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Data  */}
                  {!props.requesting &&
                     !props.error &&
                     rows.map((row) => {
                        return (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 if (column.id === "isSuccess") {
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{
                                             minWidth: column.minWidth,
                                             maxWidth: column.maxWidth,
                                          }}
                                       >
                                          {renderStatusIcon(row.isSuccess)}
                                       </TableCell>
                                    )
                                 } else {
                                    const value = row[column.id]
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                       >
                                          {value}
                                       </TableCell>
                                    )
                                 }
                              })}
                           </TableRow>
                        )
                     })}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            showFirstButton
            showLastButton
            labelRowsPerPage={t("table.pageSize", { ns: "common" })}
            labelDisplayedRows={(page) => {
               return `${t("table.page", { ns: "common" })}: ${
                  page.page + 1
               } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                  "table.record",
                  { ns: "common" }
               )}`
            }}
            rowsPerPageOptions={[10, 25, 100, 300, 500]}
            component="div"
            count={props.rowCount}
            rowsPerPage={props.pageSize}
            page={props.pageNumber - 1}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsComponent}
         />
      </Container>
   )
}
