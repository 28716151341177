import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import { useEffect, useRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import {
   Box,
   Button,
   DialogActions,
   DialogContent,
   DialogTitle,
   InputAdornment,
   MenuItem,
   Tooltip,
   useMediaQuery,
} from "@mui/material"
import { theme } from "../../styles/customMUI"
import { FilterMultipleTableInterface } from "../../interfaces/filterMultipleTable.interface"
import { MultipleSearchConditionState } from "../../interfaces/searchConditionParam"
import TuneIcon from "@mui/icons-material/Tune"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import { FilterTableEnum } from "../../enum/filterTable.enum"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { ProvinceSelectAsyncComponent } from "../ProvinceSelectAsync/provinceSelectAsync.component"
import { ProvinceInterface } from "../../interfaces/province.interface"
import { TimeRangeInterface } from "../../interfaces/timeRange.interface"
import React from "react"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { convertDay } from "../../helper/date.helper"
import dayjs from "dayjs"
import { CoinRangeInterface } from "../../interfaces/coinRange.interface"
import { UsageTimeRangeInterface } from "../../interfaces/usageTimeRange.interface"
import { getLabelUserStatus } from "../../helper/userStatus.helper"
import { UserStatusInterface } from "../../interfaces/userStatus.interface"
import { UserStatus } from "../../enum/userStatus.enum"

type FilterMultipleTableComponentProp = {
   types: FilterMultipleTableInterface[]
   searchCondition: MultipleSearchConditionState[]
   onFilter: (searchCondition: MultipleSearchConditionState[]) => void
}

export const FilterMultipleTableComponent = (
   props: FilterMultipleTableComponentProp
) => {
   const { t } = useTranslation(namespaces.common)
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [dataDialog, setDataDialog] = useState<MultipleSearchConditionState[]>(
      props.searchCondition
   )
   const timeRageFromRef = useRef<Array<HTMLDivElement | null>>([])
   const timeRageToRef = useRef<Array<HTMLDivElement | null>>([])
   const usageTimeRageFromRef = useRef<Array<HTMLDivElement | null>>([])
   const usageTimeRageToRef = useRef<Array<HTMLDivElement | null>>([])
   const [disableFilterButton, setDisableFilterButton] =
      useState<boolean>(false)

   /**
    * Handle open dialog
    */
   const handleOpenDialog = () => {
      setOpenDialog(true)
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
   }

   /**
    * Handle filter dialog
    */
   const handleFilterDialog = () => {
      props.onFilter(dataDialog)
      handleCloseDialog()
   }

   /**
    * Handle clear dialog
    */
   const handleClearDialog = () => {
      props.onFilter([])
      handleCloseDialog()
   }

   /**
    * Handle change dialog
    */
   const handleChangeDialog = (
      type: FilterTableEnum,
      typeIndex: number,
      value:
         | null
         | string
         | boolean
         | ProvinceInterface
         | TimeRangeInterface
         | CoinRangeInterface
         | UsageTimeRangeInterface
         | UserStatusInterface,
      isFromTimeRange?: boolean
   ) => {
      let data = JSON.parse(
         JSON.stringify(dataDialog)
      ) as MultipleSearchConditionState[]
      let index = data.findIndex((d) => d.option === type)
      if (index >= 0) {
         if (value && Object.values(value).some((x) => !!x)) {
            if (data[index].option === FilterTableEnum.CREATE_AT) {
               data[index].value = retriveDataForTimeRange(
                  data[index].value as TimeRangeInterface,
                  value as TimeRangeInterface,
                  isFromTimeRange
               )
            } else if (data[index].option === FilterTableEnum.USAGE) {
               data[index].value = retriveDataForUsageTimeRange(
                  data[index].value as UsageTimeRangeInterface,
                  value as UsageTimeRangeInterface,
                  isFromTimeRange
               )
            } else if (data[index].option === FilterTableEnum.COIN) {
               data[index].value = retriveDataForCoinRange(
                  data[index].value as CoinRangeInterface,
                  value as CoinRangeInterface,
                  isFromTimeRange
               )
            } else {
               data[index].value = value
            }
         } else {
            data.splice(index, 1)
         }
      } else {
         if (value) {
            const condition: MultipleSearchConditionState = {
               option: type,
               value: value,
            }
            data.splice(typeIndex, 0, condition)
         }
      }
      setDataDialog(data)
   }

   /**
    * Retrive data for time range
    * @param filterData
    * @param value
    * @param isFromTimeRange
    * @returns
    */
   const retriveDataForTimeRange = (
      filterData: TimeRangeInterface,
      value: TimeRangeInterface,
      isFromTimeRange?: boolean
   ) => {
      // Null
      if (!value) {
         return value
      }

      // From
      if (isFromTimeRange) {
         const result: TimeRangeInterface = {
            from: value.from,
            to: dayjs(filterData.to),
         }
         return result
      }

      // To
      const result: TimeRangeInterface = {
         from: dayjs(filterData.from),
         to: value.to,
      }
      return result
   }

   /**
    * Retrive data for coin range
    * @param filterData
    * @param value
    * @param isFromTimeRange
    * @returns
    */
   const retriveDataForCoinRange = (
      filterData: CoinRangeInterface,
      value: CoinRangeInterface,
      isFromCoinRange?: boolean
   ) => {
      // Null
      if (!value) {
         return value
      }

      // From
      if (isFromCoinRange) {
         const result: CoinRangeInterface = {
            coinFrom: value.coinFrom,
            coinTo: filterData.coinTo,
         }
         return result
      }

      // To
      const result: CoinRangeInterface = {
         coinFrom: filterData.coinFrom,
         coinTo: value.coinTo,
      }
      return result
   }

   /**
    * Retrive data for time range
    * @param filterData
    * @param value
    * @param isFromTimeRange
    * @returns
    */
   const retriveDataForUsageTimeRange = (
      filterData: UsageTimeRangeInterface,
      value: UsageTimeRangeInterface,
      isFromTimeRange?: boolean
   ) => {
      // Null
      if (!value) {
         return value
      }

      // From
      if (isFromTimeRange) {
         const result: UsageTimeRangeInterface = {
            usageFrom: value.usageFrom,
            usageTo: dayjs(filterData.usageTo),
         }
         return result
      }

      // To
      const result: UsageTimeRangeInterface = {
         usageFrom: dayjs(filterData.usageFrom),
         usageTo: value.usageTo,
      }
      return result
   }

   useEffect(() => {
      setDataDialog(props.searchCondition)
   }, [openDialog, props.searchCondition])

   useEffect(() => {
      const inValidFrom = timeRageFromRef.current
         .filter((e) => e)
         .some((e) => {
            return (
               e
                  ?.getElementsByTagName("input")[0]
                  .getAttribute("aria-invalid") === "true"
            )
         })
      const inValidTo = timeRageToRef.current
         .filter((e) => e)
         .some((e) => {
            return (
               e
                  ?.getElementsByTagName("input")[0]
                  .getAttribute("aria-invalid") === "true"
            )
         })
      const inValidUsageFrom = usageTimeRageFromRef.current
         .filter((e) => e)
         .some((e) => {
            return (
               e
                  ?.getElementsByTagName("input")[0]
                  .getAttribute("aria-invalid") === "true"
            )
         })
      const inValidUsageTo = usageTimeRageToRef.current
         .filter((e) => e)
         .some((e) => {
            return (
               e
                  ?.getElementsByTagName("input")[0]
                  .getAttribute("aria-invalid") === "true"
            )
         })
      setDisableFilterButton(
         inValidFrom || inValidTo || inValidUsageFrom || inValidUsageTo
      )
   }, [dataDialog])

   return (
      <Grid
         container
         alignItems={"center"}
         justifyContent="flex-start"
         spacing={2}
      >
         {props.searchCondition.map((e, index) => {
            let filters = props.types.filter((cond) => cond.type === e.option)
            let label = filters.length > 0 ? filters[0].text : ""
            let value
            if (e.option === FilterTableEnum.PROVINCE) {
               value = e.value as ProvinceInterface
               value = value ? value.name : null
            } else if (e.option === FilterTableEnum.COIN) {
               value = e.value as CoinRangeInterface
               let from = value.coinFrom ? value.coinFrom : "_"
               let to = value.coinTo ? value.coinTo : "_"
               value = value ? from + " - " + to : null
            } else if (e.option === FilterTableEnum.CREATE_AT) {
               value = e.value as TimeRangeInterface
               let from = value.from
                  ? convertDay(dayjs(value.from).toISOString())
                  : "_"
               let to = value.to
                  ? convertDay(dayjs(value.to).toISOString())
                  : "_"
               value = value ? from + " - " + to : null
            } else if (e.option === FilterTableEnum.USAGE) {
               value = e.value as UsageTimeRangeInterface
               let from = value.usageFrom
                  ? convertDay(dayjs(value.usageFrom).toISOString())
                  : "_"
               let to = value.usageTo
                  ? convertDay(dayjs(value.usageTo).toISOString())
                  : "_"
               value = value ? from + " - " + to : null
            } else if (e.option === FilterTableEnum.ALMOST_OUT_OF_DATE) {
               value = e.value ? "Có" : "Không"
            } else if (e.option === FilterTableEnum.USER_STATUS) {
               value = e.value as UserStatusInterface
               value = e.value ? getLabelUserStatus(value.status) : ""
            } else {
               value = e.value
            }
            return (
               <Grid key={index} item xs={12} md={6}>
                  <FormControl fullWidth>
                     <TextField
                        id={"outlined-adornment-sample" + index}
                        label={label}
                        value={value}
                        InputProps={{
                           readOnly: true,
                           startAdornment: (
                              <InputAdornment position="start">
                                 <TuneIcon />
                              </InputAdornment>
                           ),
                        }}
                     />
                  </FormControl>
               </Grid>
            )
         })}
         <Grid item xs={12} md={3}>
            <Tooltip title={t("buttons.filter")}>
               <IconButton aria-label="filter" onClick={handleOpenDialog}>
                  <FilterAltIcon />
               </IconButton>
            </Tooltip>
         </Grid>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={false}
            maxWidth={"xl"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="off"
               noValidate
               sx={{ width: "100%" }}
            >
               <DialogTitle>{t("buttons.filter")}</DialogTitle>
               <DialogContent>
                  <Grid
                     container
                     maxWidth={fullScreen ? "unset" : 600}
                     spacing={2}
                  >
                     {props.types.map((e, index) => {
                        let filters = dataDialog.filter(
                           (cond) => cond.option === e.type
                        )
                        let value = filters.length > 0 ? filters[0].value : ""
                        if (e.type === FilterTableEnum.PROVINCE) {
                           value = value as ProvinceInterface
                           return (
                              <Grid key={e.type} item xs={12}>
                                 <FormControl fullWidth>
                                    <ProvinceSelectAsyncComponent
                                       value={value}
                                       variant="standard"
                                       disabled={false}
                                       error={false}
                                       helperText={""}
                                       isMarginNormal
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <TuneIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                       onInputChange={(inputValue: any) => {
                                          handleChangeDialog(
                                             e.type,
                                             index,
                                             inputValue
                                          )
                                       }}
                                    />
                                 </FormControl>
                              </Grid>
                           )
                        }
                        if (e.type === FilterTableEnum.CREATE_AT) {
                           value = value as TimeRangeInterface
                           let from = value.from
                           let to = value.to
                           return (
                              <React.Fragment key={e.type}>
                                 <Grid
                                    item
                                    xs={6}
                                    ref={(el) =>
                                       (timeRageFromRef.current[index] = el)
                                    }
                                 >
                                    <FormControl fullWidth>
                                       <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                       >
                                          <DesktopDatePicker
                                             disableFuture
                                             disableHighlightToday
                                             label={e.text + " từ"}
                                             value={from ? from : null}
                                             inputFormat={"DD/MM/YYYY"}
                                             onChange={(
                                                val: any,
                                                keyboardInputValue?:
                                                   | string
                                                   | undefined
                                             ) => {
                                                handleChangeDialog(
                                                   e.type,
                                                   index,
                                                   {
                                                      from: val,
                                                      to: to,
                                                   },
                                                   true
                                                )
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <TuneIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             renderInput={(params) => (
                                                <TextField
                                                   {...params}
                                                   margin="normal"
                                                   variant="standard"
                                                />
                                             )}
                                          />
                                       </LocalizationProvider>
                                    </FormControl>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={6}
                                    ref={(el) =>
                                       (timeRageToRef.current[index] = el)
                                    }
                                 >
                                    <FormControl fullWidth>
                                       <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                       >
                                          <DesktopDatePicker
                                             disableFuture
                                             disableHighlightToday
                                             label={e.text + " đến"}
                                             value={to ? to : null}
                                             inputFormat={"DD/MM/YYYY"}
                                             minDate={from}
                                             onChange={(
                                                val: any,
                                                keyboardInputValue?:
                                                   | string
                                                   | undefined
                                             ) => {
                                                handleChangeDialog(
                                                   e.type,
                                                   index,
                                                   {
                                                      from: from,
                                                      to: val,
                                                   },
                                                   false
                                                )
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <TuneIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             renderInput={(params) => (
                                                <TextField
                                                   {...params}
                                                   margin="normal"
                                                   variant="standard"
                                                />
                                             )}
                                          />
                                       </LocalizationProvider>
                                    </FormControl>
                                 </Grid>
                              </React.Fragment>
                           )
                        }
                        if (e.type === FilterTableEnum.COIN) {
                           value = value as CoinRangeInterface
                           let from = value.coinFrom
                           let to = value.coinTo
                           return (
                              <React.Fragment key={e.type}>
                                 <Grid item xs={6}>
                                    <FormControl fullWidth>
                                       <TextField
                                          margin="normal"
                                          variant="standard"
                                          type="number"
                                          id="outlined-adornment-coinFrom"
                                          label={e.text + " tối thiểu"}
                                          value={from}
                                          onChange={(event) =>
                                             handleChangeDialog(
                                                e.type,
                                                index,
                                                {
                                                   coinFrom: parseInt(
                                                      event.target.value
                                                   ),
                                                   coinTo: to,
                                                },
                                                true
                                             )
                                          }
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <TuneIcon />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <FormControl fullWidth>
                                       <TextField
                                          type="number"
                                          margin="normal"
                                          variant="standard"
                                          id="outlined-adornment-coinTo"
                                          label={e.text + " tối đa"}
                                          value={to}
                                          onChange={(event) =>
                                             handleChangeDialog(e.type, index, {
                                                coinFrom: from,
                                                coinTo: parseInt(
                                                   event.target.value
                                                ),
                                             })
                                          }
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <TuneIcon />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </FormControl>
                                 </Grid>
                              </React.Fragment>
                           )
                        }
                        if (e.type === FilterTableEnum.USAGE) {
                           value = value as UsageTimeRangeInterface
                           let from = value.usageFrom
                           let to = value.usageTo
                           return (
                              <React.Fragment key={e.type}>
                                 <Grid
                                    item
                                    xs={6}
                                    ref={(el) =>
                                       (usageTimeRageFromRef.current[index] =
                                          el)
                                    }
                                 >
                                    <FormControl fullWidth>
                                       <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                       >
                                          <DesktopDatePicker
                                             disableFuture
                                             disableHighlightToday
                                             label={e.text + " từ"}
                                             value={from ? from : null}
                                             inputFormat={"DD/MM/YYYY"}
                                             onChange={(
                                                val: any,
                                                keyboardInputValue?:
                                                   | string
                                                   | undefined
                                             ) => {
                                                handleChangeDialog(
                                                   e.type,
                                                   index,
                                                   {
                                                      usageFrom: val,
                                                      usageTo: to,
                                                   },
                                                   true
                                                )
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <TuneIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             renderInput={(params) => (
                                                <TextField
                                                   {...params}
                                                   margin="normal"
                                                   variant="standard"
                                                />
                                             )}
                                          />
                                       </LocalizationProvider>
                                    </FormControl>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={6}
                                    ref={(el) =>
                                       (usageTimeRageToRef.current[index] = el)
                                    }
                                 >
                                    <FormControl fullWidth>
                                       <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                       >
                                          <DesktopDatePicker
                                             disableHighlightToday
                                             label={e.text + " đến"}
                                             value={to ? to : null}
                                             inputFormat={"DD/MM/YYYY"}
                                             minDate={from}
                                             onChange={(
                                                val: any,
                                                keyboardInputValue?:
                                                   | string
                                                   | undefined
                                             ) => {
                                                handleChangeDialog(
                                                   e.type,
                                                   index,
                                                   {
                                                      usageFrom: from,
                                                      usageTo: val,
                                                   },
                                                   false
                                                )
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <TuneIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             renderInput={(params) => (
                                                <TextField
                                                   {...params}
                                                   margin="normal"
                                                   variant="standard"
                                                />
                                             )}
                                          />
                                       </LocalizationProvider>
                                    </FormControl>
                                 </Grid>
                              </React.Fragment>
                           )
                        }
                        if (e.type === FilterTableEnum.USER_STATUS) {
                           value = value as UserStatusInterface
                           return (
                              <Grid key={e.type} item xs={12}>
                                 <FormControl fullWidth>
                                    <TextField
                                       select
                                       type="text"
                                       margin="normal"
                                       variant="standard"
                                       id="outlined-adornment-user-status"
                                       label={e.text}
                                       value={value.status}
                                       onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                       ) => {
                                          handleChangeDialog(e.type, index, {
                                             status: event.target
                                                .value as UserStatus,
                                          })
                                       }}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <TuneIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    >
                                       {Object.values(UserStatus).map(
                                          (status) => {
                                             return (
                                                <MenuItem
                                                   key={status}
                                                   value={status}
                                                >
                                                   {getLabelUserStatus(status)}
                                                </MenuItem>
                                             )
                                          }
                                       )}
                                    </TextField>
                                 </FormControl>
                              </Grid>
                           )
                        }
                        return (
                           <Grid key={e.type} item xs={12}>
                              <FormControl key={e.type} fullWidth>
                                 <TextField
                                    id={"outlined-adornment-sample" + index}
                                    type="text"
                                    defaultValue={value}
                                    margin="normal"
                                    variant="standard"
                                    label={e.text}
                                    onChange={(event) =>
                                       handleChangeDialog(
                                          e.type,
                                          index,
                                          event.target.value
                                       )
                                    }
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <TuneIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </FormControl>
                           </Grid>
                        )
                     })}
                  </Grid>
               </DialogContent>
               <DialogActions>
                  <Button color="error" onClick={handleCloseDialog}>
                     {t("buttons.cancel")}
                  </Button>
                  <Button
                     endIcon={<FilterAltOffIcon />}
                     onClick={handleClearDialog}
                  >
                     {t("buttons.delete")}
                  </Button>
                  <Button
                     disabled={disableFilterButton}
                     endIcon={<FilterAltIcon />}
                     onClick={handleFilterDialog}
                  >
                     {t("buttons.filter")}
                  </Button>
               </DialogActions>
            </Box>
         </Dialog>
      </Grid>
   )
}
