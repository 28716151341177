export const getLabelGender = (value: number) => {
   let result = ""
   switch (value) {
      case 1:
         result = "Nam"
         break
      case 2:
         result = "Nữ"
         break
      default:
         break
   }
   return result
}
