import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import { getProvinceAPI } from "../../../api/province.api"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { ProvinceInterface } from "../../interfaces/province.interface"

type ProvinceSelectAsyncComponentProps = {
   multiple?: boolean
   required?: boolean
   disabled?: boolean
   defaultValue?: ProvinceInterface | ProvinceInterface[]
   value?: ProvinceInterface | ProvinceInterface[]
   variant?: "standard" | "filled" | "outlined"
   error: boolean
   helperText: string | undefined
   InputProps?: any
   isMarginNormal?: boolean
   onInputChange: (value: ProvinceInterface) => void
}

export const ProvinceSelectAsyncComponent = (
   props: ProvinceSelectAsyncComponentProps
) => {
   const { t } = useTranslation(namespaces.common)

   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState<readonly ProvinceInterface[]>(
      []
   )
   const loading = open && options.length === 0

   React.useEffect(() => {
      let active = true

      if (!loading) {
         return undefined
      }

      ;(async () => {
         getProvinceAPI().then((res) => {
            const optionData = res.data.map((e: any) => {
               const province: ProvinceInterface = {
                  id: e.id,
                  name: e.name,
               }
               return province
            })
            setOptions(optionData)
         })
         if (active) {
         }
      })()

      return () => {
         active = false
      }
   }, [loading])

   React.useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])

   return (
      <Autocomplete
         id="asynchronous-province"
         multiple={props.multiple ? props.multiple : false}
         open={open}
         onOpen={() => {
            setOpen(true)
         }}
         onClose={() => {
            setOpen(false)
         }}
         onChange={(event: any, newValue: any | null) => {
            props.onInputChange(newValue)
         }}
         value={props.value ? props.value : null}
         defaultValue={props.defaultValue ? props.defaultValue : null}
         isOptionEqualToValue={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.name}
         options={options}
         loading={loading}
         disabled={props.disabled}
         clearIcon={null}
         noOptionsText={t("dialog.noOption")}
         renderOption={(htmlProps, option) => {
            return (
               <li {...htmlProps} key={option.id}>
                  {option.name}
               </li>
            )
         }}
         readOnly={props.InputProps?.readOnly ? true : false}
         renderInput={(params) => (
            <TextField
               {...params}
               required={props.required ? true : false}
               margin={props.isMarginNormal ? "normal" : "none"}
               fullWidth
               variant={props.variant}
               error={props.error}
               helperText={props.helperText}
               label={t("field.province")}
               InputProps={{
                  ...params.InputProps,
                  ...props.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {loading ? (
                           <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}
