import { DepartmentUserState } from "./departmentUser.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectDepartmentUserState = (state: RootState): DepartmentUserState =>
   state.departmentUser

export const selectRequestingDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.requesting
)

export const selectSelectedDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.selectedId
)

export const selectDataDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.data
)

export const selectErrorDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.error
)

export const selectCountDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.rowCount
)

export const selectPageNumberDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.paging.pageNumber
)

export const selectPageSizeDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.paging.pageSize
)

export const selectSearchConditionDepartmentUser = createSelector(
   selectDepartmentUserState,
   (state: DepartmentUserState) => state.searchCondition
)
