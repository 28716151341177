import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   getDepartmentUserSuccess,
   getDepartmentUserFail,
   requestDepartmentUser,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
} from "./departmentUser.slice"
import {
   getDepartmentUserAPI,
   GetDepartmentUserPayloadAPIInterface,
} from "../../api/departmentUser.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getDepartmentUserAsync,
   reloadGetDepartmentUserAsync,
} from "./departmentUser.action"
import {
   selectPageNumberDepartmentUser,
   selectPageSizeDepartmentUser,
   selectSearchConditionDepartmentUser,
   selectSelectedDepartmentUser,
} from "./departmentUser.selector"

/**
 * Get departments saga
 * @param action
 */
function* getDepartmentUserSaga(action: PayloadAction<any>): any {
   try {
      yield put(requestDepartmentUser(action.payload))
      const pageNumber = yield select(selectPageNumberDepartmentUser)
      const pageSize = yield select(selectPageSizeDepartmentUser)
      const searchCondition = yield select(selectSearchConditionDepartmentUser)
      const selectedDepartment = yield select(selectSelectedDepartmentUser)
      const params: GetDepartmentUserPayloadAPIInterface = {
         PageNumber: pageNumber,
         PageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         id: selectedDepartment,
      }
      const { response, error } = yield call(getDepartmentUserAPI, params)
      if (!response) {
         throw error
      }
      yield put(getDepartmentUserSuccess(response.data))
   } catch (error: any) {
      yield put(getDepartmentUserFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      const selectedDepartment = yield select(selectSelectedDepartmentUser)
      yield put(getDepartmentUserAsync(selectedDepartment))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      const selectedDepartment = yield select(selectSelectedDepartmentUser)
      yield put(getDepartmentUserAsync(selectedDepartment))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      const selectedDepartment = yield select(selectSelectedDepartmentUser)
      yield put(getDepartmentUserAsync(selectedDepartment))
   } catch (error) {}
}

/**
 * Reload get department user saga
 */
function* reloadGetDepartmentUserSaga(): any {
   try {
      const selectedDepartment = yield select(selectSelectedDepartmentUser)
      yield put(getDepartmentUserAsync(selectedDepartment))
   } catch (error) {}
}

/**
 * Root department saga
 */
export function* departmentUserSaga() {
   yield takeLatest(getDepartmentUserAsync, getDepartmentUserSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(reloadGetDepartmentUserAsync, reloadGetDepartmentUserSaga)
}
