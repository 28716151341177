import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { WardInterface } from "../../interfaces/ward.interface"
import { getWardAPI } from "../../../api/ward.api"

type WardSelectAsyncComponentProps = {
   multiple?: boolean
   disabled: boolean
   value: WardInterface
   variant?: "standard" | "filled" | "outlined"
   error: boolean
   helperText: string | undefined
   InputProps?: any
   isMarginNormal?: boolean
   onInputChange: (value: WardInterface) => void
   districtId?: string
}

export const WardSelectAsyncComponent = (
   props: WardSelectAsyncComponentProps
) => {
   const { t } = useTranslation(namespaces.common)

   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState<readonly WardInterface[]>([])
   const loading = open && options.length === 0

   React.useEffect(() => {
      if (!loading) {
         return undefined
      }

      ;(async () => {
         getWardAPI(props.districtId ? props.districtId : "")
            .then((res) => {
               if (!res.data || res.data.length === 0) {
                  setOpen(false)
               } else {
                  const optionData = res.data.map((e: any) => {
                     const ward: WardInterface = {
                        id: e.id,
                        name: e.name,
                     }
                     return ward
                  })
                  setOptions(optionData)
               }
            })
            .catch((err) => {
               setOpen(false)
            })
      })()
   }, [loading, props.districtId])

   React.useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])

   return (
      <Autocomplete
         id="asynchronous-ward"
         multiple={props.multiple ? props.multiple : false}
         open={open}
         onOpen={() => {
            if (props.districtId) {
               setOpen(true)
            }
         }}
         onClose={() => {
            setOpen(false)
         }}
         onChange={(event: any, newValue: any | null) => {
            props.onInputChange(newValue)
         }}
         value={props.value ? props.value : null}
         isOptionEqualToValue={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.name}
         options={options}
         loading={loading}
         disabled={props.disabled}
         clearIcon={null}
         noOptionsText={t("dialog.noOption")}
         renderOption={(htmlProps, option) => {
            return (
               <li {...htmlProps} key={option.id}>
                  {option.name}
               </li>
            )
         }}
         readOnly={props.InputProps?.readOnly ? true : false}
         renderInput={(params) => (
            <TextField
               {...params}
               margin={props.isMarginNormal ? "normal" : "none"}
               fullWidth
               variant={props.variant}
               error={props.error}
               helperText={props.helperText}
               label={t("field.ward")}
               InputProps={{
                  ...params.InputProps,
                  ...props.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {loading ? (
                           <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}
