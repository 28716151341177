import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import AddIcon from "@mui/icons-material/Add"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import CircularProgress from "@mui/material/CircularProgress"
import commonStyles from "../../common/styles/common.module.css"
import Typography from "@mui/material/Typography"
import TablePagination from "@mui/material/TablePagination"
import { columns, createData, filterTypes } from "./apps.table"
import ReplayIcon from "@mui/icons-material/Replay"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { AppsInterface } from "../../common/interfaces/apps.interface"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { useAppsPermission } from "./apps.permission"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"
import { FilterTableComponent } from "../../common/components/FilterTable/filterTable.component"
import { AppsDialogInput, appsDialogSchema } from "./apps.form"
import React, { useState } from "react"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import EditIcon from "@mui/icons-material/Edit"
import { zodResolver } from "@hookform/resolvers/zod"
import {
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControlLabel,
   IconButton,
   LinearProgress,
   TextField,
   Tooltip,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { AppsStatus } from "../../common/enum/appsStatus.enum"
import { AppsStatusComponent } from "../../common/components/AppsStatus/appsStatus.component"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"

type AppsComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   data: AppsInterface[]
   error: string
   searchCondition: SearchConditionState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleUpdateAppsAsync: (
      id: string,
      values: AppsDialogInput,
      isActive: boolean
   ) => Promise<any>
   handleAddAppsAsync: (values: AppsDialogInput) => Promise<any>
   handleGetKeyAppsAsync: (id: string) => Promise<any>
   handleDeleteAppsAsync: (id: string) => Promise<any>
   handleReloadGetAppsAsync: () => void
}

export const AppsComponent = (props: AppsComponentProps) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
   const { t } = useTranslation(namespaces.pages.apps)
   const { allowAddApps, allowGetKeyApps, allowDeleteApps, allowUpdateApps } =
      useAppsPermission()

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.NONE
   )
   const [selectedDialog, setSelectedDialog] = useState<AppsInterface | null>(
      null
   )

   // Transaction list
   const rows = props.data.map((app) =>
      createData(
         app.id,
         app.name,
         app.isActive,
         app.createdAt,
         app.zaloOaId,
         app.zaloUserName,
         app.zaloOtpTemplateId,
         app.isUseOtpSystem
      )
   )

   // Form department dialog
   const {
      register,
      formState: { errors },
      handleSubmit,
      setValue,
      watch,
      control,
      reset,
   } = useForm<AppsDialogInput>({
      resolver: zodResolver(appsDialogSchema),
   })

   const isUseOtpSystem = watch("isUseOtpSystem")

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: AppsInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      setSelectedDialog(selected)
      setValue("name", selected ? selected.name : "")
      setValue("zaloOaId", selected ? selected.zaloOaId : undefined)
      setValue("zaloUserName", selected ? selected.zaloUserName : undefined)
      setValue(
         "zaloOtpTemplateId",
         selected ? selected.zaloOtpTemplateId : undefined
      )
      setValue("isUseOtpSystem", selected ? selected.isUseOtpSystem : true)
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      setValue("name", "")
      setValue("zaloOaId", undefined)
      setValue("zaloUserName", undefined)
      setValue("zaloOtpTemplateId", undefined)
      reset()
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<AppsDialogInput> = (
      values: AppsDialogInput
   ) => {
      setRequestingDialog(true)
      if (typeDialog === DialogMethodEnum.ADD) {
         props
            .handleAddAppsAsync(values)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack("Thêm ứng dụng thành công!", "success")
               setOpenDialog(false)
               props.handleReloadGetAppsAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               if (err.response.data?.detail != null) {
                  props.handlePushNotistack(err.response.data?.detail, "error")
               } else {
                  props.handlePushNotistack("Thêm ứng dụng thất bại!", "error")
               }
            })
      }
      if (typeDialog === DialogMethodEnum.UPDATE && selectedDialog !== null) {
         props
            .handleUpdateAppsAsync(
               selectedDialog?.id,
               values,
               selectedDialog?.isActive
            )
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Cập nhật ứng dụng thành công!",
                  "success"
               )
               setOpenDialog(false)
               props.handleReloadGetAppsAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               if (err.response.data?.detail != null) {
                  props.handlePushNotistack(err.response.data?.detail, "error")
               } else {
                  props.handlePushNotistack(
                     "Cập nhật ứng dụng thất bại!",
                     "error"
                  )
               }
            })
      }
   }

   /**
    * On active apps
    */
   const onActiveApps = (isActive: boolean) => {
      if (selectedDialog == null) {
         return
      }
      setRequestingDialog(true)
      props
         .handleUpdateAppsAsync(selectedDialog?.id, selectedDialog, isActive)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Cập nhật trạng thái ứng dụng thành công!",
               "success"
            )
            setOpenDialog(false)
            props.handleReloadGetAppsAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            if (err.response.data?.detail != null) {
               props.handlePushNotistack(err.response.data?.detail, "error")
            } else {
               props.handlePushNotistack(
                  "Cập nhật trạng thái ứng dụng thất bại!",
                  "error"
               )
            }
         })
   }

   /**
    * On delete apps
    */
   const onDeleteApps = () => {
      setRequestingDialog(true)
      props
         .handleDeleteAppsAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa ứng dụng thành công!", "success")
            setOpenDialog(false)
            props.handleReloadGetAppsAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa ứng dụng thất bại!", "error")
         })
   }

   /**
    * On delete apps
    */
   const onGetKeyApps = () => {
      setRequestingDialog(true)
      props
         .handleGetKeyAppsAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Đã tạo lại mật khẩu cho ứng dụng, thông tin đã được gửi vào email của hệ thống!",
               "success"
            )
            setOpenDialog(false)
            props.handleReloadGetAppsAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Tạo lại mật khẩu cho ứng dụng thất bại!",
               "error"
            )
         })
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("button.addApps")}
            {typeDialog === DialogMethodEnum.UPDATE && t("button.updateApps")}
            {typeDialog === DialogMethodEnum.START && t("button.activeApps")}
            {typeDialog === DialogMethodEnum.PAUSE && t("button.deactiveApps")}
            {typeDialog === DialogMethodEnum.DELETE && t("button.deleteApps")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button type="submit" disabled={requestingDialog}>
                  {t("button.add")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.UPDATE && (
               <Button type="submit" disabled={requestingDialog}>
                  {t("button.update")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.START && (
               <Button
                  type="submit"
                  disabled={requestingDialog}
                  onClick={() => onActiveApps(true)}
               >
                  {t("button.active")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.PAUSE && (
               <Button
                  type="submit"
                  disabled={requestingDialog}
                  onClick={() => onActiveApps(false)}
               >
                  {t("button.deactive")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeleteApps}
               >
                  {t("button.delete")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DETAIL && (
               <Button disabled={requestingDialog} onClick={onGetKeyApps}>
                  {t("button.getKey")}
               </Button>
            )}
            <Button color="info" onClick={handleCloseDialog}>
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteAppsConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.DETAIL) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.getKeyAppsConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.PAUSE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deactiveAppsConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }

      if (typeDialog === DialogMethodEnum.START) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.activeAppsConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }

      return (
         <DialogContent>
            <TextField
               autoFocus
               required
               margin="normal"
               id="name"
               label={t("appsTable.name")}
               fullWidth
               variant="standard"
               disabled={requestingDialog}
               error={!!errors.name}
               helperText={errors.name ? errors.name.message : ""}
               {...register("name")}
            />
            <Controller
               control={control}
               name="isUseOtpSystem"
               defaultValue={true}
               render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                     sx={{
                        marginTop: "25px",
                     }}
                     label={t("appsTable.isUseOtpSystem")}
                     control={<Checkbox checked={value} onChange={onChange} />}
                  />
               )}
            />
            {!isUseOtpSystem && (
               <React.Fragment>
                  <TextField
                     required
                     margin="normal"
                     id="zaloOaId"
                     label={t("appsTable.zaloOaId")}
                     fullWidth
                     variant="standard"
                     disabled={requestingDialog}
                     error={!!errors.zaloOaId}
                     helperText={errors.zaloOaId ? errors.zaloOaId.message : ""}
                     {...register("zaloOaId")}
                  />
                  <TextField
                     required
                     margin="normal"
                     id="zaloUserName"
                     label={t("appsTable.zaloUserName")}
                     fullWidth
                     variant="standard"
                     disabled={requestingDialog}
                     error={!!errors.zaloUserName}
                     helperText={
                        errors.zaloUserName ? errors.zaloUserName.message : ""
                     }
                     {...register("zaloUserName")}
                  />
                  <TextField
                     required
                     margin="normal"
                     id="zaloOtpTemplateId"
                     label={t("appsTable.zaloOtpTemplateId")}
                     fullWidth
                     variant="standard"
                     disabled={requestingDialog}
                     error={!!errors.zaloOtpTemplateId}
                     helperText={
                        errors.zaloOtpTemplateId
                           ? errors.zaloOtpTemplateId.message
                           : ""
                     }
                     {...register("zaloOtpTemplateId")}
                  />
               </React.Fragment>
            )}
         </DialogContent>
      )
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        types={filterTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  {allowAddApps && (
                     <Grid item xs={12} md={12} xl={6}>
                        <Grid container spacing={2} justifyContent="flex-end">
                           <Grid item xs={12} sm={12} xl={4}>
                              <Button
                                 fullWidth
                                 sx={{
                                    minHeight: "36px",
                                 }}
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() =>
                                    handleOpenDialog(DialogMethodEnum.ADD)
                                 }
                              >
                                 {t("button.addApps")}
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  )}
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {t(column.label)}
                           </TableCell>
                        ))}
                        <TableCell key={"action"} style={{ minWidth: 170 }}>
                           {t("appsTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 let value = row[column.id]
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {column.id === "isActive" ? (
                                          <AppsStatusComponent
                                             status={
                                                value
                                                   ? AppsStatus.ACTIVE
                                                   : AppsStatus.PAUSE
                                             }
                                          ></AppsStatusComponent>
                                       ) : (
                                          <div>
                                             {column.format
                                                ? column.format(value)
                                                : value}
                                          </div>
                                       )}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 {allowGetKeyApps && (
                                    <Tooltip title={t("button.getKey")}>
                                       <IconButton
                                          aria-label="edit"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DETAIL,
                                                row
                                             )
                                          }
                                       >
                                          <ReplayIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowDeleteApps && (
                                    <Tooltip title={t("button.delete")}>
                                       <IconButton
                                          aria-label="update"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DELETE,
                                                row
                                             )
                                          }
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowUpdateApps && (
                                    <Tooltip title={t("button.update")}>
                                       <IconButton
                                          aria-label="update"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.UPDATE,
                                                row
                                             )
                                          }
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {!row.isActive && allowUpdateApps && (
                                    <Tooltip title={t("button.active")}>
                                       <IconButton
                                          aria-label="start"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.START,
                                                row
                                             )
                                          }
                                       >
                                          <PlayArrowIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {row.isActive && allowUpdateApps && (
                                    <Tooltip title={t("button.deactive")}>
                                       <IconButton
                                          aria-label="deactive"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.PAUSE,
                                                row
                                             )
                                          }
                                       >
                                          <PauseIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <Dialog
            fullScreen={fullScreen}
            maxWidth={"sm"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="on"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Container>
   )
}
