import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import { FilterTableEnum } from "../../enum/filterTable.enum"
import { SearchConditionState } from "../../interfaces/searchConditionParam"

type FilterTableComponentProps = {
   types: FilterTableEnum[]
   searchCondition: SearchConditionState
   onFilter: (value: string, option: string) => void
}

export const FilterTableComponent = (props: FilterTableComponentProps) => {
   const { t } = useTranslation(namespaces.common)

   const [option, setOption] = useState(props.searchCondition.option)
   const [value, setValue] = useState(props.searchCondition.value)

   useEffect(() => {
      setOption(props.searchCondition.option)
      setValue(props.searchCondition.value)
   }, [props.searchCondition])

   /**
    * Render option
    * @param type
    * @returns
    */
   const renderOption = (type: FilterTableEnum) => {
      return (
         <MenuItem key={type} value={type}>
            {getLabelSearch(type)}
         </MenuItem>
      )
   }

   /**
    * Get label search input
    * @param type
    * @returns
    */
   const getLabelSearch = (type: FilterTableEnum) => {
      if (type === FilterTableEnum.FULLNAME) {
         return t("filter.fullname")
      }
      if (type === FilterTableEnum.EMAIL) {
         return t("filter.email")
      }
      if (type === FilterTableEnum.PHONE_NUMBER) {
         return t("filter.phoneNumber")
      }
      if (type === FilterTableEnum.DEPARTMENT_NAME) {
         return t("filter.departmentName")
      }
      if (type === FilterTableEnum.USER_TYPE_NAME) {
         return t("filter.typeName")
      }
      if (type === FilterTableEnum.RECHARGE_PACKAGE_NAME) {
         return t("filter.rechargePackageName")
      }
      if (type === FilterTableEnum.TITLE) {
         return t("filter.title")
      }
      if (type === FilterTableEnum.TEMPLATE_NAME) {
         return t("filter.template")
      }
      if (type === FilterTableEnum.EMAIL_NAME) {
         return t("filter.emailName")
      }
      if (type === FilterTableEnum.APP_NAME) {
         return t("filter.appsName")
      }
   }

   /**
    * Handle change options
    * @param event
    */
   const handleChangeOption = (event: SelectChangeEvent) => {
      setOption(event.target.value as any)
   }

   /**
    * Handle change value
    * @param event
    */
   const handleChangeValue = (event: any) => {
      setValue(event.target.value as string)
   }

   /**
    * Handle change value
    * @param event
    */
   const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
         props.onFilter(value, option)
      }
   }

   return (
      <Grid
         container
         alignItems={"center"}
         justifyContent="flex-start"
         spacing={2}
      >
         {props.types.length > 1 && (
            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                     {t("filter.searchType")}
                  </InputLabel>
                  <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={option}
                     label={t("filter.searchType")}
                     onChange={handleChangeOption}
                  >
                     {props.types.map((type) => renderOption(type))}
                  </Select>
               </FormControl>
            </Grid>
         )}
         <Grid
            item
            xs={12}
            md={props.types.length > 1 ? 6 : 12}
            lg={props.types.length > 1 ? 6 : 12}
            xl={6}
         >
            <FormControl fullWidth>
               <TextField
                  id="outlined-adornment-phoneNumber"
                  label={
                     props.types.length > 1
                        ? t("filter.searchValue")
                        : getLabelSearch(props.types[0])
                  }
                  value={value}
                  onChange={handleChangeValue}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="filter"
                              onClick={() => props.onFilter(value, option)}
                           >
                              <SearchIcon />
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
            </FormControl>
         </Grid>
      </Grid>
   )
}
