import { useCallback, useEffect, useState } from "react"
import { TypeUserDetailComponent } from "./typeUserDetail.component"
import { TypeUserInterface } from "../../../common/interfaces/typeUser.interface"
import { SortColumnEnum } from "../../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../../common/enum/sortOrder.enum"
import { FilterTableEnum } from "../../../common/enum/filterTable.enum"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import {
   GetTypeUserDetailPayloadAPIInterface,
   getTypeUserDetailAPI,
} from "../../../api/typeUser.api"

type TypeUserDetailContainerProps = {
   typeUser: TypeUserInterface
}

export const TypeUserDetailContainer = (
   props: TypeUserDetailContainerProps
) => {
   const [requesting, setRequesting] = useState<boolean>(false)
   const [pageSize, setPageSize] = useState<number>(10)
   const [pageNumber, setPageNumber] = useState<number>(1)
   const [searchCondition, setSearchCondition] = useState<SearchConditionState>(
      {
         option: FilterTableEnum.NONE,
         value: "",
      }
   )
   const [rowCount, setRowCount] = useState<number>(0)
   const [userData, setUserData] = useState<any[]>([])
   const [error, setError] = useState<string>("")

   /**
    * Handle get department user adding async
    */
   const handleGetTypeUserDetailAsync = useCallback(() => {
      setRequesting(true)
      const params: GetTypeUserDetailPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [
            ["USERTYPECONDITIONID", props.typeUser.id],
            [searchCondition.option, searchCondition.value],
         ],
         SortCondition: {
            column: SortColumnEnum.NONE,
            order: SortOrderEnum.ASC,
         },
      }
      getTypeUserDetailAPI(params)
         .then((res: any) => {
            setUserData(res.data.data)
            setRowCount(res.data.rowCount)
         })
         .catch(() => {
            setError("table.error")
         })
         .finally(() => {
            setRequesting(false)
         })
   }, [pageNumber, pageSize, searchCondition, props.typeUser])

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage + 1)
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setPageSize(Number(event.target.value))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      setSearchCondition({
         value: value,
         option: option as FilterTableEnum,
      })
   }

   useEffect(() => {
      handleGetTypeUserDetailAsync()
   }, [pageSize, pageNumber, searchCondition, handleGetTypeUserDetailAsync])

   return (
      <TypeUserDetailComponent
         typeUser={props.typeUser}
         requesting={requesting}
         pageSize={pageSize}
         pageNumber={pageNumber}
         rowCount={rowCount}
         userData={userData}
         error={error}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handleChangeSearchCondition={handleChangeSearchCondition}
      />
   )
}
