import * as React from "react"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"

type TablePaginationActionsComponentProps = {
   count: number
   page: number
   rowsPerPage: number
   onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
   ) => void
}

export const TablePaginationActionsComponent = (
   props: TablePaginationActionsComponentProps
) => {
   const { count, page, rowsPerPage, onPageChange } = props

   const handlePageButtonClick = (
      event: React.ChangeEvent<unknown>,
      selectPage: number
   ) => {
      onPageChange(event as React.MouseEvent<HTMLButtonElement>, selectPage - 1)
   }

   return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
         <Pagination
            showFirstButton
            showLastButton
            page={page + 1}
            count={Math.ceil(count / rowsPerPage)}
            color="secondary"
            onChange={(event: React.ChangeEvent<unknown>, selectPage: number) =>
               handlePageButtonClick(event, selectPage)
            }
         />
      </Box>
   )
}
