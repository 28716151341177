import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetManagementWalletPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody {}

export const getManagementWalletAPI = (
   payload: GetManagementWalletPayloadAPIInterface
) => {
   return AxiosAuth.post("wallet/get-wallet", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
