import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import PasswordIcon from "@mui/icons-material/Password"
import LinearProgress from "@mui/material/LinearProgress"
import {
   SettingPasswordInput,
   settingPasswordSchema,
} from "./settingPassword.form"

type SettingPasswordComponentProps = {
   handlePushNotistack: (message: string, variant: string) => void
   handleResetPasswordAsync: (values: SettingPasswordInput) => Promise<any>
}

export const SettingPasswordComponent = (
   props: SettingPasswordComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.setting)

   // State
   const [isSettingPasswordRequesting, setIsSettingPasswordRequesting] =
      useState<boolean>(false)
   const [showPassword, setShowPassword] = useState<boolean>(false)
   const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
   const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
      useState<boolean>(false)

   // Form
   const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      clearErrors,
   } = useForm<SettingPasswordInput>({
      resolver: zodResolver(settingPasswordSchema),
   })

   /**
    * Handle mouse down password
    * @param event
    */
   const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
   }

   /**
    * Handle click show password
    */
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
   }

   /**
    * Handle click show password
    */
   const handleClickShowNewPassword = () => {
      setShowNewPassword(!showNewPassword)
   }

   /**
    * Handle click show password
    */
   const handleClickShowNewPasswordConfirmation = () => {
      setShowNewPasswordConfirmation(!showNewPasswordConfirmation)
   }

   /**
    * On change accordion
    */
   const onChangeAccordion = () => {
      reset()
      clearErrors()
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<SettingPasswordInput> = (
      values: SettingPasswordInput
   ) => {
      setIsSettingPasswordRequesting(true)
      props
         .handleResetPasswordAsync(values)
         .then((res) => {
            props.handlePushNotistack(
               "Mật khẩu cập nhật thành công!",
               "success"
            )
            setIsSettingPasswordRequesting(false)
            reset()
            clearErrors()
         })
         .catch((err) => {
            setIsSettingPasswordRequesting(false)
            props.handlePushNotistack("Mật khẩu cập nhật thất bại!", "error")
         })
   }

   return (
      <Accordion defaultExpanded={false} onChange={onChangeAccordion}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
         >
            <PasswordIcon />
            <Typography ml={2}>{t("changePassword.accordion")}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            {isSettingPasswordRequesting && (
               <LinearProgress color="secondary" />
            )}
            <Box
               component="form"
               autoComplete="on"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               <FormControl fullWidth margin="normal">
                  <TextField
                     required
                     id="outlined-adornment-password"
                     label={t("changePassword.password")}
                     type={showPassword ? "text" : "password"}
                     error={!!errors.password}
                     defaultValue={undefined}
                     InputProps={{
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={() => handleClickShowPassword()}
                                 onMouseDown={handleMouseDownPassword}
                                 edge="end"
                              >
                                 {showPassword ? (
                                    <VisibilityOff />
                                 ) : (
                                    <Visibility />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                     helperText={errors.password ? errors.password.message : ""}
                     disabled={isSettingPasswordRequesting}
                     {...register("password")}
                  />
               </FormControl>
               <FormControl fullWidth margin="normal">
                  <TextField
                     required
                     id="outlined-adornment-new-password"
                     label={t("changePassword.newPassword")}
                     type={showNewPassword ? "text" : "password"}
                     error={!!errors.newPassword}
                     InputProps={{
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle new password confirm visibility"
                                 onClick={() => handleClickShowNewPassword()}
                                 onMouseDown={handleMouseDownPassword}
                                 edge="end"
                              >
                                 {showNewPassword ? (
                                    <VisibilityOff />
                                 ) : (
                                    <Visibility />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                     helperText={
                        errors.newPassword ? errors.newPassword.message : ""
                     }
                     disabled={isSettingPasswordRequesting}
                     {...register("newPassword")}
                  />
               </FormControl>
               <FormControl fullWidth margin="normal">
                  <TextField
                     required
                     id="outlined-adornment-new-password-confirmation"
                     label={t("changePassword.confirmNewPassword")}
                     type={showNewPasswordConfirmation ? "text" : "password"}
                     error={!!errors.newPasswordConfirmation}
                     InputProps={{
                        endAdornment: (
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle new password confirm visibility"
                                 onClick={() =>
                                    handleClickShowNewPasswordConfirmation()
                                 }
                                 onMouseDown={handleMouseDownPassword}
                                 edge="end"
                              >
                                 {showNewPasswordConfirmation ? (
                                    <VisibilityOff />
                                 ) : (
                                    <Visibility />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                     helperText={
                        errors.newPasswordConfirmation
                           ? errors.newPasswordConfirmation.message
                           : ""
                     }
                     disabled={isSettingPasswordRequesting}
                     {...register("newPasswordConfirmation")}
                  />
               </FormControl>
               <Stack mt={2} spacing={2} direction="row">
                  <Button
                     type="submit"
                     color="secondary"
                     variant="contained"
                     disabled={isSettingPasswordRequesting}
                  >
                     {t("button.update")}
                  </Button>
               </Stack>
            </Box>
         </AccordionDetails>
      </Accordion>
   )
}
