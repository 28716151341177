import {
   selectPageNumberRechargePack,
   selectPageSizeRechargePack,
   selectSearchConditionRechargePack,
   selectSortConditionRechargePack,
} from "./rechargePack.selector"
import {
   getRechargePackAPI,
   GetRechargePackPayloadAPIInterface,
} from "../../api/rechargePack.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getRechargePackAsync,
} from "./rechargePack.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getRechargePackFail,
   getRechargePackSuccess,
   requestRechargePack,
} from "./rechargePack.slice"

/**
 * Get management type user saga
 * @param action
 */
function* getRechargePackSaga(): any {
   try {
      yield put(requestRechargePack())
      const pageNumber = yield select(selectPageNumberRechargePack)
      const pageSize = yield select(selectPageSizeRechargePack)
      const searchCondition = yield select(selectSearchConditionRechargePack)
      const sortCondition = yield select(selectSortConditionRechargePack)
      const params: GetRechargePackPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getRechargePackAPI, params)
      if (!response) {
         throw error
      }
      yield put(getRechargePackSuccess(response))
   } catch (error: any) {
      yield put(getRechargePackFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getRechargePackAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getRechargePackAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getRechargePackAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getRechargePackAsync())
   } catch (error) {}
}

/**
 * Root recharge pack saga
 */
export function* rechargePackSaga() {
   yield takeLatest(getRechargePackAsync, getRechargePackSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
