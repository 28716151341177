import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { getTypeUserSelectAPI } from "../../../api/typeUser.api"
import { TypeUserSelectInterface } from "../../interfaces/typeUser.interface"
import { useSelector } from "react-redux"
import { selectAuthTokenApp } from "../../../states/auth/auth.selector"

type TypeUserSelectAsyncComponentProps = {
   disabled: boolean
   variant?: "standard" | "filled" | "outlined" | undefined
   error: boolean
   helperText: string | undefined
   value: TypeUserSelectInterface[]
   InputProps?: any
   onInputChange: (value: TypeUserSelectInterface) => void
}

export const TypeUserSelectAsyncComponent = (
   props: TypeUserSelectAsyncComponentProps
) => {
   const { t } = useTranslation(namespaces.common)
   const tokenApp = useSelector(selectAuthTokenApp)

   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState<
      readonly TypeUserSelectInterface[]
   >([])
   const loading = open && options.length === 0

   React.useEffect(() => {
      if (!loading) {
         return undefined
      }

      ;(async () => {
         getTypeUserSelectAPI(tokenApp as string).then((res) => {
            const optionData = res.data.map((e: any) => {
               const typeUser: TypeUserSelectInterface = {
                  id: e.id,
                  name: e.name,
               }
               return typeUser
            })
            setOptions(optionData)
         })
      })()

      return () => {}
   }, [loading, tokenApp])

   React.useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])

   return (
      <Autocomplete
         id="asynchronous-demo"
         multiple
         open={open}
         onOpen={() => {
            setOpen(true)
         }}
         onClose={() => {
            setOpen(false)
         }}
         onChange={(event: any, newValue: any | null) => {
            props.onInputChange(newValue)
         }}
         value={props.value ? props.value : []}
         isOptionEqualToValue={(option, value) => option.name === value.name}
         getOptionLabel={(option) => option.name}
         options={options}
         loading={loading}
         disabled={props.disabled}
         noOptionsText={t("dialog.noOption")}
         renderOption={(htmlProps, option) => {
            return (
               <li {...htmlProps} key={option.id}>
                  {option.name}
               </li>
            )
         }}
         renderInput={(params) => (
            <TextField
               {...params}
               margin="dense"
               fullWidth
               variant={props.variant}
               error={props.error}
               helperText={props.helperText}
               label={t("field.typeUser")}
               InputProps={{
                  ...params.InputProps,
                  ...props.InputProps,
                  endAdornment: (
                     <React.Fragment>
                        {loading ? (
                           <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                     </React.Fragment>
                  ),
               }}
            />
         )}
      />
   )
}
