import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
   addEmailAPI,
   AddEmailPayloadAPIInterface,
   deleteEmailAPI,
   DeleteEmailPayloadAPIInterface,
   getEmailDetailAPI,
   GetEmailDetailPayloadAPIInterface,
   updateEmailAPI,
   UpdateEmailPayloadAPIInterface,
} from "../../api/email.api"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { getEmailAsync } from "../../states/email/email.action"
import { CreateEmailComponent } from "./createEmail.component"
import { CreateEmailInput } from "./createEmail.form"
import { useEffect } from "react"
import { dateToISOLikeButLocal } from "../../common/helper/date.helper"

export const CreateEmailContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   let { id } = useParams()

   useEffect(() => {}, [id, dispatch])

   /**
    * Handle add async
    * @param values
    * @returns
    */
   const handleAddAsync = (
      values: CreateEmailInput,
      content: string,
      users: string[]
   ) => {
      const params: AddEmailPayloadAPIInterface = {
         name: values.name,
         content: content,
         startDate: values.startDate?.toDateString(),
         endDate: values.endDate?.toDateString(),
         time: dateToISOLikeButLocal(values.time),
         type: values.type,
         allUser: values.isAll ? 1 : 0,
         userTypeIds: values.userType ? values.userType.map((x) => x.id) : [],
         userIds: users,
      }
      return addEmailAPI(params)
   }

   /**
    * Handle update async
    * @param values
    * @returns
    */
   const handleUpdateAsync = (
      updateId: string,
      values: CreateEmailInput,
      content: string,
      users: string[]
   ) => {
      const params: UpdateEmailPayloadAPIInterface = {
         id: updateId,
         name: values.name,
         content: content,
         startDate: values.startDate?.toDateString(),
         endDate: values.endDate?.toDateString(),
         time: dateToISOLikeButLocal(values.time),
         type: values.type,
         allUser: values.isAll ? 1 : 0,
         userTypeIds: values.userType ? values.userType.map((x) => x.id) : [],
         userIds: users,
      }
      return updateEmailAPI(params)
   }

   /**
    * Handle get detail async
    * @param values
    * @returns
    */
   const handleGetDetailAsync = (updateId: string) => {
      const params: GetEmailDetailPayloadAPIInterface = {
         id: updateId,
      }
      return getEmailDetailAPI(params)
   }

   /**
    * Handle delete async
    * @param values
    * @returns
    */
   const handleDeleteAsync = (updateId: string) => {
      const params: DeleteEmailPayloadAPIInterface = {
         id: updateId,
      }
      return deleteEmailAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle reload get email
    */
   const handleReloadGetEmail = () => {
      dispatch(getEmailAsync())
   }

   /**
    * Handle cancel create
    */
   const handleCancel = () => {
      navigate("/dashboard/marketing")
   }

   return (
      <CreateEmailComponent
         updateId={id}
         handleCancel={handleCancel}
         handlePushNotistack={handlePushNotistack}
         handleReloadGetEmail={handleReloadGetEmail}
         handleAddAsync={handleAddAsync}
         handleUpdateAsync={handleUpdateAsync}
         handleDeleteAsync={handleDeleteAsync}
         handleGetDetailAsync={handleGetDetailAsync}
      />
   )
}
