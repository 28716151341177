import { Chip } from "@mui/material"
import { AppsStatus } from "../../enum/appsStatus.enum"
import { getLabelAppsStatus } from "../../helper/appsStatus.helper"

type AppsStatusProp = {
   status: AppsStatus
}

export const AppsStatusComponent = (props: AppsStatusProp) => {
   const getColor = () => {
      if (props.status === AppsStatus.ACTIVE) {
         return "success"
      }
      if (props.status === AppsStatus.PAUSE) {
         return "secondary"
      }
      return undefined
   }

   return <Chip label={getLabelAppsStatus(props.status)} color={getColor()} />
}
