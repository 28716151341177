import {
   Box,
   Button,
   FormControl,
   FormControlLabel,
   InputAdornment,
   Radio,
   RadioGroup,
   Stack,
   TextField,
   Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ManagementUserDetailInfoInterface } from "../../../common/interfaces/managementUserDetailInfo.interface"
import { namespaces } from "../../../i18n/i18n.constants"
import InfoIcon from "@mui/icons-material/Info"
import CircularProgress from "@mui/material/CircularProgress"
import React, { useEffect } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { ProvinceSelectAsyncComponent } from "../../../common/components/ProvinceSelectAsync/provinceSelectAsync.component"
import {
   ManagementUserDetailInfoInput,
   managementUserDetailInfoSchema,
} from "./managementUserDetailInfo.form"
import { zodResolver } from "@hookform/resolvers/zod"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import PhoneIcon from "@mui/icons-material/Phone"
import PublicIcon from "@mui/icons-material/Public"
import { adminAccountTypes } from "../../../common/variables/accountType"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { useManagementUserDetailPermission } from "../managementUserDetail.permission"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import CakeIcon from "@mui/icons-material/Cake"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle"
import PinDropIcon from "@mui/icons-material/PinDrop"
import PlaceIcon from "@mui/icons-material/Place"
import { DevTool } from "@hookform/devtools"
import { DistrictSelectAsyncComponent } from "../../../common/components/DistrictSelectAsync/districtSelectAsync.component"
import { WardSelectAsyncComponent } from "../../../common/components/WardSelectAsync/wardSelectAsync.component"

type ManagementUserDetailInfoComponentProps = {
   requesting: boolean
   error: string
   data: ManagementUserDetailInfoInterface | null
   modeEdit: boolean
   setModeEditAsync: (value: boolean) => void
   handleUpdateManagementUserDetailInfoAsync: (
      data: ManagementUserDetailInfoInput
   ) => void
}

export const ManagementUserDetailInfoComponent = (
   props: ManagementUserDetailInfoComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.managementUserDetail)

   const { allowUpdateManagementUser } = useManagementUserDetailPermission()

   const {
      register,
      control,
      formState: { errors },
      getValues,
      setValue,
      clearErrors,
      handleSubmit,
      watch,
   } = useForm<ManagementUserDetailInfoInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(managementUserDetailInfoSchema),
   })

   /**
    * Handle cancel moode edit
    */
   const handleCancelModeEdit = () => {
      props.setModeEditAsync(false)
      if (props.data) {
         setValue("name", props.data.fullname)
         setValue("email", props.data.email)
         setValue("phoneNumber", props.data.phoneNumber)
         setValue("note", props.data.note)
         setValue("accountType", props.data.accountTypeId)
         setValue("province", props.data.province)
         setValue("district", props.data.district)
         setValue("ward", props.data.ward)
         setValue(
            "birthday",
            props.data.birthday ? new Date(props.data.birthday) : null
         )
         setValue("isMale", props.data.isMale)
         setValue("identityCard", props.data.identityCard)
         setValue(
            "identityCardDate",
            props.data.identityCardDate
               ? new Date(props.data.identityCardDate)
               : null
         )
         setValue("identityCardPlace", props.data.identityCardPlace)
         setValue("addressNumber", props.data.addressNumber)
         setValue("address", props.data.address)
         clearErrors()
      }
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<ManagementUserDetailInfoInput> = (
      values: ManagementUserDetailInfoInput
   ) => {
      if (!props.modeEdit) {
         props.setModeEditAsync(true)
      } else {
         if (!props.data) {
            return
         }
         props.handleUpdateManagementUserDetailInfoAsync(values)
      }
   }

   useEffect(() => {
      if (props.data) {
         setValue("name", props.data.fullname)
         setValue("email", props.data.email)
         setValue("phoneNumber", props.data.phoneNumber)
         setValue("note", props.data.note)
         setValue("accountType", props.data.accountTypeId)
         setValue("province", props.data.province)
         setValue("district", props.data.district)
         setValue("ward", props.data.ward)
         setValue(
            "birthday",
            props.data.birthday ? new Date(props.data.birthday) : null
         )
         setValue("isMale", props.data.isMale)
         setValue("identityCard", props.data.identityCard)
         setValue(
            "identityCardDate",
            props.data.identityCardDate
               ? new Date(props.data.identityCardDate)
               : null
         )
         setValue("identityCardPlace", props.data.identityCardPlace)
         setValue("addressNumber", props.data.addressNumber)
         setValue("address", props.data.address)
      }
   }, [props.data, setValue])

   return (
      <Box
         component="form"
         autoComplete="off"
         noValidate
         onSubmit={handleSubmit(onSubmitForm)}
         sx={{
            border: "1px solid #80808061",
            borderRadius: 1,
            padding: 3,
         }}
      >
         <DevTool control={control} />
         <Stack direction="row" spacing={2}>
            <InfoIcon />
            <Typography variant="body1" fontWeight={500}>
               {t("info.title")}
            </Typography>
         </Stack>
         {/* Loading */}
         {props.requesting && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "20vh",
               }}
            >
               <CircularProgress color="secondary" />
            </Stack>
         )}
         {/* Error */}
         {!props.requesting && props.error && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "20vh",
               }}
            >
               <Typography variant="body1" color="error">
                  {t(props.error, { ns: "common" })}
               </Typography>
            </Stack>
         )}
         {/* Data */}
         {!props.requesting && !props.error && (
            <React.Fragment>
               <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value } }: any) => (
                     <ProvinceSelectAsyncComponent
                        value={value}
                        isMarginNormal
                        variant="standard"
                        error={!!errors.province}
                        helperText={errors.province ? "Tỉnh bắt buộc nhập" : ""}
                        disabled={props.requesting}
                        InputProps={{
                           readOnly: !props.modeEdit,
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PublicIcon />
                              </InputAdornment>
                           ),
                        }}
                        onInputChange={(inputValue: any) => {
                           setValue("district", null)
                           setValue("ward", null)
                           onChange(inputValue)
                        }}
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="district"
                  render={({ field: { onChange, value } }: any) => (
                     <DistrictSelectAsyncComponent
                        isMarginNormal
                        variant="standard"
                        provinceId={watch("province")?.id}
                        value={value}
                        error={!!errors.district}
                        helperText={
                           errors.district ? "Quận/Huyện bắt buộc nhập" : ""
                        }
                        disabled={props.requesting}
                        InputProps={{
                           readOnly: !props.modeEdit,
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PublicIcon />
                              </InputAdornment>
                           ),
                        }}
                        onInputChange={(inputValue: any) => {
                           setValue("ward", null)
                           onChange(inputValue)
                        }}
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="ward"
                  render={({ field: { onChange, value } }: any) => (
                     <WardSelectAsyncComponent
                        isMarginNormal
                        variant="standard"
                        value={value}
                        districtId={watch("district")?.id}
                        error={!!errors.province}
                        helperText={
                           errors.province ? "Phường/Xã bắt buộc nhập" : ""
                        }
                        disabled={props.requesting}
                        InputProps={{
                           readOnly: !props.modeEdit,
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PublicIcon />
                              </InputAdornment>
                           ),
                        }}
                        onInputChange={(inputValue: any) => {
                           onChange(inputValue)
                        }}
                     />
                  )}
               />
               <TextField
                  required
                  margin="normal"
                  id="name"
                  label={t("info.name")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <FormatColorTextIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("name")}
               />
               <TextField
                  margin="normal"
                  id="email"
                  label={t("info.email")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachEmailIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("email")}
               />
               <TextField
                  required
                  margin="normal"
                  id="phoneNumber"
                  label={t("info.phoneNumber")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.phoneNumber}
                  helperText={
                     errors.phoneNumber ? errors.phoneNumber.message : ""
                  }
                  InputProps={{
                     readOnly: true,
                     startAdornment: (
                        <InputAdornment position="start">
                           <PhoneIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("phoneNumber")}
               />
               <FormControl fullWidth margin="normal">
                  <Controller
                     control={control}
                     name="birthday"
                     render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DesktopDatePicker
                              disableFuture
                              readOnly={!props.modeEdit}
                              label={t("info.birthday")}
                              inputFormat="DD/MM/YYYY"
                              value={field.value}
                              onChange={(value) => {
                                 if (value === null) {
                                    return field.onChange(null)
                                 }
                                 return field.onChange(
                                    value?.toString() === "Invalid Date"
                                       ? value?.toString()
                                       : new Date(value)
                                 )
                              }}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <CakeIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    variant="standard"
                                    error={!!errors.birthday}
                                    helperText={
                                       errors.birthday
                                          ? errors.birthday.message
                                          : ""
                                    }
                                 />
                              )}
                           />
                        </LocalizationProvider>
                     )}
                  />
               </FormControl>
               <FormControl fullWidth margin="normal">
                  <Controller
                     control={control}
                     name="isMale"
                     render={({ field }) => (
                        <RadioGroup
                           {...field}
                           row
                           value={field.value ? field.value : null}
                           onChange={(event, value) =>
                              field.onChange(parseInt(value, 10))
                           }
                        >
                           <FormControlLabel
                              key={1}
                              value={1}
                              checked={getValues("isMale") === 1}
                              disabled={
                                 props.requesting ||
                                 (!props.modeEdit && 1 !== getValues("isMale"))
                              }
                              control={<Radio unselectable="on" />}
                              label={t("info.male")}
                           />
                           <FormControlLabel
                              key={2}
                              value={2}
                              checked={getValues("isMale") === 2}
                              disabled={
                                 props.requesting ||
                                 (!props.modeEdit && 2 !== getValues("isMale"))
                              }
                              control={<Radio />}
                              label={t("info.female")}
                           />
                        </RadioGroup>
                     )}
                  />
               </FormControl>
               <TextField
                  margin="normal"
                  id="identityCard"
                  label={t("info.identityCard")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.identityCard}
                  helperText={
                     errors.identityCard ? errors.identityCard.message : ""
                  }
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <RecentActorsIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("identityCard")}
               />
               <FormControl fullWidth margin="normal">
                  <Controller
                     control={control}
                     name="identityCardDate"
                     render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DesktopDatePicker
                              readOnly={!props.modeEdit}
                              disableFuture
                              label={t("info.identityCardDate")}
                              inputFormat="DD/MM/YYYY"
                              value={field.value}
                              onChange={(value) => {
                                 if (value === null) {
                                    return field.onChange(null)
                                 }
                                 return field.onChange(
                                    value?.toString() === "Invalid Date"
                                       ? value?.toString()
                                       : new Date(value)
                                 )
                              }}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <CalendarMonthIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    variant="standard"
                                    error={!!errors.identityCardDate}
                                    helperText={
                                       errors.identityCardDate
                                          ? errors.identityCardDate.message
                                          : ""
                                    }
                                 />
                              )}
                           />
                        </LocalizationProvider>
                     )}
                  />
               </FormControl>
               <TextField
                  margin="normal"
                  id="identityCardPlace"
                  label={t("info.identityCardPlace")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.identityCardPlace}
                  helperText={
                     errors.identityCardPlace
                        ? errors.identityCardPlace.message
                        : ""
                  }
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <PersonPinCircleIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("identityCardPlace")}
               />
               <TextField
                  margin="normal"
                  id="addressNumber"
                  label={t("info.addressNumber")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.addressNumber}
                  helperText={
                     errors.addressNumber ? errors.addressNumber.message : ""
                  }
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <PinDropIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("addressNumber")}
               />
               <TextField
                  margin="normal"
                  id="address"
                  label={t("info.address")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ""}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <PlaceIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("address")}
               />
               <FormControl fullWidth margin="normal">
                  <Controller
                     control={control}
                     name="accountType"
                     defaultValue={adminAccountTypes[0].id}
                     render={({ field }) => (
                        <RadioGroup
                           {...field}
                           row
                           value={field.value}
                           onChange={(event, value) => field.onChange(value)}
                        >
                           {adminAccountTypes.map((e) => (
                              <FormControlLabel
                                 key={e.id}
                                 value={e.id}
                                 disabled={
                                    props.requesting ||
                                    (!props.modeEdit &&
                                       e.id !== getValues("accountType"))
                                 }
                                 control={<Radio />}
                                 label={e.name}
                              />
                           ))}
                        </RadioGroup>
                     )}
                  />
               </FormControl>
               <TextField
                  margin="normal"
                  id="note"
                  label={t("info.note")}
                  type="text"
                  fullWidth
                  multiline
                  variant="outlined"
                  error={!!errors.note}
                  helperText={errors.note ? errors.note.message : ""}
                  disabled={props.requesting}
                  rows={4}
                  InputProps={{
                     readOnly: !props.modeEdit,
                  }}
                  {...register("note")}
               />
               {allowUpdateManagementUser && (
                  <Button
                     type="submit"
                     disabled={props.requesting}
                     startIcon={props.modeEdit ? <CheckIcon /> : <EditIcon />}
                  >
                     {props.modeEdit ? t("button.save") : t("button.update")}
                  </Button>
               )}
               {props.modeEdit && (
                  <Button
                     color="info"
                     disabled={props.requesting}
                     startIcon={<CloseIcon />}
                     onClick={handleCancelModeEdit}
                  >
                     {t("button.back")}
                  </Button>
               )}
            </React.Fragment>
         )}
      </Box>
   )
}
