import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FilterTableComponent } from "../../common/components/FilterTable/filterTable.component"
import { TypeUserInterface } from "../../common/interfaces/typeUser.interface"
import { namespaces } from "../../i18n/i18n.constants"
import AddIcon from "@mui/icons-material/Add"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import CircularProgress from "@mui/material/CircularProgress"
import commonStyles from "../../common/styles/common.module.css"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import { visuallyHidden } from "@mui/utils"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import TablePagination from "@mui/material/TablePagination"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import { TypeUserDialogContainer } from "./typeUserDialog/typeUserDialog.container"
import { columns, createData, filterTypes } from "./typeUser.table"
import Grid from "@mui/material/Grid"
import { Box, TableSortLabel } from "@mui/material"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { createSortDirection } from "../../common/helper/sortTable.helper"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { useTypeUserPermission } from "./typeUser.permission"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import LoadingButton from "@mui/lab/LoadingButton"
import { convertDate } from "../../common/helper/date.helper"
import PeopleIcon from "@mui/icons-material/People"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"

type TypeUserComponentProps = {
   requesting: boolean
   requestingSync: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   sortCondition: SortConditionInterface
   searchCondition: SearchConditionState
   typeData: any[]
   error: string
   dateSync: string
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
   handleSyncUser: () => void
}

export const TypeUserComponent = (props: TypeUserComponentProps) => {
   const { t } = useTranslation(namespaces.pages.typeUser)

   const {
      allowAddUserType,
      allowDeleteUserType,
      allowDetailUserType,
      allowSyncUserType,
      allowUpdateUserType,
   } = useTypeUserPermission()

   // Type list
   const rows = props.typeData.map((type) =>
      createData(
         type.id,
         type.name,
         type.numberUser,
         type.province,
         type.topup_min,
         type.topup_max,
         type.use_amount_min,
         type.use_amount_max,
         type.login_min,
         type.login_max,
         type.createdAt
      )
   )
   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }

   // Dialog state
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.NONE
   )
   const [selectedDialog, setSelectedDialog] =
      useState<TypeUserInterface | null>(null)

   // Responsive setting
   let gridButton =
      (allowSyncUserType && !allowSyncUserType) ||
      (!allowSyncUserType && allowSyncUserType)
         ? 12
         : 6

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: TypeUserInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      setTypeDialog(DialogMethodEnum.NONE)
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        searchCondition={props.searchCondition}
                        types={filterTypes}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        {allowSyncUserType && (
                           <Grid item xs={12} sm={gridButton} xl={4}>
                              <LoadingButton
                                 fullWidth
                                 color="secondary"
                                 loading={props.requestingSync}
                                 disabled={props.requestingSync}
                                 loadingPosition="start"
                                 startIcon={<PublishedWithChangesIcon />}
                                 variant="contained"
                                 onClick={props.handleSyncUser}
                              >
                                 {t("button.updateUsersType")}
                              </LoadingButton>
                           </Grid>
                        )}
                        {allowAddUserType && (
                           <Grid item xs={12} sm={gridButton} xl={4}>
                              <Button
                                 fullWidth
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() =>
                                    handleOpenDialog(DialogMethodEnum.ADD)
                                 }
                              >
                                 {t("button.add")}
                              </Button>
                           </Grid>
                        )}
                     </Grid>
                  </Grid>
                  {props.dateSync !== "" && (
                     <Grid item xs={12}>
                        <Typography>
                           {t("typeTable.dateSync") +
                              convertDate(props.dateSync)}
                        </Typography>
                     </Grid>
                  )}
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {column.sortKey ? (
                                 <TableSortLabel
                                    active={
                                       props.sortCondition.column ===
                                       column.sortKey
                                    }
                                    direction={sortDirection}
                                    onClick={createSortHandler(column.sortKey)}
                                 >
                                    {t(column.label)}
                                    {props.sortCondition.column ===
                                    column.sortKey ? (
                                       <Box
                                          component="span"
                                          sx={visuallyHidden}
                                       >
                                          {props.sortCondition.order ===
                                          SortOrderEnum.DESC
                                             ? "Giảm"
                                             : "Tăng"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              ) : (
                                 t(column.label)
                              )}
                           </TableCell>
                        ))}
                        <TableCell key={"action"} style={{ minWidth: 170 }}>
                           {t("typeTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 let value = row[column.id]
                                 value = !value ? "---" : value
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {value}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 {allowUpdateUserType && (
                                    <Tooltip title={t("button.update")}>
                                       <IconButton
                                          aria-label="update"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.UPDATE,
                                                row
                                             )
                                          }
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowDetailUserType && (
                                    <Tooltip title={t("button.listUser")}>
                                       <IconButton
                                          aria-label="user-list"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DETAIL,
                                                row
                                             )
                                          }
                                       >
                                          <PeopleIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowDeleteUserType && (
                                    <Tooltip title={t("button.delete")}>
                                       <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DELETE,
                                                row
                                             )
                                          }
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <TypeUserDialogContainer
            openDialog={openDialog}
            type={typeDialog}
            selected={selectedDialog}
            handleCloseDialog={handleCloseDialog}
         />
      </Container>
   )
}
