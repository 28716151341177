import { createAction } from "@reduxjs/toolkit"

export const getDepartmentsAsync = createAction(
   "department/getDepartmentsAsync"
)

export const changePageNumberAsync = createAction<number>(
   "department/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "department/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "department/changeSearchConditionAsync"
)

export const reloadGetDepartmentAsync = createAction(
   "department/reloadGetDepartmentAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "department/changeSortConditionAsync"
)
