import {
   allowExportChartSignUpByApp,
   allowExportChartSignUpByProvince,
} from "../../states/permission/permission.selector"
import { useSelector } from "react-redux"
import {
   allowGetChartSignUpByDate,
   allowGetChartSignUpByApp,
   allowGetChartSignUpByProvince,
   allowExportChartSignUpByDate,
} from "../../states/permission/permission.selector"

export const useChartSignUpPermission = () => {
   return {
      allowGetChartSignUpByDate: useSelector(allowGetChartSignUpByDate),
      allowGetChartSignUpByApp: useSelector(allowGetChartSignUpByApp),
      allowGetChartSignUpByProvince: useSelector(allowGetChartSignUpByProvince),
      allowExportChartSignUpByDate: useSelector(allowExportChartSignUpByDate),
      allowExportChartSignUpByApp: useSelector(allowExportChartSignUpByApp),
      allowExportChartSignUpByProvince: useSelector(
         allowExportChartSignUpByProvince
      ),
   }
}
