import { createSelector } from "@reduxjs/toolkit"
import { accountTypes } from "../../common/variables/accountType"
import { RootState } from "../rootStore"
import { ManagementUserDetailState } from "./managementUserDetail.slice"

const selectManagementUserDetailState = (
   state: RootState
): ManagementUserDetailState => state.managementUserDetail

export const selectSelectedIdManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.selectedId
)

export const selectModeEditInfoManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.editInfo
)

export const selectRequestingInfoManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.requestingInfo
)

export const selectErrorInfoManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.errorInfo
)

export const selectDataInfoManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.dataInfo
)

export const selectIsCompanyManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) =>
      state.dataInfo
         ? state.dataInfo.accountTypeId ===
           accountTypes.filter((e) => e.name === "Công ty")[0].id
         : false
)

export const selectRequestingCompanyManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.requestingCompany
)

export const selectModeEditCompanyManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.editCompany
)

export const selectErrorCompanyManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.errorCompany
)

export const selectDataCompanyManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.dataCompany
)

export const selectRequestingWalletManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.requestingWallet
)

export const selectModeEditWalletManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.editInfo
)

export const selectErrorWalletManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.errorWallet
)

export const selectDataWalletManagementUserDetailState = createSelector(
   selectManagementUserDetailState,
   (state: ManagementUserDetailState) => state.dataWallet
)
