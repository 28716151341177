import TablePagination from "@mui/material/TablePagination"
import Toolbar from "@mui/material/Toolbar"
import Stack from "@mui/material/Stack"
import commonStyles from "../../../common/styles/common.module.css"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import { columns, createData, filterTypes } from "./typeUserDetail.table"
import CircularProgress from "@mui/material/CircularProgress"
import { useTranslation } from "react-i18next"
import React from "react"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import { namespaces } from "../../../i18n/i18n.constants"
import { FilterTableComponent } from "../../../common/components/FilterTable/filterTable.component"
import { TypeUserInterface } from "../../../common/interfaces/typeUser.interface"
import { TablePaginationActionsComponent } from "../../../common/components/TablePaginationActions/tablePaginationActions.component"

type TypeUserDetailComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   typeUser: TypeUserInterface
   userData: any[]
   error: string
   searchCondition: SearchConditionState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handleChangeSearchCondition: (value: string, option: string) => void
}

export const TypeUserDetailComponent = (
   props: TypeUserDetailComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.typeUserDialog)

   // User list
   const rows = props.userData.map((user) =>
      createData(
         user.id,
         user.fullName,
         user.email,
         user.phoneNumber,
         user.provinceName,
         user.topUp,
         user.useAmount,
         user.login
      )
   )

   return (
      <React.Fragment>
         <Toolbar
            sx={{
               pl: { sm: 2 },
               pr: { xs: 1, sm: 1 },
               py: 2,
            }}
         >
            <Stack
               sx={{
                  width: "100%",
               }}
               direction={"row"}
               justifyContent={"space-between"}
               alignItems={"center"}
            >
               <FilterTableComponent
                  types={filterTypes}
                  searchCondition={props.searchCondition}
                  onFilter={(value: string, option: string) =>
                     props.handleChangeSearchCondition(value, option)
                  }
               />
            </Stack>
         </Toolbar>
         <TableContainer sx={{ maxHeight: 350 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{ minWidth: column.minWidth }}
                        >
                           {t(column.label)}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {/* Loading */}
                  {props.requesting && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                           <div className={commonStyles.flexCenterCenter}>
                              <CircularProgress color="secondary" />
                           </div>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Error */}
                  {!props.requesting && props.error && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                           <Typography variant="body1" color="error">
                              {t(props.error, { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Empty */}
                  {!props.requesting && !props.error && rows.length === 0 && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                           <Typography variant="body1">
                              {t("table.empty", { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Data  */}
                  {!props.requesting &&
                     !props.error &&
                     rows.map((row, index) => (
                        <TableRow hover tabIndex={-1} key={index}>
                           {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                 >
                                    {column.format
                                       ? column.format(value)
                                       : value}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            showFirstButton
            showLastButton
            labelRowsPerPage={t("table.pageSize", { ns: "common" })}
            labelDisplayedRows={(page) => {
               return `${t("table.page", { ns: "common" })}: ${
                  page.page + 1
               } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                  "table.record",
                  { ns: "common" }
               )}`
            }}
            rowsPerPageOptions={[10, 25, 100, 300, 500]}
            component="div"
            count={props.rowCount}
            rowsPerPage={props.pageSize}
            page={props.pageNumber - 1}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsComponent}
         />
      </React.Fragment>
   )
}
