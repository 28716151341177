import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import LinearProgress from "@mui/material/LinearProgress"
import { ProvinceSelectAsyncComponent } from "../../../common/components/ProvinceSelectAsync/provinceSelectAsync.component"
import { ManageAccounts } from "@mui/icons-material"
import {
   SettingUserInfoInput,
   settingUserInfoSchema,
} from "./settingUserInfo.form"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DistrictSelectAsyncComponent } from "../../../common/components/DistrictSelectAsync/districtSelectAsync.component"
import { WardSelectAsyncComponent } from "../../../common/components/WardSelectAsync/wardSelectAsync.component"

type SettingUserInfoComponentProps = {
   isAdmin: boolean
   handlePushNotistack: (message: string, variant: string) => void
   handleGetUserInfoAsync: () => Promise<any>
   handleUpdateUserInfoAsync: (values: SettingUserInfoInput) => Promise<any>
   handleReloadGetUserInfoAsync: () => void
}

export const SettingUserInfoComponent = (
   props: SettingUserInfoComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.setting)
   // State
   const [isRequesting, setIsRequesting] = useState<boolean>(false)
   const [error, setError] = useState<string>("")

   // Form
   const {
      register,
      formState: { errors },
      handleSubmit,
      control,
      clearErrors,
      setValue,
      getValues,
      watch,
   } = useForm<SettingUserInfoInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(settingUserInfoSchema),
   })

   /**
    * On change accordion
    */
   const onChangeAccordion = (
      event: SyntheticEvent<Element, Event>,
      expanded: boolean
   ) => {
      if (expanded) {
         clearErrors()
         handleGetUserInfoAsync()
      }
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<SettingUserInfoInput> = (
      values: SettingUserInfoInput
   ) => {
      setIsRequesting(true)
      props
         .handleUpdateUserInfoAsync(values)
         .then((res) => {
            props.handlePushNotistack(
               "Cập nhật thông tin thành công!",
               "success"
            )
            setIsRequesting(false)
            props.handleReloadGetUserInfoAsync()
         })
         .catch((err) => {
            setIsRequesting(false)
            props.handlePushNotistack("Cập nhật thông tin thất bại!", "error")
            handleGetUserInfoAsync()
         })
   }

   /**
    * Handle get user info async
    */
   const handleGetUserInfoAsync = useCallback(() => {
      setIsRequesting(true)
      props
         .handleGetUserInfoAsync()
         .then((res) => {
            setValue("name", res.data.Fullname)
            setValue("email", res.data.Email)
            setValue("phoneNumber", res.data.PhoneNumber)
            setValue(
               "province",
               res.data.ProvinceId
                  ? {
                       id: res.data.ProvinceId,
                       name: res.data.ProvinceName,
                    }
                  : null
            )
            setValue(
               "district",
               res.data.DistrictId
                  ? {
                       id: res.data.DistrictId,
                       name: res.data.DistrictName,
                    }
                  : null
            )
            setValue(
               "ward",
               res.data.WardId
                  ? {
                       id: res.data.WardId,
                       name: res.data.WardName,
                    }
                  : null
            )
            setValue(
               "birthday",
               res.data.Birthday ? new Date(res.data.Birthday) : null
            )
            setValue("isMale", res.data.Gender)
            setValue("identityCard", res.data.IdentityNumber)
            setValue(
               "identityCardDate",
               res.data.IssuedDate ? new Date(res.data.IssuedDate) : null
            )
            setValue("identityCardPlace", res.data.IssuedBy)
            setValue("addressNumber", res.data.Street)
            setValue("address", res.data.Address)
            setIsRequesting(false)
         })
         .catch((err) => {
            setError("Xảy ra lỗi khi lấy thông tin.")
            setIsRequesting(false)
         })
   }, [props, setValue])

   useEffect(() => {
      handleGetUserInfoAsync()
   }, [props, setValue, handleGetUserInfoAsync])

   return (
      <Accordion defaultExpanded={false} onChange={onChangeAccordion}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="info-content"
            id="info-header"
         >
            <ManageAccounts />
            <Typography ml={2}>{t("userInfo.accordion")}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            {/* Loading */}
            {isRequesting && <LinearProgress color="secondary" />}
            {/* Error */}
            {!isRequesting && error !== "" && (
               <Typography variant="body1" color="error">
                  {error}
               </Typography>
            )}
            {/* Data */}
            {!isRequesting && error === "" && (
               <Box
                  component="form"
                  autoComplete="on"
                  noValidate
                  sx={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmitForm)}
               >
                  <FormControl fullWidth margin="normal">
                     <TextField
                        required
                        id="outlined-adornment-name"
                        label={t("userInfo.name")}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: props.isAdmin,
                        }}
                        {...register("name")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-email"
                        label={t("userInfo.email")}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: props.isAdmin,
                        }}
                        {...register("email")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-phone"
                        label={t("userInfo.phoneNumber")}
                        InputProps={{
                           readOnly: props.isAdmin,
                        }}
                        disabled={true}
                        {...register("phoneNumber")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="birthday"
                        defaultValue={null}
                        render={({ field }) => (
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                 disableFuture
                                 readOnly={props.isAdmin}
                                 label={t("userInfo.birthday")}
                                 inputFormat="DD/MM/YYYY"
                                 value={field.value}
                                 onChange={(value) => {
                                    if (value === null) {
                                       return field.onChange(null)
                                    }
                                    return field.onChange(
                                       value?.toString() === "Invalid Date"
                                          ? value?.toString()
                                          : new Date(value)
                                    )
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       error={!!errors.birthday}
                                       helperText={
                                          errors.birthday
                                             ? errors.birthday.message
                                             : ""
                                       }
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                        )}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="isMale"
                        render={({ field }) => (
                           <RadioGroup
                              {...field}
                              row
                              value={field.value}
                              onChange={(event, value) =>
                                 field.onChange(parseInt(value, 10))
                              }
                           >
                              <FormControlLabel
                                 key={1}
                                 value={1}
                                 checked={getValues("isMale") === 1}
                                 disabled={isRequesting || props.isAdmin}
                                 control={<Radio />}
                                 label={t("userInfo.male")}
                              />
                              <FormControlLabel
                                 key={2}
                                 value={2}
                                 checked={getValues("isMale") === 2}
                                 disabled={isRequesting || props.isAdmin}
                                 control={<Radio />}
                                 label={t("userInfo.female")}
                              />
                           </RadioGroup>
                        )}
                     />
                  </FormControl>
                  <TextField
                     margin="normal"
                     id="identityCard"
                     label={t("userInfo.identityCard")}
                     type="text"
                     fullWidth
                     error={!!errors.identityCard}
                     InputProps={{
                        readOnly: props.isAdmin,
                     }}
                     helperText={
                        errors.identityCard ? errors.identityCard.message : ""
                     }
                     {...register("identityCard")}
                  />
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="identityCardDate"
                        defaultValue={null}
                        render={({ field }) => (
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                 disableFuture
                                 readOnly={props.isAdmin}
                                 label={t("userInfo.identityCardDate")}
                                 inputFormat="DD/MM/YYYY"
                                 value={field.value}
                                 onChange={(value) => {
                                    if (value === null) {
                                       return field.onChange(null)
                                    }
                                    return field.onChange(
                                       value?.toString() === "Invalid Date"
                                          ? value?.toString()
                                          : new Date(value)
                                    )
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       error={!!errors.identityCardDate}
                                       helperText={
                                          errors.identityCardDate
                                             ? errors.identityCardDate.message
                                             : ""
                                       }
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                        )}
                     />
                  </FormControl>
                  <TextField
                     margin="normal"
                     id="identityCardPlace"
                     label={t("userInfo.identityCardPlace")}
                     type="text"
                     fullWidth
                     error={!!errors.identityCardPlace}
                     InputProps={{
                        readOnly: props.isAdmin,
                     }}
                     helperText={
                        errors.identityCardPlace
                           ? errors.identityCardPlace.message
                           : ""
                     }
                     {...register("identityCardPlace")}
                  />
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="province"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }: any) => (
                           <ProvinceSelectAsyncComponent
                              value={value}
                              error={!!errors.province}
                              helperText={
                                 errors.province ? "Tỉnh bắt buộc nhập" : ""
                              }
                              disabled={isRequesting}
                              InputProps={{
                                 readOnly: props.isAdmin,
                              }}
                              onInputChange={(inputValue: any) => {
                                 setValue("district", null)
                                 setValue("ward", null)
                                 onChange(inputValue)
                              }}
                           />
                        )}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="district"
                        render={({ field: { onChange, value } }: any) => (
                           <DistrictSelectAsyncComponent
                              provinceId={watch("province")?.id}
                              value={value}
                              error={!!errors.district}
                              helperText={
                                 errors.district
                                    ? "Quận/Huyện bắt buộc nhập"
                                    : ""
                              }
                              disabled={isRequesting}
                              InputProps={{
                                 readOnly: props.isAdmin,
                              }}
                              onInputChange={(inputValue: any) => {
                                 setValue("ward", null)
                                 onChange(inputValue)
                              }}
                           />
                        )}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <Controller
                        control={control}
                        name="ward"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }: any) => (
                           <WardSelectAsyncComponent
                              value={value}
                              districtId={watch("district")?.id}
                              error={!!errors.province}
                              helperText={
                                 errors.province
                                    ? "Phường/Xã bắt buộc nhập"
                                    : ""
                              }
                              disabled={isRequesting}
                              InputProps={{
                                 readOnly: props.isAdmin,
                              }}
                              onInputChange={(inputValue: any) => {
                                 onChange(inputValue)
                              }}
                           />
                        )}
                     />
                  </FormControl>
                  <TextField
                     margin="normal"
                     id="addressNumber"
                     label={t("userInfo.addressNumber")}
                     type="text"
                     fullWidth
                     error={!!errors.addressNumber}
                     InputProps={{
                        readOnly: props.isAdmin,
                     }}
                     helperText={
                        errors.addressNumber ? errors.addressNumber.message : ""
                     }
                     {...register("addressNumber")}
                  />
                  <TextField
                     margin="normal"
                     id="address"
                     label={t("userInfo.address")}
                     type="text"
                     fullWidth
                     error={!!errors.address}
                     InputProps={{
                        readOnly: props.isAdmin,
                     }}
                     helperText={errors.address ? errors.address.message : ""}
                     {...register("address")}
                  />
                  {!props.isAdmin && (
                     <Stack mt={2} spacing={2} direction="row">
                        <Button
                           type="submit"
                           color="secondary"
                           variant="contained"
                           disabled={isRequesting}
                        >
                           {t("button.update")}
                        </Button>
                     </Stack>
                  )}
               </Box>
            )}
         </AccordionDetails>
      </Accordion>
   )
}
