import { ChartSignUpState } from "./chartSignUp.slice"
import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectChartSignUpState = (state: RootState): ChartSignUpState =>
   state.chartSignUp

export const selectRequestingChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.requesting
)

export const selectRequestingExportChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.excelExporting
)

export const selectDataChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.data
)

export const selectErrorChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.error
)

export const selectFilterChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.filter
)

export const selectSearchConditionChartSignUp = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.searchCondition
)

export const selectIsDisableStatistic = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => false
)

export const selectIsDisableExport = createSelector(
   selectChartSignUpState,
   (state: ChartSignUpState) => state.data.length === 0
)
