import { FilterTableEnum } from "../../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../../common/enum/sortColumn.enum"
import { convertDate } from "../../../common/helper/date.helper"
import { CreateEmailTemplateInterface } from "../../../common/interfaces/createEmailTemplate.interface"

export interface Column {
   id: "name" | "createdAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [FilterTableEnum.TEMPLATE_NAME]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "createEmailTemplate.name",
      sortKey: SortColumnEnum.EMAIL_TEMPLATE_NAME,
      minWidth: 170,
      maxWidth: 300,
   },
   {
      id: "createdAt",
      label: "createEmailTemplate.createAt",
      sortKey: SortColumnEnum.EMAIL_TEMPLATE_CREATEAT,
      minWidth: 170,
      maxWidth: 300,
   },
]

export function createData(
   id: string,
   name: string,
   content: string,
   createdAt: string
): CreateEmailTemplateInterface {
   createdAt = convertDate(createdAt)
   return {
      id,
      name,
      content,
      createdAt,
   }
}
