import { createAction } from "@reduxjs/toolkit"

export const getManagementUserChargeHistoryAsync = createAction<string>(
   "managementUserChargeHistory/getManagementUserChargeHistoryAsync"
)

export const changePageNumberAsync = createAction<number>(
   "managementUserChargeHistory/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "managementUserChargeHistory/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "managementUserChargeHistory/changeSearchConditionAsync"
)
