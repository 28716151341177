import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { ManagementUserDetailCompanyContainer } from "./managementUserDetailCompany/managementUserDetailCompany.container"
import { ManagementUserDetailInfoContainer } from "./managementUserDetailInfo/managementUserDetailInfo.container"

type ManagementUserDetailComponentProp = {
   isCompany: boolean
}

export const ManagementUserDetailComponent = (
   props: ManagementUserDetailComponentProp
) => {
   return (
      <Container maxWidth="xl">
         <Grid container rowSpacing={5} columnSpacing={5}>
            <Grid item xs={12} md={12} xl={6}>
               <ManagementUserDetailInfoContainer />
            </Grid>
            {props.isCompany && (
               <Grid item xs={12} md={12} xl={6}>
                  <ManagementUserDetailCompanyContainer />
               </Grid>
            )}
         </Grid>
      </Container>
   )
}
