import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from "@mui/icons-material/Search"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { alpha, styled } from "@mui/material/styles"
import { OutlinedInputProps } from "@mui/material/OutlinedInput"

export const SearchTextField = styled((props: TextFieldProps) => (
   <TextField
      hiddenLabel
      InputProps={
         {
            startAdornment: (
               <InputAdornment position="start">
                  <SearchIcon sx={{ color: "gray" }} />
               </InputAdornment>
            ),
         } as Partial<OutlinedInputProps>
      }
      {...props}
   />
))(({ theme }) => ({
   "& .MuiOutlinedInput-root": {
      border: "1px solid #323232",
      overflow: "hidden",
      borderRadius: 6,
      backgroundColor: "#323232",
      color: "#FFFFFF",
      transition: theme.transitions.create([
         "border-color",
         "background-color",
         "box-shadow",
      ]),
      "&:hover": {
         backgroundColor: "#323232",
      },
      "&.Mui-focused": {
         backgroundColor: "#323232",
         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
         borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-input": {
         height: "0.4375em",
      },
   },
}))
