import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetChartSignUpByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const getChartSignUpByDateAPI = (
   payload: GetChartSignUpByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-register-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface ExportChartSignUpByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const exportChartSignUpByDateAPI = (
   payload: ExportChartSignUpByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-register-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface GetChartSignUpByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const getChartSignUpByAppAPI = (
   payload: GetChartSignUpByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-register-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartSignUpByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const exportChartSignUpByAppAPI = (
   payload: ExportChartSignUpByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-register-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface GetChartSignUpByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const getChartSignUpByProvinceAPI = (
   payload: GetChartSignUpByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-register-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartSignUpByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const exportChartSignUpByProvinceAPI = (
   payload: ExportChartSignUpByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-register-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}
