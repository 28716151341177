import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { AxisTypeEnum } from "../../common/enum/axisType.enum"
import { ChartEnum } from "../../common/enum/chart.enum"
import { ChartTypeEnum } from "../../common/enum/chartType.enum"
import { FilterChartGUIInterface } from "../../common/interfaces/filterChartGUI.interface"
import { FilterChartSearchConditionInterface } from "../../common/interfaces/filterChartSearchCondition.interface"
import { getMaxDateChart } from "../../common/helper/date.helper"
import { defaultApp, defaultProvince } from "../../common/variables/filterChart"

export interface ChartSignUpState {
   requesting: boolean
   excelExporting: boolean
   error: string
   successful: boolean
   filter: FilterChartGUIInterface
   searchCondition: FilterChartSearchConditionInterface
   data: any[]
}

const initialState: ChartSignUpState = {
   requesting: false,
   excelExporting: false,
   error: "",
   successful: false,
   filter: {
      chartType: ChartTypeEnum.SIN,
      axisType: AxisTypeEnum.VERTICAL,
   },
   searchCondition: {
      type: ChartEnum.NONE,
      fromDate: dayjs(getMaxDateChart()),
      toDate: dayjs(getMaxDateChart()),
      app: [defaultApp],
      province: [defaultProvince],
   },
   data: [],
}

export const chartSignUpSlice = createSlice({
   name: "chartSignUp",
   initialState,
   reducers: {
      requestChartSignUp: (state: ChartSignUpState) => {
         state.requesting = true
      },
      getChartSignUpSuccess: (
         state: ChartSignUpState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload
      },
      getChartSignUpFail: (
         state: ChartSignUpState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changeFilter: (state: ChartSignUpState, action: PayloadAction<any>) => {
         state.filter = { ...state.filter, ...action.payload }
      },
      changeSearchCondtion: (
         state: ChartSignUpState,
         action: PayloadAction<any>
      ) => {
         if (
            action.payload.type === ChartEnum.APP ||
            action.payload.type === ChartEnum.PROVINCE
         ) {
            state.filter.chartType = ChartTypeEnum.BAR
            state.filter.axisType = AxisTypeEnum.VERTICAL
         }

         if (
            action.payload.type === ChartEnum.DAY ||
            action.payload.type === ChartEnum.MONTH ||
            action.payload.type === ChartEnum.YEAR
         ) {
            state.filter.chartType = ChartTypeEnum.SIN
         }

         if (action.payload.type) {
            state.searchCondition.fromDate = dayjs(getMaxDateChart())
            state.searchCondition.toDate = dayjs(getMaxDateChart())
         }

         state.data = []
         state.searchCondition = { ...state.searchCondition, ...action.payload }
      },
      startExportExcelChartSignUp: (state: ChartSignUpState) => {
         state.excelExporting = true
      },
      finishExportExcelChartSignUp: (state: ChartSignUpState) => {
         state.excelExporting = false
      },
   },
})

export const {
   requestChartSignUp,
   getChartSignUpSuccess,
   getChartSignUpFail,
   changeFilter,
   changeSearchCondtion,
   startExportExcelChartSignUp,
   finishExportExcelChartSignUp,
} = chartSignUpSlice.actions

export default chartSignUpSlice.reducer
