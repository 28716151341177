import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetPromotionPackPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getPromotionPackAPI = (
   payload: GetPromotionPackPayloadAPIInterface
) => {
   return AxiosAuth.post("promotion-package/get-list", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddPromotionPackPayloadAPIInterface {
   pacakgeName: string
   coinPercent: number
   userTypeIds: string[] | null
}

export const addPromotionPackAPI = (
   payload: AddPromotionPackPayloadAPIInterface
) => {
   return AxiosAuth.post("promotion-package", payload).then((response) => ({
      response,
   }))
}

export interface UpdatePromotionPackPayloadAPIInterface {
   id: string
   packageName: string
   coinPercent: number
   userTypeIds: string[] | null
}

export const updatePromotionPackAPI = (
   payload: UpdatePromotionPackPayloadAPIInterface
) => {
   return AxiosAuth.put("promotion-package/" + payload.id, payload).then(
      (response) => ({ response })
   )
}

export interface DeletePromotionPackPayloadAPIInterface {
   id: string
}

export const deletePromotionPackAPI = (
   payload: DeletePromotionPackPayloadAPIInterface
) => {
   return AxiosAuth.delete("promotion-package/" + payload.id).then(
      (response) => ({ response })
   )
}
