import {
   selectIsTriggerSyncTypeUser,
   selectPageNumberTypeUser,
   selectPageSizeTypeUser,
   selectSearchConditionTypeUser,
   selectSortConditionTypeUser,
} from "./typeUser.selector"
import {
   getSyncTypeUserAPI,
   getTypeUserAPI,
   GetTypeUserPayloadAPIInterface,
   triggerSyncTypeUserAPI,
} from "../../api/typeUser.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getTypeUserAsync,
   reloadGetTypeUserAsync,
   getSyncUserSync,
   triggerSyncUserSync,
} from "./typeUser.action"
import { call, delay, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getTypeUserFail,
   getTypeUserSuccess,
   requestSyncUser,
   requestTypeUser,
   setIsTriggerSync,
   syncUserSuccess,
} from "./typeUser.slice"
import { selectAuthTokenApp } from "../auth/auth.selector"
import { pushNotistack } from "../notistack/notistack.slice"
import { namespaces } from "../../i18n/i18n.constants"
import { i18n } from "../../i18n/i18n"

/**
 * Get management type user saga
 * @param action
 */
function* getTypeUserSaga(): any {
   try {
      yield put(requestTypeUser())
      const pageNumber = yield select(selectPageNumberTypeUser)
      const pageSize = yield select(selectPageSizeTypeUser)
      const searchCondition = yield select(selectSearchConditionTypeUser)
      const sortCondition = yield select(selectSortConditionTypeUser)
      const params: GetTypeUserPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getTypeUserAPI, params)
      if (!response) {
         throw error
      }
      yield put(getTypeUserSuccess(response))
   } catch (error: any) {
      yield put(getTypeUserFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getTypeUserAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getTypeUserAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getTypeUserAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getTypeUserAsync())
   } catch (error) {}
}

/**
 * Reload get type user  saga
 */
function* reloadGetTypeUserSaga(): any {
   try {
      yield put(getTypeUserAsync())
   } catch (error) {}
}

/**
 * Get sync user saga
 */
function* getSyncUserSaga(): any {
   try {
      let isStillCalling = true
      const tokenApp = yield select(selectAuthTokenApp)
      while (isStillCalling) {
         const { response, error } = yield call(getSyncTypeUserAPI, tokenApp)
         if (!response) {
            throw error
         }
         if (response.data.value === "Succeeded") {
            const isTrigger = yield select(selectIsTriggerSyncTypeUser)

            if (isTrigger) {
               yield put(
                  pushNotistack({
                     message: i18n.t("notistack.syncSuscess", {
                        ns: namespaces.pages.typeUser,
                     }),
                     variant: "success",
                  })
               )
            }

            yield put(syncUserSuccess(response.data.updatedAt))

            isStillCalling = false
         } else {
            yield put(requestSyncUser())
            yield delay(
               process.env.REACT_APP_SYNC_USER_TYPE_SECOND
                  ? parseInt(process.env.REACT_APP_SYNC_USER_TYPE_SECOND)
                  : 30000
            )
         }
      }
   } catch (error) {}
}

/**
 * Trigger sync user saga
 */
function* triggerSyncUserSaga(): any {
   try {
      const { response, error } = yield call(triggerSyncTypeUserAPI)
      if (!response) {
         throw error
      }
      if (response.status === 200) {
         yield put(requestSyncUser())

         yield put(setIsTriggerSync(true))

         yield delay(
            process.env.REACT_APP_SYNC_USER_TYPE_SECOND
               ? parseInt(process.env.REACT_APP_SYNC_USER_TYPE_SECOND)
               : 30000
         )

         yield call(getSyncUserSaga)
      }
   } catch (error) {}
}

/**
 * Root type user saga
 */
export function* typeUserSaga() {
   yield takeLatest(getTypeUserAsync, getTypeUserSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(reloadGetTypeUserAsync, reloadGetTypeUserSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
   yield takeLatest(getSyncUserSync, getSyncUserSaga)
   yield takeLatest(triggerSyncUserSync, triggerSyncUserSaga)
}
