export const namespaces = {
   common: "common",
   pages: {
      signIn: "pages.signIn",
      register: "pages.register",
      forgotPassword: "pages.forgotPassword",
      chart: "pages.chart",
      chartSignIn: "pages.chartSignIn",
      department: "pages.department",
      managementUser: "pages.managementUser",
      managementUserDetail: "pages.managementUserDetail",
      managementUserCompany: "pages.managementUserCompany",
      managementUserHistory: "pages.managementUserHistory",
      managementUserChargeHistory: "pages.managementUserChargeHistory",
      managementWallet: "pages.managementWallet",
      managementWalletRecharge: "pages.managementWalletRecharge",
      typeUser: "pages.typeUser",
      typeUserDialog: "pages.typeUserDialog",
      rechargePack: "pages.rechargePack",
      promotionPack: "pages.promotionPack",
      email: "pages.email",
      createEmail: "pages.createEmail",
      setting: "pages.setting",
      outOfSession: "pages.outOfSession",
      paymentResult: "pages.paymentResult",
      apps: "pages.apps",
   },
}

export const languages = {
   vn: "vn",
}
