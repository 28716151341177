import { PermissionState } from "./permission.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { AuthState } from "../auth/auth.slice"
import { FunctionEnum } from "../../common/enum/function.enum"

const selectPermissionState = (state: RootState): PermissionState =>
   state.permission

const selectAuthState = (state: RootState): AuthState => state.auth

export const selectPermission = createSelector(
   selectPermissionState,
   (permissionState: PermissionState) => permissionState
)

export const selectAllowRoutes = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): FunctionEnum[] =>
      permissionState.function
)

// Chart sign in
export const allowGetChartSignInByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_SIGN_IN_CHART_BY_DATE)
)

export const allowGetChartSignInByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_SIGN_IN_CHART_BY_APP)
)

export const allowGetChartSignInByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_SIGN_IN_CHART_BY_PROVINCE
      )
)

export const allowShowChartSignIn = createSelector(
   allowGetChartSignInByDate,
   allowGetChartSignInByApp,
   allowGetChartSignInByProvince,
   (date: boolean, app: boolean, province: boolean): boolean =>
      date || app || province
)

export const allowExportChartSignInByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_IN_CHART_BY_DATE
      )
)

export const allowExportChartSignInByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_IN_CHART_BY_APP
      )
)

export const allowExportChartSignInByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_IN_CHART_BY_PROVINCE
      )
)

// Chart sign up
export const allowGetChartSignUpByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_SIGN_UP_CHART_BY_DATE)
)

export const allowGetChartSignUpByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_SIGN_UP_CHART_BY_APP)
)

export const allowGetChartSignUpByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_SIGN_UP_CHART_BY_PROVINCE
      )
)

export const allowShowChartSignUp = createSelector(
   allowGetChartSignUpByDate,
   allowGetChartSignUpByApp,
   allowGetChartSignUpByProvince,
   (date: boolean, app: boolean, province: boolean): boolean =>
      date || app || province
)

export const allowExportChartSignUpByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_UP_CHART_BY_DATE
      )
)

export const allowExportChartSignUpByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_UP_CHART_BY_APP
      )
)

export const allowExportChartSignUpByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_SIGN_UP_CHART_BY_PROVINCE
      )
)

// Chart using wallet
export const allowGetChartUsingWalletByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_USING_WALLET_CHART_BY_DATE
      )
)

export const allowGetChartUsingWalletByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_USING_WALLET_CHART_BY_APP
      )
)

export const allowGetChartUsingWalletByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_USING_WALLET_CHART_BY_PROVINCE
      )
)

export const allowShowChartUsingWallet = createSelector(
   allowGetChartUsingWalletByDate,
   allowGetChartUsingWalletByApp,
   allowGetChartUsingWalletByProvince,
   (date: boolean, app: boolean, province: boolean): boolean =>
      date || app || province
)

export const allowExportChartUsingWalletByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_USING_WALLET_CHART_BY_DATE
      )
)

export const allowExportChartUsingWalletByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_USING_WALLET_CHART_BY_APP
      )
)

export const allowExportChartUsingWalletByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_USING_WALLET_CHART_BY_PROVINCE
      )
)

// Chart charge wallet
export const allowGetChartChargeWalletByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_DATE
      )
)

export const allowGetChartChargeWalletByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_APP
      )
)

export const allowGetChartChargeWalletByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_PROVINCE
      )
)

export const allowShowChartChargeWallet = createSelector(
   allowGetChartChargeWalletByDate,
   allowGetChartChargeWalletByApp,
   allowGetChartChargeWalletByProvince,
   (date: boolean, app: boolean, province: boolean): boolean =>
      date || app || province
)

export const allowExportChartChargeWalletByDate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_CHARGE_WALLET_CHART_BY_DATE
      )
)

export const allowExportChartChargeWalletByApp = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_CHARGE_WALLET_CHART_BY_APP
      )
)

export const allowExportChartChargeWalletByProvince = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.EXPORT_CHARGE_WALLET_CHART_BY_PROVINCE
      )
)

// Chart user type
export const allowGetChartUserType = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_USER_TYPE_CHART)
)

export const allowShowChartUserType = createSelector(
   allowGetChartUserType,
   (isShow: boolean): boolean => isShow
)

export const allowExportChartUserType = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.EXPORT_USER_TYPE_CHART)
)

// Management user
export const allowAddManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_MANAGEMENT_USER)
)

export const allowUpdateManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_MANAGEMENT_USER)
)

export const allowDetailManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_MANAGEMENT_USER_DETAIL)
)

export const allowDeleteManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_MANAGEMENT_USER)
)

export const allowExportManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.EXPORT_MANAGEMENT_USER)
)

export const allowHistoryManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.HISTORY_MANAGEMENT_USER)
)

export const allowHistoryChargeManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(
         FunctionEnum.HISTORY_CHARGE_MANAGEMENT_USER
      )
)

export const allowGetCountManagementUser = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_COUNT_MANAGEMENT_USER)
)

// Recharge Package
export const allowAddRechargePackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_RECHARGE_PACK)
)

export const allowDetailRechargePackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_RECHARGE_PACK)
)

export const allowUpdateRechargePackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_RECHARGE_PACK)
)

export const allowDeleteRechargePackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_RECHARGE_PACK)
)

// Promotion Package
export const allowAddPromotionPackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_PROMOTION_PACK)
)

export const allowDetailPromotionPackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_PROMOTION_PACK)
)

export const allowUpdatePromotionPackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_PROMOTION_PACK)
)

export const allowDeletePromotionPackage = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_PROMOTION_PACK)
)

// User Type
export const allowAddUserType = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_USER_TYPE)
)

export const allowDetailUserType = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_DETAIL_USER_TYPE)
)

export const allowUpdateUserType = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_USER_TYPE)
)

export const allowDeleteUserType = createSelector(
   selectAuthState,
   selectPermissionState,
   (authState: AuthState, permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_USER_TYPE)
)

// Email
export const allowAddEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_EMAIL)
)

export const allowUpdateEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_EMAIL)
)

export const allowDeleteEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_EMAIL)
)

export const allowPauseEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.PAUSE_EMAIL)
)

export const allowStartEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.START_EMAIL)
)

export const allowReStartEmail = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.RESTART_EMAIL)
)

// Create email template
export const allowGetListCreateEmailTemplate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_LIST_EMAIL_TEMPLATE)
)
export const allowAddCreateEmailTemplate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_EMAIL_TEMPLATE)
)

export const allowUpdateCreateEmailTemplate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_EMAIL_TEMPLATE)
)

export const allowDeleteCreateEmailTemplate = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.DELETE_EMAIL_TEMPLATE)
)

// Applications
export const allowGetListApps = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_LIST_APPS)
)
export const allowAddApps = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.ADD_APPS)
)

export const allowDeleteApps = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean => false
   // permissionState.function.includes(FunctionEnum.DELETE_APPS)
)

export const allowUpdateApps = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.UPDATE_APPS)
)

export const allowGetKeyApps = createSelector(
   selectPermissionState,
   (permissionState: PermissionState): boolean =>
      permissionState.function.includes(FunctionEnum.GET_KEY_APPS)
)
