import {
   selectPageNumberManagementUserHistory,
   selectPageSizeManagementUserHistory,
   selectSearchConditionManagementUserHistory,
   selectSelectedManagementUserHistory,
} from "./managementUserHistory.selector"
import {
   getManagementUserHistoryAPI,
   GetManagementUserHistoryPayloadAPIInterface,
} from "../../api/managementUserHistory.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementUserHistoryAsync,
} from "./managementUserHistory.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   getManagementUserHistoryFail,
   getManagementUserHistorySuccess,
   requestManagementUserHistory,
} from "./managementUserHistory.slice"

/**
 * Get management user history saga
 * @param action
 */
function* getManagementUserHistorySaga(action: PayloadAction<any>): any {
   try {
      yield put(requestManagementUserHistory(action.payload))
      const pageNumber = yield select(selectPageNumberManagementUserHistory)
      const pageSize = yield select(selectPageSizeManagementUserHistory)
      const searchCondition = yield select(
         selectSearchConditionManagementUserHistory
      )
      const params: GetManagementUserHistoryPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         id: action.payload.id,
      }
      const { response, error } = yield call(
         getManagementUserHistoryAPI,
         params
      )
      if (!response) {
         throw error
      }
      yield put(getManagementUserHistorySuccess(response))
   } catch (error: any) {
      yield put(getManagementUserHistoryFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      const selected = yield select(selectSelectedManagementUserHistory)
      yield put(getManagementUserHistoryAsync(selected))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      const selected = yield select(selectSelectedManagementUserHistory)
      yield put(getManagementUserHistoryAsync(selected))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      const selected = yield select(selectSelectedManagementUserHistory)
      yield put(getManagementUserHistoryAsync(selected))
   } catch (error) {}
}

/**
 * Root management user history saga
 */
export function* managementUserHistorySaga() {
   yield takeLatest(getManagementUserHistoryAsync, getManagementUserHistorySaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
}
