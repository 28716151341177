import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getCreateEmailTemplateAsync,
   reloadGetCreateEmailTemplateAsync,
} from "../../../states/createEmailTemplate/createEmailTemplate.action"
import {
   selectCountCreateEmailTemplate,
   selectDataCreateEmailTemplate,
   selectErrorCreateEmailTemplate,
   selectPageNumberCreateEmailTemplate,
   selectPageSizeCreateEmailTemplate,
   selectRequestingCreateEmailTemplate,
   selectSearchConditionCreateEmailTemplate,
   selectSortConditionCreateEmailTemplate,
} from "../../../states/createEmailTemplate/createEmailTemplate.selector"
import { CreateEmailTemplateComponent } from "./createEmailTemplate.component"
import { SortColumnEnum } from "../../../common/enum/sortColumn.enum"
import {
   AddCreateEmailTemplatePayloadAPIInterface,
   DeleteCreateEmailTemplatePayloadAPIInterface,
   GetDetailCreateEmailTemplatePayloadAPIInterface,
   UpdateCreateEmailTemplatePayloadAPIInterface,
   addCreateEmailTemplateAPI,
   deleteCreateEmailTemplateAPI,
   getDetailCreateEmailTemplateAPI,
   updateCreateEmailTemplateAPI,
} from "../../../api/createEmailTemplate.api"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { CreateEmailTemplateInput } from "./createEmailTemplate.form"

type CreateEmailTemplateContainerProps = {
   handleApplyEmailTemplate: (template: string) => void
}

export const CreateEmailTemplateContainer = (
   props: CreateEmailTemplateContainerProps
) => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingCreateEmailTemplate)
   const templateData = useSelector(selectDataCreateEmailTemplate)
   const rowCount = useSelector(selectCountCreateEmailTemplate)
   const pageNumber = useSelector(selectPageNumberCreateEmailTemplate)
   const pageSize = useSelector(selectPageSizeCreateEmailTemplate)
   const error = useSelector(selectErrorCreateEmailTemplate)
   const sortCondition = useSelector(selectSortConditionCreateEmailTemplate)
   const searchCondition = useSelector(selectSearchConditionCreateEmailTemplate)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle get detail async
    * @param values
    * @returns
    */
   const handleGetDetailAsync = (id: string) => {
      const params: GetDetailCreateEmailTemplatePayloadAPIInterface = {
         id: id,
      }
      return getDetailCreateEmailTemplateAPI(params)
   }

   /**
    * Handle add async
    * @param values
    * @returns
    */
   const handleAddAsync = (
      values: CreateEmailTemplateInput,
      content: string
   ) => {
      const params: AddCreateEmailTemplatePayloadAPIInterface = {
         name: values.name,
         content: content,
      }
      return addCreateEmailTemplateAPI(params)
   }

   /**
    * Handle update async
    * @param values
    * @returns
    */
   const handleUpdateAsync = (
      id: string,
      values: CreateEmailTemplateInput,
      content: string
   ) => {
      const params: UpdateCreateEmailTemplatePayloadAPIInterface = {
         id: id,
         name: values.name,
         content: content,
      }
      return updateCreateEmailTemplateAPI(params)
   }

   /**
    * Handle delete async
    * @param values
    * @returns
    */
   const handleDeleteAsync = (id: string) => {
      const params: DeleteCreateEmailTemplatePayloadAPIInterface = {
         id: id,
      }
      return deleteCreateEmailTemplateAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle reload async
    */
   const handleReloadAsync = () => {
      dispatch(reloadGetCreateEmailTemplateAsync())
   }

   useEffect(() => {
      dispatch(getCreateEmailTemplateAsync())
   }, [dispatch])

   return (
      <CreateEmailTemplateComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         templateData={templateData}
         sortCondition={sortCondition}
         searchCondition={searchCondition}
         error={error}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChangeSortCondition={handleChangeSortCondition}
         handleApplyEmailTemplate={props.handleApplyEmailTemplate}
         handleGetDetailAsync={handleGetDetailAsync}
         handleAddAsync={handleAddAsync}
         handleUpdateAsync={handleUpdateAsync}
         handleDeleteAsync={handleDeleteAsync}
         handlePushNotistack={handlePushNotistack}
         handleReloadAsync={handleReloadAsync}
      />
   )
}
