import {
   RefreshTokenPayloadAPIInterface,
   refreshTokenAPI,
} from "./../../api/auth.api"
import { call, delay, put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import {
   SignInPayloadAPIInterface,
   SignInAppPayloadAPIInterface,
   signInAPI,
   signInAppAPI,
} from "../../api/auth.api"
import {
   signInSuccess,
   signInFail,
   signInRequest,
   logout,
   resetSignIn,
   authenAppSuccess,
} from "./auth.slice"
import { clearNotistack, pushNotistack } from "../notistack/notistack.slice"
import { decodeToken } from "react-jwt"
import {
   checkAuthAsync,
   signInAsync,
   logoutAsync,
   resetSignInAsync,
} from "./auth.action"
import { removeUserInfo } from "../user/user.slice"
import { getPermissionSaga } from "../permission/permission.saga"
import { getUserInfoSaga } from "../user/user.saga"
import { i18n } from "../../i18n/i18n"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Authenticate app for sign in process
 * @returns
 */
export function* authenticateApp(): any {
   try {
      const params: SignInAppPayloadAPIInterface = {
         username: process.env
            .REACT_APP_AUTHENTICATE_APP_USERNAME_KEY as string,
         password: process.env
            .REACT_APP_AUTHENTICATE_APP_PASSWORD_KEY as string,
      }
      const { response, error } = yield call(signInAppAPI, params)
      if (!response) {
         throw error
      } else {
         yield put(authenAppSuccess(response.data.token))
         return response.data.token
      }
   } catch (error: any) {
      yield put(signInFail("error.signIn"))
   }
}

/**
 * Sign in saga
 * Interact with session/cookie storage
 * @param action
 */
function* signInSaga(action: PayloadAction<any>): any {
   try {
      yield put(signInRequest())
      yield delay(1000)
      const token = yield call(authenticateApp)
      if (token) {
         const params: SignInPayloadAPIInterface = {
            phoneNumber: action.payload.values.phoneNumber,
            password: action.payload.values.password,
         }

         const { response, error } = yield call(signInAPI, token, params)
         if (!response) {
            throw error
         }

         const myDecodedToken: any = decodeToken(response.data.token)
         // Session
         const d = new Date()
         d.setTime(d.getTime() + response.data.expiredIn * 1000)
         const expires = d.toUTCString()
         const obj = {
            token: response.data.token,
            expiredIn: expires,
         }
         localStorage.clear()
         sessionStorage.clear()
         if (action.payload.values.isSave) {
            localStorage.setItem(
               process.env.REACT_APP_AUTHENTICATE_USER_KEY as string,
               JSON.stringify(obj)
            )
         } else {
            sessionStorage.setItem(
               process.env.REACT_APP_AUTHENTICATE_USER_KEY as string,
               JSON.stringify(obj)
            )
         }
         const payload = {
            userid: myDecodedToken.name,
            role: myDecodedToken.role,
         }

         yield call(getPermissionSaga, obj.token)

         yield call(getUserInfoSaga)

         yield put(signInSuccess(payload))

         yield put(
            pushNotistack({
               message: i18n.t("notistack.welcome", { ns: namespaces.common }),
               variant: "success",
            })
         )

         action.payload.navigate("/")
      }
   } catch (error: any) {
      yield put(signInFail(error.response.data.detail))
   }
}

/**
 * Check authentication whenever init app
 */
function* checkAuthenticateSaga(): any {
   try {
      yield put(signInRequest())
      yield delay(1000)
      yield call(authenticateApp)
      const local = localStorage.getItem(
         process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
      )
      const session = sessionStorage.getItem(
         process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
      )
      const userData = local || session
      if (!userData) {
         yield put(signInFail(userData))
      } else {
         const obj = JSON.parse(userData)

         if (new Date(obj.expiredIn) < new Date()) {
            yield put(signInFail(""))
            window.location.href = "/session"
            return
         }

         const response = yield call(refreshTokenSaga, obj.token)

         yield call(getPermissionSaga, obj.token)

         const myDecodedToken: any = decodeToken(response.data.token as string)

         // Session
         const d = new Date()
         d.setTime(d.getTime() + response.data.expiredIn * 1000)
         const expires = d.toUTCString()
         const newSaveObj = {
            token: response.data.token,
            expiredIn: expires,
         }
         if (local) {
            localStorage.setItem(
               process.env.REACT_APP_AUTHENTICATE_USER_KEY as string,
               JSON.stringify(newSaveObj)
            )
         } else {
            sessionStorage.setItem(
               process.env.REACT_APP_AUTHENTICATE_USER_KEY as string,
               JSON.stringify(newSaveObj)
            )
         }

         const payload = {
            userid: myDecodedToken.name,
            role: myDecodedToken.role,
         }

         yield call(getUserInfoSaga)

         yield put(signInSuccess(payload))
      }
   } catch (error: any) {
      yield put(signInFail(error.message))
   }
}

/**
 * Logout
 * @param action
 */
function* logoutSaga(action: PayloadAction<any>): any {
   try {
      localStorage.removeItem(
         process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
      )
      sessionStorage.removeItem(
         process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
      )
      yield put(logout())
      yield put(removeUserInfo())
      yield put(clearNotistack())
      window.location.reload()
   } catch (error: any) {}
}

/**
 * Refresh token
 * @param token
 * @returns
 */
function* refreshTokenSaga(token: string): any {
   try {
      const params: RefreshTokenPayloadAPIInterface = {
         token: token,
      }
      const { response, error } = yield call(refreshTokenAPI, params)
      if (!response) {
         throw error
      }
      return response
   } catch (error: any) {
      throw error
   }
}

/**
 * Reset sign in saga
 */
export function* resetSignInSaga(): any {
   try {
      yield put(resetSignIn())
   } catch (error: any) {
      throw error
   }
}

/**
 * Root auth saga
 */
export function* authSaga() {
   yield takeLatest(checkAuthAsync, checkAuthenticateSaga)
   yield takeLatest(signInAsync, signInSaga)
   yield takeLatest(logoutAsync, logoutSaga)
   yield takeLatest(resetSignInAsync, resetSignInSaga)
}
