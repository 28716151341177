import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import {
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   Grid,
   LinearProgress,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
   TextField,
   Toolbar,
   Tooltip,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import { FilterTableComponent } from "../../../common/components/FilterTable/filterTable.component"
import { TablePaginationActionsComponent } from "../../../common/components/TablePaginationActions/tablePaginationActions.component"
import { DialogMethodEnum } from "../../../common/enum/dialog.enum"
import { SortOrderEnum } from "../../../common/enum/sortOrder.enum"
import { filterTypes, columns, createData } from "./createEmailTemplate.table"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { SortConditionInterface } from "../../../common/interfaces/sortCondition.interface"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import { SortColumnEnum } from "../../../common/enum/sortColumn.enum"
import AddIcon from "@mui/icons-material/Add"
import { createSortDirection } from "../../../common/helper/sortTable.helper"
import { visuallyHidden } from "@mui/utils"
import commonStyles from "../../../common/styles/common.module.css"
import { useCreateEmailTemplatePermission } from "./createEmailTemplate.permission"
import { useEffect, useState } from "react"
import { CreateEmailTemplateInterface } from "../../../common/interfaces/createEmailTemplate.interface"
import { SubmitHandler, useForm } from "react-hook-form"
import {
   CreateEmailTemplateInput,
   createEmailTemplateSchema,
} from "./createEmailTemplate.form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCKEditor } from "ckeditor4-react"
import ColorizeIcon from "@mui/icons-material/Colorize"

type CreateEmailTemplateComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   sortCondition: SortConditionInterface
   searchCondition: SearchConditionState
   templateData: any[]
   error: string
   handlePushNotistack: (message: string, variant: string) => void
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
   handleApplyEmailTemplate: (template: string) => void
   handleGetDetailAsync: (id: string) => Promise<any>
   handleDeleteAsync: (id: string) => Promise<any>
   handleAddAsync: (
      values: CreateEmailTemplateInput,
      content: string
   ) => Promise<any>
   handleUpdateAsync: (
      id: string,
      values: CreateEmailTemplateInput,
      content: string
   ) => Promise<any>
   handleReloadAsync: () => void
}

export const CreateEmailTemplateComponent = (
   props: CreateEmailTemplateComponentProps
) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
   const { t } = useTranslation(namespaces.pages.createEmail)
   const [requesting, setRequesting] = useState<boolean>(false)
   const [element, setElement] = useState<HTMLDivElement | null>(null)
   const [dataEditor, setDataEditor] = useState<string>("")

   const {
      allowDeleteCreateEmailTemplate,
      allowAddCreateEmailTemplate,
      allowUpdateCreateEmailTemplate,
   } = useCreateEmailTemplatePermission()

   // Form
   const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
      setValue,
   } = useForm<CreateEmailTemplateInput>({
      resolver: zodResolver(createEmailTemplateSchema),
   })

   // Template list
   const rows = props.templateData.map((template) =>
      createData(
         template.id,
         template.name,
         template.content,
         template.createdAt
      )
   )

   // Editor
   const { editor } = useCKEditor({
      element,
      subscribeTo: ["blur", "change", "focus"],
   })

   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }

   // Dialog state
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.NONE
   )
   const [selectedDialog, setSelectedDialog] =
      useState<CreateEmailTemplateInterface | null>(null)

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: CreateEmailTemplateInterface | null = null
   ) => {
      reset()
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
         if (type === DialogMethodEnum.UPDATE) {
            props
               .handleGetDetailAsync(selected.id)
               .then((res) => {
                  setValue("name", res.response.data.name)
                  setDataEditor(res.response.data.content)
                  setRequesting(false)
               })
               .catch((err) => {
                  setRequesting(false)
               })
         }
      }
   }

   /**
    * Handle apply template
    * @param id
    */
   const handleApplyTemplate = (id: string) => {
      props
         .handleGetDetailAsync(id)
         .then((res) => {
            props.handleApplyEmailTemplate(res.response.data.content)
            props.handlePushNotistack("Áp dụng mẫu thành công!", "success")
         })
         .catch((err) => {
            props.handlePushNotistack("Áp dụng mẫu thất bại!", "success")
         })
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      setTypeDialog(DialogMethodEnum.NONE)
      setDataEditor("")
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requesting) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("dialog.title.add")}
            {typeDialog === DialogMethodEnum.DELETE && t("dialog.title.delete")}
            {typeDialog === DialogMethodEnum.UPDATE && t("dialog.title.update")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button type="submit" disabled={requesting}>
                  {t("button.addTemplate")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.UPDATE && (
               <Button type="submit" disabled={requesting}>
                  {t("button.update")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requesting}
                  onClick={onDeleteEmailTemplate}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requesting}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteConfirm", { item: selectedDialog?.name })}
               </Typography>
            </DialogContent>
         )
      }

      if (
         typeDialog === DialogMethodEnum.ADD ||
         typeDialog === DialogMethodEnum.UPDATE
      ) {
         return (
            <DialogContent>
               <FormControl fullWidth margin="normal">
                  <TextField
                     autoFocus
                     variant="standard"
                     id="outlined-adornment-name"
                     label={t("field.name")}
                     required
                     error={!!errors.name}
                     helperText={errors.name ? errors.name.message : ""}
                     InputProps={{
                        readOnly: requesting,
                     }}
                     {...register("name")}
                  />
               </FormControl>
               <div ref={setElement}></div>
            </DialogContent>
         )
      }

      return <DialogContent></DialogContent>
   }

   /**
    * On delete
    */
   const onDeleteEmailTemplate = () => {
      setRequesting(true)
      props
         .handleDeleteAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequesting(false)
            props.handlePushNotistack("Xóa mẫu thành công!", "success")
            handleCloseDialog()
            props.handleReloadAsync()
         })
         .catch((err) => {
            setRequesting(false)
            if (err.response.data.status === 500) {
               props.handlePushNotistack(err.response.data.detail, "error")
            } else {
               props.handlePushNotistack("Xóa mẫu thất bại!", "error")
            }
         })
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<CreateEmailTemplateInput> = (
      values: CreateEmailTemplateInput
   ) => {
      setRequesting(true)
      if (typeDialog === DialogMethodEnum.ADD) {
         props
            .handleAddAsync(values, editor.getData())
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack("Thêm mẫu thành công!", "success")
               handleCloseDialog()
               props.handleReloadAsync()
            })
            .catch((err) => {
               setRequesting(false)
               if (err.response.data.status === 500) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack("Thêm mẫu thất bại!", "error")
               }
            })
      } else if (typeDialog === DialogMethodEnum.UPDATE) {
         if (!selectedDialog) {
            return
         }
         props
            .handleUpdateAsync(selectedDialog.id, values, editor.getData())
            .then((res) => {
               setRequesting(false)
               props.handlePushNotistack("Cập nhật mẫu thành công!", "success")
               handleCloseDialog()
               props.handleReloadAsync()
            })
            .catch((err) => {
               setRequesting(false)
               if (err.response.data?.detail != null) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack("Cập nhật mẫu thất bại!", "error")
               }
            })
      }
   }

   useEffect(() => {
      if (editor) {
         editor.setData(dataEditor)
      }
   }, [dataEditor, editor])

   return (
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
         <Toolbar
            sx={{
               pl: { sm: 2 },
               pr: { xs: 1, sm: 1 },
               py: 2,
            }}
         >
            <Grid
               container
               spacing={2}
               alignItems="center"
               justifyContent="space-between"
            >
               <Grid item xs={12} md={12} xl={6}>
                  <FilterTableComponent
                     searchCondition={props.searchCondition}
                     types={filterTypes}
                     onFilter={(value: string, option: string) =>
                        props.handleChangeSearchCondition(value, option)
                     }
                  />
               </Grid>
               <Grid item xs={12} md={12} xl={6}>
                  <Grid container spacing={2} justifyContent="flex-end">
                     {allowAddCreateEmailTemplate && (
                        <Grid item xs={12} sm={12} xl={4}>
                           <Button
                              fullWidth
                              color="secondary"
                              variant="contained"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                 handleOpenDialog(DialogMethodEnum.ADD)
                              }
                           >
                              {t("createEmailTemplate.add")}
                           </Button>
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            </Grid>
         </Toolbar>
         <TableContainer>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                           }}
                        >
                           {column.sortKey ? (
                              <TableSortLabel
                                 active={
                                    props.sortCondition.column ===
                                    column.sortKey
                                 }
                                 direction={sortDirection}
                                 onClick={createSortHandler(column.sortKey)}
                              >
                                 {t(column.label)}
                                 {props.sortCondition.column ===
                                 column.sortKey ? (
                                    <Box component="span" sx={visuallyHidden}>
                                       {props.sortCondition.order ===
                                       SortOrderEnum.DESC
                                          ? "Giảm"
                                          : "Tăng"}
                                    </Box>
                                 ) : null}
                              </TableSortLabel>
                           ) : (
                              t(column.label)
                           )}
                        </TableCell>
                     ))}
                     <TableCell key={"action"} style={{ minWidth: 170 }}>
                        {t("createEmailTemplate.action")}
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {/* Loading */}
                  {props.requesting && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <div className={commonStyles.flexCenterCenter}>
                              <CircularProgress color="secondary" />
                           </div>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Error */}
                  {!props.requesting && props.error && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <Typography variant="body1" color="error">
                              {t(props.error, { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Empty */}
                  {!props.requesting && !props.error && rows.length === 0 && (
                     <TableRow>
                        <TableCell colSpan={columns.length + 1}>
                           <Typography variant="body1">
                              {t("table.empty", { ns: "common" })}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  )}
                  {/* Data  */}
                  {!props.requesting &&
                     !props.error &&
                     rows.map((row) => (
                        <TableRow
                           hover
                           role="checkbox"
                           tabIndex={-1}
                           key={row.id}
                        >
                           {columns.map((column) => {
                              let value = row[column.id]
                              value = !value ? "---" : value
                              return (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                       minWidth: column.minWidth,
                                       maxWidth: column.maxWidth,
                                    }}
                                 >
                                    {value}
                                 </TableCell>
                              )
                           })}
                           <TableCell key={"action"}>
                              {allowUpdateCreateEmailTemplate && (
                                 <Tooltip
                                    title={t("createEmailTemplate.update")}
                                 >
                                    <IconButton
                                       aria-label="update"
                                       onClick={() =>
                                          handleOpenDialog(
                                             DialogMethodEnum.UPDATE,
                                             row
                                          )
                                       }
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </Tooltip>
                              )}
                              {allowDeleteCreateEmailTemplate && (
                                 <Tooltip
                                    title={t("createEmailTemplate.delete")}
                                 >
                                    <IconButton
                                       aria-label="delete"
                                       onClick={() =>
                                          handleOpenDialog(
                                             DialogMethodEnum.DELETE,
                                             row
                                          )
                                       }
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                 </Tooltip>
                              )}
                              <Tooltip title={t("createEmailTemplate.apply")}>
                                 <IconButton
                                    aria-label="apply"
                                    onClick={() => handleApplyTemplate(row.id)}
                                 >
                                    <ColorizeIcon />
                                 </IconButton>
                              </Tooltip>
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            showFirstButton
            showLastButton
            labelRowsPerPage={t("table.pageSize", { ns: "common" })}
            labelDisplayedRows={(page) => {
               return `${t("table.page", { ns: "common" })}: ${
                  page.page + 1
               } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                  "table.record",
                  { ns: "common" }
               )}`
            }}
            rowsPerPageOptions={[5]}
            component="div"
            count={props.rowCount}
            rowsPerPage={props.pageSize}
            page={props.pageNumber - 1}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsComponent}
         />
         <Dialog
            fullScreen={fullScreen}
            fullWidth={
               typeDialog === DialogMethodEnum.ADD ||
               typeDialog === DialogMethodEnum.UPDATE
            }
            maxWidth={
               typeDialog === DialogMethodEnum.ADD ||
               typeDialog === DialogMethodEnum.UPDATE
                  ? "md"
                  : "sm"
            }
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="on"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Paper>
   )
}
