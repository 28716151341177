import { createAction } from "@reduxjs/toolkit"

export const getManagementUserAsync = createAction(
   "managementUser/getManagementUserAsync"
)

export const getCountManagementUserAsync = createAction(
   "managementUser/get/CountManagementUserAsync"
)

export const changePageNumberAsync = createAction<number>(
   "managementUser/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "managementUser/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "managementUser/changeSearchConditionAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "managementUser/changeSortConditionAsync"
)

export const exportManagementUserAsync = createAction(
   "managementUser/exportManagementUserAsync"
)
