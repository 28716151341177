import {
   selectPageNumberPromotionPack,
   selectPageSizePromotionPack,
   selectSearchConditionPromotionPack,
   selectSortConditionPromotionPack,
} from "./promotionPack.selector"
import {
   getPromotionPackAPI,
   GetPromotionPackPayloadAPIInterface,
} from "../../api/promotionPack.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getPromotionPackAsync,
} from "./promotionPack.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getPromotionPackFail,
   getPromotionPackSuccess,
   requestPromotionPack,
} from "./promotionPack.slice"

/**
 * Get management type user saga
 * @param action
 */
function* getPromotionPackSaga(): any {
   try {
      yield put(requestPromotionPack())
      const pageNumber = yield select(selectPageNumberPromotionPack)
      const pageSize = yield select(selectPageSizePromotionPack)
      const searchCondition = yield select(selectSearchConditionPromotionPack)
      const sortCondition = yield select(selectSortConditionPromotionPack)
      const params: GetPromotionPackPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getPromotionPackAPI, params)
      if (!response) {
         throw error
      }
      yield put(getPromotionPackSuccess(response))
   } catch (error: any) {
      yield put(getPromotionPackFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getPromotionPackAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getPromotionPackAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getPromotionPackAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getPromotionPackAsync())
   } catch (error) {}
}

/**
 * Root recharge pack saga
 */
export function* promotionPackSaga() {
   yield takeLatest(getPromotionPackAsync, getPromotionPackSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
