import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { TypeUserState } from "./typeUser.slice"

const selectTypeUserState = (state: RootState): TypeUserState => state.typeUser

export const selectRequestingTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.requesting
)

export const selectDataTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.data
)

export const selectErrorTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.error
)

export const selectCountTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.rowCount
)

export const selectPageNumberTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.paging.pageNumber
)

export const selectPageSizeTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.paging.pageSize
)

export const selectSearchConditionTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.searchCondition
)

export const selectSortConditionTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.sortCondition
)

export const selectIsTriggerSyncTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.isTriggerSync
)

export const selectRequestingSyncTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.requestingSync
)

export const selectDateSyncTypeUser = createSelector(
   selectTypeUserState,
   (state: TypeUserState) => state.dateSync
)
