import { boolean, object, string, TypeOf, z } from "zod"
import { SPACE_REGEX } from "../../common/variables/regex"

export const appsDialogSchema = object({
   name: string()
      .min(1, "Tên ứng dụng bắt buộc nhập")
      .regex(new RegExp(SPACE_REGEX), "Tên ứng dụng bắt buộc nhập"),
   zaloOaId: string().optional(),
   zaloUserName: string().optional(),
   zaloOtpTemplateId: string().optional(),
   isUseOtpSystem: boolean().default(true),
}).superRefine((data, ctx) => {
   if (data.isUseOtpSystem === false) {
      if (!data.zaloOaId) {
         ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "[Zalo] Mã OA bắt buộc nhập",
            path: ["zaloOaId"],
         })
      }
      if (!data.zaloUserName) {
         ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "[Zalo] Tài khoản bắt buộc nhập",
            path: ["zaloUserName"],
         })
      }
      if (!data.zaloOtpTemplateId) {
         ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "[Zalo] Mã mẫu gửi OTP bắt buộc nhập",
            path: ["zaloOtpTemplateId"],
         })
      }
   }
})

export type AppsDialogInput = TypeOf<typeof appsDialogSchema>
