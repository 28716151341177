import styles from "./filterChart.module.css"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import React, { useEffect, useRef, useState } from "react"
import {
   defaultApp,
   defaultProvince,
   filterChartConst,
} from "./../../variables/filterChart"
import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Button from "@mui/material/Button"
import BarChartIcon from "@mui/icons-material/BarChart"
import PieChartIcon from "@mui/icons-material/PieChart"
import SsidChartIcon from "@mui/icons-material/SsidChart"
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom"
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Stack from "@mui/material/Stack"
import { FilterChartGUIInterface } from "../../interfaces/filterChartGUI.interface"
import { FilterChartSearchConditionInterface } from "../../interfaces/filterChartSearchCondition.interface"
import Grid from "@mui/material/Grid"
import { ChartEnum } from "../../enum/chart.enum"
import { ChartTypeEnum } from "../../enum/chartType.enum"
import { AxisTypeEnum } from "../../enum/axisType.enum"
import AddchartIcon from "@mui/icons-material/Addchart"
import DownloadIcon from "@mui/icons-material/Download"
import { CalendarPickerView } from "@mui/x-date-pickers"
import { LoadingButton } from "@mui/lab"
import { convertDay, getMaxDateChart } from "../../helper/date.helper"
import { Autocomplete, CircularProgress, Typography } from "@mui/material"
import { ProvinceInterface } from "../../interfaces/province.interface"
import { getProvinceAPI } from "../../../api/province.api"
import { AppInterface } from "../../interfaces/app.interface"
import { getAppAPI } from "../../../api/app.api"

type FilterChartComponentProps = {
   requesting: boolean
   requestingExport: boolean
   filter: FilterChartGUIInterface
   searchCondtion: FilterChartSearchConditionInterface
   isDisableStatistic: boolean
   isDisableExport: boolean
   isAllowGetDate: boolean
   isAllowGetApp: boolean
   isAllowGetProvince: boolean
   isAllowExportProvince: boolean
   isAllowExportDate: boolean
   isAllowExportApp: boolean
   handleStatistic: () => void
   handleChangeFilter: (data: any) => void
   handleChangeSearchCondition: (data: any) => void
   handleChartExport: () => void
}

export const FilterChartComponent = (props: FilterChartComponentProps) => {
   const { t } = useTranslation(namespaces.common)
   const fromRef = useRef<HTMLDivElement>(null)
   const toRef = useRef<HTMLDivElement>(null)
   const [fromInvalid, setFromInvalid] = useState<boolean>(true)
   const [toInvalid, setToInvalid] = useState<boolean>(true)
   // Province option
   const [openFilterProvince, setOpenFilterProvince] = useState(false)
   const [optionsFilterProvince, setOptionsFilterProvince] = useState<
      readonly ProvinceInterface[]
   >([defaultProvince])

   const loadingProvince =
      openFilterProvince && optionsFilterProvince.length === 1

   useEffect(() => {
      if (!loadingProvince) {
         return undefined
      }

      ;(async () => {
         getProvinceAPI().then((res) => {
            const provinces = res.data.map((e: any) => {
               const province: ProvinceInterface = {
                  id: e.id,
                  name: e.name,
               }
               return province
            })
            setOptionsFilterProvince(optionsFilterProvince.concat(provinces))
         })
      })()
   }, [loadingProvince, setOptionsFilterProvince, optionsFilterProvince])

   useEffect(() => {
      if (!openFilterProvince) {
         setOptionsFilterProvince([defaultProvince])
      }
   }, [openFilterProvince, setOptionsFilterProvince])

   // App option
   const [openFilterApp, setOpenFilterApp] = useState(false)
   const [optionsFilterApp, setOptionsFilterApp] = useState<
      readonly AppInterface[]
   >([defaultApp])

   const loadingApp = openFilterApp && optionsFilterApp.length === 1

   useEffect(() => {
      if (!loadingApp) {
         return undefined
      }

      ;(async () => {
         getAppAPI().then((res) => {
            const apps = res.data.data.map((e: any) => {
               const app: AppInterface = {
                  id: e.id,
                  name: e.name,
               }
               return app
            })
            setOptionsFilterApp(optionsFilterApp.concat(apps))
         })
      })()
   }, [loadingApp, setOptionsFilterApp, optionsFilterApp])

   useEffect(() => {
      if (!openFilterApp) {
         setOptionsFilterApp([defaultApp])
      }
   }, [openFilterApp, setOptionsFilterApp])

   /**
    * Handle change type
    * @param event
    */
   const handleChangeType = (event: SelectChangeEvent) => {
      const type = event.target.value
      props.handleChangeSearchCondition({ type: type as string })
   }

   /**
    * Handle change datetime from
    * @param newValue
    */
   const handleChangeDatetimeFrom = (newValue: Dayjs | null) => {
      props.handleChangeSearchCondition({ fromDate: newValue })
   }

   /**
    * Handle change datetime to
    * @param newValue
    */
   const handleChangeDatetimeTo = (newValue: Dayjs | null) => {
      props.handleChangeSearchCondition({ toDate: newValue })
   }

   /**
    * Handle change chart type
    * @param event
    */
   const handleChangeChartType = (
      event: React.MouseEvent<HTMLElement>,
      newChartType: ChartTypeEnum | null
   ) => {
      if (!newChartType) {
         return
      }
      props.handleChangeFilter({ chartType: newChartType })
   }

   /**
    * Handle change bar chart group mode
    * @param event
    */
   const handleChangeBarChartGroupMode = (
      event: React.MouseEvent<HTMLElement>,
      newGroupMode: AxisTypeEnum | null
   ) => {
      if (!newGroupMode) {
         return
      }
      props.handleChangeFilter({ axisType: newGroupMode })
   }

   /**
    * Get views
    * @returns
    */
   const getViews = (): readonly CalendarPickerView[] | undefined => {
      if (props.searchCondtion.type === ChartEnum.DAY) {
         return undefined
      }
      if (props.searchCondtion.type === ChartEnum.MONTH) {
         return ["month", "year"]
      }
      if (props.searchCondtion.type === ChartEnum.YEAR) {
         return ["year"]
      }
      return undefined
   }

   /**
    * Get input format
    * @returns
    */
   const getInputFormat = (): string => {
      if (props.searchCondtion.type === ChartEnum.DAY) {
         return "DD/MM/YYYY"
      }
      if (props.searchCondtion.type === ChartEnum.MONTH) {
         return "MM/YYYY"
      }
      if (props.searchCondtion.type === ChartEnum.YEAR) {
         return "YYYY"
      }
      return "DD/MM/YYYY"
   }

   useEffect(() => {
      const fromInvalidRef = fromRef.current
         ?.getElementsByTagName("input")[0]
         .getAttribute("aria-invalid")
      const toInvalidRef = toRef.current
         ?.getElementsByTagName("input")[0]
         .getAttribute("aria-invalid")
      setFromInvalid(fromInvalidRef === "true")
      setToInvalid(toInvalidRef === "true")
   }, [props.searchCondtion.fromDate, props.searchCondtion.toDate])

   return (
      <React.Fragment>
         <Grid container>
            <Grid item xs={12} lg={3}>
               <FormControl fullWidth margin="normal">
                  <InputLabel id="type-select-label">
                     {t("filter.chartType")}
                  </InputLabel>
                  <Select
                     displayEmpty
                     labelId="type-select-label"
                     id="type-select"
                     value={
                        props.searchCondtion.type === ChartEnum.NONE
                           ? ""
                           : props.searchCondtion.type
                     }
                     label={t("filter.chartType")}
                     onChange={handleChangeType}
                  >
                     {filterChartConst
                        .filter(
                           (e) =>
                              (e.id === ChartEnum.DAY ||
                                 e.id === ChartEnum.MONTH ||
                                 e.id === ChartEnum.YEAR) &&
                              props.isAllowGetDate
                        )
                        .map((filter) => (
                           <MenuItem key={filter.id} value={filter.id}>
                              {t(filter.text)}
                           </MenuItem>
                        ))}
                     {filterChartConst
                        .filter(
                           (e) => e.id === ChartEnum.APP && props.isAllowGetApp
                        )
                        .map((filter) => (
                           <MenuItem key={filter.id} value={filter.id}>
                              {t(filter.text)}
                           </MenuItem>
                        ))}
                     {filterChartConst
                        .filter(
                           (e) =>
                              e.id === ChartEnum.PROVINCE &&
                              props.isAllowGetProvince
                        )
                        .map((filter) => (
                           <MenuItem key={filter.id} value={filter.id}>
                              {t(filter.text)}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={12} lg={0.5}>
               <Stack
                  className={styles.divider}
                  alignItems="center"
                  justifyContent="center"
               >
                  <div></div>
               </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
               <FormControl fullWidth margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DesktopDatePicker
                        disableFuture
                        disableHighlightToday
                        label={t("filter.datetimeFrom")}
                        inputFormat={getInputFormat()}
                        value={props.searchCondtion.fromDate}
                        onChange={handleChangeDatetimeFrom}
                        views={getViews()}
                        maxDate={dayjs(getMaxDateChart())}
                        ref={fromRef}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </FormControl>
            </Grid>
            <Grid item xs={12} lg={0.5}>
               <Stack
                  className={styles.forwardIcon}
                  alignItems="center"
                  justifyContent="center"
               >
                  <ArrowForwardIcon></ArrowForwardIcon>
               </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
               <FormControl fullWidth margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DesktopDatePicker
                        disableFuture
                        disableHighlightToday
                        label={t("filter.datetimeTo")}
                        inputFormat={getInputFormat()}
                        value={props.searchCondtion.toDate}
                        minDate={props.searchCondtion.fromDate}
                        onChange={handleChangeDatetimeTo}
                        views={getViews()}
                        maxDate={dayjs(getMaxDateChart())}
                        ref={toRef}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </FormControl>
            </Grid>
            {props.searchCondtion.type === ChartEnum.PROVINCE && (
               <Grid item xs={12}>
                  {/* TODO */}
                  <FormControl fullWidth margin="normal">
                     <Autocomplete
                        id="filter-chart-province"
                        multiple
                        disableCloseOnSelect
                        open={openFilterProvince}
                        onOpen={() => {
                           setOpenFilterProvince(true)
                        }}
                        onClose={() => {
                           setOpenFilterProvince(false)
                        }}
                        onChange={(
                           event: any,
                           newValue: ProvinceInterface[] | null
                        ) => {
                           const isAll =
                              event.target.getAttribute("id") ===
                              "filter-chart-province-option-0"
                           if (!isAll && newValue) {
                              if (
                                 (newValue.includes(defaultProvince) &&
                                    newValue.length > 1) ||
                                 !newValue.includes(defaultProvince)
                              ) {
                                 const filtered = newValue.filter(
                                    (x) => x.id !== defaultProvince.id
                                 )
                                 props.handleChangeSearchCondition({
                                    province: filtered,
                                 })
                              } else {
                                 props.handleChangeSearchCondition({
                                    province: [defaultProvince],
                                 })
                              }
                           } else {
                              props.handleChangeSearchCondition({
                                 province: [defaultProvince],
                              })
                           }
                        }}
                        value={props.searchCondtion.province}
                        defaultValue={[optionsFilterProvince[0]]}
                        isOptionEqualToValue={(option, value) =>
                           option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        options={optionsFilterProvince}
                        loading={loadingProvince}
                        noOptionsText={t("dialog.noOption")}
                        renderOption={(htmlProps, option) => {
                           return (
                              <li id={option.id} {...htmlProps} key={option.id}>
                                 {option.name}
                              </li>
                           )
                        }}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              fullWidth
                              label={t("field.province")}
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loadingProvince ? (
                                          <CircularProgress
                                             color="inherit"
                                             size={20}
                                          />
                                       ) : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />
                  </FormControl>
               </Grid>
            )}
            {props.searchCondtion.type === ChartEnum.APP && (
               <Grid item xs={12}>
                  {/* TODO */}
                  <FormControl fullWidth margin="normal">
                     <Autocomplete
                        id="filter-chart-app"
                        multiple
                        disableCloseOnSelect
                        open={openFilterApp}
                        onOpen={() => {
                           setOpenFilterApp(true)
                        }}
                        onClose={() => {
                           setOpenFilterApp(false)
                        }}
                        onChange={(
                           event: any,
                           newValue: AppInterface[] | null
                        ) => {
                           const isAll =
                              event.target.getAttribute("id") ===
                              "filter-chart-app-option-0"
                           if (!isAll && newValue) {
                              if (
                                 (newValue.includes(defaultApp) &&
                                    newValue.length > 1) ||
                                 !newValue.includes(defaultApp)
                              ) {
                                 const filtered = newValue.filter(
                                    (x) => x.id !== defaultApp.id
                                 )
                                 props.handleChangeSearchCondition({
                                    app: filtered,
                                 })
                              } else {
                                 props.handleChangeSearchCondition({
                                    app: [defaultApp],
                                 })
                              }
                           } else {
                              props.handleChangeSearchCondition({
                                 app: [defaultApp],
                              })
                           }
                        }}
                        value={props.searchCondtion.app}
                        defaultValue={[optionsFilterApp[0]]}
                        isOptionEqualToValue={(option, value) =>
                           option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        options={optionsFilterApp}
                        loading={loadingApp}
                        noOptionsText={t("dialog.noOption")}
                        renderOption={(htmlProps, option) => {
                           return (
                              <li id={option.id} {...htmlProps} key={option.id}>
                                 {option.name}
                              </li>
                           )
                        }}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              fullWidth
                              label={t("field.app")}
                              InputProps={{
                                 ...params.InputProps,
                                 endAdornment: (
                                    <React.Fragment>
                                       {loadingProvince ? (
                                          <CircularProgress
                                             color="inherit"
                                             size={20}
                                          />
                                       ) : null}
                                       {params.InputProps.endAdornment}
                                    </React.Fragment>
                                 ),
                              }}
                           />
                        )}
                     />
                  </FormControl>
               </Grid>
            )}
         </Grid>
         <Grid sx={{ marginTop: 2 }} container gap={3}>
            {props.searchCondtion.type !== ChartEnum.NONE &&
               props.searchCondtion.fromDate &&
               props.searchCondtion.toDate && (
                  <Grid item xs={12} lg={2}>
                     <ToggleButtonGroup
                        fullWidth
                        value={props.filter.chartType}
                        exclusive
                        onChange={handleChangeChartType}
                        aria-label="chart type"
                        className={styles.buttonGroup}
                     >
                        {(props.searchCondtion.type === ChartEnum.APP ||
                           props.searchCondtion.type ===
                              ChartEnum.PROVINCE) && (
                           <ToggleButton
                              value={ChartTypeEnum.BAR}
                              aria-label="bar"
                           >
                              <BarChartIcon />
                           </ToggleButton>
                        )}
                        {(props.searchCondtion.type === ChartEnum.APP ||
                           props.searchCondtion.type ===
                              ChartEnum.PROVINCE) && (
                           <ToggleButton
                              value={ChartTypeEnum.PIE}
                              aria-label="pie"
                           >
                              <PieChartIcon />
                           </ToggleButton>
                        )}
                        {(props.searchCondtion.type === ChartEnum.DAY ||
                           props.searchCondtion.type === ChartEnum.MONTH ||
                           props.searchCondtion.type === ChartEnum.YEAR) && (
                           <ToggleButton
                              value={ChartTypeEnum.SIN}
                              aria-label="sin"
                           >
                              <SsidChartIcon />
                           </ToggleButton>
                        )}
                     </ToggleButtonGroup>
                  </Grid>
               )}
            {props.filter.chartType === ChartTypeEnum.BAR &&
               props.searchCondtion.fromDate &&
               props.searchCondtion.toDate && (
                  <Grid item xs={12} lg={2}>
                     <ToggleButtonGroup
                        fullWidth
                        value={props.filter.axisType}
                        exclusive
                        onChange={handleChangeBarChartGroupMode}
                        aria-label="bar chart group mode"
                        className={styles.buttonGroupMode}
                     >
                        <ToggleButton
                           value={AxisTypeEnum.VERTICAL}
                           aria-label="vertical"
                        >
                           <AlignVerticalBottomIcon />
                        </ToggleButton>
                        <ToggleButton
                           value={AxisTypeEnum.HORIZONTAL}
                           aria-label="horizontal"
                        >
                           <AlignHorizontalLeftIcon />
                        </ToggleButton>
                     </ToggleButtonGroup>
                  </Grid>
               )}
            {props.searchCondtion.type !== ChartEnum.NONE &&
               props.searchCondtion.fromDate &&
               props.searchCondtion.toDate && (
                  <Grid item xs={12} lg={2}>
                     <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        className={styles.buttonText}
                        onClick={props.handleStatistic}
                        disabled={
                           props.isDisableStatistic || fromInvalid || toInvalid
                        }
                        startIcon={<AddchartIcon />}
                     >
                        {t("buttons.statisticize")}
                     </Button>
                  </Grid>
               )}
            {(((props.searchCondtion.type === ChartEnum.DAY ||
               props.searchCondtion.type === ChartEnum.MONTH ||
               props.searchCondtion.type === ChartEnum.YEAR) &&
               props.isAllowExportDate) ||
               (props.searchCondtion.type === ChartEnum.APP &&
                  props.isAllowExportApp) ||
               (props.searchCondtion.type === ChartEnum.PROVINCE &&
                  props.isAllowExportProvince)) &&
               props.searchCondtion.fromDate &&
               props.searchCondtion.toDate && (
                  <Grid item xs={12} lg={2}>
                     <LoadingButton
                        fullWidth
                        color="secondary"
                        loading={props.requestingExport}
                        disabled={props.isDisableExport}
                        loadingPosition="start"
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        className={styles.buttonText}
                        onClick={props.handleChartExport}
                     >
                        {t("buttons.exportExcel")}
                     </LoadingButton>
                  </Grid>
               )}
            <Grid item xs={12}>
               <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  {t("chart.note") + convertDay(getMaxDateChart().toString())}
               </Typography>
            </Grid>
         </Grid>
      </React.Fragment>
   )
}
