import { AxiosBase } from "./config/axiosBase.instance"

export interface SignInAppPayloadAPIInterface {
   username: string
   password: string
}

export const signInAppAPI = (payload: SignInAppPayloadAPIInterface) => {
   return AxiosBase.post("authenticate/application", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface SignInPayloadAPIInterface {
   phoneNumber: string
   password: string
}

export const signInAPI = (
   token: string,
   payload: SignInPayloadAPIInterface
) => {
   return AxiosBase.post("authenticate/user", payload, {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   })
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface RefreshTokenPayloadAPIInterface {
   token: string
}

export const refreshTokenAPI = (payload: RefreshTokenPayloadAPIInterface) => {
   return AxiosBase.get(
      process.env.REACT_APP_API_ENDPOINT + "authenticate/refresh-token",
      {
         headers: {
            Authorization: `Bearer ${payload.token}`,
         },
      }
   )
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
