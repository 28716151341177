import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RoleEnum } from "../../common/enum/role.enum"

export interface AuthState {
   requesting: boolean
   successful: boolean
   error: string
   userid: string
   role: RoleEnum | null
   tokenApp: string
}

const initialState: AuthState = {
   requesting: false,
   successful: false,
   error: "",
   userid: "",
   role: null,
   tokenApp: "",
}

export const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      authenAppFail: (state: AuthState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload ? action.payload : ""
      },
      authenAppSuccess: (state: AuthState, action: PayloadAction<string>) => {
         state.tokenApp = action.payload
      },
      signInRequest: (state: AuthState) => {
         state.requesting = true
      },
      signInSuccess: (state: AuthState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.userid = action.payload.userid
         state.role = action.payload.role
      },
      signInFail: (state: AuthState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload ? action.payload : ""
      },
      resetSignIn: (state: AuthState) => {
         state.error = ""
      },
      logout: (state: AuthState) => {
         state.requesting = true
         state.successful = false
         state.error = ""
         state.userid = ""
         state.role = null
      },
   },
})

export const {
   authenAppFail,
   authenAppSuccess,
   signInRequest,
   signInSuccess,
   signInFail,
   resetSignIn,
   logout,
} = authSlice.actions

export default authSlice.reducer
