import { AxiosBase } from "./config/axiosBase.instance"

export interface PhoneNumberStepPayloadAPIInterface {
   phoneNumber: string
}

export const phoneNumberStepAPI = (
   payload: PhoneNumberStepPayloadAPIInterface
) => {
   return AxiosBase.post("user/forgot-password", payload).then((response) => ({
      response,
   }))
}

export interface VerifyStepPayloadAPIInterface {
   phoneNumber: string
   newPassword: string
   otp: string
}

export const verifyStepAPI = (payload: VerifyStepPayloadAPIInterface) => {
   return AxiosBase.post("user/reset-password", payload).then((response) => ({
      response,
   }))
}

export interface ResetPasswordStepPayloadAPIInterface {
   phoneNumber: string
   password: string
}

export const resetPasswordStepAPI = (
   payload: ResetPasswordStepPayloadAPIInterface
) => {
   return AxiosBase.post("null", payload).then((response) => ({ response }))
}

export interface ResendOTPStepPayloadAPIInterface {
   phoneNumber: string
}

export const resendOTPStepAPI = (payload: ResendOTPStepPayloadAPIInterface) => {
   return AxiosBase.post("user/resend-otp", payload).then((response) => ({
      response,
   }))
}
