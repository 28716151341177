import { useSelector } from "react-redux"
import {
   allowAddPromotionPackage,
   allowDeletePromotionPackage,
   allowDetailPromotionPackage,
   allowUpdatePromotionPackage,
} from "../../states/permission/permission.selector"

export const usePromotionPackPermission = () => {
   return {
      allowDetailPromotionPackage: useSelector(allowDetailPromotionPackage),
      allowDeletePromotionPackage: useSelector(allowDeletePromotionPackage),
      allowAddPromotionPackage: useSelector(allowAddPromotionPackage),
      allowUpdatePromotionPackage: useSelector(allowUpdatePromotionPackage),
   }
}
