import { Chip } from "@mui/material"
import { MarketingStatus } from "../../enum/marketingStatus.enum"
import { getLabelMarketingStatus } from "../../helper/marketingStatus.helper"

type MarketingStatusProp = {
   status: MarketingStatus
}

export const MarketingStatusComponent = (props: MarketingStatusProp) => {
   const getColor = () => {
      if (props.status === MarketingStatus.ACTIVE) {
         return "success"
      }
      if (props.status === MarketingStatus.PAUSE) {
         return "secondary"
      }
      return undefined
   }

   return (
      <Chip label={getLabelMarketingStatus(props.status)} color={getColor()} />
   )
}
