import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import AddIcon from "@mui/icons-material/Add"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import CircularProgress from "@mui/material/CircularProgress"
import commonStyles from "../../common/styles/common.module.css"
import Typography from "@mui/material/Typography"
import TablePagination from "@mui/material/TablePagination"
import { columns, createData } from "./managementWallet.table"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Box from "@mui/material/Box"
import styles from "./managementWallet.module.css"
import { ManagementWalletInterface } from "../../common/interfaces/managementWallet.interface"
import { date, dollar } from "../../images/images"
import { ManagementWalletFilterTableComponent } from "./managementWalletFilterTable/managementWalletFilterTable.component"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"
import { Dayjs } from "dayjs"
import { SearchConditionWalletState } from "../../common/interfaces/searchConditionParam"
import { useManagementWalletPermission } from "./managementWallet.permission"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"

type ManagementWalletComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   walletData: ManagementWalletInterface
   error: string
   searchCondition: SearchConditionWalletState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (
      option: FilterTableWalletEnum,
      from: Dayjs,
      to: Dayjs
   ) => void
   handleManagementWalletRecharge: () => void
}

export const ManagementWalletComponent = (
   props: ManagementWalletComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.managementWallet)
   const { allowRechargeManagementWallet } = useManagementWalletPermission()

   // Transaction list
   const amount = props.walletData.amount
   const expiredDate = props.walletData.expireDate
   const rows = props.walletData.transactions.map((transaction) =>
      createData(
         transaction.id,
         transaction.transactionDisplayNumber,
         transaction.transactionType,
         transaction.amount,
         transaction.description,
         transaction.addDay,
         transaction.createdAt
      )
   )

   return (
      <Container maxWidth="xl">
         <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
            <Grid item xs={12} md={12} xl={6}>
               <Card sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                     <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h6">
                           {t("card.wallet")}: {amount}
                        </Typography>
                     </CardContent>
                  </Box>
                  <Box className={styles.cardMedia}>
                     <img src={dollar} alt="Date Wallpaper"></img>
                  </Box>
               </Card>
            </Grid>
            <Grid item xs={12} md={12} xl={6}>
               <Card sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                     <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h6">
                           {t("card.walletDate")}: {expiredDate}
                        </Typography>
                     </CardContent>
                  </Box>
                  <Box className={styles.cardMedia}>
                     <img src={date} alt="Date Wallpaper"></img>
                  </Box>
               </Card>
            </Grid>
         </Grid>
         <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={11}>
                     <ManagementWalletFilterTableComponent
                        searchCondition={props.searchCondition}
                        onFilter={(
                           option: FilterTableWalletEnum,
                           from: Dayjs,
                           to: Dayjs
                        ) => {
                           props.handleChangeSearchCondition(option, from, to)
                        }}
                     />
                  </Grid>
                  {!allowRechargeManagementWallet && (
                     <Grid item xs={12} md={12} xl={1}>
                        <Grid container spacing={2} justifyContent="flex-end">
                           <Grid item xs={12}>
                              <Button
                                 fullWidth
                                 sx={{
                                    minHeight: "36px",
                                 }}
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() =>
                                    props.handleManagementWalletRecharge()
                                 }
                              >
                                 {t("button.recharge")}
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  )}
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {t(column.label)}
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 let value = row[column.id]
                                 if (column.id.toString() === "amount") {
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                          sx={{
                                             color:
                                                row.transactionType === 0
                                                   ? "green"
                                                   : "red",
                                          }}
                                       >
                                          {value}
                                       </TableCell>
                                    )
                                 } else {
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                       >
                                          {value}
                                       </TableCell>
                                    )
                                 }
                              })}
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
      </Container>
   )
}
