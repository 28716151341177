import styles from "./chart.module.css"
import { ResponsiveBar } from "@nivo/bar"
import { ResponsivePie } from "@nivo/pie"
import { FilterChartGUIInterface } from "../../interfaces/filterChartGUI.interface"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import React, { Fragment, useEffect, useState } from "react"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"
import commonStyles from "../../../common/styles/common.module.css"
import {
   mappingChartBar,
   mappingChartPie,
   mappingChartSin,
} from "../../helper/chart.helper"
import { FilterChartSearchConditionInterface } from "../../interfaces/filterChartSearchCondition.interface"
import { AxisTypeEnum } from "../../enum/axisType.enum"
import { ChartTypeEnum } from "../../enum/chartType.enum"
import { ResponsiveLine } from "@nivo/line"

type ChartComponentProps = {
   refChart?: any
   requesting: boolean
   filter: FilterChartGUIInterface
   searchCondition?: FilterChartSearchConditionInterface
   data: any[]
   error: string
}

export const ChartComponent = (props: ChartComponentProps) => {
   const { t } = useTranslation(namespaces.common)
   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   })

   /**
    * Get label bar chart
    * @param d
    * @returns
    */
   const getLabelBarChart = (d: any) => {
      return `${d.value}`
   }

   const getHeight = () => {
      if (
         props.filter.chartType === ChartTypeEnum.BAR &&
         props.filter.axisType === AxisTypeEnum.HORIZONTAL &&
         props.data.length > 10
      ) {
         return props.data.length * 30 + "px"
      } else {
         return "700px"
      }
   }

   const getWidth = () => {
      if (
         props.filter.chartType === ChartTypeEnum.BAR &&
         props.filter.axisType === AxisTypeEnum.VERTICAL &&
         props.data.length * 80 > windowSize.width
      ) {
         return props.data.length * 80 + "px"
      } else if (
         props.filter.chartType === ChartTypeEnum.SIN &&
         props.data.length * 80 > windowSize.width
      ) {
         return props.data.length * 80 + "px"
      } else {
         return "100%"
      }
   }

   useEffect(() => {
      function handleWindowResize() {
         setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      }

      window.addEventListener("resize", handleWindowResize)

      return () => {
         window.removeEventListener("resize", handleWindowResize)
      }
   }, [])

   return (
      <Fragment>
         <Divider className={commonStyles.dividerMargin}>
            <Chip color="secondary" label={t("divider.chart")} />
         </Divider>
         {/* Sum */}
         {!props.requesting && !props.error && props.data.length > 0 && (
            <div>
               <h3>
                  Tổng:{" "}
                  {props.data.reduce((total, item) => {
                     return total + item.y
                  }, 0)}
               </h3>
            </div>
         )}
         <div className={styles.container}>
            <div
               ref={props.refChart}
               className={styles.chartHeight}
               style={{ height: getHeight(), width: getWidth() }}
            >
               {/* Loading */}
               {props.requesting && <CircularProgress color="secondary" />}
               {/* Error */}
               {!props.requesting && props.error && (
                  <Typography variant="body1" color="error">
                     {t(props.error, { ns: "common" })}
                  </Typography>
               )}
               {/* Empty */}
               {!props.requesting &&
                  !props.error &&
                  props.data.length === 0 && (
                     <Typography variant="body1">
                        {t("error.empty", { ns: "common" })}
                     </Typography>
                  )}
               {/* Bar */}
               {!props.requesting &&
                  !props.error &&
                  props.data.length > 0 &&
                  props.filter.chartType === ChartTypeEnum.BAR && (
                     <ResponsiveBar
                        layout={
                           props.filter.axisType === AxisTypeEnum.VERTICAL
                              ? "vertical"
                              : "horizontal"
                        }
                        data={mappingChartBar(
                           props.filter.axisType,
                           props.data
                        )}
                        enableGridY={true}
                        enableGridX={true}
                        colorBy="indexValue"
                        groupMode="grouped"
                        keys={["value"]}
                        indexBy="id"
                        margin={{
                           top: 50,
                           right: 20,
                           bottom: 100,
                           left:
                              props.filter.axisType === AxisTypeEnum.VERTICAL
                                 ? 40
                                 : 120,
                        }}
                        padding={0.1}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={{ scheme: "paired" }}
                        defs={[
                           {
                              id: "dots",
                              type: "patternDots",
                              background: "inherit",
                              color: "#38bcb2",
                              size: 4,
                              padding: 1,
                              stagger: true,
                           },
                           {
                              id: "lines",
                              type: "patternLines",
                              background: "inherit",
                              color: "#eed312",
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                           },
                        ]}
                        fill={[
                           {
                              match: {
                                 id: "fries",
                              },
                              id: "dots",
                           },
                           {
                              match: {
                                 id: "sandwich",
                              },
                              id: "lines",
                           },
                        ]}
                        borderColor={{
                           from: "color",
                           modifiers: [["darker", 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                           tickSize: 5,
                           tickPadding: 15,
                           tickRotation: 0,
                           legendPosition: "middle",
                           legendOffset: 40,
                        }}
                        axisLeft={{
                           tickSize: 5,
                           tickPadding: 15,
                           tickRotation: 0,
                           legendPosition: "middle",
                           legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                           from: "color",
                           modifiers: [["darker", 1.6]],
                        }}
                        label={(d) => getLabelBarChart(d)}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        tooltip={({ value, data }) => (
                           <div
                              style={{
                                 padding: 12,
                                 background: "white",
                              }}
                           >
                              <span>
                                 {data.id}: <strong>{value}</strong>
                              </span>
                           </div>
                        )}
                     />
                  )}
               {/* Pie */}
               {!props.requesting &&
                  !props.error &&
                  props.data.length > 0 &&
                  props.filter.chartType === ChartTypeEnum.PIE && (
                     <ResponsivePie
                        data={mappingChartPie(props.data)}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        colors={{ scheme: "paired" }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                           from: "color",
                           modifiers: [["darker", 0.2]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                           from: "color",
                           modifiers: [["darker", 2]],
                        }}
                        defs={[
                           {
                              id: "dots",
                              type: "patternDots",
                              background: "inherit",
                              color: "rgba(255, 255, 255, 0.3)",
                              size: 4,
                              padding: 1,
                              stagger: true,
                           },
                           {
                              id: "lines",
                              type: "patternLines",
                              background: "inherit",
                              color: "rgba(255, 255, 255, 0.3)",
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                           },
                        ]}
                        fill={[
                           {
                              match: {
                                 id: "ruby",
                              },
                              id: "dots",
                           },
                           {
                              match: {
                                 id: "c",
                              },
                              id: "dots",
                           },
                           {
                              match: {
                                 id: "go",
                              },
                              id: "dots",
                           },
                           {
                              match: {
                                 id: "python",
                              },
                              id: "dots",
                           },
                           {
                              match: {
                                 id: "scala",
                              },
                              id: "lines",
                           },
                           {
                              match: {
                                 id: "lisp",
                              },
                              id: "lines",
                           },
                           {
                              match: {
                                 id: "elixir",
                              },
                              id: "lines",
                           },
                           {
                              match: {
                                 id: "javascript",
                              },
                              id: "lines",
                           },
                        ]}
                        legends={[
                           {
                              anchor: "bottom",
                              direction: "row",
                              justify: false,
                              translateX: 0,
                              translateY: 100,
                              itemsSpacing: 20,
                              itemWidth: 100,
                              itemHeight: 18,
                              itemTextColor: "#999",
                              itemDirection: "left-to-right",
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: "circle",
                              effects: [
                                 {
                                    on: "hover",
                                    style: {
                                       itemTextColor: "#000",
                                    },
                                 },
                              ],
                           },
                        ]}
                     />
                  )}
               {/* Sin */}
               {!props.requesting &&
                  !props.error &&
                  props.data.length > 0 &&
                  props.filter.chartType === ChartTypeEnum.SIN && (
                     <ResponsiveLine
                        data={mappingChartSin("User", props.data)}
                        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                        xScale={{ type: "point" }}
                        yScale={{
                           type: "linear",
                           min: "auto",
                           max: "auto",
                           stacked: true,
                           reverse: false,
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        pointSize={10}
                        pointColor={{ theme: "background" }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        tooltip={(point) => (
                           <div
                              style={{
                                 padding: 12,
                                 background: "white",
                              }}
                           >
                              <span>
                                 {point.point.data.xFormatted}:{" "}
                                 <strong>
                                    {parseInt(
                                       point.point.data.yFormatted as string,
                                       10
                                    )}
                                 </strong>
                              </span>
                           </div>
                        )}
                     />
                  )}
            </div>
         </div>
      </Fragment>
   )
}
