import styles from "./formError.module.css"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"

type FormErrorComponentProps = {
   error: string | null
}

export const FormErrorComponent = (props: FormErrorComponentProps): any => {
   const { t } = useTranslation(namespaces.common)

   return props.error ? (
      <div className={styles.container}>
         <Typography variant="body1" gutterBottom>
            {t(props.error)}
         </Typography>
      </div>
   ) : (
      <div></div>
   )
}
