import { object, string, TypeOf } from "zod"
import { PASSWORD_REGEX, SPACE_REGEX } from "../../../common/variables/regex"

export const settingPasswordSchema = object({
   password: string()
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự.")
      .default(""),
   newPassword: string()
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự."),
   newPasswordConfirmation: string()
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự."),
}).refine((data) => data.newPassword === data.newPasswordConfirmation, {
   message: "Mật khẩu không trùng khớp",
   path: ["newPasswordConfirmation"],
})

export type SettingPasswordInput = TypeOf<typeof settingPasswordSchema>
