export enum DialogMethodEnum {
   ADD = "ADD",
   UPDATE = "UPDATE",
   DELETE = "DELETE",
   DETAIL = "DETAIL",
   HISTORY = "HISTORY",
   CHARGE = "CHARGE",
   START = "START",
   PAUSE = "PAUSE",
   RESTART = "RESTART",
   NONE = "NONE",
}
