import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"
import { AxiosBase } from "./config/axiosBase.instance"

export interface GetTypeUserPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getTypeUserAPI = (payload: GetTypeUserPayloadAPIInterface) => {
   return AxiosAuth.post("user-type-condition", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export const getTypeUserSelectAPI = (tokenApp: string) => {
   return AxiosBase.get("user-type-condition", {
      headers: {
         Authorization: `Bearer ${tokenApp}`,
      },
   })
}

export interface AddTypeUserPayloadAPIInterface {
   name: string
   provinceId?: string[] | null
   topup_min?: number
   topup_max?: number
   use_amount_min?: number
   use_amount_max?: number
   login_min?: number
   login_max?: number
}

export const addTypeUserAPI = (payload: AddTypeUserPayloadAPIInterface) => {
   return AxiosAuth.post("user-type-condition/create", payload).then(
      (response) => ({
         response,
      })
   )
}

export interface UpdateTypeUserPayloadAPIInterface {
   id: string
   name: string
   provinceId?: string[] | null
   topup_min?: number
   topup_max?: number
   use_amount_min?: number
   use_amount_max?: number
   login_min?: number
   login_max?: number
}

export const updateTypeUserAPI = (
   payload: UpdateTypeUserPayloadAPIInterface
) => {
   return AxiosAuth.put("user-type-condition", payload).then((response) => ({
      response,
   }))
}

export interface DeleteTypeUserPayloadAPIInterface {
   id: string
}

export const deleteTypeUserAPI = (
   payload: DeleteTypeUserPayloadAPIInterface
) => {
   return AxiosAuth.delete("user-type-condition/" + payload.id).then(
      (response) => ({ response })
   )
}

export const updateUsersTypeUserAPI = () => {
   return AxiosAuth.post("user-type").then((response) => ({ response }))
}

export const getSyncTypeUserAPI = (tokenApp: string) => {
   return AxiosBase.get("configuration/code?configName=SyncUpUserType", {
      headers: {
         Authorization: `Bearer ${tokenApp}`,
      },
   })
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export const triggerSyncTypeUserAPI = () => {
   return AxiosBase.post("user-type")
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface GetTypeUserDetailPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getTypeUserDetailAPI = (
   payload: GetTypeUserDetailPayloadAPIInterface
) => {
   return AxiosAuth.post("user-type/get-list", payload)
}
