import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import styles from "./sidebar.module.css"
import FormControl from "@mui/material/FormControl"
import { SearchTextField } from "./../Search/search"
import List from "@mui/material/List"
import { routes } from "../../variables/route"
import { SidebarItemComponent } from "./SidebarItem/sidebarItem.component"
import { useState } from "react"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Avatar from "@mui/material/Avatar"
import { centerIDLogo } from "../../../images/images"
import { FunctionEnum } from "../../enum/function.enum"
import Button from "@mui/material/Button"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"

type SidebarComponentProps = {
   open: boolean
   setOpen: (value: boolean) => void
   allowRoutes: FunctionEnum[]
}

export const SidebarComponent = (props: SidebarComponentProps) => {
   const { t } = useTranslation(namespaces.common)
   // States
   const [searchText, setSearchText] = useState("")

   /**
    * On change search input
    * @param event
    */
   const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setSearchText(event.target.value.toLowerCase())
   }

   return (
      <div className={styles.container}>
         <Stack
            py={"30px"}
            direction="row"
            justifyContent="center"
            alignItems="center"
         >
            <IconButton sx={{ p: 0 }}>
               <Avatar className={styles.logo} alt="logo" src={centerIDLogo} />
            </IconButton>
            {props.open && <p className={styles.textLogo}>{t("appName")}</p>}
         </Stack>
         <Button
            sx={{
               minWidth: "unset",
            }}
            variant="outlined"
            fullWidth
            onClick={() => {
               props.setOpen(!props.open)
            }}
         >
            {!props.open ? (
               <KeyboardDoubleArrowRightIcon />
            ) : (
               <KeyboardDoubleArrowLeftIcon />
            )}
         </Button>
         {props.open && (
            <div>
               <FormControl fullWidth margin="normal">
                  <SearchTextField
                     id="search-sidebar"
                     placeholder={t("field.search")}
                     onChange={(event) => handleChange(event)}
                  ></SearchTextField>
               </FormControl>
            </div>
         )}
         <List
            sx={{ width: "100%" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
         >
            {routes
               .filter(
                  (route) =>
                     props.allowRoutes.some((e) =>
                        route.permissionKeys.includes(e)
                     ) || route.permissionKeys.includes(FunctionEnum.NONE)
               )
               .filter(
                  (route) =>
                     route.showOnSidebar &&
                     t(route.text).toLowerCase().includes(searchText)
               )
               .map((sidebar, key) => (
                  <SidebarItemComponent
                     key={key}
                     open={props.open}
                     config={sidebar}
                  ></SidebarItemComponent>
               ))}
         </List>
      </div>
   )
}
