import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppsInterface } from "../../common/interfaces/apps.interface"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { handleSortOrder } from "../../common/helper/sortTable.helper"

export interface AppsState {
   requesting: boolean
   successful: boolean
   error: string
   data: AppsInterface[]
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionState
   sortCondition: SortConditionInterface
}

const initialState: AppsState = {
   requesting: false,
   successful: false,
   error: "",
   data: [],
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableEnum.NONE,
      value: "",
   },
   sortCondition: {
      column: SortColumnEnum.NONE,
      order: SortOrderEnum.ASC,
   },
}

export const appsSlice = createSlice({
   name: "apps",
   initialState,
   reducers: {
      requestApps: (state: AppsState) => {
         state.requesting = true
      },
      getAppsSuccess: (state: AppsState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data.data
         state.rowCount = action.payload.data.rowCount
      },
      getAppsFail: (state: AppsState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: AppsState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: AppsState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: AppsState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.value = action.payload.value
      },
      changeSortConditionSuccess: (
         state: AppsState,
         action: PayloadAction<any>
      ) => {
         state.sortCondition.order = handleSortOrder(
            state.sortCondition.column,
            action.payload.column,
            state.sortCondition.order
         )
         state.sortCondition.column = action.payload.column
      },
   },
})

export const {
   requestApps,
   getAppsSuccess,
   getAppsFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
} = appsSlice.actions

export default appsSlice.reducer
