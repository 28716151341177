import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"

import TabPanel from "@mui/lab/TabPanel"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import { ChartSignInContainer } from "../chartSignIn/chartSignIn.container"
import Tabs from "@mui/material/Tabs"
import { useChartPermission } from "./chart.permission"
import { ChartSignUpContainer } from "../chartSignUp/chartSignUp.container"
import { ChartUsingWalletContainer } from "../chartUsingWallet/chartUsingWallet.container"
import { ChartChargeWalletContainer } from "../chartChargeWallet/chartChargeWallet.container"
import { ChartUserTypeContainer } from "../chartUserType/chartUserType.container"

export const ChartComponent = () => {
   const { t } = useTranslation(namespaces.pages.chart)
   const {
      allowShowChartSignIn,
      allowShowChartSignUp,
      allowShowChartUsingWallet,
      allowShowChartChargeWallet,
      allowShowChartUserType,
   } = useChartPermission()

   const [tab, setTab] = React.useState("1")

   /**
    * Handle change tab
    * @param event
    * @param newValue
    */
   const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
      setTab(newValue)
   }

   useEffect(() => {
      if (allowShowChartSignIn) {
         setTab("1")
         return
      }
      if (allowShowChartSignUp) {
         setTab("2")
         return
      }
      if (allowShowChartUsingWallet) {
         setTab("3")
         return
      }
      if (allowShowChartChargeWallet) {
         setTab("4")
         return
      }
      if (allowShowChartUserType) {
         setTab("5")
         return
      }
   }, [
      allowShowChartSignIn,
      allowShowChartSignUp,
      allowShowChartUsingWallet,
      allowShowChartChargeWallet,
      allowShowChartUserType,
   ])

   return (
      <Container maxWidth="xl">
         <Box
            sx={{
               width: "100%",
            }}
         >
            <Tabs
               allowScrollButtonsMobile
               variant="scrollable"
               value={tab}
               scrollButtons
               onChange={handleChangeTab}
               aria-label="lab API tabs example"
            >
               {allowShowChartSignIn && (
                  <Tab label={t("tab.chartSignIn")} value="1" />
               )}
               {allowShowChartSignUp && (
                  <Tab label={t("tab.chartSignUp")} value="2" />
               )}
               {allowShowChartUsingWallet && (
                  <Tab label={t("tab.chartUsingWallet")} value="3" />
               )}
               {allowShowChartChargeWallet && (
                  <Tab label={t("tab.chartChargeWallet")} value="4" />
               )}
               {allowShowChartUserType && (
                  <Tab label={t("tab.chartUserType")} value="5" />
               )}
            </Tabs>
            <TabContext value={tab}>
               {allowShowChartSignIn && (
                  <TabPanel value="1">
                     <ChartSignInContainer />
                  </TabPanel>
               )}
               {allowShowChartSignUp && (
                  <TabPanel value="2">
                     <ChartSignUpContainer />
                  </TabPanel>
               )}
               {allowShowChartUsingWallet && (
                  <TabPanel value="3">
                     <ChartUsingWalletContainer />
                  </TabPanel>
               )}
               {allowShowChartChargeWallet && (
                  <TabPanel value="4">
                     <ChartChargeWalletContainer />
                  </TabPanel>
               )}
               {allowShowChartUserType && (
                  <TabPanel value="5">
                     <ChartUserTypeContainer />
                  </TabPanel>
               )}
            </TabContext>
         </Box>
      </Container>
   )
}
