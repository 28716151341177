import { selectAuthIsAdminRole } from "../../states/auth/auth.selector"
import {
   selectIsCompanyAccountTypeUserInfo,
   selectIsEkycUserInfo,
} from "../../states/user/user.selector"
import { SettingComponent } from "./setting.component"
import { useSelector } from "react-redux"

export const SettingContainer = () => {
   const isAccountCompany = useSelector(selectIsCompanyAccountTypeUserInfo)
   const isEkyc = useSelector(selectIsEkycUserInfo)
   const isAdmin = useSelector(selectAuthIsAdminRole)

   return (
      <SettingComponent
         isAccountCompany={isAccountCompany}
         isEkyc={isEkyc}
         isAdmin={isAdmin}
      />
   )
}
