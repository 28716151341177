import {
   selectPageNumberManagementWallet,
   selectPageSizeManagementWallet,
   selectSearchConditionManagementWallet,
} from "./managementWallet.selector"
import {
   getManagementWalletAPI,
   GetManagementWalletPayloadAPIInterface,
} from "./../../api/managementWallet.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementWalletAsync,
} from "./managementWallet.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   getManagementWalletFail,
   getManagementWalletSuccess,
   requestManagementWallet,
} from "./managementWallet.slice"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"

/**
 * Get management wallet saga
 * @param action
 */
function* getManagementWalletSaga(): any {
   try {
      yield put(requestManagementWallet())
      const pageNumber = yield select(selectPageNumberManagementWallet)
      const pageSize = yield select(selectPageSizeManagementWallet)
      const searchCondition = yield select(
         selectSearchConditionManagementWallet
      )
      const params: GetManagementWalletPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [
            ["FROMDATE", searchCondition.from],
            ["TODATE", searchCondition.to],
         ],
      }

      if (searchCondition.option !== FilterTableWalletEnum.ALL) {
         params.SearchConditions.unshift([
            "TRANSACTIONTYPE",
            searchCondition.option,
         ])
      }

      const { response, error } = yield call(getManagementWalletAPI, params)
      if (!response) {
         throw error
      }
      yield put(getManagementWalletSuccess(response.data))
   } catch (error: any) {
      yield put(getManagementWalletFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getManagementWalletAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getManagementWalletAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getManagementWalletAsync())
   } catch (error) {}
}

/**
 * Root management wallet saga
 */
export function* managementWalletSaga() {
   yield takeLatest(getManagementWalletAsync, getManagementWalletSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
}
