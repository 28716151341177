export enum QueriesEnum {
   ID = "ID",
   PHONENUMBER = "PHONENUMBER",
   FULLNAME = "FULLNAME",
   EMAIL = "EMAIL",
   PROVINCE_ID = "PROVINCEID",
   PROVINCE_NAME = "PROVINCE.NAME as ProvinceName",
   DISTRICT_ID = "DISTRICTID",
   DISTRICT_NAME = "DISTRICT.NAME as DistrictName",
   WARD_ID = "WARDID",
   WARD_NAME = "WARD.NAME as WardName",
   ADDRESS = "ADDRESS",
   ROLEID = "ROLEID",
   CREATED_AT = "CREATEDAT",
   CREATED_BY_ID = "CREATEDBYID",
   UPDATED_AT = "UPDATEDAT",
   UPDATED_BY_ID = "UPDATEDBYID",
   IS_ACTIVE = "ISACTIVE",
   NOTE = "NOTE",
   ACCOUNT_TYPE_ID = "ACCOUNTTYPEID",
   COMPANY_ID = "COMPANYID",
   STREET = "STREET",
   GENDER = "GENDER",
   BIRTHDAY = "BIRTHDAY",
   IDENTITY_NUMBER = "IDENTITYNUMBER",
   ISSUEDDATE = "ISSUEDDATE",
   ISSUEDBY = "ISSUEDBY",
   IMAGE = "IMAGE",
   IS_EKYC = "ISEKYC",
   EKYC_NAME = "UserEkyc.NAME",
   EKYC_IDENTITY_CARD = "UserEkyc.IDENTITYCARD",
   EKYC_BIRTHDAY = "UserEkyc.BIRTHDAY",
   EKYC_ADDRESS = "UserEkyc.ADDRESS",
   EKYC_ADDRESS_HOME = "UserEkyc.ADDRESSHOME",
   EKYC_ETHNIC = "UserEkyc.ETHNIC",
   EKYC_RELIGION = "UserEkyc.RELIGION",
   EKYC_ISSUED_DATE = "UserEkyc.ISSUEDDATE",
   EKYC_ISSUED_BY = "UserEkyc.ISSUEDBY",
   EKYC_SIMILAR = "UserEkyc.SIMILAR",
   COMPANY_NAME = "COMPANY.NAME",
   COMPANY_ADDRESS = "COMPANY.ADDRESS",
   COMPANY_PHONENUMBER = "COMPANY.PHONE",
   COMPANY_TAX = "COMPANY.TAX",
   COMPANY_EMAIL = "COMPANY.EMAIL",
   WALLET = "WALLET.AMOUNT",
   WALLET_DATE = "WALLET.EXPIREDDATE",
   ALL = "ALL",
}
