import { object, string, TypeOf, z } from "zod"
import {
   PHONENUMBER_REGEX,
   PHONENUMBER_VN_REGEX,
} from "../../../common/variables/regex"

export const settingCompanySchema = object({
   name: string(),
   taxCode: string(),
   address: string(),
   phoneNumber: string()
      .regex(
         new RegExp(PHONENUMBER_REGEX),
         "Số điện thoại không đúng định dạng."
      )
      .regex(
         new RegExp(PHONENUMBER_VN_REGEX),
         "Số điện thoại không đúng định dạng."
      )
      .or(z.literal("")),
   email: string()
      .email("Email không đúng định dạng")
      .optional()
      .or(z.literal("")),
})

export type SettingCompanyInput = TypeOf<typeof settingCompanySchema>
