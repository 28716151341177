import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
   phoneNumberStepAPI,
   PhoneNumberStepPayloadAPIInterface,
   resendOTPStepAPI,
   ResendOTPStepPayloadAPIInterface,
   resetPasswordStepAPI,
   ResetPasswordStepPayloadAPIInterface,
   verifyStepAPI,
   VerifyStepPayloadAPIInterface,
} from "../../api/forgotPassword.api"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { ForgotPasswordComponent } from "./forgotPassword.component"
import { resetSignInAsync } from "../../states/auth/auth.action"

export const ForgotPasswordContainer = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   /**
    * On navigate sign in
    */
   const onSignIn = () => {
      navigate("/sign-in")
      dispatch(resetSignInAsync())
   }

   /**
    * On navigate register
    */
   const onSignUp = () => {
      navigate("/register")
   }

   /**
    * Handle phone number step async
    * @param phoneNumber
    * @returns
    */
   const handlePhoneNumberStepAsync = (phoneNumber: string) => {
      const params: PhoneNumberStepPayloadAPIInterface = {
         phoneNumber: phoneNumber,
      }
      return phoneNumberStepAPI(params)
   }

   /**
    * Handle reset password step async
    * @param phoneNumber
    * @returns
    */
   const handleVerifyStepAsync = (
      phoneNumber: string,
      newPassword: string,
      otp: string
   ) => {
      const params: VerifyStepPayloadAPIInterface = {
         phoneNumber: phoneNumber,
         newPassword: newPassword,
         otp: otp,
      }
      return verifyStepAPI(params)
   }

   /**
    * Handle reset password step async
    * @param phoneNumber
    * @returns
    */
   const handleResetPasswordStepAsync = (
      phoneNumber: string,
      password: string
   ) => {
      const params: ResetPasswordStepPayloadAPIInterface = {
         phoneNumber: phoneNumber,
         password: password,
      }
      return resetPasswordStepAPI(params)
   }

   /**
    * Handle reset password step async
    * @param phoneNumber
    * @returns
    */
   const handleResendOTPStepAsync = (phoneNumber: string) => {
      const params: ResendOTPStepPayloadAPIInterface = {
         phoneNumber: phoneNumber,
      }
      return resendOTPStepAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   return (
      <ForgotPasswordComponent
         onSignIn={onSignIn}
         onSignUp={onSignUp}
         handlePushNotistack={handlePushNotistack}
         handlePhoneNumberStepAsync={handlePhoneNumberStepAsync}
         handleVerifyStepAsync={handleVerifyStepAsync}
         handleResetPasswordStepAsync={handleResetPasswordStepAsync}
         handleResendOTPStepAsync={handleResendOTPStepAsync}
      />
   )
}
