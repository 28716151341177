import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { ManagementUserHistoryState } from "./managementUserHistory.slice"

const selectManagementUserHistoryState = (
   state: RootState
): ManagementUserHistoryState => state.managementUserHistory

export const selectRequestingManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.requesting
)

export const selectSelectedManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.selectedId
)

export const selectDataManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.data
)

export const selectErrorManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.error
)

export const selectCountManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.rowCount
)

export const selectPageNumberManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.paging.pageNumber
)

export const selectPageSizeManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.paging.pageSize
)

export const selectSearchConditionManagementUserHistory = createSelector(
   selectManagementUserHistoryState,
   (state: ManagementUserHistoryState) => state.searchCondition
)
