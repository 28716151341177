import { Routes, Navigate, useLocation } from "react-router-dom"
import { Route } from "react-router-dom"
import { useSelector } from "react-redux"
import { createElement, useEffect } from "react"
// Screen
import { SignInContainer } from "../pages/signIn/signIn.container"
import { RegisterContainer } from "../pages/register/register.container"
import { availableRoutes } from "../common/variables/route"
import { ForgotPasswordContainer } from "../pages/forgotPassword/forgotPassword.container"
import { DashboardContainer } from "../pages/dashboard/dashboard.container"
import { selectAllowRoutes } from "../states/permission/permission.selector"
import { FunctionEnum } from "../common/enum/function.enum"
import { isAuthorization } from "../common/helper/auth.helper"
import { OutOfSessionComponent } from "../pages/outOfSession/outOfSession.component"
import { PaymentResultContainer } from "../pages/paymentResult/paymentResult.container"

export const MainRoute = () => {
   const isAuthenticated = isAuthorization()
   const allowRoutes: FunctionEnum[] = useSelector(selectAllowRoutes)
   const { pathname } = useLocation()

   const getDefaultDashboardRouteByRole = () => {
      return <Navigate to="/dashboard/setting" />
   }

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [pathname])

   return (
      <Routes>
         {/* Redirect default */}
         <Route
            path="/"
            element={
               isAuthenticated ? (
                  getDefaultDashboardRouteByRole()
               ) : (
                  <Navigate to="/sign-in" />
               )
            }
         />
         <Route
            path="/sign-in"
            element={
               !isAuthenticated ? (
                  <SignInContainer />
               ) : (
                  getDefaultDashboardRouteByRole()
               )
            }
         />
         <Route
            path="/register"
            element={
               !isAuthenticated ? (
                  <RegisterContainer />
               ) : (
                  getDefaultDashboardRouteByRole()
               )
            }
         />
         <Route
            path="/forgot-password"
            element={
               !isAuthenticated ? (
                  <ForgotPasswordContainer />
               ) : (
                  getDefaultDashboardRouteByRole()
               )
            }
         />
         {/* Dashboard route */}
         <Route
            path="/dashboard"
            element={
               isAuthenticated ? (
                  <DashboardContainer />
               ) : (
                  <Navigate to="/sign-in" />
               )
            }
         >
            {availableRoutes
               .filter(
                  (route) =>
                     allowRoutes.some((e) =>
                        route.permissionKeys.includes(e)
                     ) || route.permissionKeys.includes(FunctionEnum.NONE)
               )
               .map((route) => (
                  <Route
                     key={route.link}
                     path={route.link}
                     element={createElement(route.element)}
                  />
               ))}
         </Route>
         <Route path="*" element={getDefaultDashboardRouteByRole()} />
         <Route
            path="/session"
            element={
               !isAuthenticated ? (
                  <OutOfSessionComponent />
               ) : (
                  getDefaultDashboardRouteByRole()
               )
            }
         />
         <Route
            path="/payment"
            element={
               isAuthenticated ? (
                  <PaymentResultContainer />
               ) : (
                  <Navigate to="/session" />
               )
            }
         />
      </Routes>
   )
}
