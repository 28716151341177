import { useSelector } from "react-redux"
import { selectAllowRoutes } from "../../../states/permission/permission.selector"
import { FunctionEnum } from "../../enum/function.enum"
import { AppBarComponent } from "./appBar.component"

export const AppBarContainer = () => {
   const allowRoutes: FunctionEnum[] = useSelector(selectAllowRoutes)

   return <AppBarComponent allowRoutes={allowRoutes} />
}
