import { createAction } from "@reduxjs/toolkit"

export const getCreateEmailTemplateAsync = createAction(
   "createEmailTemplate/getCreateEmailTemplateAsync"
)

export const changePageNumberAsync = createAction<number>(
   "createEmailTemplate/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "createEmailTemplate/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "createEmailTemplate/changeSearchConditionAsync"
)

export const reloadGetCreateEmailTemplateAsync = createAction(
   "createEmailTemplate/reloadGetCreateEmailTemplateAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "createEmailTemplate/changeSortConditionAsync"
)
