import { SettingEkycComponent } from "./settingEkyc.component"
import { QueriesEnum } from "../../../common/enum/queries.enum"
import { genQueries } from "../../../common/helper/queries.helper"
import {
   GetUserInfoPayloadAPIInterface,
   getUserInfoAPI,
} from "../../../api/setting.api"
import { useSelector } from "react-redux"
import { selectIsEkycUserInfo } from "../../../states/user/user.selector"

export const SettingEkycContainer = () => {
   const isEkyc = useSelector(selectIsEkycUserInfo)

   /**
    * Handle get ekyc async
    * @returns
    */
   const handleGetEkycAsync = () => {
      const params: GetUserInfoPayloadAPIInterface = {
         queries: genQueries(
            QueriesEnum.EKYC_NAME,
            QueriesEnum.EKYC_IDENTITY_CARD,
            QueriesEnum.EKYC_BIRTHDAY,
            QueriesEnum.EKYC_ADDRESS,
            QueriesEnum.EKYC_ADDRESS_HOME,
            QueriesEnum.EKYC_ETHNIC,
            QueriesEnum.EKYC_RELIGION,
            QueriesEnum.EKYC_ISSUED_DATE,
            QueriesEnum.EKYC_ISSUED_BY,
            QueriesEnum.EKYC_SIMILAR
         ),
      }
      return getUserInfoAPI(params)
   }

   return (
      <SettingEkycComponent
         isEkyc={isEkyc}
         handleGetEkycAsync={handleGetEkycAsync}
      />
   )
}
