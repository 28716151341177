import { ChartEnum } from "../../common/enum/chart.enum"
import { call, select } from "redux-saga/effects"
import { put, takeLatest } from "redux-saga/effects"
import {
   finishExportExcelChartSignUp,
   getChartSignUpFail,
   getChartSignUpSuccess,
   requestChartSignUp,
   startExportExcelChartSignUp,
} from "./chartSignUp.slice"
import {
   exportChartSignUpByAppAPI,
   ExportChartSignUpByAppPayloadAPIInterface,
   exportChartSignUpByDateAPI,
   ExportChartSignUpByDatePayloadAPIInterface,
   exportChartSignUpByProvinceAPI,
   ExportChartSignUpByProvincePayloadAPIInterface,
   getChartSignUpByAppAPI,
   GetChartSignUpByAppPayloadAPIInterface,
   getChartSignUpByDateAPI,
   GetChartSignUpByDatePayloadAPIInterface,
   getChartSignUpByProvinceAPI,
   GetChartSignUpByProvincePayloadAPIInterface,
} from "../../api/chartSignUp.api"
import {
   exportExcelChartSignUpAsync,
   getChartSignUpAsync,
} from "./chartSignUp.action"
import { selectSearchConditionChartSignUp } from "./chartSignUp.selector"
import { getParamMode } from "../../common/helper/chart.helper"
import { PayloadAction } from "@reduxjs/toolkit"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   ColumnsExportExcel,
   ConditionsExportExcel,
} from "../../common/interfaces/exportExcel.interface"
import {
   exportExcel,
   getDateHeadline,
} from "../../common/helper/exportExcel.helper"
import {
   convertDate,
   getFormatDateExportExcel,
} from "../../common/helper/date.helper"
import { i18n } from "../../i18n/i18n"
import { filterChartConst } from "../../common/variables/filterChart"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Get chart sign in saga
 * @param action
 */
function* getChartSignUpSaga(): any {
   try {
      yield put(requestChartSignUp())
      const searchCondition = yield select(selectSearchConditionChartSignUp)
      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: GetChartSignUpByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(getChartSignUpByDateAPI, params)
         if (!response) {
            throw error
         }
         yield put(getChartSignUpSuccess(response.data.data))
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: GetChartSignUpByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(getChartSignUpByAppAPI, params)
         if (!response) {
            throw error
         }
         yield put(getChartSignUpSuccess(response.data.data))
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: GetChartSignUpByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartSignUpByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartSignUpSuccess(response.data.data))
      }
   } catch (error: any) {
      yield put(getChartSignUpFail("error.chart"))
   }
}

/**
 * Export excel chart sign in saga
 * @param action
 */
function* exportExcelChartSignUpSaga(action: PayloadAction<any>): any {
   try {
      yield put(startExportExcelChartSignUp())
      const searchCondition = yield select(selectSearchConditionChartSignUp)

      // Excel config
      const titleString =
         " " +
         i18n.t("filter.chartType", { ns: namespaces.common }) +
         " " +
         i18n.t(
            filterChartConst.filter((e) => e.id === searchCondition.type)[0]
               .text,
            { ns: namespaces.common }
         )
      const workBookName =
         i18n.t("workBookName", {
            ns: namespaces.pages.chart,
         }) +
         "_SIGNUP_" +
         searchCondition.type
      const titleSheetData =
         i18n.t("titleSheetData.signUp", {
            ns: namespaces.pages.chart,
         }) + titleString
      const titleSheetChart =
         i18n.t("titleSheetChart.signUp", {
            ns: namespaces.pages.chart,
         }) + titleString
      const conditions: ConditionsExportExcel[] = [
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.fromDate
            ),
         },
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.toDate
            ),
         },
      ]

      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.accountTypeName", {
                  ns: namespaces.pages.chart,
               }),
               key: "accountTypeName",
            },
            {
               header: i18n.t("table.registerAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "registerAt",
               format: (value: string) => convertDate(value),
            },
         ]
         const params: ExportChartSignUpByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            exportChartSignUpByDateAPI,
            params
         )
         if (!response) {
            throw error
         }

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: ExportChartSignUpByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartSignUpByAppAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.accountTypeName", {
                  ns: namespaces.pages.chart,
               }),
               key: "accountTypeName",
            },
            {
               header: i18n.t("table.applicationName", {
                  ns: namespaces.pages.chart,
               }),
               key: "applicationName",
            },
            {
               header: i18n.t("table.registerAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "registerAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: ExportChartSignUpByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartSignUpByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }

         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.accountTypeName", {
                  ns: namespaces.pages.chart,
               }),
               key: "accountTypeName",
            },
            {
               header: i18n.t("table.province", {
                  ns: namespaces.pages.chart,
               }),
               key: "provinceName",
            },
            {
               header: i18n.t("table.registerAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "registerAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportSuccess", {
               ns: namespaces.pages.chart,
            }),
            variant: "success",
         })
      )

      yield put(finishExportExcelChartSignUp())
   } catch (error) {
      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportFail", {
               ns: namespaces.pages.chart,
            }),
            variant: "error",
         })
      )
      yield put(finishExportExcelChartSignUp())
   }
}

/**
 * Root chart sign in saga
 */
export function* chartSignUpSaga() {
   yield takeLatest(getChartSignUpAsync, getChartSignUpSaga)
   yield takeLatest(exportExcelChartSignUpAsync, exportExcelChartSignUpSaga)
}
