import { useEffect, useState } from "react"
import { ManagementWalletRechargeComponent } from "./managementWalletRecharge.component"
import { getManagementWalletRechargeAPI } from "../../api/managementWalletRecharge.api"
import { ManagementWalletPackInterface } from "../../common/interfaces/managementWalletPack.interface"

export const ManagementWalletRechargeContainer = () => {
   const [requesting, setRequesting] = useState<boolean>(false)
   const [data, setData] = useState<ManagementWalletPackInterface[]>([])
   const [error, setError] = useState<string>("")

   useEffect(() => {
      setRequesting(true)
      getManagementWalletRechargeAPI()
         .then((res) => {
            const resData = res.response.data.map((e: any) => {
               const a: ManagementWalletPackInterface = {
                  id: e.id,
                  promotionId: e.promotionId,
                  name: e.name,
                  amount: e.amount,
                  discount: e.coinPercent ? e.coinPercent : 0,
                  bonus: e.bonus ? 0 : e.bonus,
                  addDay: e.addDay,
                  coin: e.coin,
               }
               return a
            })
            setData(resData)
         })
         .catch((err) => {
            setError("Xảy ra lỗi trong quá trình lấy thông tin!")
         })
         .finally(() => {
            setRequesting(false)
         })
   }, [])

   return (
      <ManagementWalletRechargeComponent
         requesting={requesting}
         data={data}
         error={error}
      />
   )
}
