import { useSelector } from "react-redux"
import {
   allowAddRechargePackage,
   allowDeleteRechargePackage,
   allowDetailRechargePackage,
   allowUpdateRechargePackage,
} from "../../states/permission/permission.selector"

export const useRechargePackPermission = () => {
   return {
      allowDetailRechargePackage: useSelector(allowDetailRechargePackage),
      allowDeleteRechargePackage: useSelector(allowDeleteRechargePackage),
      allowAddRechargePackage: useSelector(allowAddRechargePackage),
      allowUpdateRechargePackage: useSelector(allowUpdateRechargePackage),
   }
}
