import {
   Box,
   Button,
   InputAdornment,
   Stack,
   TextField,
   Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ManagementUserDetailCompanyInterface } from "../../../common/interfaces/managementUserDetailCompany.interface"
import { namespaces } from "../../../i18n/i18n.constants"
import CircularProgress from "@mui/material/CircularProgress"
import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {
   ManagementUserDetailCompanyInput,
   managementUserDetailCompanySchema,
} from "./managementUserDetailCompany.form"
import { zodResolver } from "@hookform/resolvers/zod"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import PhoneIcon from "@mui/icons-material/Phone"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import ApartmentIcon from "@mui/icons-material/Apartment"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import { useManagementUserDetailPermission } from "../managementUserDetail.permission"

type ManagementUserDetailCompanyComponentProps = {
   requesting: boolean
   error: string
   data: ManagementUserDetailCompanyInterface | null
   modeEdit: boolean
   setModeEditAsync: (value: boolean) => void
   handleUpdateManagementUserDetailCompanyAsync: (
      data: ManagementUserDetailCompanyInput
   ) => void
}

export const ManagementUserDetailCompanyComponent = (
   props: ManagementUserDetailCompanyComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.managementUserDetail)

   const { allowUpdateManagementUser } = useManagementUserDetailPermission()

   const {
      register,
      formState: { errors },
      setValue,
      clearErrors,
      handleSubmit,
   } = useForm<ManagementUserDetailCompanyInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(managementUserDetailCompanySchema),
   })

   /**
    * Handle cancel moode edit
    */
   const handleCancelModeEdit = () => {
      props.setModeEditAsync(false)
      if (props.data) {
         setValue("name", props.data.name)
         setValue("taxCode", props.data.taxCode)
         setValue("address", props.data.address)
         setValue("phoneNumber", props.data.phoneNumber)
         setValue("email", props.data.email)
         clearErrors()
      }
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<ManagementUserDetailCompanyInput> = (
      values: ManagementUserDetailCompanyInput
   ) => {
      if (!props.modeEdit) {
         props.setModeEditAsync(true)
      } else {
         if (!props.data) {
            return
         }
         props.handleUpdateManagementUserDetailCompanyAsync(values)
      }
   }

   useEffect(() => {
      if (props.data) {
         setValue("name", props.data.name)
         setValue("taxCode", props.data.taxCode)
         setValue("address", props.data.address)
         setValue("phoneNumber", props.data.phoneNumber)
         setValue("email", props.data.email)
      }
   }, [props.data, setValue])

   return (
      <Box
         component="form"
         autoComplete="off"
         noValidate
         onSubmit={handleSubmit(onSubmitForm)}
         sx={{
            border: "1px solid #80808061",
            borderRadius: 1,
            padding: 3,
         }}
      >
         <Stack direction="row" spacing={2}>
            <ApartmentIcon />
            <Typography variant="body1" fontWeight={500}>
               {t("company.title")}
            </Typography>
         </Stack>
         {/* Loading */}
         {props.requesting && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "20vh",
               }}
            >
               <CircularProgress color="secondary" />
            </Stack>
         )}
         {/* Error */}
         {!props.requesting && props.error && (
            <Stack
               alignItems="center"
               justifyContent="center"
               sx={{
                  height: "20vh",
               }}
            >
               <Typography variant="body1" color="error">
                  {t(props.error, { ns: "common" })}
               </Typography>
            </Stack>
         )}
         {/* Data */}
         {!props.requesting && !props.error && (
            <React.Fragment>
               <TextField
                  margin="normal"
                  id="name"
                  label={t("company.name")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <FormatColorTextIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("name")}
               />
               <TextField
                  margin="normal"
                  id="taxCode"
                  label={t("company.taxCode")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.taxCode}
                  helperText={errors.taxCode ? errors.taxCode.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <AccountBalanceIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("taxCode")}
               />
               <TextField
                  margin="normal"
                  id="address"
                  label={t("company.address")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <ContactMailIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("address")}
               />
               <TextField
                  margin="normal"
                  id="phoneNumber"
                  label={t("company.phoneNumber")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.phoneNumber}
                  helperText={
                     errors.phoneNumber ? errors.phoneNumber.message : ""
                  }
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <PhoneIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("phoneNumber")}
               />
               <TextField
                  margin="normal"
                  id="email"
                  label={t("company.email")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  disabled={props.requesting}
                  InputProps={{
                     readOnly: !props.modeEdit,
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachEmailIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("email")}
               />
               {allowUpdateManagementUser && (
                  <Button
                     type="submit"
                     disabled={props.requesting}
                     startIcon={props.modeEdit ? <CheckIcon /> : <EditIcon />}
                  >
                     {props.modeEdit ? t("button.save") : t("button.update")}
                  </Button>
               )}
               {props.modeEdit && (
                  <Button
                     color="info"
                     disabled={props.requesting}
                     startIcon={<CloseIcon />}
                     onClick={handleCancelModeEdit}
                  >
                     {t("button.back")}
                  </Button>
               )}
            </React.Fragment>
         )}
      </Box>
   )
}
