import { useSelector } from "react-redux"
import {
   allowGetChartUserType,
   allowExportChartUserType,
} from "../../states/permission/permission.selector"

export const useChartUserTypePermission = () => {
   return {
      allowGetChartUserType: useSelector(allowGetChartUserType),
      allowExportChartUserType: useSelector(allowExportChartUserType),
   }
}
