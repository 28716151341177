import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetChartChargeWalletByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const getChartChargeWalletByDateAPI = (
   payload: GetChartChargeWalletByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-recharge-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface ExportChartChargeWalletByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const exportChartChargeWalletByDateAPI = (
   payload: ExportChartChargeWalletByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-recharge-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface GetChartChargeWalletByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const getChartChargeWalletByAppAPI = (
   payload: GetChartChargeWalletByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-recharge-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartChargeWalletByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const exportChartChargeWalletByAppAPI = (
   payload: ExportChartChargeWalletByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-recharge-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface GetChartChargeWalletByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const getChartChargeWalletByProvinceAPI = (
   payload: GetChartChargeWalletByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-recharge-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartChargeWalletByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const exportChartChargeWalletByProvinceAPI = (
   payload: ExportChartChargeWalletByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-recharge-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}
