import { createAction } from "@reduxjs/toolkit"

export const getDepartmentUserAsync = createAction<string>(
   "departmentUser/getDepartmentUserAsync"
)

export const changePageNumberAsync = createAction<number>(
   "departmentUser/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "departmentUser/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "departmentUser/changeSearchConditionAsync"
)

export const reloadGetDepartmentUserAsync = createAction(
   "departmentUser/reloadGetDepartmentUserAsync"
)
