import { useDispatch, useSelector } from "react-redux"
import {
   addUserToDepartmentUserAPI,
   AddUserToDepartmentUserAPIInterface,
   deleteUserInDepartmentUserAPI,
   DeleteUserInDepartmentUserPayloadAPIInterface,
} from "../../../api/departmentUser.api"
import { DeparmentUserAddingInterface } from "../../../common/interfaces/departmentUserAdding.interface"
import { reloadGetDepartmentAsync } from "../../../states/department/department.action"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   reloadGetDepartmentUserAsync,
} from "../../../states/departmentUser/departmentUser.action"
import {
   selectCountDepartmentUser,
   selectDataDepartmentUser,
   selectErrorDepartmentUser,
   selectPageNumberDepartmentUser,
   selectPageSizeDepartmentUser,
   selectRequestingDepartmentUser,
   selectSearchConditionDepartmentUser,
   selectSelectedDepartmentUser,
} from "../../../states/departmentUser/departmentUser.selector"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { DepartmentUserComponent } from "./departmentUser.component"

export const DepartmentUserContainer = () => {
   const dispatch = useDispatch()

   // Selector
   const requesting = useSelector(selectRequestingDepartmentUser)
   const error = useSelector(selectErrorDepartmentUser)
   const userData = useSelector(selectDataDepartmentUser)
   const rowCount = useSelector(selectCountDepartmentUser)
   const pageNumber = useSelector(selectPageNumberDepartmentUser)
   const pageSize = useSelector(selectPageSizeDepartmentUser)
   const selectId = useSelector(selectSelectedDepartmentUser)
   const searchCondition = useSelector(selectSearchConditionDepartmentUser)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle add department user sync
    * @param values
    * @returns
    */
   const handleAddDepartmentUserSync = (
      departmentId: string,
      values: DeparmentUserAddingInterface[]
   ) => {
      const params: AddUserToDepartmentUserAPIInterface = {
         departmentId: departmentId,
         userIds: values.map((e) => e.id),
      }
      return addUserToDepartmentUserAPI(params)
   }

   /**
    * Handle delete depatertment user sync
    * @param values
    * @returns
    */
   const handleDeleteDepartmentUserAsync = (id: string) => {
      const params: DeleteUserInDepartmentUserPayloadAPIInterface = {
         id: id,
      }
      return deleteUserInDepartmentUserAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Reload department user list
    */
   const handleReloadGetDeparmentsUserAsync = () => {
      dispatch(reloadGetDepartmentUserAsync())
   }

   /**
    * Reload department list
    */
   const handleReloadGetDeparmentsAsync = () => {
      dispatch(reloadGetDepartmentAsync())
   }

   return selectId.length > 0 ? (
      <DepartmentUserComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         error={error}
         userData={userData}
         selectId={selectId}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleAddDepartmentUserSync={handleAddDepartmentUserSync}
         handleReloadGetDeparmentsUserAsync={handleReloadGetDeparmentsUserAsync}
         handleReloadGetDeparmentsAsync={handleReloadGetDeparmentsAsync}
         handleDeleteDepartmentUserAsync={handleDeleteDepartmentUserAsync}
      />
   ) : null
}
