import { Fragment } from "react"
import { ChartComponent } from "../../common/components/Chart/chart.component"
import { FilterChartComponent } from "../../common/components/FilterChart/filterChart.component"
import { FilterChartGUIInterface } from "../../common/interfaces/filterChartGUI.interface"
import { FilterChartSearchConditionInterface } from "../../common/interfaces/filterChartSearchCondition.interface"
import { useChartSignUpPermission } from "./chartSignUp.permission"

type ChartSignUpComponentProps = {
   refChart: any
   // Chart
   requestingChart: boolean
   requestingExport: boolean
   dataChart: any[]
   errorChart: string
   filterChart: FilterChartGUIInterface
   searchConditionChart: FilterChartSearchConditionInterface
   isDisableStatistic: boolean
   isDisableExport: boolean
   handleStatistic: () => void
   handleChangeFilter: (data: any) => void
   handleChangeSearchCondition: (data: any) => void
   handleChartExport: () => void
}

export const ChartSignUpComponent = (props: ChartSignUpComponentProps) => {
   const {
      allowGetChartSignUpByDate,
      allowGetChartSignUpByApp,
      allowGetChartSignUpByProvince,
      allowExportChartSignUpByDate,
      allowExportChartSignUpByApp,
      allowExportChartSignUpByProvince,
   } = useChartSignUpPermission()

   return (
      <Fragment>
         <FilterChartComponent
            requesting={props.requestingChart}
            requestingExport={props.requestingExport}
            filter={props.filterChart}
            searchCondtion={props.searchConditionChart}
            isDisableStatistic={props.isDisableStatistic}
            isDisableExport={props.isDisableExport}
            isAllowGetDate={allowGetChartSignUpByDate}
            isAllowGetApp={allowGetChartSignUpByApp}
            isAllowGetProvince={allowGetChartSignUpByProvince}
            isAllowExportDate={allowExportChartSignUpByDate}
            isAllowExportApp={allowExportChartSignUpByApp}
            isAllowExportProvince={allowExportChartSignUpByProvince}
            handleStatistic={props.handleStatistic}
            handleChartExport={props.handleChartExport}
            handleChangeFilter={props.handleChangeFilter}
            handleChangeSearchCondition={props.handleChangeSearchCondition}
         />
         <ChartComponent
            refChart={props.refChart}
            requesting={props.requestingChart}
            filter={props.filterChart}
            searchCondition={props.searchConditionChart}
            data={props.dataChart}
            error={props.errorChart}
         />
      </Fragment>
   )
}
