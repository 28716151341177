import { AxiosAuth } from "./config/axiosAuth.instance"
import { AxiosBase } from "./config/axiosBase.instance"

export interface GetUserPayloadAPIInterface {
   queries: string
}

export const getUserAPI = (payload: GetUserPayloadAPIInterface) => {
   return AxiosAuth.get("user?queries=" + payload.queries)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface GetUserAdminPayloadAPIInterface {
   userid: string
   queries: string
}

export const getUserAdminAPI = (
   token: string,
   payload: GetUserAdminPayloadAPIInterface
) => {
   return AxiosBase.get(
      "user/admin/" + payload.userid + "?queries=" + payload.queries,
      {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      }
   )
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
