import { CreateEmailUserInterface } from "../../common/interfaces/createEmailUser.interface"
import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"

export interface CreateEmailUserState {
   requesting: boolean
   successful: boolean
   error: string
   data: CreateEmailUserInterface[]
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionState
}

const initialState: CreateEmailUserState = {
   requesting: false,
   successful: false,
   error: "",
   data: [],
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableEnum.NONE,
      value: "",
   },
}

export const createEmailUser = createSlice({
   name: "createEmailUser",
   initialState,
   reducers: {
      requestCreateEmailUser: (state: CreateEmailUserState) => {
         state.requesting = true
      },
      getCreateEmailUserSuccess: (
         state: CreateEmailUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data.data
         state.rowCount = action.payload.data.rowCount
      },
      getCreateEmailUserFail: (
         state: CreateEmailUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: CreateEmailUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: CreateEmailUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: CreateEmailUserState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.value = action.payload.value
      },
   },
})

export const {
   requestCreateEmailUser,
   getCreateEmailUserSuccess,
   getCreateEmailUserFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
} = createEmailUser.actions

export default createEmailUser.reducer
