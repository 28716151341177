import { SignInComponent } from "./signIn.component"
import { useDispatch, useSelector } from "react-redux"
import {
   selectAuthIsError,
   selectAuthError,
   selectAuthRequesting,
} from "../../states/auth/auth.selector"
import { useNavigate } from "react-router-dom"
import { signInAsync } from "../../states/auth/auth.action"
import { resetRegisterUser } from "../../states/register/register.slice"
import { SignInInput } from "./signIn.form"

export const SignInContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   // Selector
   const isSignInError = useSelector(selectAuthIsError)
   const isSignInRequesting = useSelector(selectAuthRequesting)
   const signInError = useSelector(selectAuthError)

   /**
    * On submit form sign in
    * @param values
    */
   const onSubmitForm = (values: SignInInput) => {
      dispatch(signInAsync({ values, navigate }))
   }

   /**
    * On navigate register
    */
   const onSignUp = () => {
      dispatch(resetRegisterUser())
      navigate("/register")
   }

   /**
    * On navigate forgot password
    */
   const onForgotPassword = () => {
      navigate("/forgot-password")
   }

   return (
      <SignInComponent
         isSignInRequesting={isSignInRequesting}
         isSignInError={isSignInError}
         signInError={signInError}
         onSubmitForm={onSubmitForm}
         onSignUp={onSignUp}
         onForgotPassword={onForgotPassword}
      />
   )
}
