import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetChartUsingWalletByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const getChartUsingWalletByDateAPI = (
   payload: GetChartUsingWalletByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-checkout-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface ExportChartUsingWalletByDatePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   mode: number
}

export const exportChartUsingWalletByDateAPI = (
   payload: ExportChartUsingWalletByDatePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-checkout-by-date?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         "&Mode=" +
         payload.mode
   ).then((response) => ({ response }))
}

export interface GetChartUsingWalletByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const getChartUsingWalletByAppAPI = (
   payload: GetChartUsingWalletByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-checkout-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartUsingWalletByAppPayloadAPIInterface {
   fromDate: Date
   toDate: Date
   appIds: string[]
}

export const exportChartUsingWalletByAppAPI = (
   payload: ExportChartUsingWalletByAppPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-checkout-by-app?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.appIds.reduce((sum, x) => sum + "&AppIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface GetChartUsingWalletByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const getChartUsingWalletByProvinceAPI = (
   payload: GetChartUsingWalletByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/user-checkout-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}

export interface ExportChartUsingWalletByProvincePayloadAPIInterface {
   fromDate: Date
   toDate: Date
   provinceIds: string[]
}

export const exportChartUsingWalletByProvinceAPI = (
   payload: ExportChartUsingWalletByProvincePayloadAPIInterface
) => {
   return AxiosAuth.get(
      "statistics/export-user-checkout-by-province?FromDate=" +
         payload.fromDate +
         "&ToDate=" +
         payload.toDate +
         payload.provinceIds.reduce((sum, x) => sum + "&ProvinceIds=" + x, "")
   ).then((response) => ({ response }))
}
