import { useSnackbar, VariantType } from "notistack"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectNotistack } from "../../../states/notistack/notistack.selector"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { clearNotistack } from "../../../states/notistack/notistack.slice"

export const NotistackComponent = ({ children }: any) => {
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const dispatch = useDispatch()
   const notistack = useSelector(selectNotistack)

   /**
    * Close button action
    * @param snackbarId
    * @returns
    */
   const action = useCallback(
      (snackbarId: any) => (
         <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
               closeSnackbar(snackbarId)
            }}
         >
            <CloseIcon sx={{ color: "white" }} fontSize="inherit" />
         </IconButton>
      ),
      [closeSnackbar]
   )

   /**
    * Close button action
    * @param snackbarId
    * @returns
    */
   const close = useCallback(() => dispatch(clearNotistack()), [dispatch])

   useEffect(() => {
      if (notistack.message) {
         enqueueSnackbar(notistack.message, {
            variant: notistack.variant as VariantType,
            autoHideDuration: 4000,
            action: action,
            anchorOrigin: {
               vertical: "top",
               horizontal: "right",
            },
            onClose: close,
         })
      }
   }, [notistack, enqueueSnackbar, action, close])

   return { ...children }
}
