import {
   VNPAY_PAYMENT_COMMAND,
   VNPAY_PAYMENT_CURRCODE,
   VNPAY_PAYMENT_IPADDR,
   VNPAY_PAYMENT_LOCALE,
   VNPAY_PAYMENT_ORDER_INFO,
   VNPAY_PAYMENT_ORDER_TYPE,
   VNPAY_PAYMENT_RETURN_URL,
   VNPAY_PAYMENT_SECURE_HASH,
   VNPAY_PAYMENT_TMNCODE,
   VNPAY_PAYMENT_URL,
   VNPAY_PAYMENT_VERSION,
} from "../variables/vnPayCheckOut"
import { convertDateToPay } from "./date.helper"
import * as crypto from "crypto-js"
import queryString from "query-string"
import { genUUID } from "./uuid.helper"
import { VnPayPaymentInterface } from "../interfaces/vnPayPayment.interface"
import { Buffer } from "buffer"

/**
 * Gen VNPAY payment url
 * @param amount
 * @returns
 */
export const genVnPayPaymentUrl = (amount: number): VnPayPaymentInterface => {
   const nowTime = new Date()
   const expiredTime = new Date()
   expiredTime.setMinutes(nowTime.getMinutes() + 6)

   // eslint-disable-next-line @typescript-eslint/naming-convention
   let vnp_Params: { [k: string]: any } = {
      vnp_Amount: amount * 100,
      vnp_Command: VNPAY_PAYMENT_COMMAND,
      vnp_CreateDate: convertDateToPay(nowTime),
      vnp_CurrCode: VNPAY_PAYMENT_CURRCODE,
      vnp_IpAddr: VNPAY_PAYMENT_IPADDR,
      vnp_Locale: VNPAY_PAYMENT_LOCALE,
      vnp_OrderInfo: Buffer.from(VNPAY_PAYMENT_ORDER_INFO).toString("base64"),
      vnp_OrderType: VNPAY_PAYMENT_ORDER_TYPE,
      vnp_ReturnUrl: encodeURIComponent(VNPAY_PAYMENT_RETURN_URL),
      vnp_TmnCode: VNPAY_PAYMENT_TMNCODE,
      vnp_TxnRef: genUUID(),
      vnp_Version: VNPAY_PAYMENT_VERSION,
   }

   return {
      params: vnp_Params,
   }
}

/**
 * Update VNPAY payment url
 * @param amount
 * @returns
 */
export const updateTransactionNoVnPayPaymentUrl = (
   transactionNo: string,
   params: {
      [k: string]: any
   }
): VnPayPaymentInterface => {
   // params.vnp_TxnRef = transactionNo

   const hmac = crypto.HmacSHA512(
      queryString.stringify(params, { encode: false }),
      VNPAY_PAYMENT_SECURE_HASH
   )
   const signed = crypto.enc.Hex.stringify(hmac)

   params.vnp_SecureHash = signed

   return {
      params: params,
      url:
         VNPAY_PAYMENT_URL +
         "?" +
         queryString.stringify(params, { encode: false }),
   }
}
