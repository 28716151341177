import { useDispatch } from "react-redux"
import {
   addTypeUserAPI,
   AddTypeUserPayloadAPIInterface,
   deleteTypeUserAPI,
   DeleteTypeUserPayloadAPIInterface,
   updateTypeUserAPI,
   UpdateTypeUserPayloadAPIInterface,
} from "../../../api/typeUser.api"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { TypeUserDialogComponent } from "./typeUserDialog.component"
import { reloadGetTypeUserAsync } from "../../../states/typeUser/typeUser.action"
import { DialogMethodEnum } from "../../../common/enum/dialog.enum"
import { TypeUserInterface } from "../../../common/interfaces/typeUser.interface"
import { TypeUserDialogInput } from "./typeUserDialog.form"

type TypeUserContainerProps = {
   openDialog: boolean
   type: DialogMethodEnum
   selected: TypeUserInterface | null
   handleCloseDialog: () => void
}

export const TypeUserDialogContainer = (props: TypeUserContainerProps) => {
   const dispatch = useDispatch()

   /**
    * Handle cancel
    */
   const handleCancel = () => {}

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle add type user async
    * @param values
    * @returns
    */
   const handleAddTypeUserAsync = (values: TypeUserDialogInput) => {
      const params: AddTypeUserPayloadAPIInterface = {
         name: values.name,
         provinceId: values.province ? values.province.map((e) => e.id) : null,
         topup_min: values.fromMoneyChargeWallet,
         topup_max: values.toMoneyChargeWallet,
         use_amount_min: values.fromMoneyUsingWallet,
         use_amount_max: values.toMoneyUsingWallet,
         login_min: values.fromTimeSignIn,
         login_max: values.toTimeSignIn,
      }
      return addTypeUserAPI(params)
   }

   /**
    * Handle update type user async
    * @param values
    * @returns
    */
   const handleUpdateTypeUserAsync = (values: TypeUserDialogInput) => {
      const params: UpdateTypeUserPayloadAPIInterface = {
         id: props.selected?.id as string,
         name: values.name,
         provinceId: values.province ? values.province.map((e) => e.id) : null,
         topup_min: values.fromMoneyChargeWallet,
         topup_max: values.toMoneyChargeWallet,
         use_amount_min: values.fromMoneyUsingWallet,
         use_amount_max: values.toMoneyUsingWallet,
         login_min: values.fromTimeSignIn,
         login_max: values.toTimeSignIn,
      }
      return updateTypeUserAPI(params)
   }

   /**
    * Handle type user sync
    * @param values
    * @returns
    */
   const handleDeleteTypeUserAsync = (id: string) => {
      const params: DeleteTypeUserPayloadAPIInterface = {
         id: id,
      }
      return deleteTypeUserAPI(params)
   }

   /**
    * Handle reload get ype user async
    */
   const handleReloadGetTypeUserAsync = () => {
      dispatch(reloadGetTypeUserAsync())
   }

   return (
      <TypeUserDialogComponent
         openDialog={props.openDialog}
         type={props.type}
         selected={props.selected}
         handleCloseDialog={props.handleCloseDialog}
         handleCancel={handleCancel}
         handleAddTypeUserAsync={handleAddTypeUserAsync}
         handleUpdateTypeUserAsync={handleUpdateTypeUserAsync}
         handlePushNotistack={handlePushNotistack}
         handleDeleteTypeUserAsync={handleDeleteTypeUserAsync}
         handleReloadGetTypeUserAsync={handleReloadGetTypeUserAsync}
      />
   )
}
