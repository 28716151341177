import {
   selectPageNumberManagementUserChargeHistory,
   selectPageSizeManagementUserChargeHistory,
   selectSearchConditionManagementUserChargeHistory,
   selectSelectedIdManagementUserChargeHistory,
} from "./managementUserChargeHistory.selector"
import {
   getManagementUserChargeHistoryAPI,
   GetManagementUserChargeHistoryPayloadAPIInterface,
} from "../../api/managementUserChargeHistory.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementUserChargeHistoryAsync,
} from "./managementUserChargeHistory.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   getManagementUserChargeHistoryFail,
   getManagementUserChargeHistorySuccess,
   requestManagementUserChargeHistory,
} from "./managementUserChargeHistory.slice"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"

/**
 * Get management wallet saga
 * @param action
 */
function* getManagementUserChargeHistorySaga(
   action: PayloadAction<string>
): any {
   try {
      yield put(requestManagementUserChargeHistory(action.payload))
      const pageNumber = yield select(
         selectPageNumberManagementUserChargeHistory
      )
      const pageSize = yield select(selectPageSizeManagementUserChargeHistory)
      const searchCondition = yield select(
         selectSearchConditionManagementUserChargeHistory
      )
      const params: GetManagementUserChargeHistoryPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [
            ["USERID", action.payload],
            ["FROMDATE", searchCondition.from],
            ["TODATE", searchCondition.to],
         ],
      }

      if (searchCondition.option !== FilterTableWalletEnum.ALL) {
         params.SearchConditions.unshift([
            "TRANSACTIONTYPE",
            searchCondition.option,
         ])
      }

      const { response, error } = yield call(
         getManagementUserChargeHistoryAPI,
         params
      )
      if (!response) {
         throw error
      }
      yield put(getManagementUserChargeHistorySuccess(response.data))
   } catch (error: any) {
      yield put(getManagementUserChargeHistoryFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))

      const selectId = yield select(selectSelectedIdManagementUserChargeHistory)

      yield put(getManagementUserChargeHistoryAsync(selectId))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))

      const selectId = yield select(selectSelectedIdManagementUserChargeHistory)

      yield put(getManagementUserChargeHistoryAsync(selectId))
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))

      const selectId = yield select(selectSelectedIdManagementUserChargeHistory)

      yield put(getManagementUserChargeHistoryAsync(selectId))
   } catch (error) {}
}

/**
 * Root management wallet saga
 */
export function* managementUserChargeHistorySaga() {
   yield takeLatest(
      getManagementUserChargeHistoryAsync,
      getManagementUserChargeHistorySaga
   )
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
}
