import { AxisTypeEnum } from "../enum/axisType.enum"
import { ChartEnum } from "../enum/chart.enum"

/**
 * Get param mode for chart api
 * @param type
 * @returns
 */
export const getParamMode = (type: ChartEnum) => {
   if (type === ChartEnum.DAY) {
      return 0
   }
   if (type === ChartEnum.MONTH) {
      return 1
   }
   if (type === ChartEnum.YEAR) {
      return 2
   }
   return 0
}

/**
 * Get mapping data for chart pie
 * @param data
 * @returns
 */
export const mappingChartPie = (data: any) => {
   return data.map((e: any) => {
      return {
         id: e.x,
         label: e.x,
         value: e.y,
      }
   })
}

/**
 * Get mapping data for chart pie (user type)
 * @param data
 * @returns
 */
export const mappingChartPieUserType = (data: any) => {
   const first = {
      id: data.userType.x,
      label: data.userType.x,
      value: data.userType.y,
   }
   const second = {
      id: data.remainUser.x,
      label: data.remainUser.x,
      value: data.remainUser.y,
   }

   return [first, second]
}

/**
 * Get mapping data for chart bar
 * @param data
 * @returns
 */
export const mappingChartBar = (axis: AxisTypeEnum, data: any) => {
   const mappedData = data.map((e: any) => {
      return {
         id: e.x,
         value: e.y,
         label: e.x,
      }
   })

   if (axis === AxisTypeEnum.HORIZONTAL) {
      return mappedData.reverse()
   }
   return mappedData
}

/**
 * Get mapping data for chart sin
 * @param label
 * @param data
 * @returns
 */
export const mappingChartSin = (label: string, data: any) => {
   return [
      {
         id: label,
         data: data,
      },
   ]
}
