import { ChartChargeWalletState } from "./chartChargeWallet.slice"
import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectChartChargeWalletState = (
   state: RootState
): ChartChargeWalletState => state.chartChargeWallet

export const selectRequestingChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.requesting
)

export const selectRequestingExportChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.excelExporting
)

export const selectDataChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.data
)

export const selectErrorChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.error
)

export const selectFilterChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.filter
)

export const selectSearchConditionChartChargeWallet = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.searchCondition
)

export const selectIsDisableStatistic = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => false
)

export const selectIsDisableExport = createSelector(
   selectChartChargeWalletState,
   (state: ChartChargeWalletState) => state.data.length === 0
)
