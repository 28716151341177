import { AccountType } from "../interfaces/accountType.interface"

export const accountTypes: AccountType[] = [
   {
      name: "Cá nhân",
      id: String(process.env.REACT_APP_ACCOUNT_TYPE_ID_USER),
      isShowAdmin: true,
      isShowRegister: true,
   },
   {
      name: "Công ty",
      id: String(process.env.REACT_APP_ACCOUNT_TYPE_ID_COMPANY),
      isShowAdmin: true,
      isShowRegister: true,
   },
]

export const registerAccountTypes: AccountType[] = accountTypes.filter(
   (e) => e.isShowRegister
)

export const adminAccountTypes: AccountType[] = accountTypes.filter(
   (e) => e.isShowAdmin
)
