import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { ManagementUserChargeHistoryComponent } from "./managementUserChargeHistory.component"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"
import { Dayjs } from "dayjs"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementUserChargeHistoryAsync,
} from "../../states/managementUserChargeHistory/managementUserChargeHistory.action"
import {
   selectRequestingManagementUserChargeHistory,
   selectDataManagementUserChargeHistory,
   selectCountManagementUserChargeHistory,
   selectPageNumberManagementUserChargeHistory,
   selectPageSizeManagementUserChargeHistory,
   selectErrorManagementUserChargeHistory,
   selectSearchConditionManagementUserChargeHistory,
} from "../../states/managementUserChargeHistory/managementUserChargeHistory.selector"
import { useParams } from "react-router-dom"

export const ManagementUserChargeHistoryContainer = () => {
   const dispatch = useDispatch()
   let { id } = useParams()
   // Selector
   const requesting = useSelector(selectRequestingManagementUserChargeHistory)
   const walletData = useSelector(selectDataManagementUserChargeHistory)
   const rowCount = useSelector(selectCountManagementUserChargeHistory)
   const pageNumber = useSelector(selectPageNumberManagementUserChargeHistory)
   const pageSize = useSelector(selectPageSizeManagementUserChargeHistory)
   const error = useSelector(selectErrorManagementUserChargeHistory)
   const searchCondition = useSelector(
      selectSearchConditionManagementUserChargeHistory
   )

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param option
    * @param from
    * @param to
    */
   const handleChangeSearchCondition = (
      option: FilterTableWalletEnum,
      from: Dayjs,
      to: Dayjs
   ) => {
      dispatch(changeSearchConditionAsync({ option, from, to }))
   }

   useEffect(() => {
      dispatch(getManagementUserChargeHistoryAsync(id as string))
   }, [id, dispatch])

   return (
      <ManagementUserChargeHistoryComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         walletData={walletData}
         error={error}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
      />
   )
}
