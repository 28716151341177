import React, { useState } from "react"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import Collapse from "@mui/material/Collapse"
import TableCell from "@mui/material/TableCell"
import Box from "@mui/material/Box"
import { DepartmentInterface } from "../../../common/interfaces/department.interface"
import IconButton from "@mui/material/IconButton"
import PeopleIcon from "@mui/icons-material/People"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import Toolbar from "@mui/material/Toolbar"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"
import { Tooltip } from "@material-ui/core"
import { Column } from "../department.table"
import { DialogMethodEnum } from "../../../common/enum/dialog.enum"
import Grid from "@mui/material/Grid"

type DepartmentRoleDetailComponentProps = {
   columns: readonly Column[]
   row: DepartmentInterface
   selectedId: string
   handleOpenDialog: (
      type: DialogMethodEnum,
      selected: DepartmentInterface | null
   ) => void
   handleSelectDepartmentUser: (values: DepartmentInterface) => void
}

export const DepartmentRoleDetailComponent = (
   props: DepartmentRoleDetailComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.department)

   // Row state
   const [open, setOpen] = useState(false)

   const { columns, row } = props

   return (
      <React.Fragment>
         <TableRow
            hover
            selected={props.selectedId === row.id}
            role="checkbox"
            tabIndex={-1}
            key={row.id}
         >
            <TableCell>
               <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
               >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
               </IconButton>
            </TableCell>
            {columns.map((column) => {
               const value = row[column.id]
               return (
                  <TableCell
                     key={column.id}
                     align={column.align}
                     style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                     }}
                  >
                     {value}
                  </TableCell>
               )
            })}
            <TableCell key={"action"} style={{ minWidth: 170 }}>
               <Tooltip title={t("button.updateDepartment")}>
                  <IconButton
                     aria-label="edit"
                     onClick={() =>
                        props.handleOpenDialog(DialogMethodEnum.UPDATE, row)
                     }
                  >
                     <EditIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip title={t("button.listUser")}>
                  <IconButton
                     aria-label="user-list"
                     onClick={() => props.handleSelectDepartmentUser(row)}
                  >
                     <PeopleIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip title={t("button.deleteDepartment")}>
                  <IconButton
                     aria-label="delete"
                     onClick={() =>
                        props.handleOpenDialog(DialogMethodEnum.DELETE, row)
                     }
                  >
                     <DeleteIcon />
                  </IconButton>
               </Tooltip>
            </TableCell>
         </TableRow>
         <TableRow>
            <TableCell
               style={{ paddingBottom: 0, paddingTop: 0 }}
               colSpan={columns.length + 2}
            >
               <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                     sx={{
                        margin: 1,
                        paddingBottom: 4,
                     }}
                  >
                     <Toolbar>
                        <Typography variant="h6" gutterBottom component="div">
                           {t("departmentTable.roleList")}
                        </Typography>
                     </Toolbar>
                     {/* Empty */}
                     {props.row.features.length === 0 && (
                        <Typography variant="body1" textAlign="center">
                           {t("table.empty", { ns: "common" })}
                        </Typography>
                     )}
                     {/* Data */}
                     {props.row.features.length > 0 && (
                        <Box
                           sx={{
                              maxHeight: "500px",
                              overflowY: "auto",
                           }}
                        >
                           <Grid container>
                              {row.features.map((role) => (
                                 <Grid key={role.id} item xs={12} md={6} lg={4}>
                                    <Card
                                       variant="outlined"
                                       sx={{ minHeight: "90px" }}
                                    >
                                       <CardContent>
                                          <Typography ml={3} variant="body1">
                                             {role.name}
                                          </Typography>
                                       </CardContent>
                                    </Card>
                                 </Grid>
                              ))}
                           </Grid>
                        </Box>
                     )}
                  </Box>
               </Collapse>
            </TableCell>
         </TableRow>
      </React.Fragment>
   )
}
