export enum SortColumnEnum {
   USER_FULLNAME = "FULLNAME",
   USER_EMAIL = "EMAIL",
   USER_CREATE_AT = "CREATEAT",
   DEPARTMENT_NAME = "DEPARTMENTNAME",
   DEPARTMENT_CREATE_AT = "DEPARTMENTCREATEAT",
   USER_TYPE_NAME = "USERTYPENAME",
   USER_TYPE_CREATE_AT = "USERTYPECREATEAT",
   RECHARGE_PACKAGE_NAME = "RECHARGEPACKAGENAME",
   RECHARGE_PACKAGE_AMOUNT = "RECHARGEPACKAGEAMOUNT",
   RECHARGE_PACKAGE_COIN = "RECHARGEPACKAGECOIN",
   RECHARGE_PACKAGE_ADDDAY = "RECHARGEPACKAGEADDDAY",
   RECHARGE_PACKAGE_CREATEAT = "RECHARGEPACKAGECREATEAT",
   PROMOTION_PACKAGE_NAME = "PROMOTIONPACKAGENAME",
   PROMOTION_PACKAGE_PERCENT = "PROMOTIONPACKAGECOINPERCENT",
   PROMOTION_PACKAGE_CREATEAT = "PROMOTIONPACKAGECREATEAT",
   EMAIL_TEMPLATE_NAME = "EMAILTEMPLATENAME",
   EMAIL_TEMPLATE_CREATEAT = "EMAILTEMPLATECREATEAT",
   EMAIL_NAME = "MARKETINGNAME",
   EMAIL_CREATEAT = "MARKETINGCREATEAT",
   NONE = "",
}
