import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getManagementWalletAsync,
} from "../../states/managementWallet/managementWallet.action"
import {
   selectCountManagementWallet,
   selectDataManagementWallet,
   selectErrorManagementWallet,
   selectPageNumberManagementWallet,
   selectPageSizeManagementWallet,
   selectRequestingManagementWallet,
   selectSearchConditionManagementWallet,
} from "../../states/managementWallet/managementWallet.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { ManagementWalletComponent } from "./managementWallet.component"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"
import { Dayjs } from "dayjs"

export const ManagementWalletContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   // Selector
   const requesting = useSelector(selectRequestingManagementWallet)
   const walletData = useSelector(selectDataManagementWallet)
   const rowCount = useSelector(selectCountManagementWallet)
   const pageNumber = useSelector(selectPageNumberManagementWallet)
   const pageSize = useSelector(selectPageSizeManagementWallet)
   const error = useSelector(selectErrorManagementWallet)
   const searchCondition = useSelector(selectSearchConditionManagementWallet)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param option
    * @param from
    * @param to
    */
   const handleChangeSearchCondition = (
      option: FilterTableWalletEnum,
      from: Dayjs,
      to: Dayjs
   ) => {
      dispatch(changeSearchConditionAsync({ option, from, to }))
   }

   /**
    * Handle management wallet recharge
    */
   const handleManagementWalletRecharge = () => {
      navigate("/dashboard/managementWallet/recharge")
   }

   useEffect(() => {
      dispatch(getManagementWalletAsync())
   }, [dispatch])

   return (
      <ManagementWalletComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         walletData={walletData}
         error={error}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleManagementWalletRecharge={handleManagementWalletRecharge}
      />
   )
}
