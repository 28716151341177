import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { convertDate } from "../../common/helper/date.helper"
import { DepartmentInterface } from "../../common/interfaces/department.interface"
import { FeatureInterface } from "../../common/interfaces/feature.interface"

export interface Column {
   id: "name" | "totalUser" | "totalAuthority" | "createAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [FilterTableEnum.DEPARTMENT_NAME]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "departmentTable.name",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.DEPARTMENT_NAME,
   },
   {
      id: "totalUser",
      label: "departmentTable.totalUser",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "totalAuthority",
      label: "departmentTable.totalAuthority",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createAt",
      label: "departmentTable.createAt",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.DEPARTMENT_CREATE_AT,
   },
]

export function createData(
   id: string,
   name: string,
   totalUser: number,
   totalAuthority: number,
   createAt: string,
   features: FeatureInterface[]
): DepartmentInterface {
   createAt = convertDate(createAt)
   return { id, name, totalUser, totalAuthority, createAt, features }
}
