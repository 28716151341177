import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   exportExcelChartSignInAsync,
   getChartSignInAsync,
} from "../../states/chartSignIn/chartSignIn.action"
import {
   selectDataChartSignIn,
   selectErrorChartSignIn,
   selectFilterChartSignIn,
   selectIsDisableExport,
   selectIsDisableStatistic,
   selectRequestingChartSignIn,
   selectRequestingExportChartSignIn,
   selectSearchConditionChartSignIn,
} from "../../states/chartSignIn/chartSignIn.selector"
import {
   changeFilter,
   changeSearchCondtion,
} from "../../states/chartSignIn/chartSignIn.slice"
import { ChartSignInComponent } from "./chartSignIn.component"

export const ChartSignInContainer = () => {
   const dispatch = useDispatch()
   const refChart = useRef()
   // Selector chart
   const requestingChart = useSelector(selectRequestingChartSignIn)
   const requestingExport = useSelector(selectRequestingExportChartSignIn)
   const dataChart = useSelector(selectDataChartSignIn)
   const errorChart = useSelector(selectErrorChartSignIn)
   const filterChart = useSelector(selectFilterChartSignIn)
   const searchConditionChart = useSelector(selectSearchConditionChartSignIn)
   const isDisableStatistic = useSelector(selectIsDisableStatistic)
   const isDisableExport = useSelector(selectIsDisableExport)

   /**
    * Handle statistic
    */
   const handleStatistic = () => {
      dispatch(getChartSignInAsync())
   }

   /**
    * Handle change search condition
    * @param data
    */
   const handleChangeSearchCondition = (data: any) => {
      dispatch(changeSearchCondtion(data))
   }

   /**
    * Handle change filter
    * @param data
    */
   const handleChangeFilter = (data: any) => {
      dispatch(changeFilter(data))
   }

   /**
    * Handle chart export
    */
   const handleChartExport = async () => {
      dispatch(exportExcelChartSignInAsync({ refChart: refChart }))
   }

   return (
      <ChartSignInComponent
         refChart={refChart}
         requestingChart={requestingChart}
         requestingExport={requestingExport}
         dataChart={dataChart}
         errorChart={errorChart}
         filterChart={filterChart}
         searchConditionChart={searchConditionChart}
         isDisableStatistic={isDisableStatistic}
         isDisableExport={isDisableExport}
         handleStatistic={handleStatistic}
         handleChangeFilter={handleChangeFilter}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChartExport={handleChartExport}
      />
   )
}
