import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface UserState {
   requesting: boolean
   fullname: string
   email: string
   accountTypeId: string
   isEkyc: boolean
}

const initialState: UserState = {
   requesting: false,
   fullname: "",
   email: "",
   accountTypeId: "",
   isEkyc: false,
}

export const userSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      requestUserInfo: (state: UserState) => {
         state.requesting = true
      },
      addUserInfo: (state: UserState, action: PayloadAction<any>) => {
         state.requesting = false
         state.fullname = action.payload.Fullname
         state.email = action.payload.Email
         state.accountTypeId = action.payload.AccountTypeId
         state.isEkyc = action.payload.IsEkyc
      },
      removeUserInfo: (state: UserState) => {
         state.requesting = false
         state.fullname = ""
         state.email = ""
         state.accountTypeId = ""
         state.isEkyc = false
      },
   },
})

export const { addUserInfo, requestUserInfo, removeUserInfo } =
   userSlice.actions

export default userSlice.reducer
