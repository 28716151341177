import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { AppsState } from "./apps.slice"

const selectAppsState = (state: RootState): AppsState => state.apps

export const selectRequestingApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.requesting
)

export const selectDataApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.data
)

export const selectErrorApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.error
)

export const selectSortConditionApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.sortCondition
)

export const selectCountApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.rowCount
)

export const selectPageNumberApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.paging.pageNumber
)

export const selectPageSizeApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.paging.pageSize
)

export const selectSearchConditionApps = createSelector(
   selectAppsState,
   (state: AppsState) => state.searchCondition
)
