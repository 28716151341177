import styles from "./dashboard.module.css"
import { SidebarContainer } from "./../../common/components/Sidebar/sidebar.container"
import { Outlet } from "react-router-dom"
import { BreadcrumbComponent } from "../../common/components/Breadcrumb/breadcrumb.component"
import { Stack } from "@mui/system"
import { UserMenu } from "../../common/components/UserMenu/userMenu.component"
import Divider from "@mui/material/Divider"
import { useState } from "react"
import clsx from "clsx"
import { AppBarContainer } from "../../common/components/Appbar/appBar.container"

export const DashboardComponent = () => {
   const [open, setOpen] = useState(true)

   return (
      <div className={clsx(styles.container, open ? null : styles.close)}>
         <div className={styles.sidebar}>
            <SidebarContainer
               open={open}
               setOpen={(value: boolean) => setOpen(value)}
            />
         </div>
         <div className={styles.appbar}>
            <AppBarContainer />
         </div>
         <div className={styles.viewboard}>
            <Stack
               direction={"row"}
               justifyContent={"space-between"}
               alignItems={"center"}
            >
               <BreadcrumbComponent></BreadcrumbComponent>
               <UserMenu></UserMenu>
            </Stack>
            <Divider />
            <div className={styles.dashboard}>
               <Outlet></Outlet>
            </div>
         </div>
      </div>
   )
}
