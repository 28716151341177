import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import {
   Box,
   Button,
   CardContent,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton,
   Tooltip,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import TablePagination from "@mui/material/TablePagination"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import { FilterTableComponent } from "../../../common/components/FilterTable/filterTable.component"
import commonStyles from "../../../common/styles/common.module.css"
import { CreateEmailUserInterface } from "../../../common/interfaces/createEmailUser.interface"
import CircularProgress from "@mui/material/CircularProgress"
import { columns, createData, filterTypes } from "./createEmailUser.table"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import { useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"

type CreateEmailUserComponentProps = {
   disabled: boolean
   selected: string[]
   setSelected: (ids: string[]) => void
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   userData: any[]
   error: string
   searchCondition: SearchConditionState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleGetUserAsync: (userIds: string[]) => Promise<any>
}

export const CreateEmailUserComponent = (
   props: CreateEmailUserComponentProps
) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
   const { t } = useTranslation(namespaces.pages.createEmail)

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [selectedUser, setSelectedUser] = useState<any[]>([])
   const [selectedUserError, setSelectedUserError] = useState<string>("")

   // User list
   const rows = props.userData.map((user) =>
      createData(
         user.id,
         user.fullname,
         user.email,
         user.phoneNumber,
         user.province,
         user.createAt
      )
   )

   // Selected user list
   const selectedRows = selectedUser.map((user) =>
      createData(
         user.id,
         user.fullname,
         user.email,
         user.phoneNumber,
         user.province,
         user.createAt
      )
   )

   const isSelected = (user: CreateEmailUserInterface) =>
      props.selected.findIndex((x) => x === user.id) !== -1

   /**
    * Handle click check box
    * @param event
    * @param name
    */
   const handleClickCheckbox = (
      event: React.MouseEvent<unknown> | undefined,
      user: CreateEmailUserInterface
   ) => {
      const selectedIndex = props.selected.findIndex((x) => x === user.id)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(props.selected, user.id)
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(props.selected.slice(1))
      } else if (selectedIndex === props.selected.length - 1) {
         newSelected = newSelected.concat(props.selected.slice(0, -1))
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            props.selected.slice(0, selectedIndex),
            props.selected.slice(selectedIndex + 1)
         )
      }
      props.setSelected(newSelected)

      const newSelectedUser = selectedUser.filter((x) => x.id !== user.id)
      setSelectedUser(newSelectedUser)
   }

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = () => {
      if (props.disabled) {
         return
      }
      if (props.selected.length === 0) {
         return
      }
      setOpenDialog(true)
      setRequestingDialog(true)
      props
         .handleGetUserAsync(props.selected)
         .then((res) => {
            setSelectedUser(res.response.data)
            setRequestingDialog(false)
         })
         .catch((err) => {
            setRequestingDialog(false)
            setSelectedUserError("Lấy thông tin danh sách người dùng thất bại!")
         })
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return <DialogTitle>{t("dialog.title.userlist")}</DialogTitle>
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            <Button
               color="info"
               disabled={requestingDialog}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      return (
         <DialogContent>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell key={column.id} align={column.align}>
                              {t(column.label)}
                           </TableCell>
                        ))}
                        <TableCell key={"action"}>
                           {t("createEmailUserTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {requestingDialog && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!requestingDialog && selectedUserError && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(selectedUserError, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!requestingDialog &&
                        !selectedUserError &&
                        selectedRows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", {
                                       ns: "common",
                                    })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!requestingDialog &&
                        !selectedUserError &&
                        selectedRows.map((row, index) => (
                           <TableRow hover tabIndex={-1} key={row.id}>
                              {columns.map((column) => {
                                 let value =
                                    column.id === "province"
                                       ? row[column.id].name
                                       : row[column.id]
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {value}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 <Tooltip title={t("button.delete")}>
                                    <IconButton
                                       aria-label="delete"
                                       onClick={() =>
                                          handleClickCheckbox(undefined, row)
                                       }
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                 </Tooltip>
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </DialogContent>
      )
   }

   return (
      <Card
         sx={{
            marginTop: "15px",
         }}
      >
         <CardHeader
            avatar={<SupervisorAccountIcon></SupervisorAccountIcon>}
            title={<Typography variant="body1">{t("card.user")}</Typography>}
         ></CardHeader>
         <CardContent>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={12}>
                     <FilterTableComponent
                        types={filterTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                     <Typography
                        sx={{
                           flex: "1 1 100%",
                           fontWeight: "bold",
                           textDecoration: "underline",
                           cursor: "pointer",
                        }}
                        color="inhreit"
                        variant="subtitle1"
                        component="div"
                        onClick={() => handleOpenDialog()}
                     >
                        {props.selected.length} người dùng được chọn (Click để
                        xem chi tiết)
                     </Typography>
                  </Grid>
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        {columns.map((column) => (
                           <TableCell key={column.id} align={column.align}>
                              {t(column.label)}
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row, index) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              <TableCell padding="checkbox">
                                 <Checkbox
                                    disabled={props.disabled}
                                    onClick={(event) =>
                                       handleClickCheckbox(event, row)
                                    }
                                    color="primary"
                                    checked={isSelected(row)}
                                    inputProps={{
                                       "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                    }}
                                 />
                              </TableCell>
                              {columns.map((column) => {
                                 let value =
                                    column.id === "province"
                                       ? row[column.id].name
                                       : row[column.id]
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {value}
                                    </TableCell>
                                 )
                              })}
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
            />
         </CardContent>
         <Dialog
            fullScreen={fullScreen}
            maxWidth={"lg"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box sx={{ width: "100%" }}>
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Card>
   )
}
