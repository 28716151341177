import {
   selectPageNumberEmail,
   selectPageSizeEmail,
   selectSearchConditionEmail,
   selectSortConditionEmail,
} from "./email.selector"
import { getEmailAPI, GetEmailPayloadAPIInterface } from "../../api/email.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getEmailAsync,
} from "./email.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getEmailFail,
   getEmailSuccess,
   requestEmail,
} from "./email.slice"

/**
 * Get email saga
 * @param action
 */
function* getEmailSaga(): any {
   try {
      yield put(requestEmail())
      const pageNumber = yield select(selectPageNumberEmail)
      const pageSize = yield select(selectPageSizeEmail)
      const searchCondition = yield select(selectSearchConditionEmail)
      const sortCondition = yield select(selectSortConditionEmail)
      const params: GetEmailPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getEmailAPI, params)
      if (!response) {
         throw error
      }
      yield put(getEmailSuccess(response))
   } catch (error: any) {
      yield put(getEmailFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getEmailAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getEmailAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getEmailAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getEmailAsync())
   } catch (error) {}
}

/**
 * Root email saga
 */
export function* emailSaga() {
   yield takeLatest(getEmailAsync, getEmailSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
