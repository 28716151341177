import { SettingEkycInput, settingEkycSchema } from "./settingEkyc.form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Typography,
} from "@mui/material"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import LinearProgress from "@mui/material/LinearProgress"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"

type SettingEkycComponentProps = {
   isEkyc: boolean
   handleGetEkycAsync: () => Promise<any>
}

export const SettingEkycComponent = (props: SettingEkycComponentProps) => {
   const { t } = useTranslation(namespaces.pages.setting)
   // State
   const [isRequesting, setIsRequesting] = useState<boolean>(false)
   const [error, setError] = useState<string>("")

   // Form
   const {
      register,
      formState: { errors },
      clearErrors,
      setValue,
   } = useForm<SettingEkycInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(settingEkycSchema),
   })

   /**
    * On change accordion
    */
   const onChangeAccordion = (
      event: SyntheticEvent<Element, Event>,
      expanded: boolean
   ) => {
      if (expanded) {
         clearErrors()
         handleGetEkycAsync()
      }
   }

   /**
    * Handle get ekyc async
    */
   const handleGetEkycAsync = useCallback(() => {
      setIsRequesting(true)
      props
         .handleGetEkycAsync()
         .then((res) => {
            setValue("name", res.data.Name)
            setValue("identityCard", res.data.IdentityCard)
            setValue("birthday", res.data.Birthday)
            setValue("address", res.data.Address)
            setValue("addressHome", res.data.AddressHome)
            setValue("ethnic", res.data.Ethnic)
            setValue("religion", res.data.Eeligion)
            setValue("issuedDAte", res.data.IssuedDAte)
            setValue("issuedBy", res.data.IssuedBy)
            setValue("similar", res.data.Similar)
            setIsRequesting(false)
         })
         .catch((err) => {
            setError("Xảy ra lỗi khi lấy thông tin.")
            setIsRequesting(false)
         })
   }, [props, setValue])

   useEffect(() => {
      handleGetEkycAsync()
   }, [props, setValue, handleGetEkycAsync])

   return (
      <Accordion defaultExpanded={false} onChange={onChangeAccordion}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
         >
            <VerifiedUserIcon color={props.isEkyc ? "success" : "error"} />
            <Typography ml={2}>{t("ekyc.accordion")}</Typography>
            <Typography
               ml={2}
               sx={{
                  color: props.isEkyc ? "#2e7d32" : "#d32f2f",
                  fontStyle: "italic",
               }}
            >
               {props.isEkyc ? t("ekyc.verify") : t("ekyc.notVerify")}
            </Typography>
         </AccordionSummary>
         <AccordionDetails>
            {/* Loading */}
            {isRequesting && <LinearProgress color="secondary" />}
            {/* Error */}
            {!isRequesting && error !== "" && (
               <Typography variant="body1" color="error">
                  {error}
               </Typography>
            )}
            {/* Data */}
            {!isRequesting && error === "" && (
               <Box
                  component="form"
                  autoComplete="on"
                  noValidate
                  sx={{ width: "100%" }}
               >
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-name"
                        label={t("ekyc.name")}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("name")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-identityCard"
                        label={t("ekyc.identityCard")}
                        error={!!errors.identityCard}
                        helperText={
                           errors.identityCard
                              ? errors.identityCard.message
                              : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("identityCard")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-birthday"
                        label={t("ekyc.birthday")}
                        error={!!errors.birthday}
                        helperText={
                           errors.birthday ? errors.birthday.message : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("birthday")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-address"
                        label={t("ekyc.address")}
                        error={!!errors.address}
                        helperText={
                           errors.address ? errors.address.message : ""
                        }
                        disabled={isRequesting}
                        {...register("address")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-addressHome"
                        label={t("ekyc.addressHome")}
                        error={!!errors.addressHome}
                        helperText={
                           errors.addressHome ? errors.addressHome.message : ""
                        }
                        disabled={isRequesting}
                        {...register("addressHome")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-ethnic"
                        label={t("ekyc.ethnic")}
                        error={!!errors.ethnic}
                        helperText={errors.ethnic ? errors.ethnic.message : ""}
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("ethnic")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-religion"
                        label={t("ekyc.religion")}
                        error={!!errors.religion}
                        helperText={
                           errors.religion ? errors.religion.message : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("religion")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-issuedDAte"
                        label={t("ekyc.issuedDAte")}
                        error={!!errors.issuedDAte}
                        helperText={
                           errors.issuedDAte ? errors.issuedDAte.message : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("issuedDAte")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-issuedBy"
                        label={t("ekyc.issuedBy")}
                        error={!!errors.issuedBy}
                        helperText={
                           errors.issuedBy ? errors.issuedBy.message : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("issuedBy")}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        id="outlined-adornment-similar"
                        label={t("ekyc.similar")}
                        error={!!errors.similar}
                        helperText={
                           errors.similar ? errors.similar.message : ""
                        }
                        disabled={isRequesting}
                        InputProps={{
                           readOnly: true,
                        }}
                        {...register("similar")}
                     />
                  </FormControl>
               </Box>
            )}
         </AccordionDetails>
      </Accordion>
   )
}
