import { DepartmentState } from "./department.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectDepartmentState = (state: RootState): DepartmentState =>
   state.department

export const selectRequestingDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.requesting
)

export const selectDataDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.data
)

export const selectErrorDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.error
)

export const selectCountDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.rowCount
)

export const selectPageNumberDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.paging.pageNumber
)

export const selectPageSizeDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.paging.pageSize
)

export const selectSearchConditionDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.searchCondition
)

export const selectSortConditionDepartment = createSelector(
   selectDepartmentState,
   (state: DepartmentState) => state.sortCondition
)
