import { ChartEnum } from "./../../common/enum/chart.enum"
import { call, select } from "redux-saga/effects"
import { put, takeLatest } from "redux-saga/effects"
import {
   finishExportExcelChartSignIn,
   getChartSignInFail,
   getChartSignInSuccess,
   requestChartSignIn,
   startExportExcelChartSignIn,
} from "./chartSignIn.slice"
import {
   exportChartSignInByAppAPI,
   ExportChartSignInByAppPayloadAPIInterface,
   exportChartSignInByDateAPI,
   ExportChartSignInByDatePayloadAPIInterface,
   exportChartSignInByProvinceAPI,
   ExportChartSignInByProvincePayloadAPIInterface,
   getChartSignInByAppAPI,
   GetChartSignInByAppPayloadAPIInterface,
   getChartSignInByDateAPI,
   GetChartSignInByDatePayloadAPIInterface,
   getChartSignInByProvinceAPI,
   GetChartSignInByProvincePayloadAPIInterface,
} from "../../api/chartSignIn.api"
import {
   exportExcelChartSignInAsync,
   getChartSignInAsync,
} from "./chartSignIn.action"
import { selectSearchConditionChartSignIn } from "./chartSignIn.selector"
import { getParamMode } from "../../common/helper/chart.helper"
import { PayloadAction } from "@reduxjs/toolkit"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   ColumnsExportExcel,
   ConditionsExportExcel,
} from "../../common/interfaces/exportExcel.interface"
import {
   exportExcel,
   getDateHeadline,
} from "../../common/helper/exportExcel.helper"
import {
   convertDate,
   getFormatDateExportExcel,
} from "../../common/helper/date.helper"
import { i18n } from "../../i18n/i18n"
import { filterChartConst } from "../../common/variables/filterChart"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Get chart sign in saga
 * @param action
 */
function* getChartSignInSaga(): any {
   try {
      yield put(requestChartSignIn())
      const searchCondition = yield select(selectSearchConditionChartSignIn)
      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: GetChartSignInByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(getChartSignInByDateAPI, params)
         if (!response) {
            throw error
         }
         yield put(getChartSignInSuccess(response.data.data))
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: GetChartSignInByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(getChartSignInByAppAPI, params)
         if (!response) {
            throw error
         }
         yield put(getChartSignInSuccess(response.data.data))
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: GetChartSignInByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartSignInByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartSignInSuccess(response.data.data))
      }
   } catch (error: any) {
      yield put(getChartSignInFail("error.chart"))
   }
}

/**
 * Export excel chart sign in saga
 * @param action
 */
function* exportExcelChartSignInSaga(action: PayloadAction<any>): any {
   try {
      yield put(startExportExcelChartSignIn())
      const searchCondition = yield select(selectSearchConditionChartSignIn)

      // Excel config
      const titleString =
         " " +
         i18n.t("filter.chartType", { ns: namespaces.common }) +
         " " +
         i18n.t(
            filterChartConst.filter((e) => e.id === searchCondition.type)[0]
               .text,
            { ns: namespaces.common }
         )
      const workBookName =
         i18n.t("workBookName", {
            ns: namespaces.pages.chart,
         }) +
         "_SIGNIN_" +
         searchCondition.type
      const titleSheetData =
         i18n.t("titleSheetData.signIn", {
            ns: namespaces.pages.chart,
         }) + titleString

      const titleSheetChart =
         i18n.t("titleSheetChart.signIn", {
            ns: namespaces.pages.chart,
         }) + titleString
      const conditions: ConditionsExportExcel[] = [
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.fromDate
            ),
         },
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.toDate
            ),
         },
      ]

      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: ExportChartSignInByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            exportChartSignInByDateAPI,
            params
         )
         if (!response) {
            throw error
         }

         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", {
                  ns: namespaces.pages.chart,
               }),
               key: "email",
            },
            {
               header: i18n.t("table.note", { ns: namespaces.pages.chart }),
               key: "note",
            },
            {
               header: i18n.t("table.loginAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "loginAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: ExportChartSignInByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartSignInByAppAPI,
            params
         )
         if (!response) {
            throw error
         }

         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", {
                  ns: namespaces.pages.chart,
               }),
               key: "email",
            },
            {
               header: i18n.t("table.note", { ns: namespaces.pages.chart }),
               key: "note",
            },
            {
               header: i18n.t("table.applicationName", {
                  ns: namespaces.pages.chart,
               }),
               key: "applicationName",
            },
            {
               header: i18n.t("table.loginAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "loginAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: ExportChartSignInByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartSignInByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }

         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", {
                  ns: namespaces.pages.chart,
               }),
               key: "email",
            },
            {
               header: i18n.t("table.note", { ns: namespaces.pages.chart }),
               key: "note",
            },
            {
               header: i18n.t("table.province", { ns: namespaces.pages.chart }),
               key: "provinceName",
            },
            {
               header: i18n.t("table.loginAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "loginTime",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum = "Tổng người dùng: " + data.length

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportSuccess", {
               ns: namespaces.pages.chart,
            }),
            variant: "success",
         })
      )

      yield put(finishExportExcelChartSignIn())
   } catch (error) {
      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportFail", {
               ns: namespaces.pages.chart,
            }),
            variant: "error",
         })
      )
      yield put(finishExportExcelChartSignIn())
   }
}

/**
 * Root chart sign in saga
 */
export function* chartSignInSaga() {
   yield takeLatest(getChartSignInAsync, getChartSignInSaga)
   yield takeLatest(exportExcelChartSignInAsync, exportExcelChartSignInSaga)
}
