import { useDispatch, useSelector } from "react-redux"
import {
   resetPasswordAPI,
   ResetPasswordAPIInterface,
} from "../../../api/setting.api"
import { selectAuthUserId } from "../../../states/auth/auth.selector"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { SettingPasswordComponent } from "./settingPassword.component"
import { SettingPasswordInput } from "./settingPassword.form"

export const SettingPasswordContainer = () => {
   const dispatch = useDispatch()
   const authUserId = useSelector(selectAuthUserId)

   /**
    * Handle update Password async
    * @returns
    */
   const handleResetPasswordAsync = (values: SettingPasswordInput) => {
      const params: ResetPasswordAPIInterface = {
         currentPassword: values.password,
         newPassword: values.newPassword,
      }
      return resetPasswordAPI(authUserId as string, params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   return (
      <SettingPasswordComponent
         handlePushNotistack={handlePushNotistack}
         handleResetPasswordAsync={handleResetPasswordAsync}
      />
   )
}
