import { UserState } from "./user.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { accountTypes } from "../../common/variables/accountType"

const selectUserState = (state: RootState): UserState => state.user

export const selectUserInfo = createSelector(
   selectUserState,
   (userState: UserState) => userState
)

export const selectIsCompanyAccountTypeUserInfo = createSelector(
   selectUserState,
   (userState: UserState) =>
      userState.accountTypeId ===
      accountTypes.filter((e) => e.name === "Công ty")[0].id
)

export const selectIsEkycUserInfo = createSelector(
   selectUserState,
   (userState: UserState) => userState.isEkyc
)
