import commonStyles from "../../../common/styles/common.module.css"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { useState } from "react"
import TablePagination from "@mui/material/TablePagination"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import DeleteIcon from "@mui/icons-material/Delete"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import DialogContent from "@mui/material/DialogContent"
import AddIcon from "@mui/icons-material/Add"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import { DepartmentUserInterface } from "../../../common/interfaces/department.interface"
import { FilterTableComponent } from "../../../common/components/FilterTable/filterTable.component"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"
import React from "react"
import { columns, createData, filterTypes } from "./departmentUser.table"
import { DepartmentUserAddingContainer } from "./departmentUserAdding/departmentUserAdding.container"
import { DialogMethodEnum } from "../../../common/enum/dialog.enum"
import { DeparmentUserAddingInterface } from "../../../common/interfaces/departmentUserAdding.interface"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import { SearchConditionState } from "../../../common/interfaces/searchConditionParam"
import { TablePaginationActionsComponent } from "../../../common/components/TablePaginationActions/tablePaginationActions.component"

type DepartmentUserComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   error: string
   userData: any[]
   selectId: string
   searchCondition: SearchConditionState
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleAddDepartmentUserSync: (
      departmentId: string,
      values: DeparmentUserAddingInterface[]
   ) => Promise<any>
   handleDeleteDepartmentUserAsync: (id: string) => Promise<any>
   handleReloadGetDeparmentsUserAsync: () => void
   handleReloadGetDeparmentsAsync: () => void
}

export const DepartmentUserComponent = (
   props: DepartmentUserComponentProps
) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const { t } = useTranslation(namespaces.pages.department)

   // User list
   const rows = props.userData.map((user) =>
      createData(
         user.id,
         user.fullname,
         user.email,
         user.phoneNumber,
         user.province
      )
   )

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.ADD
   )
   const [selectedDialog, setSelectedDialog] =
      useState<DepartmentUserInterface | null>(null)

   // Add user to department state
   const [addUsers, setAddUsers] = useState<DeparmentUserAddingInterface[]>([])

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: DepartmentUserInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      setAddUsers([])
   }

   /**
    * Handle add user to department
    * @param user
    */
   const handleAddUserToDepartment = (user: DeparmentUserAddingInterface) => {
      setAddUsers([...addUsers, user])
   }

   /**
    * Handle remove user to department
    * @param user
    */
   const handleRemoveUserToDepartment = (
      user: DeparmentUserAddingInterface
   ) => {
      setAddUsers(addUsers.filter((e) => e.id !== user.id))
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("button.addUser")}
            {typeDialog === DialogMethodEnum.DELETE && t("button.deleteUser")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button
                  type="submit"
                  disabled={requestingDialog}
                  onClick={handleSubmitAddUsers}
               >
                  {t("button.add")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeleteUserDepartment}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requestingDialog}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteUserConfirm", {
                     item: selectedDialog?.fullname,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      return (
         <DialogContent>
            <DepartmentUserAddingContainer
               addUsers={addUsers}
               handleAddUserToDepartment={handleAddUserToDepartment}
               handleRemoveUserToDepartment={handleRemoveUserToDepartment}
            />
         </DialogContent>
      )
   }

   /**
    * Handle submit add user
    */
   const handleSubmitAddUsers = () => {
      if (props.selectId !== "") {
         setRequestingDialog(true)
         props
            .handleAddDepartmentUserSync(props.selectId, addUsers)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Thêm Người dùng thành công!",
                  "success"
               )
               handleCloseDialog()
               props.handleReloadGetDeparmentsUserAsync()
               props.handleReloadGetDeparmentsAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               if (err.response.data.status === 500) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack(
                     "Thêm Người dùng thất bại!",
                     "error"
                  )
               }
            })
      }
   }

   /**
    * On delete user in department
    */
   const onDeleteUserDepartment = () => {
      setRequestingDialog(true)
      props
         .handleDeleteDepartmentUserAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa Người dùng thành công!", "success")
            handleCloseDialog()
            props.handleReloadGetDeparmentsUserAsync()
            props.handleReloadGetDeparmentsAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa Người dùng thất bại!", "error")
         })
   }

   return (
      <React.Fragment>
         <Divider className={commonStyles.dividerMargin}>
            <Chip color="secondary" label={t("divider.departmentUser")} />
         </Divider>
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        types={filterTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={12} xl={4}>
                           <Button
                              fullWidth
                              color="secondary"
                              variant="contained"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                 handleOpenDialog(DialogMethodEnum.ADD)
                              }
                           >
                              {t("button.addUser")}
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                           >
                              {t(column.label)}
                           </TableCell>
                        ))}
                        <TableCell key={"action"} style={{ minWidth: 170 }}>
                           {t("userTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => {
                           return (
                              <TableRow
                                 hover
                                 role="checkbox"
                                 tabIndex={-1}
                                 key={row.id}
                              >
                                 {columns.map((column) => {
                                    const value = row[column.id]

                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                       >
                                          {value}
                                       </TableCell>
                                    )
                                 })}
                                 <TableCell
                                    key={"action"}
                                    style={{ minWidth: 170 }}
                                 >
                                    <IconButton
                                       aria-label="delete"
                                       onClick={() =>
                                          handleOpenDialog(
                                             DialogMethodEnum.DELETE,
                                             row
                                          )
                                       }
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           )
                        })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={typeDialog === DialogMethodEnum.ADD}
            maxWidth={typeDialog === DialogMethodEnum.ADD ? "xl" : "sm"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box sx={{ width: "100%" }}>
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </React.Fragment>
   )
}
