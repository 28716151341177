import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { CreateEmailTemplateState } from "./createEmailTemplate.slice"

const selectCreateEmailTemplateState = (
   state: RootState
): CreateEmailTemplateState => state.createEmailTemplate

export const selectRequestingCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.requesting
)

export const selectDataCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.data
)

export const selectErrorCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.error
)

export const selectCountCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.rowCount
)

export const selectPageNumberCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.paging.pageNumber
)

export const selectPageSizeCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.paging.pageSize
)

export const selectSearchConditionCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.searchCondition
)

export const selectSortConditionCreateEmailTemplate = createSelector(
   selectCreateEmailTemplateState,
   (state: CreateEmailTemplateState) => state.sortCondition
)
