import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { convertCurrency } from "../../common/helper/currency.helper"
import { convertDate } from "../../common/helper/date.helper"
import { RechargePackInterface } from "../../common/interfaces/rechargePack.interface"
import { TypeUserSelectInterface } from "../../common/interfaces/typeUser.interface"

export interface Column {
   id: "name" | "amount" | "coin" | "addDay" | "createAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: any) => any
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.RECHARGE_PACKAGE_NAME,
]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "rechargePackTable.name",
      sortKey: SortColumnEnum.RECHARGE_PACKAGE_NAME,
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "amount",
      label: "rechargePackTable.amount",
      sortKey: SortColumnEnum.RECHARGE_PACKAGE_AMOUNT,
      minWidth: 170,
      maxWidth: 400,
      format: (value: number) => {
         return convertCurrency(value)
      },
   },
   {
      id: "coin",
      label: "rechargePackTable.coin",
      sortKey: SortColumnEnum.RECHARGE_PACKAGE_COIN,
      minWidth: 170,
      maxWidth: 400,
      format: (value: number) => {
         return convertCurrency(value)
      },
   },
   {
      id: "addDay",
      label: "rechargePackTable.addDay",
      sortKey: SortColumnEnum.RECHARGE_PACKAGE_ADDDAY,
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createAt",
      label: "rechargePackTable.createAt",
      sortKey: SortColumnEnum.RECHARGE_PACKAGE_CREATEAT,
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   name: string,
   amount: number,
   coin: number,
   addDay: number,
   createAt: string,
   userTypeConditions: TypeUserSelectInterface[]
): RechargePackInterface {
   createAt = convertDate(createAt)
   return { id, name, amount, coin, addDay, createAt, userTypeConditions }
}
