import { createAction } from "@reduxjs/toolkit"

export const getManagementWalletAsync = createAction(
   "managementWallet/getManagementWalletAsync"
)

export const changePageNumberAsync = createAction<number>(
   "managementWallet/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "managementWallet/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "managementWallet/changeSearchConditionAsync"
)
