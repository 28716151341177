import { ChartEnum } from "../../common/enum/chart.enum"
import { call, select } from "redux-saga/effects"
import { put, takeLatest } from "redux-saga/effects"
import {
   finishExportExcelChartUsingWallet,
   getChartUsingWalletFail,
   getChartUsingWalletSuccess,
   requestChartUsingWallet,
   startExportExcelChartUsingWallet,
} from "./chartUsingWallet.slice"
import {
   exportChartUsingWalletByAppAPI,
   ExportChartUsingWalletByAppPayloadAPIInterface,
   exportChartUsingWalletByDateAPI,
   ExportChartUsingWalletByDatePayloadAPIInterface,
   exportChartUsingWalletByProvinceAPI,
   ExportChartUsingWalletByProvincePayloadAPIInterface,
   getChartUsingWalletByAppAPI,
   GetChartUsingWalletByAppPayloadAPIInterface,
   getChartUsingWalletByDateAPI,
   GetChartUsingWalletByDatePayloadAPIInterface,
   getChartUsingWalletByProvinceAPI,
   GetChartUsingWalletByProvincePayloadAPIInterface,
} from "../../api/chartUsingWallet.api"
import {
   exportExcelChartUsingWalletAsync,
   getChartUsingWalletAsync,
} from "./chartUsingWallet.action"
import { selectSearchConditionChartUsingWallet } from "./chartUsingWallet.selector"
import { getParamMode } from "../../common/helper/chart.helper"
import { PayloadAction } from "@reduxjs/toolkit"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   ColumnsExportExcel,
   ConditionsExportExcel,
} from "../../common/interfaces/exportExcel.interface"
import {
   exportExcel,
   getDateHeadline,
} from "../../common/helper/exportExcel.helper"
import {
   convertDate,
   getFormatDateExportExcel,
} from "../../common/helper/date.helper"
import { i18n } from "../../i18n/i18n"
import { filterChartConst } from "../../common/variables/filterChart"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Get chart sign in saga
 * @param action
 */
function* getChartUsingWalletSaga(): any {
   try {
      yield put(requestChartUsingWallet())
      const searchCondition = yield select(
         selectSearchConditionChartUsingWallet
      )
      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: GetChartUsingWalletByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            getChartUsingWalletByDateAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartUsingWalletSuccess(response.data.data))
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: GetChartUsingWalletByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartUsingWalletByAppAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartUsingWalletSuccess(response.data.data))
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: GetChartUsingWalletByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartUsingWalletByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartUsingWalletSuccess(response.data.data))
      }
   } catch (error: any) {
      yield put(getChartUsingWalletFail("error.chart"))
   }
}

/**
 * Export excel chart sign in saga
 * @param action
 */
function* exportExcelChartUsingWalletSaga(action: PayloadAction<any>): any {
   try {
      yield put(startExportExcelChartUsingWallet())
      const searchCondition = yield select(
         selectSearchConditionChartUsingWallet
      )

      // Excel config
      const titleString =
         " " +
         i18n.t("filter.chartType", { ns: namespaces.common }) +
         " " +
         i18n.t(
            filterChartConst.filter((e) => e.id === searchCondition.type)[0]
               .text,
            { ns: namespaces.common }
         )
      const workBookName =
         i18n.t("workBookName", {
            ns: namespaces.pages.chart,
         }) +
         "_CHECKOUT_" +
         searchCondition.type
      const titleSheetData =
         i18n.t("titleSheetData.usingWallet", {
            ns: namespaces.pages.chart,
         }) + titleString
      const titleSheetChart =
         i18n.t("titleSheetChart.usingWallet", {
            ns: namespaces.pages.chart,
         }) + titleString

      const conditions: ConditionsExportExcel[] = [
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.fromDate
            ),
         },
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.toDate
            ),
         },
      ]

      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: ExportChartUsingWalletByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            exportChartUsingWalletByDateAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.usedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin sử dụng: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: ExportChartUsingWalletByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartUsingWalletByAppAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.applicationName", {
                  ns: namespaces.pages.chart,
               }),
               key: "applicationName",
            },
            {
               header: i18n.t("table.usedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin sử dụng: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: ExportChartUsingWalletByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartUsingWalletByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.province", { ns: namespaces.pages.chart }),
               key: "provinceName",
            },
            {
               header: i18n.t("table.usedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin sử dụng: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportSuccess", {
               ns: namespaces.pages.chart,
            }),
            variant: "success",
         })
      )

      yield put(finishExportExcelChartUsingWallet())
   } catch (error) {
      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportFail", {
               ns: namespaces.pages.chart,
            }),
            variant: "error",
         })
      )
      yield put(finishExportExcelChartUsingWallet())
   }
}

/**
 * Root chart sign in saga
 */
export function* chartUsingWalletSaga() {
   yield takeLatest(getChartUsingWalletAsync, getChartUsingWalletSaga)
   yield takeLatest(
      exportExcelChartUsingWalletAsync,
      exportExcelChartUsingWalletSaga
   )
}
