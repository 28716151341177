import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface NotistackState {
   message: string | null
   variant: string | null
   time: string | null
}

const initialState: NotistackState = {
   message: null,
   variant: null,
   time: null,
}

export const notistackSlice = createSlice({
   name: "notistack",
   initialState,
   reducers: {
      pushNotistack: (state: NotistackState, action: PayloadAction<any>) => {
         state.message = action.payload.message
         state.variant = action.payload.variant
         state.time = new Date().toString()
      },
      clearNotistack: (state: NotistackState) => {
         state.message = null
         state.variant = null
         state.time = null
      },
   },
})

export const { pushNotistack, clearNotistack } = notistackSlice.actions

export default notistackSlice.reducer
