import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   exportExcelChartChargeWalletAsync,
   getChartChargeWalletAsync,
} from "../../states/chartChargeWallet/chartChargeWallet.action"
import {
   selectDataChartChargeWallet,
   selectErrorChartChargeWallet,
   selectFilterChartChargeWallet,
   selectIsDisableExport,
   selectIsDisableStatistic,
   selectRequestingChartChargeWallet,
   selectRequestingExportChartChargeWallet,
   selectSearchConditionChartChargeWallet,
} from "../../states/chartChargeWallet/chartChargeWallet.selector"
import {
   changeFilter,
   changeSearchCondtion,
} from "../../states/chartChargeWallet/chartChargeWallet.slice"
import { ChartChargeWalletComponent } from "./chartChargeWallet.component"

export const ChartChargeWalletContainer = () => {
   const dispatch = useDispatch()
   const refChart = useRef()
   // Selector chart
   const requestingChart = useSelector(selectRequestingChartChargeWallet)
   const requestingExport = useSelector(selectRequestingExportChartChargeWallet)
   const dataChart = useSelector(selectDataChartChargeWallet)
   const errorChart = useSelector(selectErrorChartChargeWallet)
   const filterChart = useSelector(selectFilterChartChargeWallet)
   const searchConditionChart = useSelector(
      selectSearchConditionChartChargeWallet
   )
   const isDisableStatistic = useSelector(selectIsDisableStatistic)
   const isDisableExport = useSelector(selectIsDisableExport)

   /**
    * Handle statistic
    */
   const handleStatistic = () => {
      dispatch(getChartChargeWalletAsync())
   }

   /**
    * Handle change search condition
    * @param data
    */
   const handleChangeSearchCondition = (data: any) => {
      dispatch(changeSearchCondtion(data))
   }

   /**
    * Handle change filter
    * @param data
    */
   const handleChangeFilter = (data: any) => {
      dispatch(changeFilter(data))
   }

   /**
    * Handle chart export
    */
   const handleChartExport = async () => {
      dispatch(exportExcelChartChargeWalletAsync({ refChart: refChart }))
   }

   return (
      <ChartChargeWalletComponent
         refChart={refChart}
         requestingChart={requestingChart}
         requestingExport={requestingExport}
         dataChart={dataChart}
         errorChart={errorChart}
         filterChart={filterChart}
         searchConditionChart={searchConditionChart}
         isDisableStatistic={isDisableStatistic}
         isDisableExport={isDisableExport}
         handleStatistic={handleStatistic}
         handleChangeFilter={handleChangeFilter}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChartExport={handleChartExport}
      />
   )
}
