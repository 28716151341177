import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { UserStatus } from "../../common/enum/userStatus.enum"
import { convertDate } from "../../common/helper/date.helper"
import { getLabelGender } from "../../common/helper/gender.helper"
import { FilterMultipleTableInterface } from "../../common/interfaces/filterMultipleTable.interface"
import { ManagementUserInterface } from "../../common/interfaces/managementUser.interface"
import { ProvinceInterface } from "../../common/interfaces/province.interface"
import { i18n } from "../../i18n/i18n"
import { namespaces } from "../../i18n/i18n.constants"

export interface Column {
   id:
      | "stt"
      | "fullname"
      | "email"
      | "phoneNumber"
      | "province"
      | "cmnd"
      | "gender"
      | "createAt"
      | "expiredDate"
      | "note"
      | "accountTypeName"
      | "status"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: any) => any
}

export const filterMultipleTypes: FilterMultipleTableInterface[] = [
   {
      type: FilterTableEnum.FULLNAME,
      text: i18n.t("userTable.name", { ns: namespaces.pages.managementUser }),
   },
   {
      type: FilterTableEnum.EMAIL,
      text: i18n.t("userTable.email", { ns: namespaces.pages.managementUser }),
   },
   {
      type: FilterTableEnum.PHONE_NUMBER,
      text: i18n.t("userTable.phoneNumber", {
         ns: namespaces.pages.managementUser,
      }),
   },
   {
      type: FilterTableEnum.PROVINCE,
      text: i18n.t("userTable.province", {
         ns: namespaces.pages.managementUser,
      }),
   },
   {
      type: FilterTableEnum.COIN,
      text: i18n.t("userTable.coinRange", {
         ns: namespaces.pages.managementUser,
      }),
   },
   {
      type: FilterTableEnum.CREATE_AT,
      text: i18n.t("userTable.createAt", {
         ns: namespaces.pages.managementUser,
      }),
   },
   {
      type: FilterTableEnum.USAGE,
      text: i18n.t("userTable.usageTimeRange", {
         ns: namespaces.pages.managementUser,
      }),
   },
   {
      type: FilterTableEnum.USER_STATUS,
      text: i18n.t("userTable.status", {
         ns: namespaces.pages.managementUser,
      }),
   },
]

export const columns: readonly Column[] = [
   {
      id: "stt",
      label: "userTable.stt",
      minWidth: 70,
      maxWidth: 400,
   },
   {
      id: "fullname",
      label: "userTable.name",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.USER_FULLNAME,
   },
   {
      id: "email",
      label: "userTable.email",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.USER_EMAIL,
   },
   {
      id: "phoneNumber",
      label: "userTable.phoneNumber",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "province",
      label: "userTable.province",
      minWidth: 170,
      maxWidth: 400,
      format: (value: ProvinceInterface) => {
         return value ? value.name : ""
      },
   },
   {
      id: "cmnd",
      label: "userTable.cmnd",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "gender",
      label: "userTable.gender",
      minWidth: 170,
      maxWidth: 400,
      format: (value: number) => {
         return getLabelGender(value)
      },
   },
   {
      id: "accountTypeName",
      label: "userTable.accountTypeName",
      minWidth: 170,
   },
   {
      id: "createAt",
      label: "userTable.createAt",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.USER_CREATE_AT,
   },
   {
      id: "expiredDate",
      label: "userTable.usageTimeRange",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "status",
      label: "userTable.status",
      minWidth: 250,
      maxWidth: 400,
   },
   { id: "note", label: "userTable.note", minWidth: 250 },
]

export function createData(
   stt: number,
   id: string,
   fullname: string,
   email: string,
   phoneNumber: string,
   province: ProvinceInterface,
   cmnd: string,
   gender: number,
   createAt: string,
   expiredDate: string,
   accountTypeId: string,
   accountTypeName: string,
   note: string,
   status: UserStatus[]
): ManagementUserInterface {
   createAt = convertDate(createAt)
   expiredDate = convertDate(expiredDate)
   return {
      stt,
      id,
      fullname,
      email,
      phoneNumber,
      province,
      cmnd,
      gender,
      createAt,
      expiredDate,
      accountTypeId,
      accountTypeName,
      note,
      status,
   }
}
