import { useSelector } from "react-redux"
import {
   allowAddEmail,
   allowDeleteEmail,
   allowPauseEmail,
   allowStartEmail,
   allowUpdateEmail,
   allowReStartEmail,
} from "../../states/permission/permission.selector"

export const useEmailPermission = () => {
   return {
      allowAddEmail: useSelector(allowAddEmail),
      allowUpdateEmail: useSelector(allowUpdateEmail),
      allowDeleteEmail: useSelector(allowDeleteEmail),
      allowPauseEmail: useSelector(allowPauseEmail),
      allowStartEmail: useSelector(allowStartEmail),
      allowReStartEmail: useSelector(allowReStartEmail),
   }
}
