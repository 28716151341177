import {
   selectPageNumberApps,
   selectPageSizeApps,
   selectSearchConditionApps,
   selectSortConditionApps,
} from "./apps.selector"
import { getAppsAPI, GetAppsPayloadAPIInterface } from "../../api/apps.api"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getAppsAsync,
} from "./apps.action"
import { call, select } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getAppsFail,
   getAppsSuccess,
   requestApps,
} from "./apps.slice"

/**
 * Get management type user saga
 * @param action
 */
function* getAppsSaga(): any {
   try {
      yield put(requestApps())
      const pageNumber = yield select(selectPageNumberApps)
      const pageSize = yield select(selectPageSizeApps)
      const searchCondition = yield select(selectSearchConditionApps)
      const sortCondition = yield select(selectSortConditionApps)
      const params: GetAppsPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getAppsAPI, params)
      if (!response) {
         throw error
      }
      yield put(getAppsSuccess(response))
   } catch (error: any) {
      yield put(getAppsFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getAppsAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getAppsAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getAppsAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getAppsAsync())
   } catch (error) {}
}

/**
 * Root recharge pack saga
 */
export function* appsSaga() {
   yield takeLatest(getAppsAsync, getAppsSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
