import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetAppsPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getAppsAPI = (payload: GetAppsPayloadAPIInterface) => {
   return AxiosAuth.post("application/get-list", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddAppsPayloadAPIInterface {
   name: string
   zaloOaId: string | undefined
   zaloUserName: string | undefined
   zaloOtpTemplateId: string | undefined
   isUseOtpSystem: boolean
}

export const addAppsAPI = (payload: AddAppsPayloadAPIInterface) => {
   return AxiosAuth.post("application", payload).then((response) => ({
      response,
   }))
}

export interface UpdateAppsPayloadAPIInterface {
   id: string
   name: string
   isActive: boolean
   zaloOaId: string | undefined
   zaloUserName: string | undefined
   zaloOtpTemplateId: string | undefined
   isUseOtpSystem: boolean
}

export const updateAppsAPI = (payload: UpdateAppsPayloadAPIInterface) => {
   return AxiosAuth.put("application/" + payload.id, payload).then(
      (response) => ({
         response,
      })
   )
}

export interface DeleteAppsPayloadAPIInterface {
   id: string
}

export const deleteAppsAPI = (payload: DeleteAppsPayloadAPIInterface) => {
   return AxiosAuth.delete("application/" + payload.id).then((response) => ({
      response,
   }))
}

export interface GetKeyAppsPayloadAPIInterface {
   id: string
}

export const getKeyAppsAPI = (payload: GetKeyAppsPayloadAPIInterface) => {
   return AxiosAuth.get("application/reset/" + payload.id).then((response) => ({
      response,
   }))
}
