import { object, string, TypeOf } from "zod"
import {
   PASSWORD_REGEX,
   PHONENUMBER_REGEX,
   PHONENUMBER_VN_REGEX,
   SPACE_REGEX,
} from "../../common/variables/regex"

export const forgotPasswordSchema = object({
   phoneNumber: string()
      .min(1, "Vui lòng nhập số điện thoại.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập số điện thoại.")
      .regex(
         new RegExp(PHONENUMBER_REGEX),
         "Số điện thoại không đúng định dạng."
      )
      .regex(
         new RegExp(PHONENUMBER_VN_REGEX),
         "Số điện thoại không đúng định dạng."
      ),
   password: string()
      .min(1, "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự."),
   passwordConfirmation: string()
      .min(1, "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự."),
}).refine((data) => data.password === data.passwordConfirmation, {
   message: "Mật khẩu không trùng khớp",
   path: ["passwordConfirmation"],
})

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>
