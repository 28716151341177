import { RegisterState } from "./register.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectRegisterState = (state: RootState): RegisterState => state.register

export const selectRegisterRequesting = createSelector(
   selectRegisterState,
   (registerState: RegisterState) => registerState.requesting
)

export const selectRegisterError = createSelector(
   selectRegisterState,
   (registerState: RegisterState) => registerState.error
)

export const selectShowOtp = createSelector(
   selectRegisterState,
   (registerState: RegisterState) => registerState.showOtp
)
