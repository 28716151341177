import styles from "./chartUserTypeFilter.module.css"
import FormControl from "@mui/material/FormControl"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import React from "react"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import AddchartIcon from "@mui/icons-material/Addchart"
import DownloadIcon from "@mui/icons-material/Download"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, CircularProgress, Typography } from "@mui/material"
import { convertDate } from "../../../common/helper/date.helper"
import { TypeUserSelectInterface } from "../../../common/interfaces/typeUser.interface"
import { getTypeUserSelectAPI } from "../../../api/typeUser.api"
import { useSelector } from "react-redux"
import { selectAuthTokenApp } from "../../../states/auth/auth.selector"
import { selectDateSyncChartUserType } from "../../../states/chartUserType/chartUserType.selector"

type ChartUserTypeFilterComponentProps = {
   requesting: boolean
   requestingExport: boolean
   searchCondition: TypeUserSelectInterface[]
   isDisableStatistic: boolean
   isDisableExport: boolean
   allowGetChartUserType: boolean
   allowExportChartUserType: boolean
   handleStatistic: () => void
   handleChangeSearchCondition: (data: any) => void
   handleChartExport: () => void
}

export const ChartUserTypeFilterComponent = (
   props: ChartUserTypeFilterComponentProps
) => {
   const { t } = useTranslation(namespaces.common)
   const tokenApp = useSelector(selectAuthTokenApp)
   const dateSync = useSelector(selectDateSyncChartUserType)

   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState<
      readonly TypeUserSelectInterface[]
   >([])

   const loading = open && options.length === 0

   React.useEffect(() => {
      if (!loading) {
         return undefined
      }

      ;(async () => {
         getTypeUserSelectAPI(tokenApp as string).then((res) => {
            const optionData = res.data.map((e: any) => {
               const typeUser: TypeUserSelectInterface = {
                  id: e.id,
                  name: e.name,
               }
               return typeUser
            })
            setOptions(optionData)
         })
      })()

      return () => {}
   }, [loading, tokenApp])

   React.useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])

   return (
      <React.Fragment>
         <Grid
            container
            rowSpacing={2}
            columnSpacing={5}
            flexDirection={"row"}
            alignItems={"center"}
         >
            <Grid
               item
               xs={12}
               lg={
                  props.allowGetChartUserType &&
                  props.searchCondition.length > 0
                     ? 8
                     : 12
               }
            >
               <FormControl fullWidth margin="normal">
                  <Autocomplete
                     id="asynchronous-user-type"
                     multiple
                     open={open}
                     onOpen={() => {
                        setOpen(true)
                     }}
                     onClose={() => {
                        setOpen(false)
                     }}
                     onChange={(event: any, newValue: any | null) => {
                        props.handleChangeSearchCondition(newValue)
                     }}
                     value={props.searchCondition}
                     isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                     }
                     getOptionLabel={(option) => option.name}
                     options={options}
                     loading={loading}
                     noOptionsText={t("dialog.noOption")}
                     renderOption={(htmlProps, option) => {
                        return (
                           <li {...htmlProps} key={option.id}>
                              {option.name}
                           </li>
                        )
                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           fullWidth
                           variant="outlined"
                           label={t("filter.chartUserType")}
                           InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                 <React.Fragment>
                                    {loading ? (
                                       <CircularProgress
                                          color="inherit"
                                          size={20}
                                       />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                 </React.Fragment>
                              ),
                           }}
                        />
                     )}
                  />
               </FormControl>
            </Grid>
            {props.allowGetChartUserType &&
               props.searchCondition.length > 0 && (
                  <Grid item xs={12} lg={2}>
                     <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        className={styles.buttonText}
                        onClick={props.handleStatistic}
                        disabled={props.isDisableStatistic}
                        startIcon={<AddchartIcon />}
                     >
                        {t("buttons.statisticize")}
                     </Button>
                  </Grid>
               )}
            {props.allowGetChartUserType &&
               props.searchCondition.length > 0 && (
                  <Grid item xs={12} lg={2}>
                     <LoadingButton
                        fullWidth
                        color="secondary"
                        loading={props.requestingExport}
                        disabled={props.isDisableExport}
                        loadingPosition="start"
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        className={styles.buttonText}
                        onClick={props.handleChartExport}
                     >
                        {t("buttons.exportExcel")}
                     </LoadingButton>
                  </Grid>
               )}
         </Grid>
         {dateSync && dateSync.length > 0 && (
            <Grid item xs={12}>
               <Typography mt={2} variant="body2" sx={{ fontStyle: "italic" }}>
                  {t("chart.note") + convertDate(dateSync)}
               </Typography>
            </Grid>
         )}
      </React.Fragment>
   )
}
