import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getManagementUserDetailAsync } from "../../states/managementUserDetail/managementUserDetail.action"
import { selectIsCompanyManagementUserDetailState } from "../../states/managementUserDetail/managementUserDetail.selector"
import { ManagementUserDetailComponent } from "./managementUserDetail.component"

export const ManagementUserDetailContainer = () => {
   const dispatch = useDispatch()

   let { id } = useParams()

   const isCompany = useSelector(selectIsCompanyManagementUserDetailState)

   useEffect(() => {
      dispatch(getManagementUserDetailAsync(id as string))
   }, [id, dispatch])

   return <ManagementUserDetailComponent isCompany={isCompany} />
}
