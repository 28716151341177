import { createAction } from "@reduxjs/toolkit"

export const registerUserAsync = createAction<any>("register/registerUserAsync")

export const otpRegisterUserAsync = createAction<any>(
   "register/otpRegisterUserAsync"
)

export const resendOtpRegisterUserAsync = createAction<any>(
   "register/resendOtpRegisterUserAsync"
)
