import { object, string, TypeOf } from "zod"
import { SPACE_REGEX } from "../../../common/variables/regex"

export const createEmailTemplateSchema = object({
   name: string()
      .min(1, "Tên mẫu bắt buộc nhập")
      .regex(new RegExp(SPACE_REGEX), "Tên mẫu bắt buộc nhập"),
})

export type CreateEmailTemplateInput = TypeOf<typeof createEmailTemplateSchema>
