import { date, number, object, string, TypeOf, z } from "zod"
import { SPACE_REGEX } from "../../../common/variables/regex"

export const settingUserInfoSchema = object({
   province: object({
      id: string().min(1),
      name: string().min(1),
   })
      .optional()
      .nullable(),
   district: object({
      id: string().min(1),
      name: string().min(1),
   })
      .optional()
      .nullable(),
   ward: object({
      id: string().min(1),
      name: string().min(1),
   })
      .optional()
      .nullable(),
   name: string()
      .min(1, "Vui lòng nhập họ và tên.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập họ và tên."),
   email: string()
      .email("Email không đúng định dạng")
      .optional()
      .or(z.literal("")),
   phoneNumber: string(),
   birthday: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .max(new Date(), {
         message: "Thời gian không được vượt quá ngày hiện tại",
      })
      .optional()
      .nullable()
      .default(null),
   isMale: number().optional().nullable(),
   identityCard: string().optional().nullable(),
   identityCardDate: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .max(new Date(), {
         message: "Thời gian không được vượt quá ngày hiện tại",
      })
      .optional()
      .nullable()
      .default(null),
   identityCardPlace: string().optional().nullable(),
   addressNumber: string().optional().nullable(),
   address: string().optional().nullable(),
})

export type SettingUserInfoInput = TypeOf<typeof settingUserInfoSchema>
