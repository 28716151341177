import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import styles from "./textLogo.module.css"
import commonStyles from "../../styles/common.module.css"
import Typography from "@mui/material/Typography"
import { centerIDLogo } from "./../../../images/images"
import clsx from "clsx"

type Props = {
   introduce: string
}

export const TextLogo = (props: Props) => {
   const { t } = useTranslation(namespaces.common)

   return (
      <div
         className={clsx(commonStyles.flexSpaceCenter, commonStyles.fullWidth)}
      >
         <div>
            <p className={styles.textLogo}>{t("appName")}</p>
            <Typography variant="body1">{t(props.introduce)}</Typography>
         </div>
         <div>
            <img className={styles.logo} src={centerIDLogo} alt="logo"></img>
         </div>
      </div>
   )
}
