import { zodResolver } from "@hookform/resolvers/zod"
import Container from "@mui/material/Container"
import LinearProgress from "@mui/material/LinearProgress"
import Grid2 from "@mui/material/Unstable_Grid2"
import React, { useEffect } from "react"
import { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import { Footer } from "../../common/components/Footer/footer"
import styles from "./forgotPassword.module.css"
import commonStyles from "../../common/styles/common.module.css"
import clsx from "clsx"
import { registerWallpage } from "../../images/images"
import { TextLogo } from "../../common/components/TextLogo/textLogo"
import { FormErrorComponent } from "../../common/components/FormError/formError.component"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import TextField from "@mui/material/TextField"
import OtpInput from "react-otp-input"
import { Stack } from "@mui/system"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import {
   ForgotPasswordInput,
   forgotPasswordSchema,
} from "./forgotPassword.form"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

const steps = ["steps.phoneNumber", "steps.otp"]

type ForgotPasswordComponentProps = {
   isForgotPasswordRequesting?: boolean
   onSignIn: () => void
   onSignUp: () => void
   handlePushNotistack: (message: string, variant: string) => void
   handlePhoneNumberStepAsync: (phoneNumber: string) => Promise<any>
   handleResendOTPStepAsync: (phoneNumber: string) => Promise<any>
   handleVerifyStepAsync: (
      phoneNumber: string,
      newPassword: string,
      otp: string
   ) => Promise<any>
   handleResetPasswordStepAsync: (
      phoneNumber: string,
      password: string
   ) => Promise<any>
}

export const ForgotPasswordComponent = (
   props: ForgotPasswordComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.forgotPassword)

   const [requestingForgotPassword, setRequestingForgotPassword] =
      useState<boolean>(false)

   // State OTP
   const [otpValue, setOtpValue] = useState<string>("")
   const [otpTimer, setOtpTimer] = useState<number>(30)
   // State steps
   const [activeStep, setActiveStep] = React.useState(0)
   const [completed, setCompleted] = React.useState<{
      [k: number]: boolean
   }>({})
   // Error
   const [error, setError] = useState<string>("")
   // Helper
   const [showPassword, setShowPassword] = useState<boolean>(false)
   const [showPasswordConfirmation, setShowPasswordConfirmation] =
      useState<boolean>(false)
   const [showHeplerTextPassword, setShowHeplerTextPassword] =
      useState<boolean>(false)
   const [
      showHeplerTextPasswordConfirmation,
      setShowHeplerTextPasswordConfirmation,
   ] = useState<boolean>(false)

   const {
      formState: {
         errors: forgotPasswordPassword,
         isValid: forgotPasswordIsValid,
      },
      control,
      handleSubmit,
      getValues,
   } = useForm<ForgotPasswordInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(forgotPasswordSchema),
   })

   /**
    * Total steps
    * @returns
    */
   const totalSteps = () => {
      return steps.length
   }

   /**
    * Completed steps
    * @returns
    */
   const completedSteps = () => {
      return Object.keys(completed).length
   }

   /**
    * Is last step
    * @returns
    */
   const isLastStep = () => {
      return activeStep === totalSteps() - 1
   }

   /**
    * All steps completed
    * @returns
    */
   const allStepsCompleted = () => {
      return completedSteps() === totalSteps()
   }

   /**
    * Handle next
    */
   const handleNext = () => {
      const newActiveStep =
         isLastStep() && !allStepsCompleted()
            ? steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1
      setActiveStep(newActiveStep)
      setError("")
   }

   /**
    * Handle back
    */
   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
   }

   /**
    * Handle complete
    */
   const handleComplete = () => {
      const newCompleted = completed
      newCompleted[activeStep] = true
      setCompleted(newCompleted)
      props.onSignIn()
   }

   /**
    * On submit form password
    */
   const onSubmitPhoneNumber: SubmitHandler<ForgotPasswordInput> = (
      values: ForgotPasswordInput
   ) => {
      setRequestingForgotPassword(true)
      props
         .handlePhoneNumberStepAsync(values.phoneNumber)
         .then((res) => {
            setRequestingForgotPassword(false)
            setError("")
            handleNext()
            setOtpTimer(30)
         })
         .catch((err) => {
            setRequestingForgotPassword(false)
            if (err.response.data.status === 500) {
               setError(err.response.data.detail)
            } else {
               setError(t("error.resendOtp", { ns: "common" }))
            }
         })
   }

   /**
    * On submid verify otp
    */
   const onSubmitVerifyOTP = () => {
      if (otpTimer === 0) {
         props.handlePushNotistack("Mã OTP hết hiệu lực!", "error")
         return
      }
      setRequestingForgotPassword(true)
      const phoneNumber = getValues("phoneNumber")
      const newPassword = getValues("password")
      props
         .handleVerifyStepAsync(phoneNumber, newPassword, otpValue)
         .then((res) => {
            setRequestingForgotPassword(false)
            props.handlePushNotistack("Xác thực OTP thành công", "success")
            setError("")
            handleComplete()
         })
         .catch((err) => {
            setRequestingForgotPassword(false)
            if (err.response.data.status === 500) {
               setError(err.response.data.detail)
            } else {
               setError(t("error.otp", { ns: "common" }))
            }
         })
   }

   /**
    * On resend otp
    */
   const onResendOtp = () => {
      setOtpValue("")
      setRequestingForgotPassword(true)
      const phoneNumber = getValues("phoneNumber")
      props
         .handleResendOTPStepAsync(phoneNumber)
         .then((res) => {
            setRequestingForgotPassword(false)
            props.handlePushNotistack("Gửi lại mã OTP thành công", "success")
            setError("")
            setOtpTimer(30)
         })
         .catch((err) => {
            setRequestingForgotPassword(false)
            if (err.response.data.status === 500) {
               setError(err.response.data.detail)
            } else {
               setError(t("error.resendOtp", { ns: "common" }))
            }
         })
   }

   /**
    * Render step header
    * @returns
    */
   const renderStepsHeader = () => {
      return (
         <Stepper
            sx={{
               marginY: "30px",
            }}
            activeStep={activeStep}
         >
            {steps.map((label, index) => {
               const stepProps: { completed?: boolean } = {}
               const labelProps: {
                  optional?: React.ReactNode
               } = {}
               return (
                  <Step key={label} {...stepProps}>
                     <StepLabel {...labelProps}>{t(label)}</StepLabel>
                  </Step>
               )
            })}
         </Stepper>
      )
   }

   /**
    * Handle mouse down password
    * @param event
    */
   const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
   }

   /**
    * Handle click show password
    * @param isPasswordConfirmation
    */
   const handleClickShowPassword = (isPasswordConfirmation: boolean) => {
      if (isPasswordConfirmation) {
         setShowPasswordConfirmation(!showPasswordConfirmation)
      } else {
         setShowPassword(!showPassword)
      }
   }

   /**
    * Render step phonenumber
    * @returns
    */
   const renderPhoneNumber = () => {
      return (
         <React.Fragment>
            {error && (
               <div className={styles.error}>
                  <FormErrorComponent error={error}></FormErrorComponent>
               </div>
            )}
            <form key={0} onSubmit={handleSubmit(onSubmitPhoneNumber)}>
               <Controller
                  defaultValue=""
                  name="phoneNumber"
                  control={control}
                  render={({
                     field: { onChange, value },
                     fieldState: { error: errorCtl },
                  }) => (
                     <TextField
                        fullWidth
                        helperText={errorCtl ? errorCtl.message : ""}
                        error={!!errorCtl}
                        value={value}
                        onChange={onChange}
                        id="phoneNumber"
                        label={t("field.phoneNumber", { ns: "common" })}
                     />
                  )}
               />
               <Controller
                  defaultValue=""
                  name="password"
                  control={control}
                  render={({
                     field: { onChange, value },
                     fieldState: { error: errorCtl },
                  }) => (
                     <TextField
                        fullWidth
                        sx={{ marginTop: "25px" }}
                        type={showPassword ? "text" : "password"}
                        helperText={errorCtl ? errorCtl.message : ""}
                        error={!!errorCtl}
                        value={value}
                        onChange={onChange}
                        id="password"
                        label={t("field.password", { ns: "common" })}
                        onFocus={() => {
                           setShowHeplerTextPassword(true)
                        }}
                        onKeyDown={() => {
                           setShowHeplerTextPassword(false)
                        }}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                       handleClickShowPassword(false)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPassword ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  )}
               />
               {!forgotPasswordPassword.password && showHeplerTextPassword && (
                  <FormHelperText>
                     Mật khẩu có chứa ít nhất 6 ký tự.
                  </FormHelperText>
               )}
               <Controller
                  defaultValue=""
                  name="passwordConfirmation"
                  control={control}
                  render={({
                     field: { onChange, value },
                     fieldState: { error: errorCtl },
                  }) => (
                     <TextField
                        fullWidth
                        sx={{ marginTop: "25px" }}
                        type={showPasswordConfirmation ? "text" : "password"}
                        helperText={errorCtl ? errorCtl.message : ""}
                        error={!!errorCtl}
                        value={value}
                        onChange={onChange}
                        id="passwordConfirmation"
                        label={t("field.passwordConfirmation", {
                           ns: "common",
                        })}
                        onFocus={() => {
                           setShowHeplerTextPasswordConfirmation(true)
                        }}
                        onKeyDown={() => {
                           setShowHeplerTextPasswordConfirmation(false)
                        }}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password confirm visibility"
                                    onClick={() =>
                                       handleClickShowPassword(true)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                 >
                                    {showPasswordConfirmation ? (
                                       <VisibilityOff />
                                    ) : (
                                       <Visibility />
                                    )}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  )}
               />
               {!forgotPasswordPassword.passwordConfirmation &&
                  showHeplerTextPasswordConfirmation && (
                     <FormHelperText>
                        Mật khẩu có chứa ít nhất 6 ký tự.
                     </FormHelperText>
                  )}
               <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                     color="inherit"
                     disabled={activeStep === 0}
                     onClick={handleBack}
                     sx={{ mr: 1 }}
                  >
                     {t("buttons.back", { ns: "common" })}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                     type="submit"
                     sx={{ mr: 1 }}
                     disabled={!forgotPasswordIsValid}
                  >
                     {t("buttons.next", { ns: "common" })}
                  </Button>
               </Box>
               <Stack
                  className={styles.buttonStack}
                  mt={2}
                  spacing={2}
                  direction="row"
               >
                  <Button
                     type="submit"
                     color="secondary"
                     variant="contained"
                     onClick={props.onSignIn}
                     disabled={props.isForgotPasswordRequesting}
                  >
                     {t("buttons.signIn", { ns: "common" })}
                  </Button>
                  <Button
                     variant="text"
                     onClick={props.onSignUp}
                     disabled={props.isForgotPasswordRequesting}
                  >
                     {t("buttons.signUp", { ns: "common" })}
                  </Button>
               </Stack>
            </form>
         </React.Fragment>
      )
   }

   /**
    * Render step OTP
    * @returns
    */
   const renderStepOTP = () => {
      return (
         <React.Fragment>
            {error && (
               <div className={styles.error}>
                  <FormErrorComponent error={error}></FormErrorComponent>
               </div>
            )}
            <Typography mb={5}>
               {t("otpCountDown")}
               <Link
                  disabled={requestingForgotPassword}
                  onClick={onResendOtp}
                  component="button"
                  color="inherit"
                  variant="body1"
               >
                  {t("button.resend")} ({otpTimer}s)
               </Link>
            </Typography>
            <Stack direction={"row"} justifyContent={"center"}>
               <OtpInput
                  value={otpValue}
                  onChange={(value: string) => setOtpValue(value)}
                  numInputs={6}
                  separator={<span> - </span>}
                  isInputNum={true}
                  inputStyle={{
                     height: "60px",
                     width: "50px",
                     fontSize: "25px",
                     fontWeight: "bold",
                  }}
               />
            </Stack>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
               <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
               >
                  {t("buttons.back", { ns: "common" })}
               </Button>
               <Box sx={{ flex: "1 1 auto" }} />
               <Button
                  disabled={otpValue.length < 6}
                  onClick={onSubmitVerifyOTP}
                  sx={{ mr: 1 }}
               >
                  {t("buttons.next", { ns: "common" })}
               </Button>
            </Box>
         </React.Fragment>
      )
   }

   useEffect(() => {
      const timerID = setInterval(() => {
         if (activeStep === 1) {
            setOtpTimer(otpTimer === 0 ? 0 : otpTimer - 1)
         }
      }, 1000)
      return () => clearInterval(timerID)
   })

   return (
      <React.Fragment>
         {requestingForgotPassword && <LinearProgress color="secondary" />}
         <Container maxWidth="xl">
            <Grid2 container>
               <Grid2 xs={12} xl={6}>
                  <div
                     className={clsx(
                        commonStyles.flexCenterCenter,
                        styles.wallpaper
                     )}
                  >
                     <img
                        src={registerWallpage}
                        alt="Forgot Password Wallpaper"
                     ></img>
                  </div>
               </Grid2>
               <Grid2 xs={12} xl={6}>
                  <div
                     className={clsx(
                        commonStyles.flexColumnCenterCenter,
                        styles.form
                     )}
                  >
                     <TextLogo introduce={t("introduce")}></TextLogo>
                     <Box pt={"40px"} sx={{ width: "100%" }}>
                        {renderStepsHeader()}
                        {activeStep === 0 && renderPhoneNumber()}
                        {activeStep === 1 && renderStepOTP()}
                        <Divider className={styles.divider} />
                     </Box>
                  </div>
               </Grid2>
               <Grid2 xs={12}>
                  <Footer></Footer>
               </Grid2>
            </Grid2>
         </Container>
      </React.Fragment>
   )
}
