import { MarketingStatus } from "../enum/marketingStatus.enum"

export const getLabelMarketingStatus = (status: MarketingStatus) => {
   if (status === MarketingStatus.ACTIVE) {
      return "Hoạt động"
   }
   if (status === MarketingStatus.PAUSE) {
      return "Tạm dừng"
   }
   return ""
}
