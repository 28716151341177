export enum EmailTypeEnum {
   BIRTHDAY = 0,
   SENT_NOW = 1,
   TIMER = 2,
   USER_REGISTER = 3,
   USER_EXTEND = 4,
   USER_ALMOST_EXPIRED = 5,
   USER_EXPIRED = 6,
   USER_UNACTIVE = 7,
}
