import { SortColumnEnum } from "./../enum/sortColumn.enum"
import { SortOrderEnum } from "../enum/sortOrder.enum"

/**
 * Create sort direction for component libary
 * @param order
 * @returns
 */
export const createSortDirection = (order: SortOrderEnum) => {
   if (order === SortOrderEnum.DESC) {
      return "desc"
   }
   if (order === SortOrderEnum.ASC) {
      return "asc"
   }
}

/**
 * Handle sort order for redux state
 * @param oldcolumn
 * @param newcolumn
 * @param order
 * @returns
 */
export const handleSortOrder = (
   oldcolumn: SortColumnEnum,
   newcolumn: SortColumnEnum,
   order: SortOrderEnum
) => {
   if (oldcolumn === newcolumn) {
      if (order === SortOrderEnum.ASC) {
         return SortOrderEnum.DESC
      } else {
         return SortOrderEnum.ASC
      }
   } else {
      return order
   }
}
