import { PagingParam } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetFeaturePayloadAPIInterface
   extends PagingParam,
      SearchConditionBody {}

export const getFeatureAPI = (payload: GetFeaturePayloadAPIInterface) => {
   return AxiosAuth.get(
      "feature?PageNumber=" +
         payload.PageNumber +
         "&PageSize=" +
         payload.PageSize +
         "&SearchConditions=" +
         JSON.stringify(payload.SearchConditions)
   )
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
