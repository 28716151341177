import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ManagementWalletInterface } from "../../common/interfaces/managementWallet.interface"
import { SearchConditionWalletState } from "../../common/interfaces/searchConditionParam"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"
import dayjs from "dayjs"
import { convertDay } from "../../common/helper/date.helper"

export interface ManagementWalletState {
   requesting: boolean
   successful: boolean
   error: string
   data: ManagementWalletInterface
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionWalletState
}

const initialState: ManagementWalletState = {
   requesting: false,
   successful: false,
   error: "",
   data: {
      amount: "0",
      expireDate: "",
      transactions: [],
   },
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableWalletEnum.ALL,
      from: dayjs(new Date()),
      to: dayjs(new Date()),
   },
}

export const managementWalletSlice = createSlice({
   name: "managementWallet",
   initialState,
   reducers: {
      requestManagementWallet: (state: ManagementWalletState) => {
         state.requesting = true
      },
      getManagementWalletSuccess: (
         state: ManagementWalletState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data.amount = action.payload.amount
         state.data.expireDate = convertDay(action.payload.expiredDate)
         state.data.transactions = action.payload.transactions.data
         state.rowCount = action.payload.transactions.rowCount
      },
      getManagementWalletFail: (
         state: ManagementWalletState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = {
            amount: "0",
            expireDate: "",
            transactions: [],
         }
      },
      changePageNumberSuccess: (
         state: ManagementWalletState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: ManagementWalletState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: ManagementWalletState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.from = action.payload.from
         state.searchCondition.to = action.payload.to
      },
   },
})

export const {
   requestManagementWallet,
   getManagementWalletSuccess,
   getManagementWalletFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
} = managementWalletSlice.actions

export default managementWalletSlice.reducer
