import { isAuthorization } from "./../../common/helper/auth.helper"
import { AuthState } from "./auth.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { RoleEnum } from "../../common/enum/role.enum"

const selectAuthState = (state: RootState): AuthState => state.auth

export const selectAuthRequesting = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.requesting
)

export const selectAuthIsError = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.error.length > 0
)

export const selectAuthError = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.error
)

export const selectIsAuth = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.successful && isAuthorization()
)

export const selectAuthUserId = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.userid
)

export const selectAuthRole = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.role
)

export const selectAuthIsAdminRole = createSelector(
   selectAuthRole,
   (role: RoleEnum | null) => role === RoleEnum.ADMIN
)

export const selectAuthTokenApp = createSelector(
   selectAuthState,
   (authState: AuthState) => authState.tokenApp
)
