import { TypeOf, array, boolean, date, number, object, string } from "zod"
import { EmailTypeEnum } from "../../common/enum/email.enum"

export const defaultNumberErr = {
   required_error: "Bắt buộc nhập",
}

export const createEmailSchema = object({
   name: string().min(1, "Tên chiến dịch bắt buộc nhập"),
   startDate: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .default(new Date()),
   endDate: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .default(new Date()),
   time: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .default(new Date(2000, 0, 1, 0, 0, 0, 0)),
   type: number().default(EmailTypeEnum.BIRTHDAY),
   isAll: boolean().default(false),
   userType: array(
      object({
         id: string().min(1),
         name: string().min(1),
      })
   ).optional(),
})

export type CreateEmailInput = TypeOf<typeof createEmailSchema>
