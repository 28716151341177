import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   getAppsAsync,
} from "../../states/apps/apps.action"
import {
   selectCountApps,
   selectDataApps,
   selectErrorApps,
   selectPageNumberApps,
   selectPageSizeApps,
   selectRequestingApps,
   selectSearchConditionApps,
} from "../../states/apps/apps.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { AppsComponent } from "./apps.component"
import { AppsDialogInput } from "./apps.form"
import {
   AddAppsPayloadAPIInterface,
   DeleteAppsPayloadAPIInterface,
   GetKeyAppsPayloadAPIInterface,
   UpdateAppsPayloadAPIInterface,
   addAppsAPI,
   deleteAppsAPI,
   getKeyAppsAPI,
   updateAppsAPI,
} from "../../api/apps.api"

export const AppsContainer = () => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingApps)
   const data = useSelector(selectDataApps)
   const rowCount = useSelector(selectCountApps)
   const pageNumber = useSelector(selectPageNumberApps)
   const pageSize = useSelector(selectPageSizeApps)
   const error = useSelector(selectErrorApps)
   const searchCondition = useSelector(selectSearchConditionApps)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }
   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle add apps async
    * @param values
    * @returns
    */
   const handleAddAppsAsync = (values: AppsDialogInput) => {
      const params: AddAppsPayloadAPIInterface = {
         name: values.name,
         zaloOaId: values.zaloOaId,
         zaloUserName: values.zaloUserName,
         zaloOtpTemplateId: values.zaloOtpTemplateId,
         isUseOtpSystem: values.isUseOtpSystem,
      }
      return addAppsAPI(params)
   }

   /**
    * Handle update apps async
    * @param values
    * @returns
    */
   const handleUpdateAppsAsync = (
      id: string,
      values: AppsDialogInput,
      isActive: boolean
   ) => {
      const params: UpdateAppsPayloadAPIInterface = {
         id: id,
         name: values.name,
         isActive: isActive,
         zaloOaId: values.zaloOaId,
         zaloUserName: values.zaloUserName,
         zaloOtpTemplateId: values.zaloOtpTemplateId,
         isUseOtpSystem: values.isUseOtpSystem,
      }
      return updateAppsAPI(params)
   }

   /**
    * Handle get key apps async
    * @param values
    * @returns
    */
   const handleGetKeyAppsAsync = (id: string) => {
      const params: GetKeyAppsPayloadAPIInterface = {
         id: id,
      }
      return getKeyAppsAPI(params)
   }

   /**
    * Handle delete apps async
    * @param values
    * @returns
    */
   const handleDeleteAppsAsync = (id: string) => {
      const params: DeleteAppsPayloadAPIInterface = {
         id: id,
      }
      return deleteAppsAPI(params)
   }

   /**
    * Reload apps list
    */
   const handleReloadGetAppsAsync = () => {
      dispatch(getAppsAsync())
   }

   useEffect(() => {
      dispatch(getAppsAsync())
   }, [dispatch])

   return (
      <AppsComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         data={data}
         error={error}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleAddAppsAsync={handleAddAppsAsync}
         handleUpdateAppsAsync={handleUpdateAppsAsync}
         handleGetKeyAppsAsync={handleGetKeyAppsAsync}
         handleDeleteAppsAsync={handleDeleteAppsAsync}
         handleReloadGetAppsAsync={handleReloadGetAppsAsync}
      />
   )
}
