import { useState } from "react"

type ShowFullValueHookProps = {
   maxLength: number
}

export const useShowFullValue = (props: ShowFullValueHookProps) => {
   const [showFullValues, setShowFullValues] = useState<string[]>([])

   /**
    * On show full value
    * @param rowId
    */
   const onShowFullValue = (rowId: string) => {
      setShowFullValues([...showFullValues, rowId])
   }

   /**
    * On hide full value
    * @param rowId
    */
   const onHideFullValue = (rowId: string) => {
      setShowFullValues([...showFullValues.filter((e) => e !== rowId)])
   }

   /**
    * Is show full value
    * @param rowId
    * @returns
    */
   const isShowFullValue = (rowId: string) => showFullValues.includes(rowId)

   /**
    * Get short value
    * @param value
    * @returns
    */
   const getShortValue = (value: any) => {
      if (
         typeof value === "object" &&
         value.name &&
         value.name.length > props.maxLength
      ) {
         const copy = JSON.parse(JSON.stringify(value))
         copy.name = copy.name.slice(0, props.maxLength) + "..."
         return copy
      }
      if (typeof value === "string" && value.length > props.maxLength) {
         return value.slice(0, props.maxLength) + "..."
      }
      if (
         typeof value === "number" &&
         value.toString().length > props.maxLength
      ) {
         return value.toString().slice(0, props.maxLength) + "..."
      }
      return value
   }

   return {
      onShowFullValue: onShowFullValue,
      onHideFullValue: onHideFullValue,
      isShowFullValue: isShowFullValue,
      getShortValue: getShortValue,
   }
}
