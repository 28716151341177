import { createAction } from "@reduxjs/toolkit"

export const getRechargePackAsync = createAction(
   "rechargePack/getRechargePackAsync"
)

export const changePageNumberAsync = createAction<number>(
   "rechargePack/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "rechargePack/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "rechargePack/changeSearchConditionAsync"
)

export const changeSortConditionAsync = createAction<any>(
   "department/changeSortConditionAsync"
)
