import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { ManagementUserState } from "./managementUser.slice"

const selectManagementUserState = (state: RootState): ManagementUserState =>
   state.managementUser

export const selectRequestingManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.requesting
)

export const selectRequestingManagementUserCount = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.requestingCount
)

export const selectRequestingExportManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.excelExporting
)

export const selectDataManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.data
)

export const selectDataManagementCountUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.dataCount
)

export const selectErrorManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.error
)

export const selectErrorManagementUserCount = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.errorCount
)

export const selectCountManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.rowCount
)

export const selectPageNumberManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.paging.pageNumber
)

export const selectPageSizeManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.paging.pageSize
)

export const selectSearchConditionManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.searchCondition
)

export const selectSortConditionManagementUser = createSelector(
   selectManagementUserState,
   (state: ManagementUserState) => state.sortCondition
)
