import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { RechargePackState } from "./rechargePack.slice"

const selectRechargePackState = (state: RootState): RechargePackState =>
   state.rechargePack

export const selectRequestingRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.requesting
)

export const selectDataRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.data
)

export const selectErrorRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.error
)

export const selectSortConditionRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.sortCondition
)

export const selectCountRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.rowCount
)

export const selectPageNumberRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.paging.pageNumber
)

export const selectPageSizeRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.paging.pageSize
)

export const selectSearchConditionRechargePack = createSelector(
   selectRechargePackState,
   (state: RechargePackState) => state.searchCondition
)
