import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import styles from "./userMenu.module.css"
import { useState } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import ListItemIcon from "@mui/material/ListItemIcon"
import { Logout, Settings } from "@mui/icons-material"
import Box from "@mui/material/Box"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import { useDispatch, useSelector } from "react-redux"
import { selectUserInfo } from "../../../states/user/user.selector"
import Skeleton from "@mui/material/Skeleton"
import { logoutAsync } from "../../../states/auth/auth.action"
import { useNavigate } from "react-router-dom"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import Tooltip from "@mui/material/Tooltip"
import { getShortValue } from "../../helper/shortText.helper"
import GppBadIcon from "@mui/icons-material/GppBad"
import GppGoodIcon from "@mui/icons-material/GppGood"

export const UserMenu = () => {
   const { t } = useTranslation(namespaces.common)
   const userInfo = useSelector(selectUserInfo)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

   /**
    * Handle open menu
    * @param event
    */
   const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget)
   }

   /**
    * Handle close menu
    */
   const handleCloseUserMenu = () => {
      setAnchorElUser(null)
   }

   /**
    * Handle logout
    */
   const handleLogout = () => {
      dispatch(logoutAsync(navigate))
      handleCloseUserMenu()
   }

   /**
    * Handle setting
    */
   const handleSetting = () => {
      navigate("/dashboard/setting")
      handleCloseUserMenu()
   }

   return (
      <Stack direction={"row"} alignItems={"center"} gap={2}>
         <Tooltip
            title={
               userInfo.isEkyc
                  ? t("userMenu.verifyEkyc")
                  : t("userMenu.notVerifyEkyc")
            }
         >
            {userInfo.isEkyc ? (
               <GppGoodIcon color="success" />
            ) : (
               <GppBadIcon color="error" />
            )}
         </Tooltip>
         {userInfo.requesting ? (
            <Skeleton height={40} width={100} />
         ) : (
            <span className={styles.name}>
               {userInfo.fullname
                  ? getShortValue(20, userInfo.fullname)
                  : "User"}
            </span>
         )}

         <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <AccountCircleIcon className={styles.logo}></AccountCircleIcon>
         </IconButton>
         <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
         >
            <Box p={3}>
               <Typography variant="body1">
                  {userInfo.fullname
                     ? getShortValue(20, userInfo.fullname)
                     : "User"}
               </Typography>
               <Typography variant="body2">
                  {getShortValue(20, userInfo.email)}
               </Typography>
            </Box>
            <Divider />
            <MenuItem sx={{ mt: 1 }} onClick={handleSetting}>
               <ListItemIcon>
                  <Settings fontSize="small" />
               </ListItemIcon>
               {t("userMenu.setting")}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>
               {t("userMenu.logout")}
            </MenuItem>
         </Menu>
      </Stack>
   )
}
