import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   addDepartmentAPI,
   AddDepartmentPayloadAPIInterface,
   deleteDepartmentAPI,
   DeleteDepartmentPayloadAPIInterface,
   updateDepartmentAPI,
   UpdateDepartmentPayloadAPIInterface,
} from "../../api/department.api"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { DepartmentInterface } from "../../common/interfaces/department.interface"
import { FeatureInterface } from "../../common/interfaces/feature.interface"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getDepartmentsAsync,
} from "../../states/department/department.action"
import {
   selectCountDepartment,
   selectDataDepartment,
   selectErrorDepartment,
   selectPageNumberDepartment,
   selectPageSizeDepartment,
   selectRequestingDepartment,
   selectSearchConditionDepartment,
   selectSortConditionDepartment,
} from "../../states/department/department.selector"
import { getDepartmentUserAsync } from "../../states/departmentUser/departmentUser.action"
import { selectSelectedDepartmentUser } from "../../states/departmentUser/departmentUser.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { DepartmentComponent } from "./department.component"
import { DepartmentDialogInput } from "./department.form"

export const DepartmentContainer = () => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingDepartment)
   const departmentData = useSelector(selectDataDepartment)
   const rowCount = useSelector(selectCountDepartment)
   const pageNumber = useSelector(selectPageNumberDepartment)
   const pageSize = useSelector(selectPageSizeDepartment)
   const error = useSelector(selectErrorDepartment)
   const selectedId = useSelector(selectSelectedDepartmentUser)
   const sortCondition = useSelector(selectSortConditionDepartment)
   const searchCondition = useSelector(selectSearchConditionDepartment)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle add department async
    * @param values
    * @returns
    */
   const handleAddDepartmentAsync = (
      values: DepartmentDialogInput,
      features: FeatureInterface[]
   ) => {
      const params: AddDepartmentPayloadAPIInterface = {
         name: values.name,
         roleIds: features.map((e) => e.id),
      }
      return addDepartmentAPI(params)
   }

   /**
    * Handle add department async
    * @param values
    * @returns
    */
   const handleUpdateDepartmentAsync = (
      id: string,
      values: DepartmentDialogInput,
      features: FeatureInterface[]
   ) => {
      const params: UpdateDepartmentPayloadAPIInterface = {
         id: id,
         name: values.name,
         roleIds: features.map((e) => e.id),
      }
      return updateDepartmentAPI(params)
   }

   /**
    * Handle add department async
    * @param values
    * @returns
    */
   const handleDeleteDepartmentAsync = (id: string) => {
      const params: DeleteDepartmentPayloadAPIInterface = {
         id: id,
      }
      return deleteDepartmentAPI(params)
   }

   /**
    * Reload department list
    */
   const handleReloadGetDeparmentsAsync = () => {
      dispatch(getDepartmentsAsync())
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle get user department
    * @param values
    */
   const handleSelectDepartmentUser = (values: DepartmentInterface) => {
      dispatch(getDepartmentUserAsync(values.id))
   }

   useEffect(() => {
      dispatch(getDepartmentsAsync())
   }, [dispatch])

   return (
      <DepartmentComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         error={error}
         departmentData={departmentData}
         sortCondition={sortCondition}
         selectedId={selectedId}
         searchCondition={searchCondition}
         handlePushNotistack={handlePushNotistack}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleReloadGetDeparmentsAsync={handleReloadGetDeparmentsAsync}
         handleAddDepartmentAsync={handleAddDepartmentAsync}
         handleUpdateDepartmentAsync={handleUpdateDepartmentAsync}
         handleDeleteDepartmentAsync={handleDeleteDepartmentAsync}
         handleSelectDepartmentUser={handleSelectDepartmentUser}
         handleChangeSortCondition={handleChangeSortCondition}
      />
   )
}
