import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetManagementUserHistoryPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody {
   id: string
}

export const getManagementUserHistoryAPI = (
   payload: GetManagementUserHistoryPayloadAPIInterface
) => {
   return AxiosAuth.get(
      "user-history?PageNumber=" +
         payload.pageNumber +
         "&PageSize=" +
         payload.pageSize +
         "&SearchConditions=" +
         JSON.stringify(payload.SearchConditions) +
         "&id=" +
         payload.id
   )
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}
