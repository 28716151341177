import { FilterTableEnum } from "../../../common/enum/filterTable.enum"
import { TypeUserDetailInterface } from "../../../common/interfaces/typeUserDetailInterface.interface"

export interface Column {
   id:
      | "fullName"
      | "email"
      | "phoneNumber"
      | "provinceName"
      | "moneyChargeWallet"
      | "moneyUsingWallet"
      | "timeSignIn"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: any) => any
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
   FilterTableEnum.PHONE_NUMBER,
]

export const columns: readonly Column[] = [
   {
      id: "fullName",
      label: "userTable.name",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "email",
      label: "userTable.email",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "phoneNumber",
      label: "userTable.phoneNumber",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "provinceName",
      label: "userTable.province",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "moneyChargeWallet",
      label: "userTable.moneyChargeWallet",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "moneyUsingWallet",
      label: "userTable.moneyUsingWallet",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "timeSignIn",
      label: "userTable.timeSignIn",
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   fullName: string,
   email: string,
   phoneNumber: string,
   provinceName: string,
   moneyChargeWallet: number,
   moneyUsingWallet: number,
   timeSignIn: number
): TypeUserDetailInterface {
   return {
      id,
      fullName,
      email,
      phoneNumber,
      provinceName,
      moneyChargeWallet,
      moneyUsingWallet,
      timeSignIn,
   }
}
