import { handleSortOrder } from "./../../common/helper/sortTable.helper"
import { SortColumnEnum } from "./../../common/enum/sortColumn.enum"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ManagementUserInterface } from "../../common/interfaces/managementUser.interface"
import { MultipleSearchConditionState } from "../../common/interfaces/searchConditionParam"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { ManagementUserCountInterface } from "../../common/interfaces/managementUserCount.interface"

export interface ManagementUserState {
   requesting: boolean
   requestingCount: boolean
   excelExporting: boolean
   successful: boolean
   successfulCount: boolean
   error: string
   errorCount: string
   data: ManagementUserInterface[]
   dataCount: ManagementUserCountInterface
   rowCount: number
   paging: PagingState
   searchCondition: MultipleSearchConditionState[]
   sortCondition: SortConditionInterface
}

const initialState: ManagementUserState = {
   requesting: false,
   requestingCount: false,
   excelExporting: false,
   successful: false,
   successfulCount: false,
   error: "",
   errorCount: "",
   data: [],
   dataCount: {
      totalUser: 0,
      totalUserActive: 0,
      totalUserExpiredDate: 0,
      totalUserLock: 0,
   },
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 1000,
   },
   searchCondition: [],
   sortCondition: {
      column: SortColumnEnum.NONE,
      order: SortOrderEnum.ASC,
   },
}

export const managementUserSlice = createSlice({
   name: "managementUser",
   initialState,
   reducers: {
      requestManagementUser: (state: ManagementUserState) => {
         state.requesting = true
      },
      requestManagementUserCount: (state: ManagementUserState) => {
         state.requestingCount = true
      },
      getManagementUserCountSuccess: (
         state: ManagementUserState,
         action: PayloadAction<any>
      ) => {
         state.requestingCount = false
         state.successfulCount = true
         state.errorCount = ""
         state.dataCount.totalUser = action.payload.totalUser
         state.dataCount.totalUserActive = action.payload.totalUserActive
         state.dataCount.totalUserExpiredDate =
            action.payload.totalUserExpiredDate
         state.dataCount.totalUserLock = action.payload.totalUserLock
      },
      getManagementUserCountFail: (
         state: ManagementUserState,
         action: PayloadAction<any>
      ) => {
         state.requestingCount = false
         state.successfulCount = false
         state.errorCount = action.payload
         state.dataCount.totalUser = action.payload.totalUser
         state.dataCount.totalUserActive = action.payload.totalUserActive
         state.dataCount.totalUserExpiredDate =
            action.payload.totalUserExpiredDate
         state.dataCount.totalUserLock = action.payload.totalUserLock
      },
      getManagementUserSuccess: (
         state: ManagementUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data
         state.rowCount = action.payload.rowCount
      },
      getManagementUserFail: (
         state: ManagementUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: ManagementUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: ManagementUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: ManagementUserState,
         action: PayloadAction<MultipleSearchConditionState[]>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition = action.payload
      },
      changeSortConditionSuccess: (
         state: ManagementUserState,
         action: PayloadAction<any>
      ) => {
         state.sortCondition.order = handleSortOrder(
            state.sortCondition.column,
            action.payload.column,
            state.sortCondition.order
         )
         state.sortCondition.column = action.payload.column
      },
      startExportExcelManagementUser: (state: ManagementUserState) => {
         state.excelExporting = true
      },
      finishExportExcelManagementUser: (state: ManagementUserState) => {
         state.excelExporting = false
      },
   },
})

export const {
   requestManagementUser,
   requestManagementUserCount,
   getManagementUserCountSuccess,
   getManagementUserCountFail,
   getManagementUserSuccess,
   getManagementUserFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   startExportExcelManagementUser,
   finishExportExcelManagementUser,
} = managementUserSlice.actions

export default managementUserSlice.reducer
