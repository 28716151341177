import { FilterTableEnum } from "../../../../common/enum/filterTable.enum"
import { DeparmentUserAddingInterface } from "../../../../common/interfaces/departmentUserAdding.interface"
import { ProvinceInterface } from "../../../../common/interfaces/province.interface"

export interface Column {
   id: "fullname" | "email" | "phoneNumber" | "province"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: any) => any
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
   FilterTableEnum.PHONE_NUMBER,
]

export const columns: readonly Column[] = [
   {
      id: "fullname",
      label: "userTable.name",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "email",
      label: "userTable.email",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "phoneNumber",
      label: "userTable.phoneNumber",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "province",
      label: "userTable.province",
      minWidth: 170,
      maxWidth: 400,
      format: (value: ProvinceInterface) => {
         return value?.name
      },
   },
]

export function createData(
   id: string,
   fullname: string,
   email: string,
   phoneNumber: string,
   province: ProvinceInterface
): DeparmentUserAddingInterface {
   return { id, fullname, email, phoneNumber, province }
}
