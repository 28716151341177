import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { convertDate } from "../../common/helper/date.helper"
import { AppsInterface } from "../../common/interfaces/apps.interface"

export interface Column {
   id: "name" | "isActive" | "createdAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: number | string | boolean) => string
}

export const filterTypes: FilterTableEnum[] = [FilterTableEnum.APP_NAME]
export const columns: readonly Column[] = [
   {
      id: "name",
      label: "appsTable.name",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "isActive",
      label: "appsTable.status",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createdAt",
      label: "appsTable.createdAt",
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   name: string,
   isActive: boolean,
   createdAt: string,
   zaloOaId: string,
   zaloUserName: string,
   zaloOtpTemplateId: string,
   isUseOtpSystem: boolean
): AppsInterface {
   createdAt = convertDate(createdAt)
   return {
      id,
      name,
      isActive,
      createdAt,
      zaloOaId,
      zaloUserName,
      zaloOtpTemplateId,
      isUseOtpSystem,
   }
}
