import { useDispatch, useSelector } from "react-redux"
import { updateManagementUserDetailInfoAsync } from "../../../states/managementUserDetail/managementUserDetail.action"
import {
   selectDataInfoManagementUserDetailState,
   selectErrorInfoManagementUserDetailState,
   selectModeEditInfoManagementUserDetailState,
   selectRequestingInfoManagementUserDetailState,
} from "../../../states/managementUserDetail/managementUserDetail.selector"
import { ManagementUserDetailInfoComponent } from "./managementUserDetailInfo.component"
import { ManagementUserDetailInfoInput } from "./managementUserDetailInfo.form"
import { setEditManagementUserDetailInfo } from "../../../states/managementUserDetail/managementUserDetail.slice"

export const ManagementUserDetailInfoContainer = () => {
   const dispatch = useDispatch()

   // Selector
   const requesting = useSelector(selectRequestingInfoManagementUserDetailState)
   const modeEdit = useSelector(selectModeEditInfoManagementUserDetailState)
   const error = useSelector(selectErrorInfoManagementUserDetailState)
   const data = useSelector(selectDataInfoManagementUserDetailState)

   /**
    * Handle update
    * @param values
    */
   const handleUpdateManagementUserDetailInfoAsync = (
      values: ManagementUserDetailInfoInput
   ) => {
      dispatch(updateManagementUserDetailInfoAsync(values))
   }

   /**
    * Handle set edit mode
    * @param value
    */
   const setModeEditAsync = (value: boolean) => {
      dispatch(setEditManagementUserDetailInfo(value))
   }

   return (
      <ManagementUserDetailInfoComponent
         requesting={requesting}
         modeEdit={modeEdit}
         error={error}
         data={data}
         setModeEditAsync={setModeEditAsync}
         handleUpdateManagementUserDetailInfoAsync={
            handleUpdateManagementUserDetailInfoAsync
         }
      />
   )
}
