import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ManagementUserChargeHistoryInterface } from "../../common/interfaces/managementUserChargeHistory.interface"
import { SearchConditionWalletState } from "../../common/interfaces/searchConditionParam"
import { FilterTableWalletEnum } from "../../common/enum/filterTableWallet.enum"
import dayjs from "dayjs"
import { convertDay } from "../../common/helper/date.helper"

export interface ManagementUserChargeHistoryState {
   selectedId: string
   requesting: boolean
   successful: boolean
   error: string
   data: ManagementUserChargeHistoryInterface
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionWalletState
}

const initialState: ManagementUserChargeHistoryState = {
   selectedId: "",
   requesting: false,
   successful: false,
   error: "",
   data: {
      amount: "0",
      expireDate: "",
      fullName: "",
      transactions: [],
   },
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableWalletEnum.ALL,
      from: dayjs(new Date()),
      to: dayjs(new Date()),
   },
}

export const managementUserChargeHistorySlice = createSlice({
   name: "managementUserChargeHistory",
   initialState,
   reducers: {
      requestManagementUserChargeHistory: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<string>
      ) => {
         state.requesting = true
         state.selectedId = action.payload
      },
      getManagementUserChargeHistorySuccess: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data.fullName = action.payload.fullName
         state.data.amount = action.payload.amount
         state.data.expireDate = convertDay(action.payload.expireDate)
         state.data.transactions = action.payload.transactions.data
         state.rowCount = action.payload.transactions.rowCount
      },
      getManagementUserChargeHistoryFail: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = {
            amount: "0",
            expireDate: "",
            fullName: "",
            transactions: [],
         }
      },
      changePageNumberSuccess: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: ManagementUserChargeHistoryState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.from = action.payload.from
         state.searchCondition.to = action.payload.to
      },
   },
})

export const {
   requestManagementUserChargeHistory,
   getManagementUserChargeHistorySuccess,
   getManagementUserChargeHistoryFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
} = managementUserChargeHistorySlice.actions

export default managementUserChargeHistorySlice.reducer
