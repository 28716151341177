import { useCallback, useEffect, useState } from "react"
import {
   getDepartmentUserAddingAPI,
   GetDepartmentUserAddingPayloadAPIInterface,
} from "../../../../api/departmentUser.api"
import { SortColumnEnum } from "../../../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../../../common/enum/sortOrder.enum"
import { SearchConditionState } from "../../../../common/interfaces/searchConditionParam"
import { DepartmentUserAddingComponent } from "./departmentUserAdding.component"
import { DeparmentUserAddingInterface } from "../../../../common/interfaces/departmentUserAdding.interface"
import { FilterTableEnum } from "../../../../common/enum/filterTable.enum"

type DepartmentUserAddingContainerProps = {
   addUsers: DeparmentUserAddingInterface[]
   handleAddUserToDepartment: (user: DeparmentUserAddingInterface) => void
   handleRemoveUserToDepartment: (user: DeparmentUserAddingInterface) => void
}

export const DepartmentUserAddingContainer = (
   props: DepartmentUserAddingContainerProps
) => {
   const [requesting, setRequesting] = useState<boolean>(false)
   const [pageSize, setPageSize] = useState<number>(10)
   const [pageNumber, setPageNumber] = useState<number>(1)
   const [searchCondition, setSearchCondition] = useState<SearchConditionState>(
      {
         option: FilterTableEnum.NONE,
         value: "",
      }
   )
   const [rowCount, setRowCount] = useState<number>(0)
   const [userData, setUserData] = useState<any[]>([])
   const [error, setError] = useState<string>("")

   /**
    * Handle get department user adding async
    */
   const handleGetDepartmentUserAddingAsync = useCallback(() => {
      setRequesting(true)
      const params: GetDepartmentUserAddingPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: SortColumnEnum.NONE,
            order: SortOrderEnum.ASC,
         },
      }
      getDepartmentUserAddingAPI(params)
         .then((res: any) => {
            setUserData(res.data.data)
            setRowCount(res.data.rowCount)
         })
         .catch(() => {
            setError("table.error")
         })
         .finally(() => {
            setRequesting(false)
         })
   }, [pageNumber, pageSize, searchCondition])

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage + 1)
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setPageSize(Number(event.target.value))
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      setSearchCondition({
         value: value,
         option: option as FilterTableEnum,
      })
   }

   useEffect(() => {
      handleGetDepartmentUserAddingAsync()
   }, [
      pageSize,
      pageNumber,
      searchCondition,
      handleGetDepartmentUserAddingAsync,
   ])

   return (
      <DepartmentUserAddingComponent
         requesting={requesting}
         pageSize={pageSize}
         pageNumber={pageNumber}
         rowCount={rowCount}
         userData={userData}
         error={error}
         addUsers={props.addUsers}
         searchCondition={searchCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleAddUserToDepartment={props.handleAddUserToDepartment}
         handleRemoveUserToDepartment={props.handleRemoveUserToDepartment}
      />
   )
}
