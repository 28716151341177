import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import LinearProgress from "@mui/material/LinearProgress"
import Paper from "@mui/material/Paper"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FilterTableComponent } from "../../common/components/FilterTable/filterTable.component"
import { EmailInterface } from "../../common/interfaces/email.interface"
import { namespaces } from "../../i18n/i18n.constants"
import AddIcon from "@mui/icons-material/Add"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import CircularProgress from "@mui/material/CircularProgress"
import commonStyles from "../../common/styles/common.module.css"
import Typography from "@mui/material/Typography"
import TablePagination from "@mui/material/TablePagination"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { columns, createData, filterTypes } from "./email.table"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"
import { useEmailPermission } from "./email.permission"
import { Tooltip, IconButton, TableSortLabel } from "@mui/material"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { createSortDirection } from "../../common/helper/sortTable.helper"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { visuallyHidden } from "@mui/utils"
import { MarketingStatusComponent } from "../../common/components/MarketingStatus/marketingStatus.component"
import { MarketingStatus } from "../../common/enum/marketingStatus.enum"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import ReplayIcon from "@mui/icons-material/Replay"

type EmailComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   emailData: any[]
   error: string
   searchCondition: SearchConditionState
   sortCondition: SortConditionInterface
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
   handleCreateEmail: () => void
   handleEditEmail: (id: string) => void
   handleDeleteAsync: (id: string) => Promise<any>
   handleStartAsync: (id: string) => Promise<any>
   handleRestartAsync: (id: string) => Promise<any>
   handlePauseAsync: (id: string) => Promise<any>
   handleReloadGetEmail: () => void
}

export const EmailComponent = (props: EmailComponentProps) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const {
      allowAddEmail,
      allowUpdateEmail,
      allowDeleteEmail,
      allowStartEmail,
      allowPauseEmail,
      allowReStartEmail,
   } = useEmailPermission()

   const { t } = useTranslation(namespaces.pages.email)

   // Type list
   const rows = props.emailData.map((email) =>
      createData(
         email.id,
         email.name,
         email.startDate,
         email.endDate,
         email.time,
         email.isActive,
         email.createdAt,
         email.type
      )
   )

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.NONE
   )
   const [selectedDialog, setSelectedDialog] = useState<EmailInterface | null>(
      null
   )

   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: EmailInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.DELETE && t("dialog.delete")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeleteEmail}
               >
                  {t("button.delete")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.START && (
               <Button
                  color="secondary"
                  disabled={requestingDialog}
                  onClick={onStartEmail}
               >
                  {t("button.start")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.RESTART && (
               <Button
                  color="secondary"
                  disabled={requestingDialog}
                  onClick={onRestartEmail}
               >
                  {t("button.restart")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.PAUSE && (
               <Button
                  color="secondary"
                  disabled={requestingDialog}
                  onClick={onPauseEmail}
               >
                  {t("button.pause")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requestingDialog}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.START) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.startEmailConfirm")}
               </Typography>
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.RESTART) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.restartEmailConfirm")}
               </Typography>
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.PAUSE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.pauseEmailConfirm")}
               </Typography>
            </DialogContent>
         )
      }
      return (
         <DialogContent>
            <Typography mb={1} variant="body1">
               {t("dialog.deleteEmailConfirm")}
            </Typography>
         </DialogContent>
      )
   }

   /**
    * On delete email
    */
   const onDeleteEmail = () => {
      setRequestingDialog(true)
      if (!selectedDialog) {
         return
      }
      props
         .handleDeleteAsync(selectedDialog.id)
         .then((res: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xoá chiến dịch thành công!", "success")
            props.handleReloadGetEmail()
            handleCloseDialog()
         })
         .catch((err: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xoá chiến dịch thất bại!", "error")
            handleCloseDialog()
         })
   }

   /**
    * On pause email
    */
   const onPauseEmail = () => {
      setRequestingDialog(true)
      if (!selectedDialog) {
         return
      }
      props
         .handlePauseAsync(selectedDialog.id)
         .then((res: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Tạm dừng chiến dịch thành công!",
               "success"
            )
            props.handleReloadGetEmail()
            handleCloseDialog()
         })
         .catch((err: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Tạm dừng chiến dịch thất bại!", "error")
            handleCloseDialog()
         })
   }

   /**
    * On start email
    */
   const onStartEmail = () => {
      setRequestingDialog(true)
      if (!selectedDialog) {
         return
      }
      props
         .handleStartAsync(selectedDialog.id)
         .then((res: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Khởi động chiến dịch thành công!",
               "success"
            )
            props.handleReloadGetEmail()
            handleCloseDialog()
         })
         .catch((err: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Khởi động chiến dịch thất bại!", "error")
            handleCloseDialog()
         })
   }

   /**
    * On restart email
    */
   const onRestartEmail = () => {
      setRequestingDialog(true)
      if (!selectedDialog) {
         return
      }
      props
         .handleRestartAsync(selectedDialog.id)
         .then((res: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Khởi động lại chiến dịch thành công!",
               "success"
            )
            handleCloseDialog()
         })
         .catch((err: any) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Khởi động lại chiến dịch thất bại!",
               "error"
            )
            handleCloseDialog()
         })
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        types={filterTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={12} xl={4}>
                           {allowAddEmail && (
                              <Button
                                 fullWidth
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() => props.handleCreateEmail()}
                              >
                                 {t("button.add")}
                              </Button>
                           )}
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {column.sortKey ? (
                                 <TableSortLabel
                                    active={
                                       props.sortCondition.column ===
                                       column.sortKey
                                    }
                                    direction={sortDirection}
                                    onClick={createSortHandler(column.sortKey)}
                                 >
                                    {t(column.label)}
                                    {props.sortCondition.column ===
                                    column.sortKey ? (
                                       <Box
                                          component="span"
                                          sx={visuallyHidden}
                                       >
                                          {props.sortCondition.order ===
                                          SortOrderEnum.DESC
                                             ? "Giảm"
                                             : "Tăng"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              ) : (
                                 t(column.label)
                              )}
                           </TableCell>
                        ))}
                        <TableCell key={"action"}>
                           {t("emailTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 let value = row[column.id]
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {column.id === "isActive" ? (
                                          <MarketingStatusComponent
                                             status={
                                                value
                                                   ? MarketingStatus.ACTIVE
                                                   : MarketingStatus.PAUSE
                                             }
                                          ></MarketingStatusComponent>
                                       ) : (
                                          <div>
                                             {column.format
                                                ? column.format(value)
                                                : value}
                                          </div>
                                       )}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 {row.type === 1 &&
                                    row.isActive &&
                                    allowReStartEmail && (
                                       <Tooltip title={t("button.restart")}>
                                          <IconButton
                                             aria-label="restart"
                                             onClick={() =>
                                                handleOpenDialog(
                                                   DialogMethodEnum.RESTART,
                                                   row
                                                )
                                             }
                                          >
                                             <ReplayIcon />
                                          </IconButton>
                                       </Tooltip>
                                    )}
                                 {row.type !== 1 &&
                                    !row.isActive &&
                                    allowStartEmail && (
                                       <Tooltip title={t("button.start")}>
                                          <IconButton
                                             aria-label="start"
                                             onClick={() =>
                                                handleOpenDialog(
                                                   DialogMethodEnum.START,
                                                   row
                                                )
                                             }
                                          >
                                             <PlayArrowIcon />
                                          </IconButton>
                                       </Tooltip>
                                    )}
                                 {row.type !== 1 &&
                                    row.isActive &&
                                    allowPauseEmail && (
                                       <Tooltip title={t("button.pause")}>
                                          <IconButton
                                             aria-label="pause"
                                             onClick={() =>
                                                handleOpenDialog(
                                                   DialogMethodEnum.PAUSE,
                                                   row
                                                )
                                             }
                                          >
                                             <PauseIcon />
                                          </IconButton>
                                       </Tooltip>
                                    )}
                                 {allowUpdateEmail && (
                                    <Tooltip title={t("button.update")}>
                                       <IconButton
                                          aria-label="update"
                                          onClick={() =>
                                             props.handleEditEmail(row.id)
                                          }
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowDeleteEmail && (
                                    <Tooltip title={t("button.delete")}>
                                       <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DELETE,
                                                row
                                             )
                                          }
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <Dialog
            fullScreen={fullScreen}
            maxWidth={"sm"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box sx={{ width: "100%" }}>
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Container>
   )
}
