import Container from "@mui/material/Container"
import Grid2 from "@mui/material/Unstable_Grid2"
import styles from "./outOfSession.module.css"
import { tokenWallpage } from "../../images/images"
import { Footer } from "../../common/components/Footer/footer"
import React from "react"
import commonStyles from "../../common/styles/common.module.css"
import clsx from "clsx"
import { TextLogo } from "../../common/components/TextLogo/textLogo"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import { useNavigate } from "react-router-dom"

export const OutOfSessionComponent = () => {
   const navigate = useNavigate()
   const { t } = useTranslation(namespaces.pages.outOfSession)

   /**
    * Handle sign in
    */
   const handeSignIn = () => {
      navigate("/sign-in")
   }

   return (
      <React.Fragment>
         <Container maxWidth="xl">
            <Grid2 container>
               <Grid2 xs={12} xl={8}>
                  <div
                     className={clsx(
                        commonStyles.flexCenterCenter,
                        styles.wallpaper
                     )}
                  >
                     <img src={tokenWallpage} alt="Token Wallpaper"></img>
                  </div>
               </Grid2>
               <Grid2 xs={12} xl={4}>
                  <div
                     className={clsx(
                        commonStyles.flexColumnCenterCenter,
                        styles.form
                     )}
                  >
                     <TextLogo introduce={t("introduce")}></TextLogo>
                     <Box sx={{ width: "100%" }} mt={8}>
                        <Typography mb={5}>{t("content")}</Typography>
                        <Stack
                           className={styles.buttonStack}
                           mt={2}
                           spacing={2}
                           direction="row"
                        >
                           <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handeSignIn}
                           >
                              {t("buttons.signIn", { ns: "common" })}
                           </Button>
                        </Stack>
                        <Divider className={styles.divider} />
                     </Box>
                  </div>
               </Grid2>
               <Grid2 xs={12}>
                  <Footer></Footer>
               </Grid2>
            </Grid2>
         </Container>
      </React.Fragment>
   )
}
