import { object, string, TypeOf } from "zod"

export const managementUserDetailCompanySchema = object({
   name: string().optional().nullable(),
   taxCode: string().optional().nullable(),
   address: string().optional().nullable(),
   phoneNumber: string().optional().nullable(),
   email: string().optional().nullable(),
})

export type ManagementUserDetailCompanyInput = TypeOf<
   typeof managementUserDetailCompanySchema
>
