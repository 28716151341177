import Card from "@mui/material/Card"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import CardActions from "@mui/material/CardActions"
import Button from "@mui/material/Button"
import Badge from "@mui/material/Badge"
import Divider from "@mui/material/Divider"
import { ManagementWalletPackInterface } from "../../../common/interfaces/managementWalletPack.interface"
import { namespaces } from "../../../i18n/i18n.constants"
import { useTranslation } from "react-i18next"
import {
   genVnPayPaymentUrl,
   updateTransactionNoVnPayPaymentUrl,
} from "../../../common/helper/vnPayCheckOut.helper"
import {
   PostPaymentResultAPIInterface,
   postPaymentResultAPI,
} from "../../../api/paymentResult.api"
import { useDispatch } from "react-redux"
import { pushNotistack } from "../../../states/notistack/notistack.slice"

type ManagementWalletPackProps = {
   pack: ManagementWalletPackInterface
}

export const ManagementWalletPackComponent = (
   props: ManagementWalletPackProps
) => {
   const dispatch = useDispatch()
   const { t } = useTranslation(namespaces.pages.managementWalletRecharge)

   /**
    * Handle checkout
    */
   const handleCheckout = () => {
      let payment = genVnPayPaymentUrl(props.pack.amount)
      // Sent to API
      const param: PostPaymentResultAPIInterface = {
         vnp_Amount: payment.params.vnp_Amount,
         vnp_BankCode: payment.params.vnp_BankCode,
         vnp_CardType: payment.params.vnp_CardType,
         vnp_OrderInfo: payment.params.vnp_OrderInfo,
         vnp_PayDate: payment.params.vnp_PayDate,
         vnp_ResponseCode: payment.params.vnp_ResponseCode,
         vnp_TmnCode: payment.params.vnp_TmnCode,
         vnp_TransactionNo: payment.params.vnp_TransactionNo,
         vnp_TransactionStatus: payment.params.vnp_TransactionStatus,
         vnp_TxnRef: payment.params.vnp_TxnRef,
         vnp_SecureHash: payment.params.vnp_SecureHash,
         vnp_RechargePackageId: props.pack.id,
         vnp_PromotionPackageId: props.pack.promotionId,
      }
      postPaymentResultAPI(param)
         .then((res) => {
            let transactionNo = res.response.data.transactionNo
            // if (transactionNo) {
            payment = updateTransactionNoVnPayPaymentUrl(
               transactionNo,
               payment.params
            )
            // }
            window.location.href = payment.url ? payment.url : ""
         })
         .catch((err) => {
            dispatch(
               pushNotistack({
                  message: "Hệ thống thanh toán đang gặp lỗi!",
                  variant: "error",
               })
            )
         })
   }

   const getCoinByPercent = (coin: number, percent: number) => {
      if (percent > 0) {
         const result = (coin * percent) / 100
         return t("coin") + coin + " + " + result.toFixed()
      }
      return t("coin") + coin
   }

   return (
      <Badge
         color="success"
         badgeContent={
            props.pack.discount ? props.pack.discount + "%" : undefined
         }
         sx={{
            width: "100%",
         }}
      >
         <Card sx={{ width: "100%", paddingBottom: "10px" }}>
            <CardHeader
               avatar={<MonetizationOnIcon></MonetizationOnIcon>}
               title={<Typography variant="h6">{props.pack.name}</Typography>}
            />
            <Divider variant="middle" />
            <CardContent>
               <Typography
                  sx={{
                     overflowWrap: "break-word",
                  }}
                  variant="h4"
               >
                  {props.pack.amount.toLocaleString("it-IT", {
                     style: "currency",
                     currency: "VND",
                  })}
               </Typography>
               {props.pack.bonus && (
                  <Typography
                     sx={{
                        overflowWrap: "break-word",
                     }}
                     variant="overline"
                     gutterBottom
                  >
                     {"+ " +
                        props.pack.bonus.toLocaleString("it-IT", {
                           style: "currency",
                           currency: "VND",
                        })}
                  </Typography>
               )}
               <Typography
                  sx={{
                     overflowWrap: "break-word",
                  }}
                  variant="body2"
               >
                  {t("addDay") + props.pack.addDay}
               </Typography>
               <Typography
                  sx={{
                     overflowWrap: "break-word",
                  }}
                  variant="body2"
               >
                  {getCoinByPercent(props.pack.coin, props.pack.discount)}
               </Typography>
            </CardContent>
            <CardActions>
               <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleCheckout}
               >
                  {t("button.select")}
               </Button>
            </CardActions>
         </Card>
      </Badge>
   )
}
