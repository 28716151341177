import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { ManagementUserChargeHistoryState } from "./managementUserChargeHistory.slice"

const selectManagementUserChargeHistoryState = (
   state: RootState
): ManagementUserChargeHistoryState => state.managementUserChargeHistory

export const selectRequestingManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.requesting
)

export const selectSelectedIdManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.selectedId
)

export const selectDataManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.data
)

export const selectErrorManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.error
)

export const selectCountManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.rowCount
)

export const selectPageNumberManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.paging.pageNumber
)

export const selectPageSizeManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.paging.pageSize
)

export const selectSearchConditionManagementUserChargeHistory = createSelector(
   selectManagementUserChargeHistoryState,
   (state: ManagementUserChargeHistoryState) => state.searchCondition
)
