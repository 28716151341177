import { ChartEnum } from "../enum/chart.enum"
import { AppInterface } from "../interfaces/app.interface"
import { ProvinceInterface } from "../interfaces/province.interface"

export interface FilterChartInterFace {
   id: ChartEnum
   text: string
}

export const filterChartConst: FilterChartInterFace[] = [
   {
      id: ChartEnum.DAY,
      text: "filter.day",
   },
   {
      id: ChartEnum.MONTH,
      text: "filter.month",
   },
   {
      id: ChartEnum.YEAR,
      text: "filter.year",
   },
   {
      id: ChartEnum.APP,
      text: "filter.apps",
   },
   {
      id: ChartEnum.PROVINCE,
      text: "filter.province",
   },
]

export const defaultProvince: ProvinceInterface = { id: "all", name: "Tất cả" }

export const defaultApp: AppInterface = { id: "all", name: "Tất cả" }
