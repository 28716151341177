import { Chip } from "@mui/material"
import { UserStatus } from "../../enum/userStatus.enum"
import { getLabelUserStatus } from "../../helper/userStatus.helper"
import React from "react"

type UserStatusProp = {
   status: UserStatus[]
}

export const UserStatusComponent = (props: UserStatusProp) => {
   const getColor = (status: string) => {
      if (status === UserStatus.ACTIVE) {
         return "success"
      }
      if (status === UserStatus.BLOCK) {
         return "error"
      }
      if (status === UserStatus.EXPIRED) {
         return "warning"
      }
      if (status === UserStatus.EXPIREDDATE) {
         return "secondary"
      }
      return undefined
   }

   return (
      <React.Fragment>
         {props.status.map((status) => {
            return (
               <Chip
                  style={{ margin: 2, width: 100 }}
                  label={getLabelUserStatus(status)}
                  color={getColor(status)}
               />
            )
         })}
      </React.Fragment>
   )
}
