import { ManagementUserHistoryInterface } from "../../../common/interfaces/managementUserHistory.interface"
import { convertDate } from "../../../common/helper/date.helper"
import { FilterTableEnum } from "../../../common/enum/filterTable.enum"

export interface Column {
   id: "invokeAt" | "featureName" | "application" | "isSuccess"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
   FilterTableEnum.PHONE_NUMBER,
]

export const columns: readonly Column[] = [
   {
      id: "featureName",
      label: "userHistoryTable.featureName",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "application",
      label: "userHistoryTable.application",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "invokeAt",
      label: "userHistoryTable.invokeAt",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "isSuccess",
      label: "userHistoryTable.isSuccess",
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   invokeAt: string,
   featureName: string,
   application: string,
   isSuccess: boolean
): ManagementUserHistoryInterface {
   invokeAt = convertDate(invokeAt)
   return { id, invokeAt, featureName, application, isSuccess }
}
