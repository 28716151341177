import { createAction } from "@reduxjs/toolkit"

export const checkAuthAsync = createAction("auth/checkAuthAsync")

export const signInAsync = createAction<any>("auth/signInAsync")

export const resetSignInAsync = createAction("auth/resetSignInAsync")

export const logoutAsync = createAction<any>("auth/logoutAsync")

export const refreshTokenAsync = createAction<string>("auth/refreshTokenAsync")
