import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ManagementUserDetailCompanyInterface } from "../../common/interfaces/managementUserDetailCompany.interface"
import { ManagementUserDetailInfoInterface } from "../../common/interfaces/managementUserDetailInfo.interface"
import { ManagementUserDetailWalletInterface } from "../../common/interfaces/managementUserDetailWallet.interface"

export interface ManagementUserDetailState {
   selectedId: string
   editCompany: boolean
   editInfo: boolean
   editWallet: boolean
   requestingCompany: boolean
   requestingInfo: boolean
   requestingWallet: boolean
   successfulCompany: boolean
   successfulInfo: boolean
   successfulWallet: boolean
   errorCompany: string
   errorInfo: string
   errorWallet: string
   dataCompany: ManagementUserDetailCompanyInterface | null
   dataInfo: ManagementUserDetailInfoInterface | null
   dataWallet: ManagementUserDetailWalletInterface | null
}

const initialState: ManagementUserDetailState = {
   selectedId: "",
   editCompany: false,
   editInfo: false,
   editWallet: false,
   requestingCompany: false,
   requestingInfo: false,
   requestingWallet: false,
   successfulCompany: false,
   successfulInfo: false,
   successfulWallet: false,
   errorCompany: "",
   errorInfo: "",
   errorWallet: "",
   dataCompany: null,
   dataInfo: null,
   dataWallet: null,
}

export const managementUserDetailSlice = createSlice({
   name: "managementUserDetail",
   initialState,
   reducers: {
      requestManagementUserDetail: (
         state: ManagementUserDetailState,
         action: PayloadAction<string>
      ) => {
         state.selectedId = action.payload
      },
      setEditManagementUserDetailInfo: (
         state: ManagementUserDetailState,
         action: PayloadAction<boolean>
      ) => {
         state.editInfo = action.payload
      },
      setEditManagementUserDetailCompany: (
         state: ManagementUserDetailState,
         action: PayloadAction<boolean>
      ) => {
         state.editCompany = action.payload
      },
      setEditManagementUserDetailWallet: (
         state: ManagementUserDetailState,
         action: PayloadAction<boolean>
      ) => {
         state.editWallet = action.payload
      },
      requestManagementUserDetailInfo: (state: ManagementUserDetailState) => {
         state.requestingInfo = true
      },
      requestManagementUserDetailCompany: (
         state: ManagementUserDetailState
      ) => {
         state.requestingCompany = true
      },
      requestManagementUserDetailWallet: (state: ManagementUserDetailState) => {
         state.requestingWallet = true
      },
      requestManagementUserDetailInfoSuccess: (
         state: ManagementUserDetailState,
         action: PayloadAction<ManagementUserDetailInfoInterface>
      ) => {
         state.requestingInfo = false
         state.dataInfo = action.payload
      },
      requestManagementUserDetailInfoFail: (
         state: ManagementUserDetailState,
         action: PayloadAction<string>
      ) => {
         state.requestingInfo = false
         state.errorInfo = action.payload
      },
      requestManagementUserDetailInfoEnd: (
         state: ManagementUserDetailState
      ) => {
         state.requestingInfo = false
      },
      requestManagementUserDetailCompanySuccess: (
         state: ManagementUserDetailState,
         action: PayloadAction<ManagementUserDetailCompanyInterface>
      ) => {
         state.requestingCompany = false
         state.dataCompany = action.payload
      },
      requestManagementUserDetailCompanyFail: (
         state: ManagementUserDetailState,
         action: PayloadAction<string>
      ) => {
         state.requestingCompany = false
         state.errorCompany = action.payload
      },
      requestManagementUserDetailCompanyEnd: (
         state: ManagementUserDetailState
      ) => {
         state.requestingCompany = false
      },
      requestManagementUserDetailWalletSuccess: (
         state: ManagementUserDetailState,
         action: PayloadAction<ManagementUserDetailWalletInterface>
      ) => {
         state.requestingWallet = false
         state.dataWallet = action.payload
      },
      requestManagementUserDetailWalletFail: (
         state: ManagementUserDetailState,
         action: PayloadAction<string>
      ) => {
         state.requestingWallet = false
         state.errorWallet = action.payload
      },
   },
})

export const {
   setEditManagementUserDetailInfo,
   setEditManagementUserDetailCompany,
   setEditManagementUserDetailWallet,
   requestManagementUserDetail,
   requestManagementUserDetailInfo,
   requestManagementUserDetailInfoSuccess,
   requestManagementUserDetailInfoFail,
   requestManagementUserDetailInfoEnd,
   requestManagementUserDetailCompany,
   requestManagementUserDetailCompanySuccess,
   requestManagementUserDetailCompanyFail,
   requestManagementUserDetailCompanyEnd,
   requestManagementUserDetailWallet,
   requestManagementUserDetailWalletSuccess,
   requestManagementUserDetailWalletFail,
} = managementUserDetailSlice.actions

export default managementUserDetailSlice.reducer
