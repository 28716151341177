/**
 * Get short value
 * @param value
 * @returns
 */
export const getShortValue = (length: number, value: any) => {
   if (typeof value === "object" && value.name && value.name.length > length) {
      const copy = JSON.parse(JSON.stringify(value))
      copy.name = copy.name.slice(0, length) + "..."
      return copy
   }
   if (typeof value === "string" && value.length > length) {
      return value.slice(0, length) + "..."
   }
   if (typeof value === "number" && value.toString().length > length) {
      return value.toString().slice(0, length) + "..."
   }
   return value
}
