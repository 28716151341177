import { UserStatus } from "../enum/userStatus.enum"

export const getLabelUserStatus = (status: UserStatus) => {
   if (status === UserStatus.ACTIVE) {
      return "Hoạt động"
   }
   if (status === UserStatus.BLOCK) {
      return "Khoá"
   }
   if (status === UserStatus.EXPIREDDATE) {
      return "Gần hết hạn"
   }
   if (status === UserStatus.EXPIRED) {
      return "Hết hạn"
   }
   return ""
}
