import { NotistackState } from "./notistack.slice"
import { RootState } from "./../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectNotistackState = (state: RootState): NotistackState =>
   state.notistack

export const selectNotistack = createSelector(
   selectNotistackState,
   (notistackState: NotistackState) => notistackState
)
