import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FunctionEnum } from "../../common/enum/function.enum"

export interface PermissionState {
   function: FunctionEnum[]
}

const initialState: PermissionState = {
   function: [],
}

export const permissionSlice = createSlice({
   name: "permission",
   initialState,
   reducers: {
      getPermissionSuccess: (
         state: PermissionState,
         action: PayloadAction<any[]>
      ) => {
         state.function = action.payload
            .map((e) => (e.method + "_" + e.url) as FunctionEnum)
            .sort()
      },
   },
})

export const { getPermissionSuccess } = permissionSlice.actions

export default permissionSlice.reducer
