import { createAction } from "@reduxjs/toolkit"

export const getCreateEmailUserAsync = createAction(
   "createEmailUser/getCreateEmailUserAsync"
)

export const changePageNumberAsync = createAction<number>(
   "createEmailUser/changePageNumberAsync"
)

export const changePageSizeAsync = createAction<number>(
   "createEmailUser/changePageSizeAsyncs"
)

export const changeSearchConditionAsync = createAction<any>(
   "createEmailUser/changeSearchConditionAsync"
)
