import { array, number, object, string, TypeOf } from "zod"
import { SPACE_REGEX } from "../../common/variables/regex"

const defaultErr = {
   required_name_error: "Tên gói bắt buộc nhập",
   positive: "Giá trị phải từ 1 trở đi",
   int: "Giá trị phải là số nguyên dương",
   max9: "Giá trị phải nhỏ hơn hoặc bằng 9 kí tự",
   max15: "Giá trị phải nhỏ hơn hoặc bằng 15 kí tự",
   max100: "Giá trị phải nhỏ hơn hoặc bằng 100 kí tự",
}

const defaultAmountErr = {
   required_error: "Giá gói bắt buộc nhập",
   invalid_type_error: "Giá gói nhập sai định dạng",
}

export const promotionPackDialogSchema = object({
   name: string()
      .min(1, defaultErr.required_name_error)
      .max(100, defaultErr.max100)
      .regex(new RegExp(SPACE_REGEX), defaultErr.required_name_error),
   percent: number(defaultAmountErr)
      .int(defaultErr.int)
      .positive(defaultErr.positive)
      .lte(999999999999999, defaultErr.max15),
   userType: array(
      object({
         id: string().min(1),
         name: string().min(1),
      })
   ).optional(),
})

export type PromotionPackRechargeDialogInput = TypeOf<
   typeof promotionPackDialogSchema
>
