import { useSelector } from "react-redux"
import {
   allowShowChartChargeWallet,
   allowShowChartSignIn,
   allowShowChartSignUp,
   allowShowChartUserType,
   allowShowChartUsingWallet,
} from "../../states/permission/permission.selector"

export const useChartPermission = () => {
   return {
      allowShowChartSignIn: useSelector(allowShowChartSignIn),
      allowShowChartSignUp: useSelector(allowShowChartSignUp),
      allowShowChartUsingWallet: useSelector(allowShowChartUsingWallet),
      allowShowChartChargeWallet: useSelector(allowShowChartChargeWallet),
      allowShowChartUserType: useSelector(allowShowChartUserType),
   }
}
