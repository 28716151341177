import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   exportExcelChartUsingWalletAsync,
   getChartUsingWalletAsync,
} from "../../states/chartUsingWallet/chartUsingWallet.action"
import {
   selectDataChartUsingWallet,
   selectErrorChartUsingWallet,
   selectFilterChartUsingWallet,
   selectIsDisableExport,
   selectIsDisableStatistic,
   selectRequestingChartUsingWallet,
   selectRequestingExportChartUsingWallet,
   selectSearchConditionChartUsingWallet,
} from "../../states/chartUsingWallet/chartUsingWallet.selector"
import {
   changeFilter,
   changeSearchCondtion,
} from "../../states/chartUsingWallet/chartUsingWallet.slice"
import { ChartUsingWalletComponent } from "./chartUsingWallet.component"

export const ChartUsingWalletContainer = () => {
   const dispatch = useDispatch()
   const refChart = useRef()
   // Selector chart
   const requestingChart = useSelector(selectRequestingChartUsingWallet)
   const requestingExport = useSelector(selectRequestingExportChartUsingWallet)
   const dataChart = useSelector(selectDataChartUsingWallet)
   const errorChart = useSelector(selectErrorChartUsingWallet)
   const filterChart = useSelector(selectFilterChartUsingWallet)
   const searchConditionChart = useSelector(
      selectSearchConditionChartUsingWallet
   )
   const isDisableStatistic = useSelector(selectIsDisableStatistic)
   const isDisableExport = useSelector(selectIsDisableExport)

   /**
    * Handle statistic
    */
   const handleStatistic = () => {
      dispatch(getChartUsingWalletAsync())
   }

   /**
    * Handle change search condition
    * @param data
    */
   const handleChangeSearchCondition = (data: any) => {
      dispatch(changeSearchCondtion(data))
   }

   /**
    * Handle change filter
    * @param data
    */
   const handleChangeFilter = (data: any) => {
      dispatch(changeFilter(data))
   }

   /**
    * Handle chart export
    */
   const handleChartExport = async () => {
      dispatch(exportExcelChartUsingWalletAsync({ refChart: refChart }))
   }

   return (
      <ChartUsingWalletComponent
         refChart={refChart}
         requestingChart={requestingChart}
         requestingExport={requestingExport}
         dataChart={dataChart}
         errorChart={errorChart}
         filterChart={filterChart}
         searchConditionChart={searchConditionChart}
         isDisableStatistic={isDisableStatistic}
         isDisableExport={isDisableExport}
         handleStatistic={handleStatistic}
         handleChangeFilter={handleChangeFilter}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChartExport={handleChartExport}
      />
   )
}
