import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getEmailAsync,
} from "../../states/email/email.action"
import {
   selectCountEmail,
   selectDataEmail,
   selectErrorEmail,
   selectPageNumberEmail,
   selectPageSizeEmail,
   selectRequestingEmail,
   selectSearchConditionEmail,
   selectSortConditionEmail,
} from "../../states/email/email.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { EmailComponent } from "./email.component"
import { useNavigate } from "react-router-dom"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import {
   DeleteEmailPayloadAPIInterface,
   PauseEmailPayloadAPIInterface,
   RestartEmailPayloadAPIInterface,
   StartEmailPayloadAPIInterface,
   deleteEmailAPI,
   pauseEmailAPI,
   restartEmailAPI,
   startEmailAPI,
} from "../../api/email.api"

export const EmailContainer = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   // Selector
   const requesting = useSelector(selectRequestingEmail)
   const emailData = useSelector(selectDataEmail)
   const rowCount = useSelector(selectCountEmail)
   const pageNumber = useSelector(selectPageNumberEmail)
   const pageSize = useSelector(selectPageSizeEmail)
   const error = useSelector(selectErrorEmail)
   const sortCondition = useSelector(selectSortConditionEmail)
   const searchCondition = useSelector(selectSearchConditionEmail)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle start async
    * @param values
    * @returns
    */
   const handleStartAsync = (id: string) => {
      const params: StartEmailPayloadAPIInterface = {
         id: id,
      }
      return startEmailAPI(params)
   }

   /**
    * Handle restart async
    * @param values
    * @returns
    */
   const handleRestartAsync = (id: string) => {
      const params: RestartEmailPayloadAPIInterface = {
         id: id,
      }
      return restartEmailAPI(params)
   }

   /**
    * Handle pause async
    * @param values
    * @returns
    */
   const handlePauseAsync = (id: string) => {
      const params: PauseEmailPayloadAPIInterface = {
         id: id,
      }
      return pauseEmailAPI(params)
   }

   /**
    * Handle delete async
    * @param values
    * @returns
    */
   const handleDeleteAsync = (id: string) => {
      const params: DeleteEmailPayloadAPIInterface = {
         id: id,
      }
      return deleteEmailAPI(params)
   }

   /**
    * Handle create email route
    */
   const handleCreateEmail = () => {
      navigate("/dashboard/marketing/create")
   }

   /**
    * Handle edit email route
    */
   const handleEditEmail = (id: string) => {
      navigate("/dashboard/marketing/edit/" + id)
   }

   /**
    * Handle reload get email
    */
   const handleReloadGetEmail = () => {
      dispatch(getEmailAsync())
   }

   useEffect(() => {
      dispatch(getEmailAsync())
   }, [dispatch])

   return (
      <EmailComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         emailData={emailData}
         sortCondition={sortCondition}
         searchCondition={searchCondition}
         error={error}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChangeSortCondition={handleChangeSortCondition}
         handleCreateEmail={handleCreateEmail}
         handleEditEmail={handleEditEmail}
         handleDeleteAsync={handleDeleteAsync}
         handleStartAsync={handleStartAsync}
         handleRestartAsync={handleRestartAsync}
         handlePauseAsync={handlePauseAsync}
         handleReloadGetEmail={handleReloadGetEmail}
      />
   )
}
