import {
   allowExportChartChargeWalletByApp,
   allowExportChartChargeWalletByProvince,
} from "../../states/permission/permission.selector"
import { useSelector } from "react-redux"
import {
   allowGetChartChargeWalletByDate,
   allowGetChartChargeWalletByApp,
   allowGetChartChargeWalletByProvince,
   allowExportChartChargeWalletByDate,
} from "../../states/permission/permission.selector"

export const useChartChargeWalletPermission = () => {
   return {
      allowGetChartChargeWalletByDate: useSelector(
         allowGetChartChargeWalletByDate
      ),
      allowGetChartChargeWalletByApp: useSelector(
         allowGetChartChargeWalletByApp
      ),
      allowGetChartChargeWalletByProvince: useSelector(
         allowGetChartChargeWalletByProvince
      ),
      allowExportChartChargeWalletByDate: useSelector(
         allowExportChartChargeWalletByDate
      ),
      allowExportChartChargeWalletByApp: useSelector(
         allowExportChartChargeWalletByApp
      ),
      allowExportChartChargeWalletByProvince: useSelector(
         allowExportChartChargeWalletByProvince
      ),
   }
}
