import { date, number, object, string, TypeOf } from "zod"
import {
   PHONENUMBER_REGEX,
   PHONENUMBER_VN_REGEX,
   SPACE_REGEX,
} from "../../../common/variables/regex"

export const managementUserDetailInfoSchema = object({
   province: object({
      id: string(),
      name: string(),
   })
      .optional()
      .nullable(),
   district: object({
      id: string(),
      name: string(),
   })
      .optional()
      .nullable(),
   ward: object({
      id: string(),
      name: string(),
   })
      .optional()
      .nullable(),
   name: string()
      .min(1, "Vui lòng nhập họ và tên.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập họ và tên."),
   email: string().optional().nullable(),
   phoneNumber: string()
      .min(1, "Vui lòng nhập số điện thoại.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập số điện thoại.")
      .regex(
         new RegExp(PHONENUMBER_REGEX),
         "Số điện thoại không đúng định dạng."
      )
      .regex(
         new RegExp(PHONENUMBER_VN_REGEX),
         "Số điện thoại không đúng định dạng."
      ),
   birthday: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .max(new Date(), {
         message: "Thời gian không được vượt quá ngày hiện tại",
      })
      .optional()
      .nullable()
      .default(null),
   isMale: number().optional().nullable(),
   identityCard: string().optional().nullable(),
   identityCardDate: date({
      invalid_type_error: "Thời gian không đúng định dạng",
   })
      .min(new Date("1899-12-31"), {
         message: "Thời gian không được nhỏ hơn năm 1900",
      })
      .max(new Date(), {
         message: "Thời gian không được vượt quá ngày hiện tại",
      })
      .optional()
      .nullable()
      .default(null),
   identityCardPlace: string().optional().nullable(),
   addressNumber: string().optional().nullable(),
   address: string().optional().nullable(),
   note: string().optional().nullable(),
   accountType: string(),
})

export type ManagementUserDetailInfoInput = TypeOf<
   typeof managementUserDetailInfoSchema
>
