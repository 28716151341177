import { ChartEnum } from "../enum/chart.enum"

/**
 * Convert Date to string
 * @param dateParam
 * @returns
 */
export const convertDate = (dateParam: string | null): string => {
   if (!dateParam) return ""
   const date = new Date(dateParam)
   return (
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
   )
}

/**
 * Convert Hours to string
 * @param dateParam
 * @returns
 */
export const convertHours = (dateParam: string | null): string => {
   if (!dateParam) return ""
   const date = new Date(dateParam)
   return (
      date.getHours().toString().padStart(2, "0") +
      " : " +
      date.getMinutes().toString().padStart(2, "0")
   )
}

/**
 * Convert Date to string
 * @param dateParam
 * @returns
 */
export const convertDay = (dateParam: string | null): string => {
   if (!dateParam) return ""
   const date = new Date(dateParam)
   return (
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear()
   )
}

/**
 * Get date to pay
 * @param date
 * @returns
 */
export const convertDateToPay = (date: Date): string => {
   return (
      date.getFullYear() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0") +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0")
   )
}

/**
 * Get format date for exporting excel
 * @param type
 * @param dateParam
 * @returns
 */
export const getFormatDateExportExcel = (
   type: ChartEnum,
   dateParam: string
): string => {
   const date = new Date(dateParam)
   const day = date.getDate().toString().padStart(2, "0")
   const month = (date.getMonth() + 1).toString().padStart(2, "0")
   const year = date.getFullYear().toString()
   if (
      type === ChartEnum.DAY ||
      type === ChartEnum.APP ||
      type === ChartEnum.PROVINCE
   ) {
      return day + "/" + month + "/" + year
   }

   if (type === ChartEnum.MONTH) {
      return month + "/" + year
   }

   if (type === ChartEnum.YEAR) {
      return year
   }

   return ""
}

/**
 * Get max date fpr chart filter
 * @returns
 */
export const getMaxDateChart = () => {
   const today = new Date()
   today.setDate(today.getDate() - 1)
   return today
}

/**
 * get Date for chart title
 * @param date
 * @returns
 */
export const getDateForChartTitle = (date: Date) => {
   return (
      date.getFullYear() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0")
   )
}

/**
 * Get date for export header
 * @param date
 * @returns
 */
export const getDateForHeaderExport = (date: Date) => {
   return (
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      ":" +
      date.getSeconds().toString().padStart(2, "0") +
      " " +
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear()
   )
}

export const dateToISOLikeButLocal = (date: Date) => {
   const offsetMs = date.getTimezoneOffset() * 60 * 1000
   const msLocal = date.getTime() - offsetMs
   const dateLocal = new Date(msLocal)
   const iso = dateLocal.toISOString()
   const isoLocal = iso.slice(0, 19)
   return isoLocal
}
