import { useSelector } from "react-redux"
import { selectAllowRoutes } from "../../../states/permission/permission.selector"
import { FunctionEnum } from "../../enum/function.enum"
import { SidebarComponent } from "./sidebar.component"

type SidebarContainerProps = {
   open: boolean
   setOpen: (value: boolean) => void
}

export const SidebarContainer = (props: SidebarContainerProps) => {
   const allowRoutes: FunctionEnum[] = useSelector(selectAllowRoutes)

   return (
      <SidebarComponent
         open={props.open}
         setOpen={props.setOpen}
         allowRoutes={allowRoutes}
      />
   )
}
