import { getDepartmentsAPI } from "./../../api/department.api"
import { call, select } from "redux-saga/effects"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getDepartmentsAsync,
   reloadGetDepartmentAsync,
} from "./department.action"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, takeLatest } from "redux-saga/effects"
import {
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   getDepartmentsFail,
   getDepartmentsSuccess,
   requestDepartments,
} from "./department.slice"
import { GetDepartmentsPayloadAPIInterface } from "../../api/department.api"
import {
   selectPageNumberDepartment,
   selectPageSizeDepartment,
   selectSearchConditionDepartment,
   selectSortConditionDepartment,
} from "./department.selector"
import { resetDepartmentUser } from "../departmentUser/departmentUser.slice"

/**
 * Get departments saga
 * @param action
 */
function* getDepartmentsSaga(): any {
   try {
      yield put(resetDepartmentUser())
      yield put(requestDepartments())
      const pageNumber = yield select(selectPageNumberDepartment)
      const pageSize = yield select(selectPageSizeDepartment)
      const searchCondition = yield select(selectSearchConditionDepartment)
      const sortCondition = yield select(selectSortConditionDepartment)
      const params: GetDepartmentsPayloadAPIInterface = {
         pageNumber: pageNumber,
         pageSize: pageSize,
         SearchConditions: [[searchCondition.option, searchCondition.value]],
         SortCondition: {
            column: sortCondition.column,
            order: sortCondition.order,
         },
      }
      const { response, error } = yield call(getDepartmentsAPI, params)
      if (!response) {
         throw error
      }
      yield put(getDepartmentsSuccess(response.data))
   } catch (error: any) {
      yield put(getDepartmentsFail("table.error"))
   }
}

/**
 * Change page number saga
 * @param action
 */
function* changePageNumberSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageNumberSuccess(action.payload))
      yield put(getDepartmentsAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changePageSizeSaga(action: PayloadAction<number>): any {
   try {
      yield put(changePageSizeSuccess(action.payload))
      yield put(getDepartmentsAsync())
   } catch (error) {}
}

/**
 * Change page size saga
 * @param action
 */
function* changeSearchConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSearchConditionSuccess(action.payload))
      yield put(getDepartmentsAsync())
   } catch (error) {}
}

/**
 * Reload get department  saga
 */
function* reloadGetDepartmentSaga(): any {
   try {
      yield put(getDepartmentsAsync())
   } catch (error) {}
}

/**
 * Change sort condition saga
 * @param action
 */
function* changeSortConditionSaga(action: PayloadAction<any>): any {
   try {
      yield put(changeSortConditionSuccess(action.payload))
      yield put(getDepartmentsAsync())
   } catch (error) {}
}

/**
 * Root department saga
 */
export function* departmentSaga() {
   yield takeLatest(getDepartmentsAsync, getDepartmentsSaga)
   yield takeLatest(changePageNumberAsync, changePageNumberSaga)
   yield takeLatest(changePageSizeAsync, changePageSizeSaga)
   yield takeLatest(changeSearchConditionAsync, changeSearchConditionSaga)
   yield takeLatest(reloadGetDepartmentAsync, reloadGetDepartmentSaga)
   yield takeLatest(changeSortConditionAsync, changeSortConditionSaga)
}
