import { UpdateQueriesInterface } from "../common/interfaces/updateQueries.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface UpdateUserInfoPayloadAPIInterface {
   Properties: UpdateQueriesInterface[]
}

export const updateUserInfoAPI = (
   payload: UpdateUserInfoPayloadAPIInterface
) => {
   return AxiosAuth.put("user", payload)
}

export interface ResetPasswordAPIInterface {
   currentPassword: string
   newPassword: string
}

export const resetPasswordAPI = (
   userId: string,
   payload: ResetPasswordAPIInterface
) => {
   return AxiosAuth.post("user/reset-password/" + userId, payload)
}

export interface GetUserInfoPayloadAPIInterface {
   queries: string
}

export const getUserInfoAPI = (payload: GetUserInfoPayloadAPIInterface) => {
   return AxiosAuth.get("user?queries=" + payload.queries)
}

export const getConfigurationAPI = () => {
   return AxiosAuth.get("configuration")
}

export interface ConfigurationPayloadAPIInterface {
   id: string
   value: number
}

export interface UpdateConfigurationPayloadAPIInterface {
   configurations: ConfigurationPayloadAPIInterface[]
}

export const updateConfigurationAPI = (
   payload: UpdateConfigurationPayloadAPIInterface
) => {
   return AxiosAuth.put("configuration", payload)
}
