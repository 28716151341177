import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { convertCurrency } from "../../common/helper/currency.helper"
import { convertDate } from "../../common/helper/date.helper"
import { PromotionPackInterface } from "../../common/interfaces/promotionPack.interface"
import { TypeUserSelectInterface } from "../../common/interfaces/typeUser.interface"

export interface Column {
   id: "name" | "percent" | "createAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: any) => any
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.RECHARGE_PACKAGE_NAME,
]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "promotionPackTable.name",
      sortKey: SortColumnEnum.PROMOTION_PACKAGE_NAME,
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "percent",
      label: "promotionPackTable.percent",
      sortKey: SortColumnEnum.PROMOTION_PACKAGE_PERCENT,
      minWidth: 170,
      maxWidth: 400,
      format: (value: number) => {
         return convertCurrency(value)
      },
   },
   {
      id: "createAt",
      label: "promotionPackTable.createAt",
      sortKey: SortColumnEnum.PROMOTION_PACKAGE_CREATEAT,
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   name: string,
   percent: number,
   createAt: string,
   userTypeConditions: TypeUserSelectInterface[]
): PromotionPackInterface {
   createAt = convertDate(createAt)
   return { id, name, percent, createAt, userTypeConditions }
}
