import { ChartEnum } from "../../common/enum/chart.enum"
import { call, select } from "redux-saga/effects"
import { put, takeLatest } from "redux-saga/effects"
import {
   finishExportExcelChartChargeWallet,
   getChartChargeWalletFail,
   getChartChargeWalletSuccess,
   requestChartChargeWallet,
   startExportExcelChartChargeWallet,
} from "./chartChargeWallet.slice"
import {
   exportChartChargeWalletByAppAPI,
   ExportChartChargeWalletByAppPayloadAPIInterface,
   exportChartChargeWalletByDateAPI,
   ExportChartChargeWalletByDatePayloadAPIInterface,
   exportChartChargeWalletByProvinceAPI,
   ExportChartChargeWalletByProvincePayloadAPIInterface,
   getChartChargeWalletByAppAPI,
   GetChartChargeWalletByAppPayloadAPIInterface,
   getChartChargeWalletByDateAPI,
   GetChartChargeWalletByDatePayloadAPIInterface,
   getChartChargeWalletByProvinceAPI,
   GetChartChargeWalletByProvincePayloadAPIInterface,
} from "../../api/chartChargeWallet.api"
import {
   exportExcelChartChargeWalletAsync,
   getChartChargeWalletAsync,
} from "./chartChargeWallet.action"
import { selectSearchConditionChartChargeWallet } from "./chartChargeWallet.selector"
import { getParamMode } from "../../common/helper/chart.helper"
import { PayloadAction } from "@reduxjs/toolkit"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   ColumnsExportExcel,
   ConditionsExportExcel,
} from "../../common/interfaces/exportExcel.interface"
import {
   exportExcel,
   getDateHeadline,
} from "../../common/helper/exportExcel.helper"
import {
   convertDate,
   getFormatDateExportExcel,
} from "../../common/helper/date.helper"
import { i18n } from "../../i18n/i18n"
import { filterChartConst } from "../../common/variables/filterChart"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Get chart sign in saga
 * @param action
 */
function* getChartChargeWalletSaga(): any {
   try {
      yield put(requestChartChargeWallet())
      const searchCondition = yield select(
         selectSearchConditionChartChargeWallet
      )
      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: GetChartChargeWalletByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            getChartChargeWalletByDateAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartChargeWalletSuccess(response.data.data))
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: GetChartChargeWalletByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartChargeWalletByAppAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartChargeWalletSuccess(response.data.data))
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: GetChartChargeWalletByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            getChartChargeWalletByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         yield put(getChartChargeWalletSuccess(response.data.data))
      }
   } catch (error: any) {
      yield put(getChartChargeWalletFail("error.chart"))
   }
}

/**
 * Export excel chart sign in saga
 * @param action
 */
function* exportExcelChartChargeWalletSaga(action: PayloadAction<any>): any {
   try {
      yield put(startExportExcelChartChargeWallet())
      const searchCondition = yield select(
         selectSearchConditionChartChargeWallet
      )

      // Excel config
      const titleString =
         " " +
         i18n.t("filter.chartType", { ns: namespaces.common }) +
         " " +
         i18n.t(
            filterChartConst.filter((e) => e.id === searchCondition.type)[0]
               .text,
            { ns: namespaces.common }
         )
      const workBookName =
         i18n.t("workBookName", {
            ns: namespaces.pages.chart,
         }) +
         "_RECHARGE_" +
         searchCondition.type
      const titleSheetData =
         i18n.t("titleSheetData.chargeWallet", {
            ns: namespaces.pages.chart,
         }) + titleString
      const titleSheetChart =
         i18n.t("titleSheetChart.chargeWallet", {
            ns: namespaces.pages.chart,
         }) + titleString

      const conditions: ConditionsExportExcel[] = [
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.fromDate
            ),
         },
         {
            headline: getDateHeadline(searchCondition.type),
            value: getFormatDateExportExcel(
               searchCondition.type,
               searchCondition.toDate
            ),
         },
      ]

      // Date
      if (
         searchCondition.type === ChartEnum.DAY ||
         searchCondition.type === ChartEnum.MONTH ||
         searchCondition.type === ChartEnum.YEAR
      ) {
         const params: ExportChartChargeWalletByDatePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            mode: getParamMode(searchCondition.type),
         }
         const { response, error } = yield call(
            exportChartChargeWalletByDateAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.addedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin đã nạp: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // App
      if (searchCondition.type === ChartEnum.APP) {
         const params: ExportChartChargeWalletByAppPayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            appIds: searchCondition.app.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartChargeWalletByAppAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.addedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.applicationName", {
                  ns: namespaces.pages.chart,
               }),
               key: "applicationName",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin đã nạp: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      // Province
      if (searchCondition.type === ChartEnum.PROVINCE) {
         const params: ExportChartChargeWalletByProvincePayloadAPIInterface = {
            fromDate: searchCondition.fromDate.format("YYYY-MM-DDTHH:mm:ss"),
            toDate: searchCondition.toDate.format("YYYY-MM-DDTHH:mm:ss"),
            provinceIds: searchCondition.province.map((x: any) => x.id),
         }
         const { response, error } = yield call(
            exportChartChargeWalletByProvinceAPI,
            params
         )
         if (!response) {
            throw error
         }
         const columns: ColumnsExportExcel[] = [
            {
               header: i18n.t("table.stt", { ns: namespaces.pages.chart }),
               key: "stt",
            },
            {
               header: i18n.t("table.fullname", { ns: namespaces.pages.chart }),
               key: "fullName",
            },
            {
               header: i18n.t("table.phoneNumber", {
                  ns: namespaces.pages.chart,
               }),
               key: "phoneNumber",
            },
            {
               header: i18n.t("table.email", { ns: namespaces.pages.chart }),
               key: "email",
            },
            {
               header: i18n.t("table.province", { ns: namespaces.pages.chart }),
               key: "provinceName",
            },
            {
               header: i18n.t("table.addedAmount", {
                  ns: namespaces.pages.chart,
               }),
               key: "amount",
            },
            {
               header: i18n.t("table.description", {
                  ns: namespaces.pages.chart,
               }),
               key: "description",
            },
            {
               header: i18n.t("table.createAt", {
                  ns: namespaces.pages.chart,
               }),
               key: "createAt",
               format: (value: string) => convertDate(value),
            },
         ]

         const data = response.data.users.map((e: any, index: number) => ({
            stt: index + 1,
            ...e,
         }))

         const sum =
            "Tổng coin đã nạp: " +
            data.reduce(
               (accumulator: number, currentValue: any) =>
                  accumulator + currentValue.amount,
               0
            )

         yield exportExcel({
            workBookName: workBookName,
            titleSheetData: titleSheetData,
            titleSheetChart: titleSheetChart,
            conditions: conditions,
            columns: columns,
            data: data,
            chartRef: action.payload.refChart,
            sum: sum,
         })
      }

      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportSuccess", {
               ns: namespaces.pages.chart,
            }),
            variant: "success",
         })
      )

      yield put(finishExportExcelChartChargeWallet())
   } catch (error) {
      yield put(
         pushNotistack({
            message: i18n.t("notistack.exportFail", {
               ns: namespaces.pages.chart,
            }),
            variant: "error",
         })
      )
      yield put(finishExportExcelChartChargeWallet())
   }
}

/**
 * Root chart sign in saga
 */
export function* chartChargeWalletSaga() {
   yield takeLatest(getChartChargeWalletAsync, getChartChargeWalletSaga)
   yield takeLatest(
      exportExcelChartChargeWalletAsync,
      exportExcelChartChargeWalletSaga
   )
}
