import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
   palette: {
      primary: {
         main: "#C49000",
         contrastText: "#000000",
      },
      secondary: {
         main: "#FBC02D",
         contrastText: "#000000",
      },
      info: {
         main: "#0000008a",
         contrastText: "#000000",
      },
   },
})
