import { getPermissionAPI } from "./../../api/permission.api"
import { call } from "redux-saga/effects"
import { put } from "redux-saga/effects"
import { getPermissionSuccess } from "./permission.slice"

/**
 * Get permission saga
 */
export function* getPermissionSaga(token: string): any {
   try {
      const { response, error } = yield call(getPermissionAPI, token)
      if (!response) {
         throw error
      }
      yield put(getPermissionSuccess(response.data))
   } catch (error: any) {
      throw error
   }
}

/**
 * Root permission saga
 */
export function* permissionSaga() {}
