/* eslint-disable @typescript-eslint/naming-convention */
import { useLocation } from "react-router-dom"
import { PaymentResultComponent } from "./paymentResult.component"
import { useEffect } from "react"
import {
   PutPaymentResultAPIInterface,
   putPaymentResultAPI,
} from "../../api/paymentResult.api"

export const PaymentResultContainer = () => {
   const useQuery = () => new URLSearchParams(useLocation().search)
   let vnp_Amount = useQuery().get("vnp_Amount")
   let vnp_BankCode = useQuery().get("vnp_BankCode")
   let vnp_CardType = useQuery().get("vnp_CardType")
   let vnp_OrderInfo = useQuery().get("vnp_OrderInfo")
   let vnp_PayDate = useQuery().get("vnp_PayDate")
   let vnp_ResponseCode = useQuery().get("vnp_ResponseCode")
   let vnp_TmnCode = useQuery().get("vnp_TmnCode")
   let vnp_TransactionNo = useQuery().get("vnp_TransactionNo")
   let vnp_TransactionStatus = useQuery().get("vnp_TransactionStatus")
   let vnp_TxnRef = useQuery().get("vnp_TxnRef")
   let vnp_SecureHash = useQuery().get("vnp_SecureHash")

   useEffect(() => {
      // Sent to API
      const param: PutPaymentResultAPIInterface = {
         vnp_Amount: vnp_Amount,
         vnp_BankCode: vnp_BankCode,
         vnp_CardType: vnp_CardType,
         vnp_OrderInfo: vnp_OrderInfo,
         vnp_PayDate: vnp_PayDate,
         vnp_ResponseCode: vnp_ResponseCode,
         vnp_TmnCode: vnp_TmnCode,
         vnp_TransactionNo: vnp_TransactionNo,
         vnp_TransactionStatus: vnp_TransactionStatus,
         vnp_TxnRef: vnp_TxnRef,
         vnp_SecureHash: vnp_SecureHash,
      }
      putPaymentResultAPI(param)
   }, [
      vnp_Amount,
      vnp_BankCode,
      vnp_CardType,
      vnp_OrderInfo,
      vnp_PayDate,
      vnp_ResponseCode,
      vnp_TmnCode,
      vnp_TransactionNo,
      vnp_TransactionStatus,
      vnp_TxnRef,
      vnp_SecureHash,
   ])

   return <PaymentResultComponent responseCode={vnp_ResponseCode} />
}
