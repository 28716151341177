import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { convertDate } from "../../common/helper/date.helper"
import { ManagementTransactionInterface } from "../../common/interfaces/managementTransaction.interface"

export interface Column {
   id: "id" | "amount" | "description" | "addDay" | "createdAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
]
export const columns: readonly Column[] = [
   { id: "id", label: "walletTable.id", minWidth: 170, maxWidth: 400 },
   { id: "amount", label: "walletTable.amount", minWidth: 170, maxWidth: 400 },
   {
      id: "description",
      label: "walletTable.description",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "addDay",
      label: "walletTable.addDay",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createdAt",
      label: "walletTable.createdAt",
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   transactionDisplayNumber: string,
   transactionType: number,
   amount: string,
   description: string,
   addDay: number,
   createdAt: string
): ManagementTransactionInterface {
   createdAt = convertDate(createdAt)
   amount = transactionType === 0 ? "+" + amount : "-" + amount
   return {
      id,
      transactionDisplayNumber,
      transactionType,
      amount,
      description,
      addDay,
      createdAt,
   }
}
