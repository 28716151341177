import {
   PASSWORD_REGEX,
   PHONENUMBER_REGEX,
   PHONENUMBER_VN_REGEX,
   SPACE_REGEX,
} from "../../common/variables/regex"
import { boolean, object, string, TypeOf } from "zod"

export const signInSchema = object({
   phoneNumber: string()
      .min(1, "Vui lòng nhập số điện thoại.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập số điện thoại.")
      .regex(
         new RegExp(PHONENUMBER_REGEX),
         "Số điện thoại không đúng định dạng."
      )
      .regex(
         new RegExp(PHONENUMBER_VN_REGEX),
         "Số điện thoại không đúng định dạng."
      ),
   password: string()
      .min(1, "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(SPACE_REGEX), "Vui lòng nhập mật khẩu.")
      .regex(new RegExp(PASSWORD_REGEX), "Mật khẩu có chứa ít nhất 6 ký tự."),
   isSave: boolean(),
})

export type SignInInput = TypeOf<typeof signInSchema>
