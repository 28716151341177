import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { SortConditionBody } from "../common/interfaces/sortConditionBody.interface"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetRechargePackPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody,
      SortConditionBody {}

export const getRechargePackAPI = (
   payload: GetRechargePackPayloadAPIInterface
) => {
   return AxiosAuth.post("recharge-package/get-list", payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
}

export interface AddRechargePackPayloadAPIInterface {
   pacakgeName: string
   amount: number
   coin: number
   addDay: number
   userTypeIds: string[] | null
}

export const addRechargePackAPI = (
   payload: AddRechargePackPayloadAPIInterface
) => {
   return AxiosAuth.post("recharge-package", payload).then((response) => ({
      response,
   }))
}

export interface UpdateRechargePackPayloadAPIInterface {
   id: string
   packageName: string
   amount: number
   coin: number
   addDay: number
   userTypeIds: string[] | null
}

export const updateRechargePackAPI = (
   payload: UpdateRechargePackPayloadAPIInterface
) => {
   return AxiosAuth.put("recharge-package/" + payload.id, payload).then(
      (response) => ({ response })
   )
}

export interface DeleteRechargePackPayloadAPIInterface {
   id: string
}

export const deleteRechargePackAPI = (
   payload: DeleteRechargePackPayloadAPIInterface
) => {
   return AxiosAuth.delete("recharge-package/" + payload.id).then(
      (response) => ({ response })
   )
}
