import { CSSProperties } from "react"
import { useSelector } from "react-redux"
import ScaleLoader from "react-spinners/ScaleLoader"
import { selectAuthRequesting } from "../../../states/auth/auth.selector"
import styles from "./pageLoading.module.css"

const override: CSSProperties = {
   display: "block",
   margin: "0 auto",
}

export const PageLoadingComponent = ({ children }: any) => {
   const isSignInRequesting = useSelector(selectAuthRequesting)

   return isSignInRequesting ? (
      <div className={styles.container}>
         <ScaleLoader
            cssOverride={override}
            color="#FBC02D"
            aria-label="Loading Spinner"
            data-testid="loader"
         />
      </div>
   ) : (
      { ...children }
   )
}
