import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   changePageNumberAsync,
   changePageSizeAsync,
   changeSearchConditionAsync,
   changeSortConditionAsync,
   getPromotionPackAsync,
} from "../../states/promotionPack/promotionPack.action"
import {
   selectCountPromotionPack,
   selectDataPromotionPack,
   selectErrorPromotionPack,
   selectPageNumberPromotionPack,
   selectPageSizePromotionPack,
   selectRequestingPromotionPack,
   selectSearchConditionPromotionPack,
   selectSortConditionPromotionPack,
} from "../../states/promotionPack/promotionPack.selector"
import { pushNotistack } from "../../states/notistack/notistack.slice"
import { PromotionPackComponent } from "./promotionPack.component"
import {
   addPromotionPackAPI,
   AddPromotionPackPayloadAPIInterface,
   deletePromotionPackAPI,
   DeletePromotionPackPayloadAPIInterface,
   updatePromotionPackAPI,
   UpdatePromotionPackPayloadAPIInterface,
} from "../../api/promotionPack.api"
import { PromotionPackRechargeDialogInput } from "./promotionPack.form"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"

export const PromotionPackContainer = () => {
   const dispatch = useDispatch()
   // Selector
   const requesting = useSelector(selectRequestingPromotionPack)
   const promotionPackData = useSelector(selectDataPromotionPack)
   const rowCount = useSelector(selectCountPromotionPack)
   const pageNumber = useSelector(selectPageNumberPromotionPack)
   const pageSize = useSelector(selectPageSizePromotionPack)
   const error = useSelector(selectErrorPromotionPack)
   const sortCondition = useSelector(selectSortConditionPromotionPack)
   const searchCondition = useSelector(selectSearchConditionPromotionPack)

   /**
    * Handle change page
    * @param event
    * @param newPage
    */
   const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(changePageNumberAsync(newPage + 1))
   }

   /**
    * Handle change rows per page
    * @param event
    */
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      dispatch(changePageSizeAsync(Number(event.target.value)))
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   /**
    * Handle change search condition
    * @param value
    * @param option
    */
   const handleChangeSearchCondition = (value: string, option: string) => {
      dispatch(changeSearchConditionAsync({ value, option }))
   }

   /**
    * Handle add pack async
    * @param values
    * @returns
    */
   const handleAddPromotionPackAsync = (
      values: PromotionPackRechargeDialogInput
   ) => {
      const params: AddPromotionPackPayloadAPIInterface = {
         pacakgeName: values.name,
         coinPercent: values.percent,
         userTypeIds: values.userType ? values.userType.map((e) => e.id) : null,
      }
      return addPromotionPackAPI(params)
   }

   /**
    * Handle update pack async
    * @param values
    * @returns
    */
   const handleUpdatePromotionPackAsync = (
      id: string,
      values: PromotionPackRechargeDialogInput
   ) => {
      const params: UpdatePromotionPackPayloadAPIInterface = {
         id: id,
         packageName: values.name,
         coinPercent: values.percent,
         userTypeIds: values.userType ? values.userType.map((e) => e.id) : null,
      }
      return updatePromotionPackAPI(params)
   }

   /**
    * Handle change sort condition
    * @param column
    * @param order
    */
   const handleChangeSortCondition = (column: SortColumnEnum) => {
      dispatch(changeSortConditionAsync({ column }))
   }

   /**
    * Handle delete pack async
    * @param values
    * @returns
    */
   const handleDeletePromotionPackAsync = (id: string) => {
      const params: DeletePromotionPackPayloadAPIInterface = {
         id: id,
      }
      return deletePromotionPackAPI(params)
   }

   /**
    * Handle reload recharge pack async
    */
   const handleReloadPromotionPackAsync = () => {
      dispatch(getPromotionPackAsync())
   }

   useEffect(() => {
      dispatch(getPromotionPackAsync())
   }, [dispatch])

   return (
      <PromotionPackComponent
         requesting={requesting}
         pageNumber={pageNumber}
         pageSize={pageSize}
         rowCount={rowCount}
         promotionPackData={promotionPackData}
         error={error}
         searchCondition={searchCondition}
         sortCondition={sortCondition}
         handleChangePage={handleChangePage}
         handleChangeRowsPerPage={handleChangeRowsPerPage}
         handlePushNotistack={handlePushNotistack}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleAddPromotionPackAsync={handleAddPromotionPackAsync}
         handleUpdatePromotionPackAsync={handleUpdatePromotionPackAsync}
         handleDeletePromotionPackAsync={handleDeletePromotionPackAsync}
         handleReloadPromotionPackAsync={handleReloadPromotionPackAsync}
         handleChangeSortCondition={handleChangeSortCondition}
      />
   )
}
