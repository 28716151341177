import { zodResolver } from "@hookform/resolvers/zod"
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Typography,
} from "@mui/material"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import {
   SettingConfigurationInput,
   settingConfigurationSchema,
} from "./settingConfiguration.form"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import LinearProgress from "@mui/material/LinearProgress"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"

type SettingConfigurationComponentProps = {
   handlePushNotistack: (message: string, variant: string) => void
   handleGetConfigurationAsync: () => Promise<any>
   handleUpdateConfigurationAsync: (
      failTimeId: string,
      lockTimeId: string,
      tokenTimeId: string,
      outOfDateId: string,
      values: SettingConfigurationInput
   ) => Promise<any>
}

export const SettingConfigurationComponent = (
   props: SettingConfigurationComponentProps
) => {
   const { t } = useTranslation(namespaces.pages.setting)
   // State
   const [isRequesting, setIsRequesting] = useState<boolean>(false)
   const [error, setError] = useState<string>("")

   const [failTimeId, setFailTimeId] = useState<string>("")
   const [lockTimeId, setLockTimeId] = useState<string>("")
   const [tokenTimeId, setTokenTimeId] = useState<string>("")
   const [outOfDateId, setOutOfDateId] = useState<string>("")

   // Form
   const {
      register,
      formState: { errors },
      handleSubmit,
      clearErrors,
      setValue,
   } = useForm<SettingConfigurationInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(settingConfigurationSchema),
   })

   /**
    * On change accordion
    */
   const onChangeAccordion = (
      event: SyntheticEvent<Element, Event>,
      expanded: boolean
   ) => {
      if (expanded) {
         clearErrors()
         handleGetConfigurationAsync()
      }
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<SettingConfigurationInput> = (
      values: SettingConfigurationInput
   ) => {
      setIsRequesting(true)
      props
         .handleUpdateConfigurationAsync(
            failTimeId,
            lockTimeId,
            tokenTimeId,
            outOfDateId,
            values
         )
         .then((res) => {
            props.handlePushNotistack(
               "Cập nhật thông tin thành công!",
               "success"
            )
            setIsRequesting(false)
         })
         .catch((err) => {
            setIsRequesting(false)
            props.handlePushNotistack("Cập nhật thông tin thất bại!", "error")
            handleGetConfigurationAsync()
         })
   }

   /**
    * Handle get Configuration async
    */
   const handleGetConfigurationAsync = useCallback(() => {
      setIsRequesting(true)
      props
         .handleGetConfigurationAsync()
         .then((res) => {
            const failTimeData = res.data.filter(
               (e: any) => e.code === "MaxLoginFailedCount"
            )
            setFailTimeId(failTimeData[0].id)
            setValue("failTime", parseInt(failTimeData[0].value))

            const lockTimeData = res.data.filter(
               (e: any) => e.code === "LoginFailedWaitingTime"
            )
            setLockTimeId(lockTimeData[0].id)
            setValue("lockTime", parseInt(lockTimeData[0].value))

            const tokenTimeData = res.data.filter(
               (e: any) => e.code === "TokenValidTime"
            )
            setTokenTimeId(tokenTimeData[0].id)
            setValue("tokenTime", parseInt(tokenTimeData[0].value))

            const outOfDateData = res.data.filter(
               (e: any) => e.code === "OutOfExpiredDateWallet"
            )
            setOutOfDateId(outOfDateData[0].id)
            setValue("outOfDate", parseInt(outOfDateData[0].value))

            setIsRequesting(false)
         })
         .catch((err) => {
            setError("Xảy ra lỗi khi lấy thông tin.")
            setIsRequesting(false)
         })
   }, [props, setValue])

   useEffect(() => {
      handleGetConfigurationAsync()
   }, [props, setValue, handleGetConfigurationAsync])

   return (
      <Accordion defaultExpanded={false} onChange={onChangeAccordion}>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
         >
            <SettingsApplicationsIcon />
            <Typography ml={2}>{t("configuration.accordion")}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            {/* Loading */}
            {isRequesting && <LinearProgress color="secondary" />}
            {/* Error */}
            {!isRequesting && error !== "" && (
               <Typography variant="body1" color="error">
                  {error}
               </Typography>
            )}
            {/* Data */}
            {!isRequesting && error === "" && (
               <Box
                  component="form"
                  autoComplete="on"
                  noValidate
                  sx={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmitForm)}
               >
                  <FormControl fullWidth margin="normal">
                     <TextField
                        type="number"
                        id="outlined-adornment-failTime"
                        label={t("configuration.failTime")}
                        error={!!errors.failTime}
                        helperText={
                           errors.failTime ? errors.failTime.message : ""
                        }
                        disabled={isRequesting}
                        {...register("failTime", {
                           setValueAs: (v) =>
                              v === "" ? undefined : parseFloat(v),
                        })}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        type="number"
                        id="outlined-adornment-lockTime"
                        label={t("configuration.lockTime")}
                        error={!!errors.lockTime}
                        helperText={
                           errors.lockTime ? errors.lockTime.message : ""
                        }
                        disabled={isRequesting}
                        {...register("lockTime", {
                           setValueAs: (v) =>
                              v === "" ? undefined : parseFloat(v),
                        })}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        type="number"
                        id="outlined-adornment-tokenTime"
                        label={t("configuration.tokenTime")}
                        error={!!errors.tokenTime}
                        helperText={
                           errors.tokenTime ? errors.tokenTime.message : ""
                        }
                        disabled={isRequesting}
                        {...register("tokenTime", {
                           setValueAs: (v) =>
                              v === "" ? undefined : parseFloat(v),
                        })}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        type="number"
                        id="outlined-adornment-outOfDate"
                        label={t("configuration.outOfDate")}
                        error={!!errors.outOfDate}
                        helperText={
                           errors.outOfDate ? errors.outOfDate.message : ""
                        }
                        disabled={isRequesting}
                        {...register("outOfDate", {
                           setValueAs: (v) =>
                              v === "" ? undefined : parseFloat(v),
                        })}
                     />
                  </FormControl>
                  <Stack mt={2} spacing={2} direction="row">
                     <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isRequesting}
                     >
                        {t("button.update")}
                     </Button>
                  </Stack>
               </Box>
            )}
         </AccordionDetails>
      </Accordion>
   )
}
