import { useDispatch } from "react-redux"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { SettingConfigurationComponent } from "./settingConfiguration.component"
import { SettingConfigurationInput } from "./settingConfiguration.form"
import {
   UpdateConfigurationPayloadAPIInterface,
   getConfigurationAPI,
   updateConfigurationAPI,
} from "../../../api/setting.api"

export const SettingConfigurationContainer = () => {
   const dispatch = useDispatch()

   /**
    * Handle get user info async
    * @returns
    */
   const handleGetConfigurationAsync = () => {
      return getConfigurationAPI()
   }

   /**
    * Handle get company async
    * @returns
    */
   const handleUpdateConfigurationAsync = (
      failTimeId: string,
      lockTimeId: string,
      tokenTimeId: string,
      outOfDateId: string,
      values: SettingConfigurationInput
   ) => {
      const params: UpdateConfigurationPayloadAPIInterface = {
         configurations: [
            {
               id: failTimeId,
               value: values.failTime,
            },
            {
               id: lockTimeId,
               value: values.lockTime,
            },
            {
               id: tokenTimeId,
               value: values.tokenTime,
            },
            {
               id: outOfDateId,
               value: values.outOfDate,
            },
         ],
      }
      return updateConfigurationAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   return (
      <SettingConfigurationComponent
         handleGetConfigurationAsync={handleGetConfigurationAsync}
         handleUpdateConfigurationAsync={handleUpdateConfigurationAsync}
         handlePushNotistack={handlePushNotistack}
      />
   )
}
