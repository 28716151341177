import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import { useState } from "react"
import TablePagination from "@mui/material/TablePagination"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import DeleteIcon from "@mui/icons-material/Delete"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import DialogContent from "@mui/material/DialogContent"
import AddIcon from "@mui/icons-material/Add"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import Dialog from "@mui/material/Dialog"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { ManagementUserInterface } from "../../common/interfaces/managementUser.interface"
import Container from "@mui/material/Container"
import InfoIcon from "@mui/icons-material/Info"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff"
import CircularProgress from "@mui/material/CircularProgress"
import { ProvinceSelectAsyncComponent } from "../../common/components/ProvinceSelectAsync/provinceSelectAsync.component"
import LinearProgress from "@mui/material/LinearProgress"
import Tooltip from "@mui/material/Tooltip"
import commonStyles from "../../common/styles/common.module.css"
import InputAdornment from "@mui/material/InputAdornment"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import AttachEmailIcon from "@mui/icons-material/AttachEmail"
import PhoneIcon from "@mui/icons-material/Phone"
import PublicIcon from "@mui/icons-material/Public"
import PasswordIcon from "@mui/icons-material/Password"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import FormHelperText from "@mui/material/FormHelperText"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useShowFullValue } from "../../common/hook/useShowFullValue.hook"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import LoadingButton from "@mui/lab/LoadingButton"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import Grid from "@mui/material/Grid"
import { ManagementUserHistoryContainer } from "./managementUserHistory/managementUserHistory.container"
import CheckIcon from "@mui/icons-material/Check"
import {
   ManagementUserDialogInput,
   managementUserDialogSchema,
} from "./managementUser.form"
import {
   columns,
   createData,
   filterMultipleTypes,
} from "./managementUser.table"
import React from "react"
import TableSortLabel from "@mui/material/TableSortLabel"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { visuallyHidden } from "@mui/utils"
import { createSortDirection } from "../../common/helper/sortTable.helper"
import { useManagementUserPermission } from "./managementUser.permission"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import FormControl from "@mui/material/FormControl"
import { adminAccountTypes } from "../../common/variables/accountType"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import CloseIcon from "@mui/icons-material/Close"
import { MultipleSearchConditionState } from "../../common/interfaces/searchConditionParam"
import { FilterMultipleTableComponent } from "../../common/components/FilterMultipleTable/filterMultipleTable.component"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"
import { UserStatusComponent } from "../../common/components/UserStatus/userStatus.component"
import { ManagementUserCountInterface } from "../../common/interfaces/managementUserCount.interface"

type ManagementUserComponentProps = {
   requesting: boolean
   requestingCount: boolean
   requestingExport: boolean
   pageSize: number
   pageNumber: number
   sortCondition: SortConditionInterface
   searchCondition: MultipleSearchConditionState[]
   rowCount: number
   userData: any[]
   userDataCount: ManagementUserCountInterface
   error: string
   errorCount: string
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleAddUserAsync: (values: ManagementUserDialogInput) => Promise<any>
   handleDeleteUserAsync: (id: string) => Promise<any>
   handleChangeSearchCondition: (
      searchCondition: MultipleSearchConditionState[]
   ) => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
   handleExportExcel: () => void
   handleReloadGetManagementUserAsync: () => void
   handleGetManagementUserDetailAsync: (id: string) => void
   handleGetChargeHistoryUserAsync: (id: string) => void
}

export const ManagementUserComponent = (
   props: ManagementUserComponentProps
) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const { t } = useTranslation(namespaces.pages.managementUser)
   const {
      allowAddManagementUser,
      allowDetailManagementUser,
      allowDeleteManagementUser,
      allowExportManagementUser,
      allowHistoryChargeManagementUser,
      allowGetCountManagementUser,
      // allowHistoryManagementUser,
   } = useManagementUserPermission()

   // User list
   const rows = props.userData.map((user, index) =>
      createData(
         props.pageSize * (props.pageNumber - 1) + (index + 1),
         user.id,
         user.fullname,
         user.email,
         user.phoneNumber,
         user.province,
         user.identityCard,
         user.gender,
         user.createAt,
         user.expiredDate,
         user.accountType.id,
         user.accountType.name,
         user.note,
         user.status
      )
   )

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.ADD
   )
   const [selectedDialog, setSelectedDialog] =
      useState<ManagementUserInterface | null>(null)
   // Form state
   const [showPassword, setShowPassword] = useState<boolean>(false)
   const [showPasswordConfirmation, setShowPasswordConfirmation] =
      useState<boolean>(false)
   const [showHeplerTextPassword, setShowHeplerTextPassword] =
      useState<boolean>(false)
   const [
      showHeplerTextPasswordConfirmation,
      setShowHeplerTextPasswordConfirmation,
   ] = useState<boolean>(false)

   // Table hook
   const { onShowFullValue, onHideFullValue, isShowFullValue, getShortValue } =
      useShowFullValue({ maxLength: 20 })

   // Form management user dialog
   const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setValue,
   } = useForm<ManagementUserDialogInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(managementUserDialogSchema),
   })

   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }

   // Responsive setting
   let gridButton =
      (allowAddManagementUser && !allowExportManagementUser) ||
      (!allowAddManagementUser && allowExportManagementUser)
         ? 12
         : 6

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: ManagementUserInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
         setValue("province", selected.province)
         setValue("name", selected.fullname)
         setValue("email", selected.email)
         setValue("phoneNumber", selected.phoneNumber)
         setValue("password", "Valid123456!")
         setValue("passwordConfirmation", "Valid123456!")
         setValue("note", selected.note)
         setValue("accountType", selected.accountTypeId)
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setRequestingDialog(false)
      setOpenDialog(false)
      setSelectedDialog(null)
      reset()
      setValue("province", { id: "", name: "" })
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("dialog.title.addUser")}
            {typeDialog === DialogMethodEnum.DELETE &&
               t("dialog.title.deleteUser")}
            {typeDialog === DialogMethodEnum.HISTORY &&
               t("dialog.title.historyUser")}
            {typeDialog === DialogMethodEnum.CHARGE &&
               t("dialog.title.chargeUser")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button
                  type="submit"
                  disabled={requestingDialog}
                  startIcon={<CheckIcon />}
               >
                  {t("button.add")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeleteUser}
                  startIcon={<DeleteIcon />}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requestingDialog}
               onClick={handleCloseDialog}
               startIcon={<CloseIcon />}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deleteUserConfirm", {
                     user: selectedDialog?.fullname,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.ADD) {
         return (
            <DialogContent>
               <Controller
                  control={control}
                  name="province"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }: any) => (
                     <ProvinceSelectAsyncComponent
                        value={value}
                        variant="standard"
                        isMarginNormal
                        error={!!errors.province}
                        helperText={errors.province ? "Tỉnh bắt buộc nhập" : ""}
                        disabled={requestingDialog}
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position="start">
                                 <PublicIcon />
                              </InputAdornment>
                           ),
                        }}
                        onInputChange={(inputValue: any) => {
                           onChange(inputValue)
                        }}
                     />
                  )}
               />
               <TextField
                  required
                  margin="normal"
                  id="name"
                  label={t("dialog.field.name")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  disabled={requestingDialog}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <FormatColorTextIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("name")}
               />
               <TextField
                  margin="normal"
                  id="email"
                  label={t("dialog.field.email")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  disabled={requestingDialog}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachEmailIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("email")}
               />
               <TextField
                  required
                  margin="normal"
                  id="phoneNumber"
                  label={t("dialog.field.phoneNumber")}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors.phoneNumber}
                  helperText={
                     errors.phoneNumber ? errors.phoneNumber.message : ""
                  }
                  disabled={requestingDialog}
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <PhoneIcon />
                        </InputAdornment>
                     ),
                  }}
                  {...register("phoneNumber")}
               />
               <TextField
                  required
                  margin="normal"
                  id="password"
                  label={t("dialog.field.password")}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  variant="standard"
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ""}
                  disabled={requestingDialog}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <PasswordIcon />
                        </InputAdornment>
                     ),
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword(false)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword ? (
                                 <VisibilityOff />
                              ) : (
                                 <Visibility />
                              )}
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
                  onFocus={() => {
                     setShowHeplerTextPassword(true)
                  }}
                  onKeyDown={() => {
                     setShowHeplerTextPassword(false)
                  }}
                  {...register("password")}
               />
               {!errors.password && showHeplerTextPassword && (
                  <FormHelperText>
                     Mật khẩu có chứa ít nhất 6 ký tự.
                  </FormHelperText>
               )}
               <TextField
                  required
                  margin="normal"
                  id="passwordConfirmation"
                  label={t("dialog.field.passwordConfirmation")}
                  type={showPasswordConfirmation ? "text" : "password"}
                  fullWidth
                  variant="standard"
                  error={!!errors.passwordConfirmation}
                  helperText={
                     errors.passwordConfirmation
                        ? errors.passwordConfirmation.message
                        : ""
                  }
                  disabled={requestingDialog}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <PasswordIcon />
                        </InputAdornment>
                     ),
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password confirm visibility"
                              onClick={() => handleClickShowPassword(true)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPasswordConfirmation ? (
                                 <VisibilityOff />
                              ) : (
                                 <Visibility />
                              )}
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
                  onFocus={() => {
                     setShowHeplerTextPasswordConfirmation(true)
                  }}
                  onKeyDown={() => {
                     setShowHeplerTextPasswordConfirmation(false)
                  }}
                  {...register("passwordConfirmation")}
               />
               {!errors.passwordConfirmation &&
                  showHeplerTextPasswordConfirmation && (
                     <FormHelperText>
                        Mật khẩu có chứa ít nhất 6 ký tự.
                     </FormHelperText>
                  )}
               <FormControl fullWidth margin="normal">
                  <Controller
                     control={control}
                     name="accountType"
                     defaultValue={adminAccountTypes[0].id}
                     render={({ field }) => (
                        <RadioGroup
                           {...field}
                           row
                           value={field.value}
                           onChange={(event, value) => field.onChange(value)}
                        >
                           {adminAccountTypes.map((e) => (
                              <FormControlLabel
                                 key={e.id}
                                 value={e.id}
                                 control={<Radio />}
                                 label={e.name}
                                 disabled={requestingDialog}
                              />
                           ))}
                        </RadioGroup>
                     )}
                  />
               </FormControl>
               <TextField
                  margin="normal"
                  id="note"
                  label={t("dialog.field.note")}
                  type="text"
                  fullWidth
                  multiline
                  variant="outlined"
                  error={!!errors.note}
                  helperText={errors.note ? errors.note.message : ""}
                  disabled={requestingDialog}
                  rows={4}
                  {...register("note")}
               />
            </DialogContent>
         )
      }
      if (typeDialog === DialogMethodEnum.HISTORY) {
         return (
            selectedDialog && (
               <ManagementUserHistoryContainer id={selectedDialog.id} />
            )
         )
      }
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<ManagementUserDialogInput> = (
      values: ManagementUserDialogInput
   ) => {
      if (typeDialog === DialogMethodEnum.ADD) {
         setRequestingDialog(true)
         props
            .handleAddUserAsync(values)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack("Thêm user thành công!", "success")
               props.handleReloadGetManagementUserAsync()
               handleCloseDialog()
            })
            .catch((err) => {
               setRequestingDialog(false)
               props.handlePushNotistack("Thêm user thất bại!", "error")
            })
      }
   }

   /**
    * On delete user
    */
   const onDeleteUser = () => {
      setRequestingDialog(true)
      props
         .handleDeleteUserAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa user thành công!", "success")
            props.handleReloadGetManagementUserAsync()
            handleCloseDialog()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa user thất bại!", "error")
         })
   }

   /**
    * Handle mouse down password
    * @param event
    */
   const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
   }

   /**
    * Handle click show password
    * @param isPasswordConfirmation
    */
   const handleClickShowPassword = (isPasswordConfirmation: boolean) => {
      if (isPasswordConfirmation) {
         setShowPasswordConfirmation(!showPasswordConfirmation)
      } else {
         setShowPassword(!showPassword)
      }
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterMultipleTableComponent
                        types={filterMultipleTypes}
                        searchCondition={props.searchCondition}
                        onFilter={(
                           searchCondition: MultipleSearchConditionState[]
                        ) => {
                           props.handleChangeSearchCondition(searchCondition)
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={gridButton} xl={5}>
                           {allowAddManagementUser && (
                              <Button
                                 fullWidth
                                 sx={{
                                    minHeight: "36px",
                                 }}
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() =>
                                    handleOpenDialog(DialogMethodEnum.ADD)
                                 }
                              >
                                 {t("button.addUser")}
                              </Button>
                           )}
                        </Grid>
                        <Grid item xs={12} sm={gridButton} xl={5}>
                           {allowExportManagementUser && (
                              <LoadingButton
                                 fullWidth
                                 sx={{
                                    minHeight: "36px",
                                 }}
                                 color="secondary"
                                 loading={props.requestingExport}
                                 loadingPosition="start"
                                 startIcon={<FileDownloadIcon />}
                                 variant="contained"
                                 onClick={() => props.handleExportExcel()}
                              >
                                 {t("button.exportExcel")}
                              </LoadingButton>
                           )}
                        </Grid>
                     </Grid>
                  </Grid>
                  {allowGetCountManagementUser && (
                     <Grid item xs={12}>
                        {props.requestingCount && (
                           <div className={commonStyles.flexCenterCenter}>
                              <CircularProgress color="secondary" />
                           </div>
                        )}
                        {/* Error */}
                        {!props.requestingCount && props.errorCount && (
                           <Typography variant="body1" color="error">
                              {t(props.errorCount, { ns: "common" })}
                           </Typography>
                        )}
                        {!props.requestingCount && !props.errorCount && (
                           <Typography variant="body1" fontWeight={"bold"}>
                              Tổng người dùng: {props.userDataCount.totalUser} -
                              Đang hoạt động:{" "}
                              {props.userDataCount.totalUserActive} - Đã hết
                              hạn: {props.userDataCount.totalUserExpiredDate} -
                              Đang khóa: {props.userDataCount.totalUserLock}
                           </Typography>
                        )}
                     </Grid>
                  )}
               </Grid>
            </Toolbar>
            <TableContainer sx={{ maxHeight: "750px" }}>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        <TableCell
                           key={"shortValue"}
                           align="left"
                           style={{ minWidth: 50 }}
                        ></TableCell>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                 minWidth: column.minWidth,
                                 maxWidth: column.maxWidth,
                              }}
                           >
                              {column.sortKey ? (
                                 <TableSortLabel
                                    active={
                                       props.sortCondition.column ===
                                       column.sortKey
                                    }
                                    direction={sortDirection}
                                    onClick={createSortHandler(column.sortKey)}
                                 >
                                    {t(column.label)}
                                    {props.sortCondition.column ===
                                    column.sortKey ? (
                                       <Box
                                          component="span"
                                          sx={visuallyHidden}
                                       >
                                          {props.sortCondition.order ===
                                          SortOrderEnum.DESC
                                             ? "Giảm"
                                             : "Tăng"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              ) : (
                                 t(column.label)
                              )}
                           </TableCell>
                        ))}
                        <TableCell
                           key={"action"}
                           align="left"
                           style={{ minWidth: 200 }}
                        >
                           {t("userTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 2}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 2}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 2}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row, index) => (
                           <TableRow hover tabIndex={-1} key={index}>
                              <TableCell key={"shortValue"}>
                                 {isShowFullValue(row.id) ? (
                                    <IconButton
                                       onClick={() => onHideFullValue(row.id)}
                                    >
                                       <Visibility />
                                    </IconButton>
                                 ) : (
                                    <IconButton
                                       onClick={() => onShowFullValue(row.id)}
                                    >
                                       <VisibilityOff />
                                    </IconButton>
                                 )}
                              </TableCell>
                              {/* TODO */}
                              {columns.map((column) => {
                                 const value = isShowFullValue(row.id)
                                    ? row[column.id]
                                    : getShortValue(row[column.id])
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {column.id === "status" ? (
                                          <UserStatusComponent
                                             status={value}
                                          ></UserStatusComponent>
                                       ) : (
                                          <div>
                                             {column.format
                                                ? column.format(value)
                                                : value}
                                          </div>
                                       )}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 {allowDetailManagementUser && (
                                    <Tooltip title={t("button.userInfo")}>
                                       <IconButton
                                          aria-label="info"
                                          onClick={() =>
                                             props.handleGetManagementUserDetailAsync(
                                                row.id
                                             )
                                          }
                                       >
                                          <InfoIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {/* {allowHistoryManagementUser && (
                                    <Tooltip title={t("button.userHistory")}>
                                       <IconButton
                                          aria-label="history"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.HISTORY,
                                                row
                                             )
                                          }
                                       >
                                          <HistoryToggleOffIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )} */}
                                 {allowHistoryChargeManagementUser && (
                                    <Tooltip title={t("button.userCharge")}>
                                       <IconButton
                                          aria-label="history"
                                          onClick={() =>
                                             props.handleGetChargeHistoryUserAsync(
                                                row.id
                                             )
                                          }
                                       >
                                          <HistoryToggleOffIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                                 {allowDeleteManagementUser && (
                                    <Tooltip title={t("button.delete")}>
                                       <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DELETE,
                                                row
                                             )
                                          }
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500, 1000, 2000, 5000]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={
               typeDialog === DialogMethodEnum.DETAIL ||
               typeDialog === DialogMethodEnum.HISTORY ||
               typeDialog === DialogMethodEnum.CHARGE
            }
            maxWidth={
               typeDialog === DialogMethodEnum.DETAIL ||
               typeDialog === DialogMethodEnum.HISTORY ||
               typeDialog === DialogMethodEnum.CHARGE
                  ? "xl"
                  : "sm"
            }
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="off"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Container>
   )
}
