import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"
import styles from "./appBar.module.css"
import FormControl from "@mui/material/FormControl"
import { SearchTextField } from "../Search/search"
import List from "@mui/material/List"
import { routes } from "../../variables/route"
import { AppBarItemComponent } from "./AppBarItem/appBarItem.component"
import React, { useState } from "react"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Avatar from "@mui/material/Avatar"
import { centerIDLogo } from "../../../images/images"
import { FunctionEnum } from "../../enum/function.enum"
import MenuIcon from "@mui/icons-material/Menu"
import Drawer from "@mui/material/Drawer"

type AppBarComponentProps = {
   allowRoutes: FunctionEnum[]
}

export const AppBarComponent = (props: AppBarComponentProps) => {
   const { t } = useTranslation(namespaces.common)
   // States
   const [searchText, setSearchText] = useState("")
   const [open, setOpen] = useState(false)

   /**
    * On change search input
    * @param event
    */
   const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setSearchText(event.target.value.toLowerCase())
   }

   /**
    * Handle close drawer
    */
   const handleCloseDrawer = () => {
      setOpen(false)
   }

   /**
    * Toggle drawer
    * @param isOpen
    * @returns
    */
   const toggleDrawer =
      (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
         if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
               (event as React.KeyboardEvent).key === "Shift")
         ) {
            return
         }

         setOpen(isOpen)
      }

   return (
      <React.Fragment>
         <Stack
            sx={{
               width: "100%",
            }}
            direction="row"
            justifyContent="space-between"
         >
            <Stack direction="row" justifyContent="center" alignItems="center">
               <IconButton sx={{ p: 0 }}>
                  <Avatar
                     className={styles.logo}
                     alt="logo"
                     src={centerIDLogo}
                  />
               </IconButton>
               <p className={styles.textLogo}>{t("appName")}</p>
            </Stack>
            <IconButton color="secondary" onClick={toggleDrawer(true)}>
               <MenuIcon></MenuIcon>
            </IconButton>
         </Stack>
         <Drawer anchor={"top"} open={open} onClose={toggleDrawer(false)}>
            <div className={styles.list}>
               <div>
                  <FormControl fullWidth margin="normal">
                     <SearchTextField
                        placeholder={t("field.search")}
                        onChange={(event) => handleChange(event)}
                     ></SearchTextField>
                  </FormControl>
               </div>
               <List
                  sx={{ width: "100%" }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
               >
                  {routes
                     .filter(
                        (route) =>
                           props.allowRoutes.some((e) =>
                              route.permissionKeys.includes(e)
                           ) || route.permissionKeys.includes(FunctionEnum.NONE)
                     )
                     .filter(
                        (route) =>
                           route.showOnSidebar &&
                           t(route.text).toLowerCase().includes(searchText)
                     )
                     .map((AppBar, key) => (
                        <AppBarItemComponent
                           key={key}
                           config={AppBar}
                           handleCloseDrawer={handleCloseDrawer}
                        ></AppBarItemComponent>
                     ))}
               </List>
            </div>
         </Drawer>
      </React.Fragment>
   )
}
