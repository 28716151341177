import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { EmailState } from "./email.slice"

const selectEmailState = (state: RootState): EmailState => state.email

export const selectRequestingEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.requesting
)

export const selectDataEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.data
)

export const selectErrorEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.error
)

export const selectCountEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.rowCount
)

export const selectPageNumberEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.paging.pageNumber
)

export const selectPageSizeEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.paging.pageSize
)

export const selectSearchConditionEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.searchCondition
)

export const selectSortConditionEmail = createSelector(
   selectEmailState,
   (state: EmailState) => state.sortCondition
)
