import clsx from "clsx"
import { loginWallpage } from "../../images/images"
import Container from "@mui/material/Container"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { TextLogo } from "../../common/components/TextLogo/textLogo"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../i18n/i18n.constants"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import { Footer } from "../../common/components/Footer/footer"
import styles from "./signIn.module.css"
import commonStyles from "../../common/styles/common.module.css"
import { useForm, SubmitHandler } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import React, { useEffect, useRef, useState } from "react"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import TextField from "@mui/material/TextField"
import { FormErrorComponent } from "../../common/components/FormError/formError.component"
import LinearProgress from "@mui/material/LinearProgress"
import { SignInInput, signInSchema } from "./signIn.form"

type SignInComponentProps = {
   isSignInError: boolean
   isSignInRequesting: boolean
   signInError: string | null
   onSubmitForm: (values: SignInInput) => void
   onSignUp: () => void
   onForgotPassword: () => void
}

export const SignInComponent = (props: SignInComponentProps) => {
   const { t } = useTranslation(namespaces.pages.signIn)
   const _signInButtonRef = useRef<any>()

   // State
   const [showPassword, setShowPassword] = useState<boolean>(false)

   // Form
   const {
      register,
      formState: { errors, isValid },
      handleSubmit,
   } = useForm<SignInInput>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(signInSchema),
   })

   /**
    * Handle mouse down password
    * @param event
    */
   const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
   }

   /**
    * Handle click show password
    */
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
   }

   /**
    * Handle submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<SignInInput> = (values: SignInInput) => {
      props.onSubmitForm(values)
   }

   /**
    * Effect enter key
    */
   useEffect(() => {
      const listener = (event: any) => {
         if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault()
            _signInButtonRef.current.click()
         }
      }
      document.addEventListener("keydown", listener)
      return () => {
         document.removeEventListener("keydown", listener)
      }
   }, [])

   return (
      <React.Fragment>
         {props.isSignInRequesting && <LinearProgress color="secondary" />}
         <Container maxWidth="xl">
            <Grid2 container>
               <Grid2 xs={12} xl={8}>
                  <div
                     className={clsx(
                        commonStyles.flexCenterCenter,
                        styles.wallpaper
                     )}
                  >
                     <img src={loginWallpage} alt="Login Wallpaper"></img>
                  </div>
               </Grid2>
               <Grid2 xs={12} xl={4}>
                  <div
                     className={clsx(
                        commonStyles.flexColumnCenterCenter,
                        styles.form
                     )}
                  >
                     <TextLogo introduce={t("introduce")}></TextLogo>
                     <FormErrorComponent
                        error={props.signInError}
                     ></FormErrorComponent>
                     <Box
                        component="form"
                        autoComplete="on"
                        noValidate
                        sx={{ width: "100%" }}
                        mt={8}
                        onSubmit={handleSubmit(onSubmitForm)}
                     >
                        <FormControl fullWidth margin="normal">
                           <TextField
                              required
                              id="outlined-adornment-phoneNumber"
                              label={t("field.phoneNumber", { ns: "common" })}
                              error={!!errors.phoneNumber}
                              helperText={
                                 errors.phoneNumber
                                    ? errors.phoneNumber.message
                                    : ""
                              }
                              disabled={props.isSignInRequesting}
                              inputProps={{ maxLength: 10 }}
                              {...register("phoneNumber")}
                           />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                           <TextField
                              required
                              id="outlined-adornment-password"
                              label={t("field.password", { ns: "common" })}
                              type={showPassword ? "text" : "password"}
                              error={!!errors.password}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                       >
                                          {showPassword ? (
                                             <VisibilityOff />
                                          ) : (
                                             <Visibility />
                                          )}
                                       </IconButton>
                                    </InputAdornment>
                                 ),
                              }}
                              disabled={props.isSignInRequesting}
                              helperText={
                                 errors.password ? errors.password.message : ""
                              }
                              {...register("password")}
                           />
                        </FormControl>
                        <Stack
                           direction="row"
                           justifyContent="space-between"
                           alignItems="center"
                           mt={3}
                        >
                           <FormControlLabel
                              control={<Checkbox />}
                              disabled={props.isSignInRequesting}
                              {...register("isSave")}
                              label={t("field.saveSession", { ns: "common" })}
                           />
                           <Link
                              onClick={() => props.onForgotPassword()}
                              component="button"
                              color="inherit"
                              variant="body1"
                           >
                              {t("forgotPassword")}
                           </Link>
                        </Stack>
                        <Stack
                           className={styles.buttonStack}
                           mt={2}
                           spacing={2}
                           direction="row"
                        >
                           <Button
                              ref={_signInButtonRef}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              disabled={!isValid || props.isSignInRequesting}
                           >
                              {t("buttons.signIn", { ns: "common" })}
                           </Button>
                           <Button
                              variant="text"
                              onClick={props.onSignUp}
                              disabled={props.isSignInRequesting}
                           >
                              {t("buttons.signUp", { ns: "common" })}
                           </Button>
                        </Stack>
                        <Divider className={styles.divider} />
                     </Box>
                  </div>
               </Grid2>
               <Grid2 xs={12}>
                  <Footer></Footer>
               </Grid2>
            </Grid2>
         </Container>
      </React.Fragment>
   )
}
