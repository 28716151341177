import { AxiosBase } from "./config/axiosBase.instance"

export const getPermissionAPI = (token: string) => {
   return AxiosBase.get("feature/granted-features", {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }).then((response) => ({
      response,
   }))
}
