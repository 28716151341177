import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TypeUserInterface } from "../../common/interfaces/typeUser.interface"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { handleSortOrder } from "../../common/helper/sortTable.helper"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"

export interface TypeUserState {
   requesting: boolean
   successful: boolean
   error: string
   data: TypeUserInterface[]
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionState
   sortCondition: SortConditionInterface
   // Sync user
   isTriggerSync: boolean
   requestingSync: boolean
   dateSync: string
}

const initialState: TypeUserState = {
   requesting: false,
   successful: false,
   error: "",
   data: [],
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableEnum.NONE,
      value: "",
   },
   sortCondition: {
      column: SortColumnEnum.NONE,
      order: SortOrderEnum.ASC,
   },
   isTriggerSync: false,
   requestingSync: true,
   dateSync: "",
}

export const typeUserSlice = createSlice({
   name: "TypeUser",
   initialState,
   reducers: {
      requestTypeUser: (state: TypeUserState) => {
         state.requesting = true
      },
      getTypeUserSuccess: (
         state: TypeUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data.data
         state.rowCount = action.payload.data.rowCount
      },
      getTypeUserFail: (state: TypeUserState, action: PayloadAction<any>) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: TypeUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: TypeUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: TypeUserState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.value = action.payload.value
      },
      changeSortConditionSuccess: (
         state: TypeUserState,
         action: PayloadAction<any>
      ) => {
         state.sortCondition.order = handleSortOrder(
            state.sortCondition.column,
            action.payload.column,
            state.sortCondition.order
         )
         state.sortCondition.column = action.payload.column
      },
      requestSyncUser: (state: TypeUserState) => {
         state.requestingSync = true
         state.dateSync = ""
      },
      syncUserSuccess: (
         state: TypeUserState,
         action: PayloadAction<string>
      ) => {
         state.isTriggerSync = false
         state.requestingSync = false
         state.dateSync = action.payload
      },
      setIsTriggerSync: (
         state: TypeUserState,
         action: PayloadAction<boolean>
      ) => {
         state.isTriggerSync = action.payload
      },
   },
})

export const {
   requestTypeUser,
   getTypeUserSuccess,
   getTypeUserFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   changeSortConditionSuccess,
   requestSyncUser,
   syncUserSuccess,
   setIsTriggerSync,
} = typeUserSlice.actions

export default typeUserSlice.reducer
