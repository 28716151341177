import {
   otpRegisterAPI,
   OtpRegisterPayloadAPIInterface,
   registerAPI,
   RegisterPayloadAPIInterface,
   resendOtpRegisterAPI,
   ResendOtpRegisterPayloadAPIInterface,
} from "./../../api/register.api"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, put, takeLatest } from "redux-saga/effects"
import {
   registerUserRequest,
   registerUserSuccess,
   registerUserFail,
   otpRegisterRequest,
   otpRegisterSuccess,
} from "./register.slice"
import { authenticateApp } from "../auth/auth.saga"
import { pushNotistack } from "../notistack/notistack.slice"
import {
   otpRegisterUserAsync,
   registerUserAsync,
   resendOtpRegisterUserAsync,
} from "./register.action"
import { resetSignIn } from "../auth/auth.slice"
import { i18n } from "../../i18n/i18n"
import { namespaces } from "../../i18n/i18n.constants"

/**
 * Register user saga
 * @param action
 * @returns
 */
export function* registerUserSaga(action: PayloadAction<any>): any {
   try {
      yield put(registerUserRequest())
      const token = yield call(authenticateApp)
      if (token) {
         const params: RegisterPayloadAPIInterface = {
            username: "",
            provinceId: action.payload.values.province?.id,
            email: action.payload.values.email,
            password: action.payload.values.password,
            fullname: action.payload.values.name,
            phoneNumber: action.payload.values.phoneNumber,
            accountTypeId: action.payload.values.accountType,
         }
         const { response, error } = yield call(registerAPI, token, params)
         if (!response) {
            throw error
         } else if (response.id) {
            yield put(registerUserFail("Tài khoản đã được đăng kí."))
         } else {
            yield put(registerUserSuccess())
         }
      }
   } catch (error: any) {
      if (error.response.data.status === 500) {
         yield put(registerUserFail(error.response.data.detail))
      } else {
         yield put(registerUserFail("error.register"))
      }
   }
}

/**
 * Otp register saga
 * @param action
 */
function* otpRegisterSaga(action: PayloadAction<any>): any {
   try {
      if (action.payload.otpTimer === 0) {
         // yield put(
         //    pushNotistack({
         //       message: i18n.t("notistack.expiredOtp", {
         //          ns: namespaces.pages.register,
         //       }),
         //       variant: "error",
         //    })
         // )
         // return
      }
      const token = yield call(authenticateApp)
      if (token) {
         yield put(otpRegisterRequest())
         const params: OtpRegisterPayloadAPIInterface = {
            phoneNumber: action.payload.phoneNumber,
            otp: action.payload.otp,
         }
         const { response, error } = yield call(otpRegisterAPI, token, params)
         if (!response) {
            throw error
         } else {
            yield put(otpRegisterSuccess())
            yield put(
               pushNotistack({
                  message: i18n.t("notistack.registerSuccess", {
                     ns: namespaces.pages.register,
                  }),
                  variant: "success",
               })
            )

            yield put(resetSignIn())

            action.payload.navigate("/sign-in")
         }
      }
   } catch (error: any) {
      if (error.response.data?.status === 500) {
         yield put(registerUserFail(error.response.data.detail))
      } else {
         yield put(registerUserFail("error.register"))
      }
   }
}

/**
 * Resend OTP register saga
 * @param action
 */
function* resendOtpRegisterSaga(action: PayloadAction<any>): any {
   try {
      const token = yield call(authenticateApp)
      if (token) {
         yield put(otpRegisterRequest())
         const params: ResendOtpRegisterPayloadAPIInterface = {
            phoneNumber: action.payload.phoneNumber,
         }
         const { response, error } = yield call(
            resendOtpRegisterAPI,
            token,
            params
         )
         if (!response) {
            throw error
         } else {
            yield put(otpRegisterSuccess())
            yield put(
               pushNotistack({
                  message: i18n.t("notistack.resendOtpSuccess", {
                     ns: namespaces.pages.register,
                  }),
                  variant: "success",
               })
            )
         }
      }
   } catch (error: any) {
      if (error.response.data.status === 500) {
         yield put(registerUserFail(error.response.data.detail))
      } else {
         yield put(registerUserFail("error.resendOtp"))
      }
   }
}
/**
 * Root register saga
 */
export function* registerSaga() {
   yield takeLatest(registerUserAsync, registerUserSaga)
   yield takeLatest(otpRegisterUserAsync, otpRegisterSaga)
   yield takeLatest(resendOtpRegisterUserAsync, resendOtpRegisterSaga)
}
