import { zodResolver } from "@hookform/resolvers/zod"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import LinearProgress from "@mui/material/LinearProgress"
import Paper from "@mui/material/Paper"
import { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { visuallyHidden } from "@mui/utils"
import { useTranslation } from "react-i18next"
import { FilterTableComponent } from "../../common/components/FilterTable/filterTable.component"
import { PromotionPackInterface } from "../../common/interfaces/promotionPack.interface"
import { namespaces } from "../../i18n/i18n.constants"
import AddIcon from "@mui/icons-material/Add"
import Toolbar from "@mui/material/Toolbar"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import CircularProgress from "@mui/material/CircularProgress"
import commonStyles from "../../common/styles/common.module.css"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import TablePagination from "@mui/material/TablePagination"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"
import DeleteIcon from "@mui/icons-material/Delete"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import {
   promotionPackDialogSchema,
   PromotionPackRechargeDialogInput,
} from "./promotionPack.form"
import { columns, createData, filterTypes } from "./promotionPack.table"
import { DialogMethodEnum } from "../../common/enum/dialog.enum"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { TypeUserSelectAsyncComponent } from "../../common/components/TypeUserSelectAsync/typeUserSelectAsync.component"
import { TableSortLabel } from "@mui/material"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { createSortDirection } from "../../common/helper/sortTable.helper"
import { SortOrderEnum } from "../../common/enum/sortOrder.enum"
import { SortConditionInterface } from "../../common/interfaces/sortCondition.interface"
import { usePromotionPackPermission } from "./promotionPack.permission"
import { TablePaginationActionsComponent } from "../../common/components/TablePaginationActions/tablePaginationActions.component"

type PromotionPackComponentProps = {
   requesting: boolean
   pageSize: number
   pageNumber: number
   rowCount: number
   promotionPackData: any[]
   searchCondition: SearchConditionState
   sortCondition: SortConditionInterface
   error: string
   handleChangePage: (event: unknown, newPage: number) => void
   handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
   handlePushNotistack: (message: string, variant: string) => void
   handleChangeSearchCondition: (value: string, option: string) => void
   handleAddPromotionPackAsync: (
      values: PromotionPackRechargeDialogInput
   ) => Promise<any>
   handleUpdatePromotionPackAsync: (
      id: string,
      values: PromotionPackRechargeDialogInput
   ) => Promise<any>
   handleDeletePromotionPackAsync: (id: string) => Promise<any>
   handleReloadPromotionPackAsync: () => void
   handleChangeSortCondition: (column: SortColumnEnum) => void
}

export const PromotionPackComponent = (props: PromotionPackComponentProps) => {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

   const { t } = useTranslation(namespaces.pages.promotionPack)

   const {
      allowAddPromotionPackage,
      allowDeletePromotionPackage,
      // allowDetailPromotionPackage,
      allowUpdatePromotionPackage,
   } = usePromotionPackPermission()

   // Type list
   const rows = props.promotionPackData.map((pack) =>
      createData(
         pack.id,
         pack.name,
         pack.coinPercent,
         pack.createdAt,
         pack.userTypeConditions
      )
   )

   const sortDirection = createSortDirection(props.sortCondition.order)
   const createSortHandler =
      (column: SortColumnEnum) => (event: React.MouseEvent<unknown>) => {
         props.handleChangeSortCondition(column)
      }

   // Dialog state
   const [requestingDialog, setRequestingDialog] = useState<boolean>(false)
   const [openDialog, setOpenDialog] = useState<boolean>(false)
   const [typeDialog, setTypeDialog] = useState<DialogMethodEnum>(
      DialogMethodEnum.ADD
   )
   const [selectedDialog, setSelectedDialog] =
      useState<PromotionPackInterface | null>(null)

   // Form PromotionPack user dialog
   const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setValue,
   } = useForm<PromotionPackRechargeDialogInput>({
      resolver: zodResolver(promotionPackDialogSchema),
   })

   /**
    * Handle open dialog
    * @param type
    * @param selected
    */
   const handleOpenDialog = (
      type: DialogMethodEnum,
      selected: PromotionPackInterface | null = null
   ) => {
      setOpenDialog(true)
      setTypeDialog(type)
      if (selected) {
         setSelectedDialog(selected)
         setValue("name", selected.name)
         setValue("percent", selected.percent)
         if (selected.userTypeConditions.length > 0) {
            setValue("userType", selected.userTypeConditions)
         }
      }
   }

   /**
    * Handle close dialog
    */
   const handleCloseDialog = () => {
      setOpenDialog(false)
      setSelectedDialog(null)
      reset()
      setValue("userType", [])
   }

   /**
    * Render dialog title
    * @returns
    */
   const renderDialogTitle = () => {
      return (
         <DialogTitle>
            {typeDialog === DialogMethodEnum.ADD && t("dialog.add")}
            {typeDialog === DialogMethodEnum.UPDATE && t("dialog.update")}
            {typeDialog === DialogMethodEnum.DELETE && t("dialog.delete")}
         </DialogTitle>
      )
   }

   /**
    * Render dialog requesting
    * @returns
    */
   const renderRequestingDialog = () => {
      if (requestingDialog) {
         return (
            <Box sx={{ width: "100%" }}>
               <LinearProgress />
            </Box>
         )
      }
   }

   /**
    * Render dialog action
    * @returns
    */
   const renderDialogAction = () => {
      return (
         <DialogActions>
            {typeDialog === DialogMethodEnum.ADD && (
               <Button type="submit" disabled={requestingDialog}>
                  {t("button.add")}
               </Button>
            )}
            {typeDialog === DialogMethodEnum.UPDATE &&
               allowUpdatePromotionPackage && (
                  <Button type="submit" disabled={requestingDialog}>
                     {t("button.update")}
                  </Button>
               )}
            {typeDialog === DialogMethodEnum.DELETE && (
               <Button
                  color="error"
                  disabled={requestingDialog}
                  onClick={onDeletePromotionPack}
               >
                  {t("button.delete")}
               </Button>
            )}
            <Button
               color="info"
               disabled={requestingDialog}
               onClick={handleCloseDialog}
            >
               {t("button.cancel")}
            </Button>
         </DialogActions>
      )
   }

   /**
    * Render dialog content
    * @returns
    */
   const renderDialogContent = () => {
      if (typeDialog === DialogMethodEnum.DELETE) {
         return (
            <DialogContent>
               <Typography mb={1} variant="body1">
                  {t("dialog.deletePromotionPackConfirm", {
                     item: selectedDialog?.name,
                  })}
               </Typography>
            </DialogContent>
         )
      }
      return (
         <DialogContent>
            <TextField
               autoFocus
               required
               margin="dense"
               id="name"
               label={t("promotionPackTable.name")}
               fullWidth
               variant="standard"
               disabled={requestingDialog}
               error={!!errors.name}
               helperText={errors.name ? errors.name.message : ""}
               inputProps={{
                  max: 100,
               }}
               InputProps={{
                  readOnly: !allowUpdatePromotionPackage,
               }}
               {...register("name")}
            />
            <TextField
               margin="dense"
               required
               id="percent"
               label={t("promotionPackTable.percent")}
               fullWidth
               type="number"
               variant="standard"
               disabled={requestingDialog}
               error={!!errors.percent}
               helperText={errors.percent ? errors.percent.message : ""}
               inputProps={{
                  min: 1,
                  max: 999999999999999,
               }}
               InputProps={{
                  readOnly: !allowUpdatePromotionPackage,
               }}
               onKeyDown={(e) => onKeydown(e)}
               {...register("percent", {
                  setValueAs: (v) => (v === "" ? undefined : parseFloat(v)),
               })}
            />
            <Controller
               control={control}
               name="userType"
               rules={{ required: true }}
               render={({ field: { onChange, value } }: any) => (
                  <TypeUserSelectAsyncComponent
                     variant="standard"
                     value={value}
                     error={!!errors.userType}
                     helperText={t("dialog.allUser")}
                     disabled={false}
                     InputProps={{
                        readOnly: !allowUpdatePromotionPackage,
                     }}
                     onInputChange={(inputValue: any) => {
                        onChange(inputValue)
                     }}
                  />
               )}
            />
         </DialogContent>
      )
   }

   /**
    * On submit form
    * @param values
    */
   const onSubmitForm: SubmitHandler<PromotionPackRechargeDialogInput> = (
      values: PromotionPackRechargeDialogInput
   ) => {
      setRequestingDialog(true)
      if (typeDialog === DialogMethodEnum.ADD) {
         props
            .handleAddPromotionPackAsync(values)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Thêm gói khuyến mãi thành công!",
                  "success"
               )
               handleCloseDialog()
               props.handleReloadPromotionPackAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               if (err.response.data.status === 500) {
                  props.handlePushNotistack(err.response.data.detail, "error")
               } else {
                  props.handlePushNotistack(
                     "Thêm gói khuyến mãi thất bại!",
                     "error"
                  )
               }
            })
      } else if (typeDialog === DialogMethodEnum.UPDATE && selectedDialog) {
         props
            .handleUpdatePromotionPackAsync(selectedDialog.id, values)
            .then((res) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Cập nhật gói khuyến mãi thành công!",
                  "success"
               )
               handleCloseDialog()
               props.handleReloadPromotionPackAsync()
            })
            .catch((err) => {
               setRequestingDialog(false)
               props.handlePushNotistack(
                  "Cập nhật gói khuyến mãi thất bại!",
                  "error"
               )
            })
      }
   }

   /**
    * On delete pack
    */
   const onDeletePromotionPack = () => {
      setRequestingDialog(true)
      props
         .handleDeletePromotionPackAsync(selectedDialog?.id as string)
         .then((res) => {
            setRequestingDialog(false)
            props.handlePushNotistack(
               "Xóa gói khuyến mãi thành công!",
               "success"
            )
            handleCloseDialog()
            props.handleReloadPromotionPackAsync()
         })
         .catch((err) => {
            setRequestingDialog(false)
            props.handlePushNotistack("Xóa gói khuyến mãi thất bại!", "error")
         })
   }

   /**
    * On key down
    * @param e
    */
   const onKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (
         e.keyCode === 189 ||
         e.keyCode === 109 ||
         e.keyCode === 188 ||
         e.keyCode === 110 ||
         e.keyCode === 190
      ) {
         e.preventDefault()
      }
   }

   return (
      <Container maxWidth="xl">
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Toolbar
               sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  py: 2,
               }}
            >
               <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
               >
                  <Grid item xs={12} md={12} xl={6}>
                     <FilterTableComponent
                        searchCondition={props.searchCondition}
                        types={filterTypes}
                        onFilter={(value: string, option: string) =>
                           props.handleChangeSearchCondition(value, option)
                        }
                     />
                  </Grid>
                  <Grid item xs={12} md={12} xl={6}>
                     <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={12} xl={4}>
                           {allowAddPromotionPackage && (
                              <Button
                                 fullWidth
                                 color="secondary"
                                 variant="contained"
                                 startIcon={<AddIcon />}
                                 onClick={() =>
                                    handleOpenDialog(DialogMethodEnum.ADD)
                                 }
                              >
                                 {t("button.add")}
                              </Button>
                           )}
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Toolbar>
            <TableContainer>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell key={column.id} align={column.align}>
                              {column.sortKey ? (
                                 <TableSortLabel
                                    active={
                                       props.sortCondition.column ===
                                       column.sortKey
                                    }
                                    direction={sortDirection}
                                    onClick={createSortHandler(column.sortKey)}
                                 >
                                    {t(column.label)}
                                    {props.sortCondition.column ===
                                    column.sortKey ? (
                                       <Box
                                          component="span"
                                          sx={visuallyHidden}
                                       >
                                          {props.sortCondition.order ===
                                          SortOrderEnum.DESC
                                             ? "Giảm"
                                             : "Tăng"}
                                       </Box>
                                    ) : null}
                                 </TableSortLabel>
                              ) : (
                                 t(column.label)
                              )}
                           </TableCell>
                        ))}
                        <TableCell key={"action"} style={{ minWidth: 170 }}>
                           {t("promotionPackTable.action")}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {/* Loading */}
                     {props.requesting && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <div className={commonStyles.flexCenterCenter}>
                                 <CircularProgress color="secondary" />
                              </div>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Error */}
                     {!props.requesting && props.error && (
                        <TableRow>
                           <TableCell colSpan={columns.length + 1}>
                              <Typography variant="body1" color="error">
                                 {t(props.error, { ns: "common" })}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     )}
                     {/* Empty */}
                     {!props.requesting &&
                        !props.error &&
                        rows.length === 0 && (
                           <TableRow>
                              <TableCell colSpan={columns.length + 1}>
                                 <Typography variant="body1">
                                    {t("table.empty", { ns: "common" })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                        )}
                     {/* Data  */}
                     {!props.requesting &&
                        !props.error &&
                        rows.map((row) => (
                           <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                           >
                              {columns.map((column) => {
                                 let value = row[column.id]
                                 return (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                       }}
                                    >
                                       {column.format
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 )
                              })}
                              <TableCell key={"action"}>
                                 <Tooltip title={t("button.update")}>
                                    <IconButton
                                       aria-label="edit"
                                       onClick={() =>
                                          handleOpenDialog(
                                             DialogMethodEnum.UPDATE,
                                             row
                                          )
                                       }
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </Tooltip>
                                 {allowDeletePromotionPackage && (
                                    <Tooltip title={t("button.delete")}>
                                       <IconButton
                                          aria-label="update"
                                          onClick={() =>
                                             handleOpenDialog(
                                                DialogMethodEnum.DELETE,
                                                row
                                             )
                                          }
                                       >
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               showFirstButton
               showLastButton
               labelRowsPerPage={t("table.pageSize", { ns: "common" })}
               labelDisplayedRows={(page) => {
                  return `${t("table.page", { ns: "common" })}: ${
                     page.page + 1
                  } - ${t("table.total", { ns: "common" })}: ${page.count} ${t(
                     "table.record",
                     { ns: "common" }
                  )}`
               }}
               rowsPerPageOptions={[10, 25, 100, 300, 500]}
               component="div"
               count={props.rowCount}
               rowsPerPage={props.pageSize}
               page={props.pageNumber - 1}
               onPageChange={props.handleChangePage}
               onRowsPerPageChange={props.handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActionsComponent}
            />
         </Paper>
         <Dialog
            fullScreen={fullScreen}
            maxWidth={"sm"}
            open={openDialog}
            onClose={handleCloseDialog}
         >
            <Box
               component="form"
               autoComplete="on"
               noValidate
               sx={{ width: "100%" }}
               onSubmit={handleSubmit(onSubmitForm)}
            >
               {renderRequestingDialog()}
               {renderDialogTitle()}
               {renderDialogContent()}
               {renderDialogAction()}
            </Box>
         </Dialog>
      </Container>
   )
}
