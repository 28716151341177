import { call, put, select, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import {
   requestManagementUserDetail,
   requestManagementUserDetailCompany,
   requestManagementUserDetailCompanyEnd,
   requestManagementUserDetailCompanyFail,
   requestManagementUserDetailCompanySuccess,
   requestManagementUserDetailInfo,
   requestManagementUserDetailInfoEnd,
   requestManagementUserDetailInfoFail,
   requestManagementUserDetailInfoSuccess,
   requestManagementUserDetailWallet,
   requestManagementUserDetailWalletFail,
   requestManagementUserDetailWalletSuccess,
   setEditManagementUserDetailCompany,
   setEditManagementUserDetailInfo,
} from "./managementUserDetail.slice"
import {
   getManagementUserDetailAsync,
   updateManagementUserDetailCompanyAsync,
   updateManagementUserDetailInfoAsync,
} from "./managementUserDetail.action"
import {
   selectIsCompanyManagementUserDetailState,
   selectSelectedIdManagementUserDetailState,
} from "./managementUserDetail.selector"
import {
   getManagementUserInfoAPI,
   GetManagementUserInfoPayloadAPIInterface,
   updateManagementUserInfoAPI,
   UpdateManagementUserInfoPayloadAPIInterface,
} from "../../api/managementUser.api"
import { genQueries } from "../../common/helper/queries.helper"
import { QueriesEnum } from "../../common/enum/queries.enum"
import { ManagementUserDetailInfoInterface } from "../../common/interfaces/managementUserDetailInfo.interface"
import { ManagementUserDetailInfoInput } from "../../pages/managementUserDetail/managementUserDetailInfo/managementUserDetailInfo.form"
import { pushNotistack } from "../notistack/notistack.slice"
import { ManagementUserDetailCompanyInterface } from "../../common/interfaces/managementUserDetailCompany.interface"
import { ManagementUserDetailCompanyInput } from "../../pages/managementUserDetail/managementUserDetailCompany/managementUserDetailCompany.form"
import { ManagementUserDetailWalletInterface } from "../../common/interfaces/managementUserDetailWallet.interface"
import { convertDay } from "../../common/helper/date.helper"

/**
 * Get management user detail saga
 * @param action
 */
function* getManagementUserDetailSaga(action: PayloadAction<string>): any {
   try {
      yield put(requestManagementUserDetail(action.payload))

      yield call(getManagementUserDetailInfoSaga)

      const isCompany = yield select(selectIsCompanyManagementUserDetailState)
      if (isCompany) {
         yield call(getManagementUserDetailCompanySaga)
      }

      yield call(getManagementUserDetailWalletSaga)
   } catch (error) {}
}

/**
 * Get management user detail info saga
 */
function* getManagementUserDetailInfoSaga(): any {
   try {
      yield put(requestManagementUserDetailInfo())
      const id = yield select(selectSelectedIdManagementUserDetailState)
      const params: GetManagementUserInfoPayloadAPIInterface = {
         id: id,
         queries: genQueries(
            QueriesEnum.FULLNAME,
            QueriesEnum.EMAIL,
            QueriesEnum.PHONENUMBER,
            QueriesEnum.PROVINCE_ID,
            QueriesEnum.PROVINCE_NAME,
            QueriesEnum.DISTRICT_ID,
            QueriesEnum.DISTRICT_NAME,
            QueriesEnum.WARD_ID,
            QueriesEnum.WARD_NAME,
            QueriesEnum.ACCOUNT_TYPE_ID,
            QueriesEnum.NOTE,
            QueriesEnum.BIRTHDAY,
            QueriesEnum.GENDER,
            QueriesEnum.IDENTITY_NUMBER,
            QueriesEnum.ISSUEDDATE,
            QueriesEnum.ISSUEDBY,
            QueriesEnum.ADDRESS,
            QueriesEnum.STREET
         ),
      }
      const { response, error } = yield call(getManagementUserInfoAPI, params)
      if (!response) {
         throw error
      }
      const data: ManagementUserDetailInfoInterface = {
         fullname: response.data.Fullname,
         email: response.data.Email,
         phoneNumber: response.data.PhoneNumber,
         province: {
            id: response.data.ProvinceId ? response.data.ProvinceId : "",
            name: response.data.ProvinceName ? response.data.ProvinceName : "",
         },
         district: {
            id: response.data.DistrictId ? response.data.DistrictId : "",
            name: response.data.DistrictName ? response.data.DistrictName : "",
         },
         ward: {
            id: response.data.WardId ? response.data.WardId : "",
            name: response.data.WardName ? response.data.WardName : "",
         },
         accountTypeId: response.data.AccountTypeId,
         note: response.data.Note,
         birthday: response.data.Birthday ? response.data.Birthday : null,
         isMale: response.data.Gender,
         identityCard: response.data.IdentityNumber,
         identityCardDate: response.data.IssuedDate
            ? response.data.IssuedDate
            : null,
         identityCardPlace: response.data.IssuedBy,
         addressNumber: response.data.Street,
         address: response.data.Address,
      }
      yield put(requestManagementUserDetailInfoSuccess(data))
   } catch (error) {
      yield put(requestManagementUserDetailInfoFail("table.error"))
   }
}

/**
 * Update management user detail info saga
 * @param action
 */
function* updateManagementUserDetailInfoSaga(
   action: PayloadAction<ManagementUserDetailInfoInput>
): any {
   try {
      yield put(requestManagementUserDetailInfo())
      const id = yield select(selectSelectedIdManagementUserDetailState)
      const params: UpdateManagementUserInfoPayloadAPIInterface = {
         Properties: [
            {
               Property: QueriesEnum.PROVINCE_ID,
               Value:
                  action.payload.province &&
                  action.payload.province.id.length > 0
                     ? action.payload.province.id
                     : null,
            },
            {
               Property: QueriesEnum.DISTRICT_ID,
               Value:
                  action.payload.district &&
                  action.payload.district.id.length > 0
                     ? action.payload.district.id
                     : null,
            },
            {
               Property: QueriesEnum.WARD_ID,
               Value:
                  action.payload.ward && action.payload.ward.id.length > 0
                     ? action.payload.ward.id
                     : null,
            },
            {
               Property: QueriesEnum.EMAIL,
               Value: action.payload.email,
            },
            {
               Property: QueriesEnum.FULLNAME,
               Value: action.payload.name,
            },
            {
               Property: QueriesEnum.ACCOUNT_TYPE_ID,
               Value: action.payload.accountType,
            },
            {
               Property: QueriesEnum.NOTE,
               Value: action.payload.note,
            },
            {
               Property: QueriesEnum.BIRTHDAY,
               Value: action.payload.birthday
                  ? action.payload.birthday.toISOString()
                  : "",
            },
            {
               Property: QueriesEnum.GENDER,
               Value: action.payload.isMale,
            },
            {
               Property: QueriesEnum.IDENTITY_NUMBER,
               Value: action.payload.identityCard,
            },
            {
               Property: QueriesEnum.ISSUEDDATE,
               Value: action.payload.identityCardDate
                  ? action.payload.identityCardDate.toISOString()
                  : "",
            },
            {
               Property: QueriesEnum.ISSUEDBY,
               Value: action.payload.identityCardPlace,
            },
            {
               Property: QueriesEnum.ADDRESS,
               Value: action.payload.address,
            },
            {
               Property: QueriesEnum.STREET,
               Value: action.payload.addressNumber,
            },
         ],
      }

      const { response, error } = yield call(
         updateManagementUserInfoAPI,
         id,
         params
      )
      if (!response) {
         throw error
      }

      const data: ManagementUserDetailInfoInterface = {
         fullname: action.payload.name,
         email: action.payload.email,
         phoneNumber: action.payload.phoneNumber,
         province: action.payload.province,
         accountTypeId: action.payload.accountType,
         note: action.payload.note,
         birthday: action.payload.birthday
            ? action.payload.birthday.toISOString()
            : null,
         isMale: action.payload.isMale,
         identityCard: action.payload.identityCard,
         identityCardDate: action.payload.identityCardDate
            ? action.payload.identityCardDate.toISOString()
            : null,
         identityCardPlace: action.payload.identityCardPlace,
         addressNumber: action.payload.addressNumber,
         address: action.payload.address,
      }

      yield put(requestManagementUserDetailInfoSuccess(data))

      yield put(setEditManagementUserDetailInfo(false))

      yield put(
         pushNotistack({
            message: "Cập nhật thông tin thành công",
            variant: "success",
         })
      )
   } catch (error) {
      yield put(requestManagementUserDetailInfoEnd())

      yield put(
         pushNotistack({
            message: "Cập nhật thông tin thất bại",
            variant: "error",
         })
      )
   }
}

/**
 * Get management user detail company saga
 */
function* getManagementUserDetailCompanySaga(): any {
   try {
      yield put(requestManagementUserDetailCompany())
      const id = yield select(selectSelectedIdManagementUserDetailState)
      const params: GetManagementUserInfoPayloadAPIInterface = {
         id: id,
         queries: genQueries(
            QueriesEnum.COMPANY_NAME,
            QueriesEnum.COMPANY_TAX,
            QueriesEnum.COMPANY_ADDRESS,
            QueriesEnum.COMPANY_PHONENUMBER,
            QueriesEnum.COMPANY_EMAIL
         ),
      }
      const { response, error } = yield call(getManagementUserInfoAPI, params)
      if (!response) {
         throw error
      }
      const data: ManagementUserDetailCompanyInterface = {
         name: response.data.Name,
         taxCode: response.data.Tax,
         address: response.data.Address,
         phoneNumber: response.data.Phone,
         email: response.data.Email,
      }

      yield put(requestManagementUserDetailCompanySuccess(data))
   } catch (error) {
      yield put(requestManagementUserDetailCompanyFail("table.error"))
   }
}

/**
 * Update management user detail company saga
 * @param action
 */
function* updateManagementUserDetailCompanySaga(
   action: PayloadAction<ManagementUserDetailCompanyInput>
): any {
   try {
      yield put(requestManagementUserDetailCompany())
      const id = yield select(selectSelectedIdManagementUserDetailState)
      const params: UpdateManagementUserInfoPayloadAPIInterface = {
         Properties: [
            {
               Property: QueriesEnum.COMPANY_NAME,
               Value: action.payload.name,
            },
            {
               Property: QueriesEnum.COMPANY_EMAIL,
               Value: action.payload.email,
            },
            {
               Property: QueriesEnum.COMPANY_TAX,
               Value: action.payload.taxCode,
            },
            {
               Property: QueriesEnum.COMPANY_ADDRESS,
               Value: action.payload.address,
            },
            {
               Property: QueriesEnum.COMPANY_PHONENUMBER,
               Value: action.payload.phoneNumber,
            },
         ],
      }

      const { response, error } = yield call(
         updateManagementUserInfoAPI,
         id,
         params
      )
      if (!response) {
         throw error
      }

      const data: ManagementUserDetailCompanyInterface = {
         name: action.payload.name,
         email: action.payload.email,
         taxCode: action.payload.taxCode,
         phoneNumber: action.payload.phoneNumber,
         address: action.payload.address,
      }

      yield put(requestManagementUserDetailCompanySuccess(data))

      yield put(setEditManagementUserDetailCompany(false))

      yield put(
         pushNotistack({
            message: "Cập nhật thông tin thành công",
            variant: "success",
         })
      )
   } catch (error) {
      yield put(requestManagementUserDetailCompanyEnd())

      yield put(
         pushNotistack({
            message: "Cập nhật thông tin thất bại",
            variant: "error",
         })
      )
   }
}

/**
 * Get management user detail wallet saga
 */
function* getManagementUserDetailWalletSaga(): any {
   try {
      yield put(requestManagementUserDetailWallet())
      const id = yield select(selectSelectedIdManagementUserDetailState)
      const params: GetManagementUserInfoPayloadAPIInterface = {
         id: id,
         queries: genQueries(QueriesEnum.WALLET, QueriesEnum.WALLET_DATE),
      }
      const { response, error } = yield call(getManagementUserInfoAPI, params)
      if (!response) {
         throw error
      }
      const data: ManagementUserDetailWalletInterface = {
         wallet: response.data.Amount,
         walletDate: response.data.ExpiredDate
            ? convertDay(response.data.ExpiredDate)
            : "",
      }

      yield put(requestManagementUserDetailWalletSuccess(data))
   } catch (error) {
      yield put(requestManagementUserDetailWalletFail("table.error"))
   }
}

/**
 * Root management user detail saga
 */
export function* managementUserDetailSaga() {
   yield takeLatest(getManagementUserDetailAsync, getManagementUserDetailSaga)
   yield takeLatest(
      updateManagementUserDetailInfoAsync,
      updateManagementUserDetailInfoSaga
   )
   yield takeLatest(
      updateManagementUserDetailCompanyAsync,
      updateManagementUserDetailCompanySaga
   )
}
