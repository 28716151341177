import { convertDate } from "../../../common/helper/date.helper"
import { ProvinceInterface } from "../../../common/interfaces/province.interface"
import { CreateEmailUserInterface } from "../../../common/interfaces/createEmailUser.interface"
import { FilterTableEnum } from "../../../common/enum/filterTable.enum"

export interface Column {
   id: "fullname" | "email" | "phoneNumber" | "province" | "createAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   format?: (value: number) => string
}

export const filterTypes: FilterTableEnum[] = [
   FilterTableEnum.FULLNAME,
   FilterTableEnum.EMAIL,
   FilterTableEnum.PHONE_NUMBER,
]

export const columns: readonly Column[] = [
   {
      id: "fullname",
      label: "createEmailUserTable.name",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "email",
      label: "createEmailUserTable.email",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "phoneNumber",
      label: "createEmailUserTable.phoneNumber",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "province",
      label: "createEmailUserTable.province",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createAt",
      label: "createEmailUserTable.createAt",
      minWidth: 170,
      maxWidth: 400,
   },
]

export function createData(
   id: string,
   fullname: string,
   email: string,
   phoneNumber: string,
   province: ProvinceInterface = { id: "", name: "" },
   createAt: string
): CreateEmailUserInterface {
   createAt = convertDate(createAt)
   return { id, fullname, email, phoneNumber, province, createAt }
}
