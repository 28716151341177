import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   exportExcelChartSignUpAsync,
   getChartSignUpAsync,
} from "../../states/chartSignUp/chartSignUp.action"
import {
   selectDataChartSignUp,
   selectErrorChartSignUp,
   selectFilterChartSignUp,
   selectIsDisableExport,
   selectIsDisableStatistic,
   selectRequestingChartSignUp,
   selectRequestingExportChartSignUp,
   selectSearchConditionChartSignUp,
} from "../../states/chartSignUp/chartSignUp.selector"
import {
   changeFilter,
   changeSearchCondtion,
} from "../../states/chartSignUp/chartSignUp.slice"
import { ChartSignUpComponent } from "./chartSignUp.component"

export const ChartSignUpContainer = () => {
   const dispatch = useDispatch()
   const refChart = useRef()
   // Selector chart
   const requestingChart = useSelector(selectRequestingChartSignUp)
   const requestingExport = useSelector(selectRequestingExportChartSignUp)
   const dataChart = useSelector(selectDataChartSignUp)
   const errorChart = useSelector(selectErrorChartSignUp)
   const filterChart = useSelector(selectFilterChartSignUp)
   const searchConditionChart = useSelector(selectSearchConditionChartSignUp)
   const isDisableStatistic = useSelector(selectIsDisableStatistic)
   const isDisableExport = useSelector(selectIsDisableExport)

   /**
    * Handle statistic
    */
   const handleStatistic = () => {
      dispatch(getChartSignUpAsync())
   }

   /**
    * Handle change search condition
    * @param data
    */
   const handleChangeSearchCondition = (data: any) => {
      dispatch(changeSearchCondtion(data))
   }

   /**
    * Handle change filter
    * @param data
    */
   const handleChangeFilter = (data: any) => {
      dispatch(changeFilter(data))
   }

   /**
    * Handle chart export
    */
   const handleChartExport = async () => {
      dispatch(exportExcelChartSignUpAsync({ refChart: refChart }))
   }

   return (
      <ChartSignUpComponent
         refChart={refChart}
         requestingChart={requestingChart}
         requestingExport={requestingExport}
         dataChart={dataChart}
         errorChart={errorChart}
         filterChart={filterChart}
         searchConditionChart={searchConditionChart}
         isDisableStatistic={isDisableStatistic}
         isDisableExport={isDisableExport}
         handleStatistic={handleStatistic}
         handleChangeFilter={handleChangeFilter}
         handleChangeSearchCondition={handleChangeSearchCondition}
         handleChartExport={handleChartExport}
      />
   )
}
