import { ChartSignInState } from "./chartSignIn.slice"
import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"

const selectChartSignInState = (state: RootState): ChartSignInState =>
   state.chartSignIn

export const selectRequestingChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.requesting
)

export const selectRequestingExportChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.excelExporting
)

export const selectDataChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.data
)

export const selectErrorChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.error
)

export const selectFilterChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.filter
)

export const selectSearchConditionChartSignIn = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.searchCondition
)

export const selectIsDisableStatistic = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => false
)

export const selectIsDisableExport = createSelector(
   selectChartSignInState,
   (state: ChartSignInState) => state.data.length === 0
)
