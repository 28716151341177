import { RootState } from "../rootStore"
import { createSelector } from "@reduxjs/toolkit"
import { PromotionPackState } from "./promotionPack.slice"

const selectPromotionPackState = (state: RootState): PromotionPackState =>
   state.promotionPack

export const selectRequestingPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.requesting
)

export const selectDataPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.data
)

export const selectErrorPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.error
)

export const selectSortConditionPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.sortCondition
)

export const selectCountPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.rowCount
)

export const selectPageNumberPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.paging.pageNumber
)

export const selectPageSizePromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.paging.pageSize
)

export const selectSearchConditionPromotionPack = createSelector(
   selectPromotionPackState,
   (state: PromotionPackState) => state.searchCondition
)
