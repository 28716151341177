import { PagingBody } from "../common/interfaces/pagingParam.interface"
import { SearchConditionBody } from "../common/interfaces/searchConditionParam"
import { AxiosAuth } from "./config/axiosAuth.instance"

export interface GetCreateEmailUserPayloadAPIInterface
   extends PagingBody,
      SearchConditionBody {}

export const getCreateEmailUserAPI = (
   payload: GetCreateEmailUserPayloadAPIInterface
) => {
   return AxiosAuth.post("user/get-list", payload).then((response) => ({
      response,
   }))
}
