/**
 * Get header authorization
 * @returns
 */
export const getHeaderAuthorization = () => {
   const local = localStorage.getItem(
      process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
   )
   const session = sessionStorage.getItem(
      process.env.REACT_APP_AUTHENTICATE_USER_KEY as string
   )
   const data = local || session

   return data ? JSON.parse(data as string) : null
}

/**
 * Get token authorization
 * @returns
 */
export const getTokenAuthorization = () => {
   const parseData = getHeaderAuthorization()

   if (!parseData) {
      return ""
   }

   return parseData.token
}

/**
 * Check is authorization
 * @returns
 */
export const isAuthorization = () => {
   const parseData = getHeaderAuthorization()

   if (!parseData) {
      return false
   }

   if (new Date(parseData.expiredIn) < new Date()) {
      localStorage.clear()
      sessionStorage.clear()
      return false
   }

   return true
}
