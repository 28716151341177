import { useSelector } from "react-redux"
import {
   allowAddCreateEmailTemplate,
   allowDeleteCreateEmailTemplate,
   allowUpdateCreateEmailTemplate,
} from "../../../states/permission/permission.selector"

export const useCreateEmailTemplatePermission = () => {
   return {
      allowDeleteCreateEmailTemplate: useSelector(
         allowDeleteCreateEmailTemplate
      ),
      allowAddCreateEmailTemplate: useSelector(allowAddCreateEmailTemplate),
      allowUpdateCreateEmailTemplate: useSelector(
         allowUpdateCreateEmailTemplate
      ),
   }
}
