import { useDispatch, useSelector } from "react-redux"
import {
   getUserInfoAPI,
   GetUserInfoPayloadAPIInterface,
   updateUserInfoAPI,
   UpdateUserInfoPayloadAPIInterface,
} from "../../../api/setting.api"
import { QueriesEnum } from "../../../common/enum/queries.enum"
import { genQueries } from "../../../common/helper/queries.helper"
import { pushNotistack } from "../../../states/notistack/notistack.slice"
import { getUserInfoAsync } from "../../../states/user/user.action"
import { SettingUserInfoComponent } from "./settingUserInfo.component"
import { SettingUserInfoInput } from "./settingUserInfo.form"
import { selectAuthIsAdminRole } from "../../../states/auth/auth.selector"

export const SettingUserInfoContainer = () => {
   const dispatch = useDispatch()
   const isAdmin = useSelector(selectAuthIsAdminRole)
   /**
    * Handle reload get user info async
    */
   const handleReloadGetUserInfoAsync = () => {
      dispatch(getUserInfoAsync())
   }

   /**
    * Handle get user info async
    * @returns
    */
   const handleGetUserInfoAsync = () => {
      const params: GetUserInfoPayloadAPIInterface = {
         queries: genQueries(
            QueriesEnum.FULLNAME,
            QueriesEnum.EMAIL,
            QueriesEnum.PHONENUMBER,
            QueriesEnum.PROVINCE_ID,
            QueriesEnum.PROVINCE_NAME,
            QueriesEnum.DISTRICT_ID,
            QueriesEnum.DISTRICT_NAME,
            QueriesEnum.WARD_ID,
            QueriesEnum.WARD_NAME,
            QueriesEnum.BIRTHDAY,
            QueriesEnum.GENDER,
            QueriesEnum.IDENTITY_NUMBER,
            QueriesEnum.ISSUEDDATE,
            QueriesEnum.ISSUEDBY,
            QueriesEnum.ADDRESS,
            QueriesEnum.STREET
         ),
      }
      return getUserInfoAPI(params)
   }

   /**
    * Handle get user info async
    * @returns
    */
   const handleUpdateUserInfoAsync = (values: SettingUserInfoInput) => {
      const params: UpdateUserInfoPayloadAPIInterface = {
         Properties: [
            {
               Property: QueriesEnum.PROVINCE_ID,
               Value:
                  values.province && values.province.id.length > 0
                     ? values.province.id
                     : null,
            },
            {
               Property: QueriesEnum.DISTRICT_ID,
               Value:
                  values.district && values.district.id.length > 0
                     ? values.district.id
                     : null,
            },
            {
               Property: QueriesEnum.WARD_ID,
               Value:
                  values.ward && values.ward.id.length > 0
                     ? values.ward.id
                     : null,
            },
            {
               Property: QueriesEnum.EMAIL,
               Value: values.email ? values.email : "",
            },
            {
               Property: QueriesEnum.FULLNAME,
               Value: values.name,
            },
            {
               Property: QueriesEnum.BIRTHDAY,
               Value: values.birthday ? values.birthday.toISOString() : "",
            },
            {
               Property: QueriesEnum.GENDER,
               Value: values.isMale,
            },
            {
               Property: QueriesEnum.IDENTITY_NUMBER,
               Value: values.identityCard,
            },
            {
               Property: QueriesEnum.ISSUEDDATE,
               Value: values.identityCardDate
                  ? values.identityCardDate.toISOString()
                  : "",
            },
            {
               Property: QueriesEnum.ISSUEDBY,
               Value: values.identityCardPlace,
            },
            {
               Property: QueriesEnum.ADDRESS,
               Value: values.address,
            },
            {
               Property: QueriesEnum.STREET,
               Value: values.addressNumber,
            },
         ],
      }
      return updateUserInfoAPI(params)
   }

   /**
    * Handle push notistack
    * @param message
    * @param variant
    */
   const handlePushNotistack = (message: string, variant: string) => {
      dispatch(
         pushNotistack({
            message: message,
            variant: variant,
         })
      )
   }

   return (
      <SettingUserInfoComponent
         isAdmin={isAdmin}
         handlePushNotistack={handlePushNotistack}
         handleGetUserInfoAsync={handleGetUserInfoAsync}
         handleUpdateUserInfoAsync={handleUpdateUserInfoAsync}
         handleReloadGetUserInfoAsync={handleReloadGetUserInfoAsync}
      />
   )
}
