import { FilterTableEnum } from "../../common/enum/filterTable.enum"
import { SortColumnEnum } from "../../common/enum/sortColumn.enum"
import { convertDate, convertHours } from "../../common/helper/date.helper"
import { EmailInterface } from "../../common/interfaces/email.interface"

export interface Column {
   id: "name" | "startDate" | "endDate" | "time" | "isActive" | "createdAt"
   label: string
   minWidth?: number
   maxWidth?: number
   align?: "right"
   sortKey?: SortColumnEnum
   format?: (value: number | string | boolean) => string
}

export const filterTypes: FilterTableEnum[] = [FilterTableEnum.EMAIL_NAME]

export const columns: readonly Column[] = [
   {
      id: "name",
      label: "emailTable.name",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.EMAIL_NAME,
   },
   {
      id: "startDate",
      label: "emailTable.startDate",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "endDate",
      label: "emailTable.endDate",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "time",
      label: "emailTable.time",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "isActive",
      label: "emailTable.status",
      minWidth: 170,
      maxWidth: 400,
   },
   {
      id: "createdAt",
      label: "emailTable.createdAt",
      minWidth: 170,
      maxWidth: 400,
      sortKey: SortColumnEnum.EMAIL_CREATEAT,
   },
]

export function createData(
   id: string,
   name: string,
   startDate: string,
   endDate: string,
   time: string,
   isActive: boolean,
   createdAt: string,
   type: number
): EmailInterface {
   startDate = type === 1 ? "Ngay lập tức" : convertDate(startDate)
   endDate = type === 1 ? "Ngay lập tức" : convertDate(endDate)
   time = type === 1 ? "Ngay lập tức" : convertHours(time)
   createdAt = convertDate(createdAt)
   return { id, name, startDate, endDate, time, isActive, createdAt, type }
}
