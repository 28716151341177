import { ManagementUserDetailContainer } from "./../../pages/managementUserDetail/managementUserDetail.container"
import { ChartContainer } from "./../../pages/chart/chart.container"
import { CreateEmailContainer } from "./../../pages/createEmail/createEmail.container"
import { RechargePackContainer } from "./../../pages/rechargePack/rechargePack.container"
import { DepartmentContainer } from "./../../pages/department/department.container"
import { ManagementUserContainer } from "./../../pages/managementUser/managementUser.container"
import { ManagementWalletContainer } from "../../pages/managementWallet/managementWallet.container"
import { TypeUserContainer } from "../../pages/typeUser/typeUser.container"
import { SettingContainer } from "../../pages/setting/setting.container"
import { EmailContainer } from "../../pages/email/email.container"
import { ManagementWalletRechargeContainer } from "../../pages/managementWalletRecharge/managementWalletRecharge.container"
import { FunctionEnum } from "../enum/function.enum"
import { ManagementUserChargeHistoryContainer } from "../../pages/managementUserChargeHistory/managementUserChargeHistory.container"
import { PromotionPackContainer } from "../../pages/promotionPack/promotionPack.container"
import { AppsContainer } from "../../pages/apps/apps.container"

export interface RouteInterface {
   id: string
   text: string
   icon?: string
   menu?: RouteInterface[]
   link?: string
   breadcrumb?: string
   showOnSidebar: boolean
   permissionKeys: FunctionEnum[]
   element?: any
}

export const routes: RouteInterface[] = [
   {
      id: "chart",
      showOnSidebar: true,
      text: "sidebar.chart",
      icon: "auto_awesome_mosaic",
      link: "/dashboard/chart",
      breadcrumb: "breadcrumbHeader.chart",
      permissionKeys: [
         FunctionEnum.GET_SIGN_IN_CHART_BY_APP,
         FunctionEnum.GET_SIGN_IN_CHART_BY_DATE,
         FunctionEnum.GET_SIGN_IN_CHART_BY_PROVINCE,
         FunctionEnum.GET_SIGN_UP_CHART_BY_APP,
         FunctionEnum.GET_SIGN_UP_CHART_BY_DATE,
         FunctionEnum.GET_SIGN_UP_CHART_BY_PROVINCE,
         FunctionEnum.GET_USING_WALLET_CHART_BY_APP,
         FunctionEnum.GET_USING_WALLET_CHART_BY_DATE,
         FunctionEnum.GET_USING_WALLET_CHART_BY_PROVINCE,
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_APP,
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_DATE,
         FunctionEnum.GET_CHARGE_WALLET_CHART_BY_PROVINCE,
         FunctionEnum.GET_USER_TYPE_CHART,
      ],
      element: ChartContainer,
   },
   {
      id: "managementUser",
      showOnSidebar: true,
      text: "sidebar.managementUser",
      link: "/dashboard/managementUser",
      icon: "account_circle",
      breadcrumb: "breadcrumbHeader.managementUser",
      permissionKeys: [FunctionEnum.GET_LIST_MANAGEMENT_USER],
      element: ManagementUserContainer,
      menu: [
         {
            id: "detail",
            showOnSidebar: false,
            text: "sidebar.managementUserDetail",
            link: "/dashboard/managementUser/detail/:id",
            breadcrumb: "breadcrumbHeader.managementUserDetail",
            permissionKeys: [FunctionEnum.GET_MANAGEMENT_USER_DETAIL],
            element: ManagementUserDetailContainer,
         },
         {
            id: "charge",
            showOnSidebar: false,
            text: "sidebar.managementUserChargeHistory",
            link: "/dashboard/managementUser/charge/:id",
            breadcrumb: "breadcrumbHeader.managementUserChargeHistory",
            permissionKeys: [FunctionEnum.HISTORY_CHARGE_MANAGEMENT_USER],
            element: ManagementUserChargeHistoryContainer,
         },
      ],
   },
   {
      id: "managementWallet",
      showOnSidebar: true,
      text: "sidebar.managementWallet",
      link: "/dashboard/managementWallet",
      icon: "wallet",
      breadcrumb: "breadcrumbHeader.managementWallet",
      permissionKeys: [FunctionEnum.GET_LIST_MANAGEMENT_WALLET],
      element: ManagementWalletContainer,
      menu: [
         {
            id: "recharge",
            showOnSidebar: false,
            text: "sidebar.managementWalletRecharge",
            link: "/dashboard/managementWallet/recharge",
            breadcrumb: "breadcrumbHeader.managementWalletRecharge",
            permissionKeys: [FunctionEnum.NONE],
            element: ManagementWalletRechargeContainer,
         },
      ],
   },
   {
      id: "typeUser",
      showOnSidebar: true,
      text: "sidebar.typeUser",
      icon: "people",
      link: "/dashboard/typeUser",
      breadcrumb: "breadcrumbHeader.typeUser",
      permissionKeys: [FunctionEnum.GET_LIST_USER_TYPE],
      element: TypeUserContainer,
   },
   {
      id: "rechargePack",
      showOnSidebar: true,
      text: "sidebar.recharge",
      icon: "local_atm",
      link: "/dashboard/rechargePack",
      breadcrumb: "breadcrumbHeader.rechargePack",
      permissionKeys: [FunctionEnum.GET_LIST_RECHARGE_PACK],
      element: RechargePackContainer,
   },
   {
      id: "promotionPack",
      showOnSidebar: true,
      text: "sidebar.promotion",
      icon: "price_change",
      link: "/dashboard/promotionPack",
      breadcrumb: "breadcrumbHeader.promotionPack",
      permissionKeys: [FunctionEnum.GET_LIST_PROMOTION_PACK],
      element: PromotionPackContainer,
   },
   {
      id: "department",
      showOnSidebar: true,
      text: "sidebar.department",
      icon: "diversity_3",
      link: "/dashboard/department",
      breadcrumb: "breadcrumbHeader.department",
      permissionKeys: [FunctionEnum.GET_LIST_DEPARTMENT],
      element: DepartmentContainer,
   },
   {
      id: "marketing",
      showOnSidebar: true,
      text: "sidebar.email",
      icon: "email",
      link: "/dashboard/marketing",
      breadcrumb: "breadcrumbHeader.email",
      permissionKeys: [FunctionEnum.GET_LIST_EMAIL],
      element: EmailContainer,
      menu: [
         {
            id: "create",
            showOnSidebar: false,
            text: "sidebar.createEmail",
            link: "/dashboard/marketing/create",
            breadcrumb: "breadcrumbHeader.createEmail",
            permissionKeys: [FunctionEnum.ADD_EMAIL],
            element: CreateEmailContainer,
         },
         {
            id: "edit",
            showOnSidebar: false,
            text: "sidebar.editEmail",
            link: "/dashboard/marketing/edit/:id",
            breadcrumb: "breadcrumbHeader.editEmail",
            permissionKeys: [FunctionEnum.UPDATE_EMAIL],
            element: CreateEmailContainer,
         },
      ],
   },
   {
      id: "apps",
      showOnSidebar: true,
      text: "sidebar.apps",
      icon: "app_registration",
      link: "/dashboard/apps",
      breadcrumb: "breadcrumbHeader.apps",
      permissionKeys: [FunctionEnum.GET_LIST_APPS],
      element: AppsContainer,
   },
   {
      id: "setting",
      showOnSidebar: false,
      text: "sidebar.setting",
      icon: "settings",
      link: "/dashboard/setting",
      breadcrumb: "breadcrumbHeader.setting",
      permissionKeys: [FunctionEnum.NONE],
      element: SettingContainer,
   },
]

const getRoutesFatten = (routesParam: RouteInterface[]): RouteInterface[] => {
   let menu: RouteInterface[] = []

   return routesParam
      .map((route) => {
         if (route.menu && route.menu.length) {
            menu = [...menu, ...route.menu]
         }
         return route
      })
      .concat(menu.length ? getRoutesFatten(menu) : menu)
      .filter((route) => route.link && route.element)
}

export const availableRoutes: RouteInterface[] = getRoutesFatten(routes)
