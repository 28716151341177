import { DepartmentUserInterface } from "./../../common/interfaces/department.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PagingState } from "../../common/interfaces/pagingParam.interface"
import { SearchConditionState } from "../../common/interfaces/searchConditionParam"
import { FilterTableEnum } from "../../common/enum/filterTable.enum"

export interface DepartmentUserState {
   selectedId: string
   requesting: boolean
   successful: boolean
   error: string
   data: DepartmentUserInterface[]
   rowCount: number
   paging: PagingState
   searchCondition: SearchConditionState
}

const initialState: DepartmentUserState = {
   selectedId: "",
   requesting: false,
   successful: false,
   error: "",
   data: [],
   rowCount: 0,
   paging: {
      pageNumber: 1,
      pageSize: 10,
   },
   searchCondition: {
      option: FilterTableEnum.NONE,
      value: "",
   },
}

export const departmentUserSlice = createSlice({
   name: "departmentUser",
   initialState,
   reducers: {
      requestDepartmentUser: (
         state: DepartmentUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = true
         if (state.selectedId !== action.payload) {
            state.paging = {
               pageNumber: 1,
               pageSize: 10,
            }
            state.searchCondition = {
               option: FilterTableEnum.NONE,
               value: "",
            }
         }
         state.selectedId = action.payload
      },
      getDepartmentUserSuccess: (
         state: DepartmentUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = true
         state.error = ""
         state.data = action.payload.data
         state.rowCount = action.payload.rowCount
      },
      getDepartmentUserFail: (
         state: DepartmentUserState,
         action: PayloadAction<any>
      ) => {
         state.requesting = false
         state.successful = false
         state.error = action.payload
         state.data = []
      },
      changePageNumberSuccess: (
         state: DepartmentUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = action.payload
      },
      changePageSizeSuccess: (
         state: DepartmentUserState,
         action: PayloadAction<number>
      ) => {
         state.paging.pageNumber = 1
         state.paging.pageSize = action.payload
      },
      changeSearchConditionSuccess: (
         state: DepartmentUserState,
         action: PayloadAction<any>
      ) => {
         state.paging.pageNumber = 1
         state.searchCondition.option = action.payload.option
         state.searchCondition.value = action.payload.value
      },
      resetDepartmentUser: (state: DepartmentUserState) => {
         state.selectedId = ""
         state.requesting = false
         state.successful = false
         state.error = ""
         state.data = []
         state.rowCount = 0
         state.paging = {
            pageNumber: 1,
            pageSize: 10,
         }
         state.searchCondition = {
            option: FilterTableEnum.NONE,
            value: "",
         }
      },
   },
})

export const {
   requestDepartmentUser,
   getDepartmentUserSuccess,
   getDepartmentUserFail,
   changePageNumberSuccess,
   changePageSizeSuccess,
   changeSearchConditionSuccess,
   resetDepartmentUser,
} = departmentUserSlice.actions

export default departmentUserSlice.reducer
