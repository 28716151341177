import { AppsStatus } from "../enum/appsStatus.enum"

export const getLabelAppsStatus = (status: AppsStatus) => {
   if (status === AppsStatus.ACTIVE) {
      return "Hoạt động"
   }
   if (status === AppsStatus.PAUSE) {
      return "Tạm dừng"
   }
   return ""
}
