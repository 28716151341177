import { useCallback, useEffect, useState } from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Link from "@mui/material/Link"
import Icon from "@mui/material/Icon"
import Typography from "@mui/material/Typography"
import { useLocation, useNavigate } from "react-router-dom"
import { RouteInterface, routes } from "../../variables/route"
import { useTranslation } from "react-i18next"
import { namespaces } from "../../../i18n/i18n.constants"

export const BreadcrumbComponent = () => {
   const location = useLocation()
   const navigate = useNavigate()
   const { t } = useTranslation(namespaces.common)
   const [breadcrumb, setBreadcrumb] = useState<RouteInterface[]>([])

   /**
    * Get breadcrumbs
    * @param paths
    * @param array
    * @param index
    * @returns
    */
   const getBreadcrumb = useCallback(
      (
         paths: string[],
         array: RouteInterface[],
         index: number
      ): RouteInterface[] => {
         let breadcrumbs = []
         if (index && index + 1 > paths.length) {
            return []
         } else {
            const config = array.filter((e) => e.id === paths[index])[0]
            breadcrumbs.push(config)
            if (config?.menu) {
               breadcrumbs.push(getBreadcrumb(paths, config.menu, index + 1))
            }
         }
         return breadcrumbs.flatMap((e) => e)
      },
      []
   )

   /**
    * On click link
    * @param link
    * @returns
    */
   const onClickLink = (link: string | undefined) => {
      if (link) {
         if (link.includes(":id")) {
            return
         }
         navigate(link)
      }
   }

   useEffect(() => {
      const paths = location.pathname
         .slice(1, location.pathname.length)
         .split("/")
      paths.shift()
      setBreadcrumb(getBreadcrumb(paths, routes, 0))
   }, [location, getBreadcrumb])

   return (
      <div>
         <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
         >
            {breadcrumb.map(
               (crumb, key) =>
                  crumb && (
                     <Link
                        key={key}
                        underline="hover"
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           cursor: "pointer",
                        }}
                        color="inherit"
                        onClick={() => onClickLink(crumb.link)}
                     >
                        {crumb.icon && (
                           <Icon sx={{ mr: 0.5 }}>{crumb.icon}</Icon>
                        )}
                        {t(crumb.text)}
                     </Link>
                  )
            )}
         </Breadcrumbs>
         <Typography
            mt={1}
            sx={{ typography: { md: "h4", xs: "h5" } }}
            gutterBottom
         >
            {t(breadcrumb[breadcrumb.length - 1]?.breadcrumb as string)}
         </Typography>
      </div>
   )
}
